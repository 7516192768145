import React from 'react';
import styles from "./bannerPpvStrip.module.css"
import facebook from "../../../assets/images/facebookwhite.svg";
import twitter from "../../../assets/images/twitterwhite.svg";
import instagram from "../../../assets/images/instawhite.svg";
import linkedin from "../../../assets/images/linkedinwhite.svg";
import { NEW_URL } from '../../../constants/applicationConstants';

export const BannerPpvStrip = (props) => {

    const {
        bannerBG,
        profileData
    } = props;


    return (
        <section>
<div className={`d-none d-sm-block`}>
            <div className={[styles.imageorlogo + " " + `row`]}>
            
                <div className={[`col-lg-4 col-md-4 col-sm-12`]}> <p className={styles.title}>{profileData?.name.toLowerCase()}</p> </div>
                <div className={[`col-lg-4 col-md-4 col-sm-12` + " " + `d-flex`]}>
                    <a href={profileData?.website} target="_blank" rel="noreferrer">
                        <p className={styles.url}>{profileData?.website}</p>
                    </a>
                </div>
                <div className={[styles.iconsection + " " + `col-lg-4 col-md-4 col-sm-12`]} >
                    <img className={styles["company-icon"]} src={!profileData?.imageUrl
                        ? `https://ui-avatars.com/api/?name=${profileData?.name}&rounded=true`
                        : `${NEW_URL}/${profileData?.imageUrl}`}
                        alt={profileData?.name} />
                    {profileData?.socialMedia.facebookUrl && <a href={profileData?.socialMedia.facebookUrl} target="_blank" rel="noreferrer">
                        <div className={[styles.icondiv]}>
                            <img className={styles["social-icon"]} src={facebook} alt={'facebook'} />
                        </div>
                    </a>}
                    {profileData?.socialMedia.instagramUrl && <a href={profileData?.socialMedia.instagramUrl} target="_blank" rel="noreferrer">
                        <div className={[styles.icondiv]}>
                            <img className={styles["social-icon"]} src={instagram} alt={'instagram'} />
                        </div>
                    </a>}
                    {profileData?.socialMedia.linkedinUrl && <a href={profileData?.socialMedia.linkedinUrl} target="_blank" rel="noreferrer">
                        <div className={[styles.icondiv]}>
                            <img className={styles["social-icon"]} src={linkedin} alt={'linkedin'} />
                        </div>
                    </a>}
                    {profileData?.socialMedia.twitterUrl && <a href={profileData?.socialMedia.twitterUrl} target="_blank" rel="noreferrer">
                        <div className={[styles.icondiv]}>
                            <img className={styles["social-icon"]} src={twitter} alt={'twitetr'} />
                        </div>
                    </a>}
                </div>
            </div>
            </div>
            <div className={[styles.imageorlogo + " " + `row` + " " + `d-block d-lg-none d-sm-none d-md-none `]}>
                <div className="d-flex">
                <div className="inlogoppv">   <img className={styles["company-icon"]} src={!profileData?.imageUrl
                    ? `https://ui-avatars.com/api/?name=${profileData?.name}&rounded=true`
                    : `${NEW_URL}/${profileData?.imageUrl}`}
                    alt={profileData?.name} /></div>
               <div className={styles.pstite}> 
                {profileData?.name}
                <a href={profileData?.website} target="_blank" rel="noreferrer" className={styles.psweb}>
                   {profileData?.website}
                </a>
                <div className={[styles.iconsection + " " + `mt-2`]} >

                    {profileData?.socialMedia.facebookUrl && <a href={profileData?.socialMedia.facebookUrl} target="_blank" rel="noreferrer">
                        <div className={[styles.icondiv]}>
                            <img className={styles["social-icon"]} src={facebook} alt={'facebook'} />
                        </div>
                    </a>}
                    {profileData?.socialMedia.instagramUrl && <a href={profileData?.socialMedia.instagramUrl} target="_blank" rel="noreferrer">
                        <div className={[styles.icondiv]}>
                            <img className={styles["social-icon"]} src={instagram} alt={'instagram'} />
                        </div>
                    </a>}
                    {profileData?.socialMedia.linkedinUrl && <a href={profileData?.socialMedia.linkedinUrl} target="_blank" rel="noreferrer">
                        <div className={[styles.icondiv]}>
                            <img className={styles["social-icon"]} src={linkedin} alt={'linkedin'} />
                        </div>
                    </a>}
                    {profileData?.socialMedia.twitterUrl && <a href={profileData?.socialMedia.twitterUrl} target="_blank" rel="noreferrer">
                        <div className={[styles.icondiv]}>
                            <img className={styles["social-icon"]} src={twitter} alt={'twitetr'} />
                        </div>
                    </a>}
                </div>
           </div> 
           </div>
            </div>
        </section>
    )
}

export default BannerPpvStrip;