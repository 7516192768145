import React from 'react'
import { Link } from "react-router-dom";

import swal from "sweetalert";
import homedashboard from "../../../../assets/images/homedashboard.svg";
import dashboardmenu from "../../../../assets/images/dashboardmenu.svg";
import offerdashboard from "../../../../assets/images/offerdashboard.svg";
import asktheexpertdashboard from "../../../../assets/images/asktheexpertdashboard.svg";
import articledashboard from "../../../../assets/images/articledashboard.svg";
import intarestdashboard from "../../../../assets/images/intarestdashboard.svg";
import accountsettingdashboard from "../../../../assets/images/accountsettingdashboard.svg";
import logoutdashboard from "../../../../assets/images/logoutdashboard.svg";
import { useHistory } from "react-router-dom";
import { useTranslation } from 'react-i18next';

const SideBarsJsx = (props) => {
  const {t} =useTranslation()
  const history = useHistory();

  const {
    response
  } = props;

  const logout = () => {
    swal({
      title: "Are you sure you want to Logout ?",
      icon: "warning",
      buttons: [
        'No',
        'Yes'
      ],
      dangerMode: true,
    }).then(function (isConfirm) {
      if (isConfirm) {
        sessionStorage.removeItem("token");
        sessionStorage.removeItem("loginAs");
        sessionStorage.removeItem("auth");
        sessionStorage.removeItem("masterID");
        sessionStorage.removeItem("expertId");
        sessionStorage.removeItem("token");
        sessionStorage.removeItem("email");
        sessionStorage.removeItem("loginData");
        sessionStorage.removeItem("expertData");
        sessionStorage.removeItem("customerId");

        sessionStorage.removeItem("planName");
        sessionStorage.removeItem("legalName");
        sessionStorage.removeItem("isLoggedIn");
        sessionStorage.removeItem("description");
        sessionStorage.removeItem("flowName");
        sessionStorage.removeItem("flowType");
        sessionStorage.removeItem("subscriptionPlanId");
        sessionStorage.removeItem("planTypeID");
        sessionStorage.removeItem("planData");
        localStorage.removeItem("flowName");
        localStorage.removeItem("flowType");
        localStorage.removeItem("verificationStatus");
        localStorage.removeItem('VerifyInstituteID');
        localStorage.removeItem('AdminFlow');
        localStorage.removeItem('VerifyExpertID');
        sessionStorage.removeItem("comingFrom");

        history.push("/");
        window.location.reload()
      } else {
        //   swal("Cancelled", "Your imaginary file is safe :)", "error");
      }
    })

  }
  // {t("PRINT.TITLE")}
  return (
    <>
     <div className="dashboard-container maincustomerdashboard">
       <div className="dashboardleftpanel">
      <div className="text-column">
      {/* <div className="responsename">
        <div className="dashboardlogo">
          <img
            src={!response?.imageUrl
              ? `https://ui-avatars.com/api/?name=${response?.firstName}+${response?.lastName}&rounded=true`
              : `${NEW_URL}/${response?.imageUrl}`}
            alt={''} 
          />
        </div>
        {`${response?.firstName} ${response?.lastName}`}
      </div> */}
      <div className="d-block d-lg-none d-sm-none d-md-none ">
      <nav className="navbar">
    <button class="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarText" aria-controls="navbarText" aria-expanded="false" aria-label="Toggle navigation">
      <span class="navbar-toggler-icon"><img src={dashboardmenu} alt="menuicon"/></span>
    </button>
    <div class="collapse navbar-collapse" id="navbarText">
      <ul className="navbar-nav dashboardinner">
         <li className="nav-item">
            <Link to={'/customerDashboard'} className={`${window.location.href?.toLowerCase().includes('customerdashboard') ? 'active' : ''}`}>
            <p className={`${window.location.href?.toLowerCase().includes('customerdashboard') ? 'activetext' : ''}`}> <span className="menusideimage"><img className="leftborder" src={homedashboard} alt={''} /></span>  {t("customersidebar.HOME")}</p> 
            </Link>
          </li>
          <li className="nav-item">
            <Link to={'/suggestedoffer'}  className={`${window.location.href?.toLowerCase().includes('suggestedoffer') ? 'active' : ''}`}>
                <p className={`${window.location.href?.toLowerCase().includes('suggestedoffer') ? 'activetext' : ''}`}><span className="menusideimage"><img className="leftborder" src={offerdashboard} alt={''} /></span> {t("customersidebar.OFFERS")}</p>
            </Link>
          </li>
          <li className="nav-item">
            <Link to={'/customerAskTheExpert'} className={`${window.location.href?.toLowerCase().includes('customerasktheexpert') ? 'active' : ''}`}>
                <p className={`${window.location.href?.toLowerCase().includes('customerasktheexpert') ? 'activetext' : ''}`}><span className="menusideimage"><img className="leftborder" src={asktheexpertdashboard} alt={''} /></span> {t("customersidebar.ASKTHEEXPERTS")}</p>
            </Link>
          </li>
          <li className="nav-item">
            <Link to={'/customerBlogs'}  className={`${window.location.href?.toLowerCase().includes('customerblogs') ? 'active' : ''}`}>
               <p className={`${window.location.href?.toLowerCase().includes('customerblogs') ? 'activetext' : ''}`}> <span className="menusideimage"><img className="leftborder" src={articledashboard} alt={''} /></span> {t("customersidebar.ARTICLES")}</p>
            </Link>
          </li>
          <li className="nav-item">
            <Link to={'/customerInterest'} className={`${window.location.href?.toLowerCase().includes('customerinterest') ? 'active' : ''}`}>
               <p className={`${window.location.href?.toLowerCase().includes('customerinterest') ? 'activetext' : ''}`}> <span className="menusideimage"><img className="leftborder" src={intarestdashboard} alt={''} /></span> {t("customersidebar.INTERESTS")}</p>
            </Link>
          </li>
          <li className="nav-item">
            <Link to={'/customerAccounts'} className={`${window.location.href?.toLowerCase().includes('customeraccounts') ? 'active' : ''}`}>
               <p className={`${window.location.href?.toLowerCase().includes('customeraccounts') ? 'activetext' : ''}`}> <span className="menusideimage"><img className="leftborder" src={accountsettingdashboard} alt={''} /></span> {t("customersidebar.ACCOUNTSETTING")}</p>
            </Link>
          </li>
          <li className="nav-item" onClick={logout}>
            <a>
              <p><span className="menusideimage"><img className="leftborder" src={logoutdashboard} alt={''} /></span> {t("customersidebar.LOGOUT")}</p>
            </a>
          </li>
        
      </ul>
    </div>

</nav>
      </div>
      <div className="dashboardlink dashboardtext mydash d-none d-sm-block">
        <ul className="dashboardinner">
          <li >
            <Link to={'/customerDashboard'}>
            <span className="menusideimage"><img className="leftborder" src={homedashboard} alt={''} /></span>  <p className={`${window.location.href?.toLowerCase().includes('customerdashboard') ? 'activetext' : ''}`}>{t("customersidebar.HOME")}</p> 
            </Link>
          </li>
          <li >
            <Link to={'/suggestedoffer'}>
                <span className="menusideimage"><img className="leftborder" src={offerdashboard} alt={''} /></span><p className={`${window.location.href?.toLowerCase().includes('suggestedoffer') ? 'activetext' : ''}`}>{t("customersidebar.OFFERS")}</p>
            </Link>
          </li>
          <li >
            <Link to={'/customerAskTheExpert'}>
                <span className="menusideimage"><img className="leftborder" src={asktheexpertdashboard} alt={''} /></span><p className={`${window.location.href?.toLowerCase().includes('customerasktheexpert') ? 'activetext' : ''}`}>{t("customersidebar.ASKTHEEXPERTS")}</p>
            </Link>
          </li>
          <li >
            <Link to={'/customerBlogs'}>
               <span className="menusideimage"><img className="leftborder" src={articledashboard} alt={''} /></span> <p className={`${window.location.href?.toLowerCase().includes('customerblogs') ? 'activetext' : ''}`}>{t("customersidebar.ARTICLES")}</p>
            </Link>
          </li>
          <li >
            <Link to={'/customerInterest'}>
               <span className="menusideimage"><img className="leftborder" src={intarestdashboard} alt={''} /></span> <p className={`${window.location.href?.toLowerCase().includes('customerinterest') ? 'activetext' : ''}`}>{t("customersidebar.INTERESTS")}</p>
            </Link>
          </li>
          <li >
            <Link to={'/customerAccounts'}>
               <span className="menusideimage"><img className="leftborder" src={accountsettingdashboard} alt={''} /></span> <p className={`${window.location.href?.toLowerCase().includes('customeraccounts') ? 'activetext' : ''}`}>{t("customersidebar.ACCOUNTSETTING")}</p>
            </Link>
          </li>
          <li onClick={logout}>
            <a>
              <span className="menusideimage"><img className="leftborder" src={logoutdashboard} alt={''} /></span><p>{t("customersidebar.LOGOUT")}</p>
            </a>
          </li>
        </ul>
      </div>
    </div>
  </div>
</div>


    </>

  );
};

export default SideBarsJsx;