import React from 'react';
import styles from "./productCard.module.css"
import { useTranslation } from "react-i18next";
import { NEW_URL } from '../../../constants/applicationConstants'; 


export const ProductCard = (props) => {
  const { t } = useTranslation();

  const {
    product,
    viewbuttonURL,
    isCategorySubCategory,
    isProductName,
    isViewButton,
    onProductClick, 
    catname,
    institutionCategory,
    subInstitutionCategory

  } = props;



  return (
    (onProductClick ?? "") != "" ?
      <div className={[`p_sliderouter` + " " + `product_main` + " " + `exclusivepadding`]} onClick={() => onProductClick(product)}>
        <div className={[`p_slider` + " " + `product_inner`]}>
          <div className={['sldeimage' + " " + 'product_image']}>
           
            <img src={`${NEW_URL}/${product.imageUrl}`} alt={''} />
           
          </div>
        </div>
      </div> :

      <div class={`${product?.parentInstitutionCategory ?? product?.institutionCategoryName ?? catname} mt-4 mb-4 categoryproductsliderinner`}>
        <div>
          {isCategorySubCategory && <div className="parentcatname">{product?.parentInstitutionCategory ?? product?.institutionCategoryName ?? catname}</div>}
          {isCategorySubCategory && <div className="productcatname d-none d-sm-block ">{product?.subInstitutionCategory?.name ?? product?.institutionSubCategoryName ?? subInstitutionCategory}</div>}
          <div className={[styles["image-decoration"] + " " + 'categoryproductsliderinnerimage']} >
            <img src={`${NEW_URL}/${product?.imageUrl}`} alt={'product image'} />
          </div>
          {isCategorySubCategory && <div className="productcatname d-block d-lg-none d-sm-none d-md-none">{product?.subInstitutionCategory?.name ?? product?.institutionSubCategoryName ?? subInstitutionCategory}</div>}
          {isProductName && <p className={[styles.productsName + " " + `text-center mt-3 mb-3 ${product?.parentInstitutionCategory ?? product?.institutionCategoryName}-text`]}>{product?.productName}</p>}
          {isViewButton && <div className="slbtn">
            <div className={[styles.viewbutton]}>
              <button onClick={viewbuttonURL} class={`viewbutton ${product?.parentInstitutionCategory ?? product?.institutionCategoryName}-viewbutton`}>{t("COMMON.VIEWDETAILS")}</button>
            </div>
          </div >
          }
        </div >

      </div>)
}

export default ProductCard;