import React from 'react'
import { NEW_URL } from '../../../../constants/applicationConstants'; import { Pagination } from '../../../common/pagination/pagination';
import { Footer } from '../../../common/footer/footer';
import { BannerPpvStrip } from "../../../common/bannerPpvStrip/bannerPpvStrip.jsx";
import bannerBG from "../../../../assets/Instituteppvimages/bannerbg.svg";
import { useTranslation } from "react-i18next";
import { AdsCard } from '../../../common/adsCard/adsCard.jsx';

const ListOfAdsJsx = (props) => {
  const { t } = useTranslation();
  const {
    profileData,
    adsData,
    onPaginationButtonClick,
    fromPage
  } = props;

  return (
    <>
      <section className="entireexpertppv listofoffermainsection">
          <BannerPpvStrip bannerBG={bannerBG} profileData={profileData} />   
        <div className="container">
          <div className="expppvofferinnersection" id="myAds">
            <div className="heading hedingpading">
              
              <p className="ads-hedding">{fromPage ? (fromPage.includes('expertPpv') ? t("PPVEXPERT.ADSHEADING") : t("PPVINSTITUTE.ADSTITLE")) : ''}</p>
              <h5 className="h5_style">{fromPage ? (fromPage.includes('expertPpv') ? t("PPVEXPERT.ADSSUBHEADINGONE") : t("PPVINSTITUTE.ADSSUBTITLE1")) : ''} </h5>
              <h5 className="h5_style">{fromPage ? (fromPage.includes('expertPpv') ? t("PPVEXPERT.ADSSUBHEADINGTWO") : t("PPVINSTITUTE.ADSSUBTITLE2")) : ''}</h5>
            </div>

            <div className="row ">

              {
                adsData?.data?.map(ad => {
                
                  return <div className="col-lg-6 col-md-6 col-sm-6 col-xs-12">
                    <AdsCard
                      imageUrl={`${NEW_URL}/${ad.imageUrl}`}
                      description={ad.description}
                      category={ad?.subInstitutionCategory?.name}
                      externalLink={ad.externalLink}
                      categoryname={ad.institutionCategoryId}
                    />
                  </div>

                })
              }

              {adsData?.data?.length === 0 && <div>No Record Found</div>}

              {adsData?.data?.length > 0 &&  <section className="newpagination">  <Pagination
                limit={10}
                totalCount={adsData?.meta?.totalCount}
                onPaginationButtonClick={onPaginationButtonClick}
              /></section>}
 

            </div>

          </div>
        </div>
      </section>
      <Footer />

    </>

  );
};

export default ListOfAdsJsx;
