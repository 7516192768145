import React, { useEffect, useState } from 'react';
import styles from './expertPpvSpecialization.module.css';
import { NEW_URL } from '../../../../constants/applicationConstants';
import { useTranslation } from "react-i18next";
import SpecializationCard from './specializationCard';
export const ExpertSpecialization = (props) => {
    const { t } = useTranslation();
    const { profileData } = props;
    const [showData, setShowData] = useState({})
    const [activeIndex, setActiveIndex] = useState(null);
    const onAnsweredQuestionClick = (data, index) => {
        setShowData(data)
        setActiveIndex(index);
    }
    useEffect(() => {
        onAnsweredQuestionClick(profileData?.expertSpecializations[0])
        setActiveIndex(0);
    }, [profileData?.expertSpecializations])
    return (
        <section className="specialisationexpertppv">
            <div className="container">
                <div className="heading">
                    <h2>{t("PPVEXPERT.SPECIALIZATIONTITLE")}</h2>
                    <h5 className="text-center">{t("PPVEXPERT.SPECIALIZATIONSUBTITLE")}</h5>                   
                    <h5 className="text-center">{t("PPVEXPERT.SPECIALIZATIONLINEONE")}</h5>
                    <h5 className="text-center">{t("PPVEXPERT.SPECIALIZATIONLINETWO")} </h5>
                </div>
            </div>
         
            <div className="container">
                <div className={[styles.myexpertnavnewdesign + " " + 'd-flex' + " " + 'globalexpertspecification']}>
                    <ul>
                        {
                            profileData?.expertSpecializations?.map((expertSpec, index) => {
                                return (
                                    <>
                                        <li className={`${activeIndex === index ? 'active' : ''} ${expertSpec?.parentInstitutionCategory} category-id${expertSpec?.institutionCategoryId}`}
                                            onClick={() => onAnsweredQuestionClick(expertSpec, index)}><div className="expiconletter d-none d-lg-none d-sm-none d-md-none ">{expertSpec?.parentInstitutionCategory}</div><div className="expicontab d-block d-lg-none d-sm-none d-md-none "><img src={`${NEW_URL}/${expertSpec?.icon}`}/></div><span>{expertSpec?.parentInstitutionCategory}</span></li>
                                    </>
                                )
                            })
                        }
                    </ul>
                    {
                        profileData?.expertSpecializations?.map((expertSpec, index) => {
                            return (
                                <>
                                   <SpecializationCard 
                                    showData={showData}
                                    expertSpec={expertSpec}
                                    activeIndex={activeIndex}
                                    index={index}
                                   />
                                </>
                            )
                        })
                    }
                </div>
            </div>
        </section>
    )
}
