import React from 'react';
import { Link } from 'react-router-dom/cjs/react-router-dom.min';
import Slider from 'react-slick'; 
import { useTranslation } from "react-i18next";
import {sliderSettingFour} from "../../../shared/sliderSetting.js"
import styles from './institutionCardList.module.css';
import { InstitutionCard } from '../institutionCard/institutionCard.jsx';

export const InstitutionCardList = (props) => {
  const { t } = useTranslation();

  const {
    searchedData,
    search,
    activeInstitutes
  } = props;

  

    return (
        <section className="ourbest ourbestexpertsearch">
                <div className="container">
                    <div className="ourbexttext" >
                        <h2 className="text-center">“{searchedData}” {t("COMMON.ININSTITUTION")}</h2>
                    </div>
                    <Slider {...sliderSettingFour}>
                        {
                            activeInstitutes?.map(data => {
                                return <InstitutionCard data={data} offerplanName={data?.planName}/>
                            })
                        }
                    </Slider>
                    <div className="showallourbesttwo text-center mb-3 mb-5">
                        <Link to={`/globalMainSearchInstitutions?search=${search}`}
                            className={`${styles.showallourbesttoo} mt-5 mb-4`}>{t("COMMON.SHOWALL")}</Link>
                    </div>
                </div>
            </section>
    )
}
