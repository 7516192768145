import React, { useState } from 'react'
import Sign_out_squre from "../../../../assets/images/Sign_out_squre.svg";
import { Trans, useTranslation } from 'react-i18next';
import { Footer } from '../../../common/footer/footer';
import { ArticlesCardJSX } from '../../../common/articlesCard/articlesCard';
import { Pagination } from '../../../common/pagination/uiPagination.js';
import article_byyou from "../../../../assets/images/article-byyou.svg";
import suggested_article from "../../../../assets/images/suggested_article.svg"
import dropbox from "../../../../assets/images/dropbox.svg";
import Sidebar from "../sidebar/sidebar.js";


const ExpertBlogJsx = (props) => {
  const { t } = useTranslation();
  const {
    blogData, onPaginationButtonClick,
    onBlogTypeClick, blogType,page
  } = props;

  const itemsPerPage = 12

  const [currentPage, setCurrentPage] = useState(1);

  const currentItems = blogData?.body?.slice(
    (page - 1) * itemsPerPage,
    page * itemsPerPage
  );
  const [openModal, setOpenModal] = useState(false);
  const [blog, setBlog] = useState({});

  return (
    <>
      <section className="main_customer_dashboard">
        <section className="entiredashboard maincustomerdash expert-dashboard">
          <div className="container-fluid">
            <div className="row">
              <div className="col-lg-4 col-sm-4">
                <div className="dashboardleftpanel martop40">
                  <div className="dashboardlinkpop martop40">
                    <Sidebar />
                  </div>
                </div>
              </div>
              <div className="col-lg-8 col-sm-8">
                <div className="dashboardrightpanel mb-5">
                  <div className="dashboardrightpannerinner">
                    <div className="dashboardrightpannerinnerleft">
                      <h2 className="h2_style font-weight-600">{t("expertDashboard.ARTICLES_HEADING")}</h2>
                      <h5 className="h5_style"><Trans i18nKey="expertDashboard.ARTICLES_TEXT"></Trans></h5>

                    </div>
                    <div className="dashboardrightpannerinnerright">
                      <div className="signout">
                        <a href="#logout">
                          <img src={Sign_out_squre} alt={''} /><span className="title">Logout</span>
                        </a>
                      </div>
                      <div id="logout" className="modal-window">
                        <div>
                          <a title="Close" className="modal-close">Close</a>
                          <div className="modal-header">

                          </div><div className="modal-body mb-4">
                            <div className="modal-body-inner">
                              <h2 className="h2_style text-center">Are you sure you want to
Logout ?</h2>
                            </div>
                          </div>
                          <div className="modal-footer">
                            <button type="button" className="ctaonebutton"> Save</button>
                            <button type="button" className="ctatwobutton"> Save</button>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="myexpertnav myaccountnav qnanav martop40 expertmain">
                    <ul>
                      <li className={`${blogType === 'ArticleByYou' && "active"}`} onClick={() => onBlogTypeClick('ArticleByYou')}><div className="micon"><img src={article_byyou} alt='byyou'/></div>{t("expertDashboard.ARTICLES_TABONE")}</li>
                      <li className={`${blogType === 'Suggested' && "active"}`} onClick={() => onBlogTypeClick('Suggested')}><div className="micon"><img src={suggested_article} alt='suggested'/></div>{t("expertDashboard.ARTICLES_TABTWO")}</li>
                    </ul>
                  </div>
                  <div className="expertblogtextback">
                    <h3>{t("expertDashboard.EXPERTAUTHOR")}</h3>
                    <h5>{t("expertDashboard.EXPERTAUTHOR_TEXT")}</h5>
                    <div className="row">
                      {
                        currentItems?.length > 0 ?
                          currentItems?.map(blog => {
                            return <div className={`col-lg-3 col-md-3 col-sm-6 p-0 blogblogmain ${blog.cats[0].cat_name?.replace(/\s+/g, '')}`} key={blog.id}>
                              <div className={`basicstructure ${blog.cats[0]?.name}`}>
                                <ArticlesCardJSX blogslug={blog?.slug}
                                  blogimage={blog?.image}
                                  blogtitle={blog?.title}
                                  blogcat={blog?.cats.map((cat) => cat.name).join(',')}
                                  articleauthor={blog?.author}
                                  articledatepublish={blog?.date_publish}
                                  isSerchArticle={false} /></div>
                            </div>

                          }) :

                          <div className="dropdefault text-center">
                            <div className="dropdefaultimage">
                              <img src={dropbox} alt={''} />
                            </div>
                            <div className="dropdefaulttext">
                              <h5>You don't have any Blogs yet. </h5>
                            </div>
                          </div>
                      }

                     <section className="newpagination"> {currentItems?.length > 0 && <Pagination
                        currentPage={currentPage}
                        page={page}
                        allOffersData={blogData?.body}
                        currentItems={currentItems}
                        itemsPerPage={itemsPerPage}
                        onPaginationButtonClick={onPaginationButtonClick}
                      />}
</section>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section><Footer />
      </section>
      
    </>

  );
};

export default ExpertBlogJsx;