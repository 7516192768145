import React, { useEffect, useState } from 'react'
import homedashboard from "../../../../assets/images/homedashboard.svg";
import articleexpertdashboard from "../../../../assets/images/articleexpertdashboard.svg";
import mypaymentdashboard from "../../../../assets/images/mypaymentdashboard.svg"
import asktheexpertdashboard from "../../../../assets/images/asktheexpertdashboard.svg";
import articledashboard from "../../../../assets/images/articledashboard.svg";
import usermanualdashboard from "../../../../assets/images/usermanualdashboard.svg";
import accountsettingdashboard from "../../../../assets/images/accountsettingdashboard.svg";
import logoutdashboard from "../../../../assets/images/logoutdashboard.svg";
import { Link } from "react-router-dom";
import { NEW_URL, baseURL } from '../../../../constants/applicationConstants';
import swal from "sweetalert";

import { useHistory } from "react-router-dom";
import axios from 'axios'
import { useTranslation } from "react-i18next";

const SideBarsJsx = (props) => {

  const { t } = useTranslation();
  const history = useHistory();
  const [state, setState] = useState()

  const {
    response
  } = props;

  useEffect(() => {
    callDetails()
  }, []);

  const callDetails = () => {
    let token = sessionStorage.getItem("token") && JSON.parse(sessionStorage.getItem("token"))
    let expertID = sessionStorage.getItem("masterID") && JSON.parse(sessionStorage.getItem("masterID"))
    const adminLoginData = sessionStorage.getItem('adminLoginData');

    axios.get(`${baseURL}/expert/${expertID}/details`, {
      headers: { 'x-access-token': token }
    }).then(res => {

      setState(res.data.data)
      if (!adminLoginData && !res.data?.data?.isPermanentPasswordSet) {
        history.push("/saveNewPassword");
        // window.location.reload()
      }

    }).catch(err => {
    })
  }

  const logout = () => {
    swal({
      title: "Are you sure you want to Logout ?",
      icon: "warning",
      content: {
        element: "img",
        attributes: {
          src: logoutdashboard,
          alt: "Custom image",
          width: "100",
          height: "100"
        }
      },
      buttons: [
        'Continue',
        'Logout'
      ],
      dangerMode: true,
    }).then(function (isConfirm) {
      if (isConfirm) {
        sessionStorage.removeItem("token");
        sessionStorage.removeItem("loginAs");
        sessionStorage.removeItem("auth");
        sessionStorage.removeItem("masterID");
        sessionStorage.removeItem("expertId");
        sessionStorage.removeItem("token");
        sessionStorage.removeItem("email");
        sessionStorage.removeItem("loginData");
        sessionStorage.removeItem("expertData");
        sessionStorage.removeItem("customerId");

        sessionStorage.removeItem("planName");
        sessionStorage.removeItem("legalName");
        sessionStorage.removeItem("isLoggedIn");
        sessionStorage.removeItem("description");
        sessionStorage.removeItem("flowName");
        sessionStorage.removeItem("flowType");
        sessionStorage.removeItem("subscriptionPlanId");
        sessionStorage.removeItem("planTypeID");
        sessionStorage.removeItem("planData");
        localStorage.removeItem("flowName");
        localStorage.removeItem("flowType");
        localStorage.removeItem("verificationStatus");
        localStorage.removeItem('VerifyInstituteID');
        localStorage.removeItem('AdminFlow');
        localStorage.removeItem('VerifyExpertID');
        sessionStorage.removeItem("comingFrom");

        history.push("/");
        window.location.reload()
      } else {
        //   swal("Cancelled", "Your imaginary file is safe :)", "error");
      }
    })

  }
  // {t("PRINT.TITLE")}
  return (
    <>
      <div className="dashboard-container maincustomerdashboard">
        <div className="dashboardleftpanel">
          <div className="d-block d-lg-none d-sm-none d-md-none ">
            <nav className="navbar">
              <button class="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarText" aria-controls="navbarText" aria-expanded="false" aria-label="Toggle navigation">
                <span class="navbar-toggler-icon"></span>
              </button>
              <div class="collapse navbar-collapse" id="navbarText">
                <ul className="navbar-nav dashboardinner">
                  <li className="nav-item">
                    <Link to={'/expertDashboardHome'} className={`${window.location.href?.toLowerCase().includes('expertdashboardhome') ? 'active' : ""}`}>
                      <span className="menusideimage"><img src={homedashboard} alt={''} /></span>
                      <p className={`${window.location.href?.toLowerCase().includes('expertdashboardhome') ? 'active' : ""}`}>Home</p>
                    </Link>
                  </li>
                  <li className="nav-item"><Link to={'/expertQna'} className={`${window.location.href?.toLowerCase().includes('expertqna') || window.location.href?.toLowerCase().includes('expertanswer') ? 'active' : ""}`}>
                    <span className="menusideimage"><img src={asktheexpertdashboard} alt={''} /></span>
                    <p className={`${window.location.href?.toLowerCase().includes('expertqna') || window.location.href?.toLowerCase().includes('expertanswer') ? 'active' : ""}`}>QnA</p></Link></li>

                  <li className="nav-item"><Link to={'/expertBlog'} className={`${window.location.href?.toLowerCase().includes('expertblog') ? 'active' : ""}`}>
                    <span className="menusideimage"><img src={articleexpertdashboard} alt={''} /></span>
                    <p className={`${window.location.href?.toLowerCase().includes('expertblog') ? 'active' : ""}`}>Articles</p></Link></li>
                  <li className="nav-item"><Link to={'/myAds'} className={`${window.location.href?.toLowerCase().includes('myads') ? 'active' : ""}`}>
                    <span className="menusideimage"><img src={articledashboard} alt={''} /></span>
                    <p className={`${window.location.href?.toLowerCase().includes('myads') ? 'active' : ""}`}>My Ads</p></Link></li>
                  <li className="nav-item"><Link to={'/expertAccounts'} className={`${window.location.href?.toLowerCase().includes('expertaccounts') ? 'active' : ""}`}>
                    <span className="menusideimage"><img src={accountsettingdashboard} alt={''} /></span>
                    <p className={`${window.location.href?.toLowerCase().includes('expertaccounts') ? 'active' : ""}`}>My Account</p></Link></li>
                  <li className="nav-item">
                    <Link to={'/expertPayment'} className={`${window.location.href?.toLowerCase().includes('expertpayment') ? 'active' : ""}`}>
                    <span className="menusideimage"><img src={mypaymentdashboard} alt={''} /></span>
                    <p className={`${window.location.href?.toLowerCase().includes('expertpayment') ? 'active' : ""}`}>My Payment</p>
                    </Link>
                    </li>
                  <li className="nav-item"><a href={'/manual/RIMIB-User-Manual-Experts.pdf'} target="_blank"><span className="menusideimage"><img src={usermanualdashboard} alt={''} /></span><p>User Manual</p></a></li>
                  <li className="nav-item" onClick={logout}><a ><span className="menusideimage"><img src={logoutdashboard} alt={''} /></span><p>Logout</p></a></li>

                </ul>
              </div>

            </nav>
          </div>
          <div className="text-column d-none d-sm-block">
            <div className="userdetails">
              <div className="dashboardlogo"><img src={!response?.imageUrl
                ? `https://ui-avatars.com/api/?name=${response?.firstName}+${response?.lastName}&rounded=true`
                : `${NEW_URL}/${response?.imageUrl}`} alt={`${response?.firstName} ${response?.lastName}`}></img></div>
              <div className="responsename">{`${response?.firstName} ${response?.lastName}`} <Link to={`/expertPpv/${response?.slugUrl}`} target="_blank" rel="noreferrer" className="viewprofilebtn">{t("COMMON.VIEWPROFILE")}</Link></div>
            </div>

            <div className="dashboardlink dashboardtext mydash">
              <ul className="dashboardinner">
                <li>
                  <Link to={'/expertDashboardHome'}>
                    <span className="menusideimage"><img src={homedashboard} alt={''} /></span>
                    <p className={`${window.location.href?.toLowerCase().includes('expertdashboardhome') ? 'active' : ""}`}>Home</p>
                  </Link>
                </li>
                <li><Link to={'/expertQna'}>
                  <span className="menusideimage"><img src={asktheexpertdashboard} alt={''} /></span>
                  <p className={`${window.location.href?.toLowerCase().includes('expertqna') || window.location.href?.toLowerCase().includes('expertanswer') ? 'active' : ""}`}>QnA</p></Link></li>

                <li><Link to={'/expertBlog'}>
                  <span className="menusideimage"><img src={articleexpertdashboard} alt={''} /></span>
                  <p className={`${window.location.href?.toLowerCase().includes('expertblog') ? 'active' : ""}`}>Articles</p></Link></li>
                <li><Link to={'/myAds'}>
                  <span className="menusideimage"><img src={articledashboard} alt={''} /></span>
                  <p className={`${window.location.href?.toLowerCase().includes('myads') ? 'active' : ""}`}>My Ads</p></Link></li>
                <li><Link to={'/expertAccounts'}>
                  <span className="menusideimage"><img src={accountsettingdashboard} alt={''} /></span>
                  <p className={`${window.location.href?.toLowerCase().includes('expertaccounts') ? 'active' : ""}`}>My Account</p></Link></li>
                <li><Link to={'/expertPayment'}>
                  <span className="menusideimage"><img src={mypaymentdashboard} alt={''} /></span>
                  <p className={`${window.location.href?.toLowerCase().includes('expertpayment') ? 'active' : ""}`}>My Payment</p></Link></li>
                <li><a href={'/manual/RIMIB-User-Manual-Experts.pdf'} target="_blank"><span className="menusideimage"><img src={usermanualdashboard} alt={''} /></span><p>User Manual</p></a></li>
                <li onClick={logout}><a ><span className="menusideimage"><img src={logoutdashboard} alt={''} /></span><p>Logout</p></a></li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </>

  );
};

export default SideBarsJsx;