import React from 'react'
import { Footer } from '../../../common/footer/footer';
import { Pagination } from '../../../common/pagination/pagination';
import Sidebar from "../sidebar/sidebar.js";

const OffersJsx = (props) => {
    const {
        offerData,
        wholeData,
        formData,
        onInputChange,
        onTouchHandler,
        onPaginationButtonClick,
        onSubmitButtonClick,        
        handleChange,
        handleChange1,
        handleChange2,
        handleChange3,

        resetPagination
    } = props;

    return (
        <>
            <section className="entiredashboard admindashboard">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-3 col-sm-3">


                            <div className="dashboardleftpanel martop40">

                                <div className="dashboardlink martop40">
                                    <Sidebar />
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-9 col-sm-9 pagination-section">
                            <div className="dashboardrightpanel martop40">
                                <section classNames="addexpertformsec">
                                    <div className="myadssection">
                                        <h2 className="h2_style martop30">Manage Offers</h2>
                                        <p>Morem ipsum dolor sit amet, consectetur adipiscing elit. </p>
                                    </div>
                                    <div className="myexpertformarea searcharea martop40">
                                        <div className="input-container form-group">
                                            <select className="input form-control form-select" placeholder=" " name="type"
                                                onChange={onInputChange}
                                                onBlur={onTouchHandler}>
                                                <option value="Elite">Elite</option>
                                                <option value="Premium">Premium</option>
                                            </select>
                                            <div className="placeholder">Type</div>
                                        </div>
                                        <div className="input-container form-group">
                                            <input type="text" className="form-control input" placeholder=" " name="instituteName"
                                                onChange={onInputChange}
                                                onBlur={onTouchHandler}
                                                value={formData.name} />
                                            <div className="placeholder">Institution Name</div></div>
                                        <div className="input-container form-group">
                                            <input type="text" className="form-control input" placeholder=" " name="productName"
                                                onChange={onInputChange}
                                                onBlur={onTouchHandler}
                                                value={formData.name} />
                                            <div className="placeholder">Name of offer</div>
                                        </div>
                                        <div className="input-container form-group">
                                            <select className="input form-control form-select" placeholder=" " name="homeFeatured"
                                                onChange={onInputChange}
                                                onBlur={onTouchHandler}>
                                                <option value="">All</option>
                                                <option value="true">Yes</option>
                                                <option value="false">No</option>
                                            </select>
                                            <div className="placeholder">Featured</div>
                                        </div>
                                        <div className="input-container form-group">
                                            <button type="submit" className="ctaonebutton" onClick={() => onSubmitButtonClick()}>Search</button>
                                        </div>
                                    </div>
                                    <div className="searchdetails">
                                        <table className="searchdetailsdb">
                                            <tr>
                                                <th>Institution Name</th>
                                                <th>Product Name</th>
                                                <th>Description</th>
                                                <th>Category</th>
                                                {/* <th>Product Details</th>
                                                <th>Commission</th> */}
                                                <th>Featured</th>
                                                <th>Top Offers</th>
                                                <th>Home Featured</th>
                                                <th>Sponsor Offer</th>

                                                <th>Comission</th>
                                                <th>Action</th>
                                            </tr>
                                            {
                                                offerData?.map(data => {
                                                    return <tr>
                                                        <td>{data.instituteName}</td>
                                                        <td>{data.offerName}</td>
                                                        <td>{data.description}</td>
                                                        <td>{data.institutionCategoryName}</td>
                                                        {/* <td><button type="submit" className="ctaonebutton">Product Details</button></td>
                                                        <td><input type="text" className="tabletextbox" /></td>
                                                        <td> <label className="container-checkbox"> <input type="checkbox" className="form-check-input" checked=" " /><span className="checkmark"></span></label><button type="submit" className="ctaonebutton">Save</button></td> */}
                                                        <td><input type="checkbox" checked={data.isFeatured} onChange={e => handleChange(e, data.instituteOfferId)} /> </td>
                                                        <td><input type="checkbox" checked={data.isTopOffer} onChange={e => handleChange1(e, data.instituteOfferId)} /></td>
                                                        <td><input type="checkbox" checked={data.isHomeFeatured} onChange={e => handleChange2(e, data.instituteOfferId)} /> </td>
                                                        <td><input type="checkbox" checked={data.isSponsor} onChange={e => handleChange3(e, data.instituteOfferId)} /> </td>

                                                        <td><input /></td>
                                                        <td><button className="ctaonebutton">Save</button></td>
                                                    </tr>
                                                })
                                            }
                                            {/* <tr>
                                                <td>ICICI Bank</td>
                                                <td>Credit card</td>
                                                <td>First line of disc...</td>
                                                <td><button type="submit" className="ctaonebutton">Product Details</button></td>
                                                <td><input type="text" className="tabletextbox" /></td>
                                                <td> <label className="container-checkbox"> <input type="checkbox" className="form-check-input" checked=" " /><span className="checkmark"></span></label><button type="submit" className="ctaonebutton">Save</button></td>
                                            </tr>
                                            <tr>
                                                <td>ICICI Bank</td>
                                                <td>Credit card</td>
                                                <td>First line of disc...</td>
                                                <td>Product Details</td>
                                                <td><input type="text" className="tabletextbox" /></td>
                                                <td> <label className="container-checkbox"> <input type="checkbox" className="form-check-input" checked=" " /><span className="checkmark"></span></label><button type="submit" className="ctaonebutton">Save</button></td>
                                            </tr> */}
                                        </table>
                                    </div>
                                </section>
                            </div>
                            {offerData?.length > 0 && <Pagination
                                limit={10}
                                resetPagi={resetPagination}
                                totalCount={wholeData?.meta?.totalCount}
                                onPaginationButtonClick={onPaginationButtonClick}
                            />}
                        </div>
                    </div>


                </div>
            </section>
            <Footer />

        </>

    );
};

export default OffersJsx;
