import React from 'react'
import Sidebar from "../sidebar/sidebar.js";
import AccountSetting from "../accountSetting/accountSetting.js";
import { Footer } from '../../../common/footer/footer';
import ManageUser from "../../dashboardCommon/manageUser/manageUser.js";
import ChangePassword from "../../../common/changePassword/changePassword.js";
import MyPlansJsxclass from '../../dashboardCommon/myPlane/myPlane';
import accountsettingdashboard from "../../../../assets/images/accountsettingdashboard.svg";

import "../myAccount/myAccount.css";
import accountsettingss from "../../../../assets/images/accountsettingdashboard.svg";
import changepass from "../../../../assets/images/Changepassword.svg";

import { useTranslation } from 'react-i18next';


const MyAccountsJsx = (props) => {
  const { t } = useTranslation();

  const {
    activeTab,
    onTabClick
  } = props;

  return (
    <>
      <section className="main_customer_dashboard">

        <section className="entiredashboard maincustomerdash customer-dashboard main-myaccount-dashboard">
          <div className="container-fluid">
            <div className="row">
              <div className="col-lg-4 col-sm-4">


                <div className="dashboardleftpanel">

                  <div className="dashboardlinkpop">
                    <Sidebar />
                  </div>
                </div>
              </div>
              <div className="col-lg-8 col-sm-8 dashboardcolor p-0">
                <div className="dashboardrightpanel">
                   <div className="dashboardrightpannerinner">
                      <div className="dashboardrightpannerinnerleft ">
                        <h2 className="h2_style font-weight-600"><span className="headingicon d-block d-lg-none d-sm-none d-md-none "><img src={accountsettingdashboard} /></span>{t("customeraccountsetting.HEADING")}</h2>
                        <h5 className="h5_style">{t("customeraccountsetting.PARAGRAPH")} </h5>
                      </div>

                    </div>
                  <section className="addexpertformsec">
                   

                    <div className="myexpertnav myaccountnav martop40 customermain account-setting-block">
                      <ul>

                        <li className={`${activeTab === 'acntStng' ? 'active' : ''}`} onClick={() => onTabClick('acntStng')}> <img src={accountsettingss} alt="" />{t("customeraccountsetting.ACCOUNTSETTINGBUTTON")}</li>
                        {/* {data.isPrimaryUser && <li className={`${activeTab === 'mngUser' ? 'active' : ''}`} onClick={() => onTabClick('mngUser')}>Manage Users</li>} */}
                        {/* <li className={`${activeTab === 'myPlans' ? 'active' : ''}`} onClick={() => onTabClick('myPlans')}>My Plans</li> */}
                        <li className={`${activeTab === 'newPass' ? 'active' : ''}`} onClick={() => onTabClick('newPass')}><img src={changepass} alt="" />{t("customeraccountsetting.CHANGEPASSWORDBUTTON")}</li>
                      </ul>
                    </div>
                    {
                      activeTab === 'acntStng' ? <AccountSetting /> : activeTab === 'mngUser' ? <ManageUser /> : activeTab === 'newPass' ? <div className="dashboard-ps-offer accountchangepass"><ChangePassword /></div> : <MyPlansJsxclass />
                    }
                  </section>
                </div>
              </div>
            </div>
          </div>
        </section><Footer />
      </section>
   
      
    </>

  );
};

export default MyAccountsJsx;
