import React, { useState } from 'react'


import { Footer } from '../../../common/footer/footer';
import { useTranslation } from "react-i18next";
import { ArticlesCardJSX } from '../../../common/articlesCard/articlesCard.jsx';
import { Pagination } from '../../../common/pagination/uiPagination';
import bannerBG from "../../../../assets/Instituteppvimages/bannerbg.svg";
import { BannerPpvStrip } from "../../../common/bannerPpvStrip/bannerPpvStrip.jsx";

const ListOfArticlesJsx = (props) => {
  const { t } = useTranslation();
  const {
    articleData,
    onPaginationButtonClick,
    profileData,page
  } = props;
  const itemsPerPage = 10

  const [currentPage, setCurrentPage] = useState(1);

  const currentItems = articleData?.body?.slice(
    (page - 1) * itemsPerPage,
    page * itemsPerPage
  );
  return (
    <>
      <section className="entireexpertppv listofoffermainsection">
        <BannerPpvStrip bannerBG={bannerBG} profileData={profileData} />
        <div class="container expppvofferinnersection">
          <div className="heading hedingpading">
            <h2>{t("ARTICLES.LISTOFARTICLETITILE")}</h2>
            <h5 className="h5_style">{t("ARTICLES.LISTOFARTICLESUBTITILE")}</h5>
            <h5 className="h5_style">{t("ARTICLES.LISTOFARTICLESUBTITILES")}</h5>
          </div>
          {
            currentItems?.length > 0 ?
              currentItems?.map(article => {
                return <ArticlesCardJSX
                  articleauthor={article?.author}
                  articledatepublish={article?.date_publish}
                  blogslug={article?.slug}
                  blogimage={article?.image}
                  blogtitle={article?.title}
                  blogcat={article?.cats.map((cat) => cat.name).join(',')}
                  blogshort_content={article?.short_content}
                  isSerchArticle={true}
                                  />

              }) : <div className="dropdefault text-center">
                <h5 className="text-cenetr">{t("ARTICLES.NOARTICLEFOUND")} </h5>
              </div>
          }
         {currentItems?.length && <section className="newpagination" > <Pagination
          currentPage={currentPage}
          page={page}
          allOffersData={articleData?.body}
          currentItems={currentItems}
          onPaginationButtonClick={onPaginationButtonClick}

        /></section>}
        </div>
      </section>
      <Footer />

    </>

  );
};

export default ListOfArticlesJsx;
