import axios from "axios";
import React, { Component } from "react";
import { baseURL,BLOG_URL,BLOG_URL_TOKEN } from "../../../../constants/applicationConstants.js";
import { Loader } from "../../../common/loader/loader.jsx";
import DashBoardJsx from "./dashBoard.jsx";

export class DashBoardclass extends Component {

  constructor(props) {
    super(props);
    this.state = {
      blogData: null,
      expertData: null,
      isProcessing: false,
      offerData: null,
      page:1,
      expage:1,
    }
  }

  onPaginationButtonClick = (page) => { 
    this.setState({ page: page }, () => this.getBlogData(),)
}

onPaginationExButtonClick = (expage) => { 
  this.setState({ expage: expage }, () => this.getExpertsProductData(),)
}

  componentDidMount = async  () => {
    await this.getExpertData();
    await this.getBlogData();
    await this.getExpertsProductData();
  
  }
  getBlogData = () => {
    this.setState({ isProcessing: true })
    const name = JSON.parse(sessionStorage.getItem("masterID"));
    const token = JSON.parse(sessionStorage.getItem('token'));
    var data = new FormData();
    data.append('limit', '8');
    data.append('device_token', '20.204.66.244');
    data.append('expertid', name);
    data.append('paged', this.state.page)

    axios.post(`${BLOG_URL}`, data,
      {
        headers: { 'Eba-access-api-base-token': BLOG_URL_TOKEN }
      }).then(res => {
        this.setState({ blogData: res.data.body}); 
      }).catch(err => {
        this.setState({ isProcessing: false })
      })
    if (!token) {
      this.props.history.push("/");
    }

    axios.get(`${baseURL}/institute/${name}/offers`, {
      headers: { 'x-access-token': token }
    }).then(res => {
      this.setState({ offerData: res.data.data})
    }).catch(err => {
      this.setState({ isProcessing: false })
    })
  }

  getExpertsProductData = () => {
   
    const name11 = localStorage.getItem("slugSet");
    const token = JSON.parse(sessionStorage.getItem('token'));
    var data = new FormData();
    data.append('limit', '8');
    data.append('device_token', '20.204.66.244');
    data.append('expertid', name11);
    data.append('paged', this.state.expage)

    axios.post(`${BLOG_URL}`, data,
      {
        headers: { 'Eba-access-api-base-token': BLOG_URL_TOKEN }
      }).then(res => {
        this.setState({ expertData: res.data.body, isProcessing:false });
      }).catch(err => {
        this.setState({ isProcessing: false })
      })
    if (!token) {
      this.props.history.push("/");
    }
  }

  getExpertData = () => {
    this.setState({  isProcessing: true });
    let token = JSON.parse(sessionStorage.getItem('token'));
    let instituteId = JSON.parse(sessionStorage.getItem("masterID")); 

    axios.get(`${baseURL}/institute/${instituteId}/experts`, {
      headers: { 'x-access-token': token }
    }).then(res => {
      this.setState({ expertData: res.data.data})
      this.getInstitutionName()
      
    }).catch(err => {
      this.setState({ isProcessing: false })
    })
  }

  getInstitutionName = () => {
    let tempArr = [];

    this.state.expertData?.map(cat => {

      tempArr.push(cat?.slugUrl)
    })
    localStorage.setItem("slugSet", tempArr.join())
  }


  render() {
    return (
      <div>
        <DashBoardJsx
          blogData={this.state.blogData}
          expertData={this.state.expertData}
          offerData={this.state.offerData}
          onPaginationButtonClick={this.onPaginationButtonClick}
          onPaginationExButtonClick={this.onPaginationExButtonClick}

        />
        {this.state.isProcessing && <Loader />}
      </div>
    );
  }
}

export default DashBoardclass;
