import axios from 'axios';
import React from 'react'
import { Component } from 'react';
import { Loader } from '../../../common/loader/loader.jsx';
import { BlogJSX } from './blogs.jsx'
import { baseURL,BLOG_URL,BLOG_URL_TOKEN } from "../../../../constants/applicationConstants.js";

export default class Blogs extends Component {
    constructor(props) {
        super(props);
        this.state = {
            blogData: null,
            isProcessing: false,
            expertData: null,
            page: 1,
            page1: 1
        }
    }

    getBlogData = () => {
        const name = JSON.parse(sessionStorage.getItem("masterID"));
        const token = JSON.parse(sessionStorage.getItem('token'));


        var data = new FormData();
        // data.append('limit', '10');
        data.append('device_token', '20.204.66.244');
        data.append('expertid', name);
        data.append('paged', this.state.page)
        this.setState({ isProcessing: true })

        axios.post(`${BLOG_URL}`, data,
            {
                headers: { 'Eba-access-api-base-token': BLOG_URL_TOKEN}
            }).then(res => {
                this.setState({ blogData: res.data, isProcessing: false });
            }).catch(err => {
                this.setState({ isProcessing: false })
            })
            if (!token) {
                this.props.history.push("/");
            }
    }
    getExpertsProductData = () => {
        const name11 = localStorage.getItem("slugSet");
        const token = JSON.parse(sessionStorage.getItem('token'));
        var data = new FormData();
        // data.append('limit', '10');
        data.append('device_token', '20.204.66.244');
        data.append('expertid', name11);
        data.append('paged', '1')

        axios.post(`${BLOG_URL}`, data,
            {
                headers: { 'Eba-access-api-base-token': BLOG_URL_TOKEN}
            }).then(res => {
                this.setState({ expertData: res.data.body, isProcessing: false });
            }).catch(err => {
                this.setState({ isProcessing: false })
            })
        if (!token) {
            this.props.history.push("/");
        }


    }
    getExpertData = () => {
        let token = JSON.parse(sessionStorage.getItem('token'));
        let instituteId = JSON.parse(sessionStorage.getItem("masterID"));
        this.setState({ isLoading: true })

        axios.get(`${baseURL}/institute/${instituteId}/experts`, {
            headers: { 'x-access-token': token }
        }).then(res => {
            this.setState({ expertData: res.data.data, isLoading: false })
            this.getInstitutionName()
        }).catch(err => {
            this.setState({ isLoading: false })
        })
    }

    getInstitutionName = () => {
        let tempArr = [];

        this.state.expertData?.map(cat => {

            tempArr.push(cat?.slugUrl)
        })
        localStorage.setItem("slugSet", tempArr.join())




    }

    componentDidMount = () => {
        this.getExpertData();
        setTimeout(() => {
          this.getBlogData();
          this.getExpertsProductData();
        }, 2000)
    
    
      }

    onPaginationButtonClick = (page) => {
        window.scrollTo(0, 0);
        this.setState({ page: page })
    }
    onPaginationButtonClick1 = (page) => {
        window.scrollTo(0, 0);
        this.setState({ page1: page })
    }

    render() {
        return (
            <div>
                <BlogJSX
                    blogData={this.state.blogData}
                    expertData={this.state.expertData}
                    onPaginationButtonClick={this.onPaginationButtonClick}
                    onPaginationButtonClick1={this.onPaginationButtonClick1}

                    page={this.state.page}
                    page1={this.state.page1}
                />
                {this.state.isProcessing && <Loader />}
            </div>
        )
    }
}