import React from 'react';
import call from "../../../assets//search/phone.svg";
import map from "../../../assets/search/location.svg";
import envelop from "../../../assets/search/email.svg";
import Phone from "../../../assets/search/phone.svg";
import profile_icon from "../../../assets/expertppvimage/profile_icon.svg";
import caution from "../../../assets/Instituteppvimages/info-red.svg";
import { Link } from "react-router-dom";
import styles from './PpvExpertCard.module.css';
import { useTranslation } from "react-i18next";

export const PpvExpertCard = (props) => {
    const {
        profileImage,
        profileName,
        ProfileCategory,
        location,
        phone,
        email,
        ppvExpertCardBtnTextmsg,
        ppvExpertCardBtnTextPv,
        planName,
        linkUrlmsg,
        linkUrlPv,
        institutionCategoryId,
    } = props;

    const { t } = useTranslation();
    
    
    const planClasses = {
        Elite: `${styles.ppvExpertCardElite} ${styles[`cardbg-id${institutionCategoryId}`]}`,
        Premium: `${styles.ppvExpertCardPremium} ${styles[`cardbg-id${institutionCategoryId}`]}`,
        Verified: `${styles.ppvExpertCardVerified} ${styles[`cardbg-id${institutionCategoryId}`]}`,
        Unverified: `${styles.ppvExpertCardUnverified} ${styles[`cardbg-id${institutionCategoryId}`]}`,
      };

      const selectedClass = planClasses[planName] || styles.ppvExpertCardDefault;
    
  return (
        <section className={selectedClass}> 
            <div className={styles.ppvExpertCardEliteinner}>
                <div className={styles.ppvExpertCardEliteUser}>
                    <div>
                        <img src={profileImage} className={styles.userImage} alt={t("PPVEXPERTCARD.USERTITLE")}/>
                    </div>
                    <div className={styles.Eplanname}>
                        {/* <img src={star} alt={t("PPVEXPERTCARD.PLANNAMEICON")}/> */}
                        {planName} 
                    </div>
                </div>
                <div className={styles.ppvExpertCardEliteDetails}>
                    <div className={styles.name}>
                        <h2>{profileName.toLowerCase()}</h2>
                    </div>
                    <div className={`${styles.category} ${styles[`category-id${institutionCategoryId}`]}`}>                        
                        {ProfileCategory?.expertSpecializations && ProfileCategory?.expertSpecializations.length > 0 &&
                            ProfileCategory?.expertSpecializations.map((item,index)=>{
                                return <span key={index} className={`categorybadge-${item?.institutionCategory?.institutionCategoryId}`}>{item.parentInstitutionCategory}</span>
                            })
                        }
                    </div>
                    <div className={styles.contact}>
                        <div className={styles.location}>
                            <div className={styles.contactImg}>
                                <img src={map} alt={t("PPVEXPERTCARD.LOCATION")}/>
                            </div>
                            {location}
                        </div>
                        <div className={styles.phone}>
                            <div className={styles.contactImg}>
                                <img src={call} alt={t("PPVEXPERTCARD.PHONE")}/>
                            </div>
                            {phone}
                        </div>
                        <div className={styles.email}>
                            <div className={styles.contactImg}>
                                <img src={envelop} alt={t("PPVEXPERTCARD.EMAIL")}/>
                            </div>
                            {email}
                        </div>
                    </div>
                    <div className={`${styles.ppvExpertCardElitebtn} ${styles[`cardBtn-id${institutionCategoryId}`]} d-flex`}>
                        
                        {planName === 'Elite' && <Link to={linkUrlmsg} className={`${styles.bdbtn}`} target="_blank"><span><img src={Phone} alt="profile Icon" /></span> {ppvExpertCardBtnTextmsg}</Link> } 
                        <Link to={linkUrlPv} className={`${styles.bdbtn}`} target="_blank"><span><img src={profile_icon} alt="profile Icon" /></span>{ppvExpertCardBtnTextPv}</Link>
                    </div>
                    {planName === "Unverified" ? 
                    <div className={styles.caution}>
                        <p>
                        <img src={caution} alt={t("PPVEXPERTCARD.INFO")}/>&nbsp;
                        {t("PPVEXPERTCARD.CAUTION")}</p>
                    </div>
                    :
                    ""}
                </div>
            </div>
        </section>
   
  );
  

};



