import axios from "axios";
import React, { Component } from "react";
import { Loader } from "../../../common/loader/loader.jsx";
import ListOfExpertJsx from "./listOfExpert.jsx";
import { baseURL } from "../../../../constants/applicationConstants.js";

export default class ListOfExpert extends Component {
  constructor(props) {
    super(props);
    this.state = {
      expertData: null,
      isProcessing: false,
      profileData: null,
      page: 1
    };
  }

  componentDidMount() {
    
    if (this.props.location?.state?.data) {
      const { data } = this.props.location?.state;

      this.setState({ profileData: data });
      this.getExpertData();
    }
  }


  getExpertData = () => {
    
    const url = `${baseURL}/institute/${this.props.location?.state?.data?.formattedLegalName}/expertsList`;
    this.setState({ isProcessing: true });

    axios.get(url).then(res => {

      this.setState({ expertData: res.data, isProcessing: false });
    }).catch(err => {
      this.setState({ isProcessing: false });
  
    });

  }

  onPaginationButtonClick = (page) => {
    window.scrollTo(0, 0);
    this.setState({ page: page })
  }


  render() {
    return (
      <div>
        <ListOfExpertJsx
          expertData={this.state.expertData}
          profileData={this.state.profileData}
          page={this.state.page}
          onPaginationButtonClick={this.onPaginationButtonClick}
        />
        {this.state.isProcessing && <Loader />}
      </div>
    );
  }
}
