import React from 'react'
import { useEffect, useState } from 'react';

import { Footer } from '../../../common/footer/footer';
import { useTranslation } from "react-i18next";

import bannerBG from "../../../../assets/Instituteppvimages/bannerbg.svg";
import { BannerPpvStrip } from "../../../common/bannerPpvStrip/bannerPpvStrip.jsx";
import { Pagination } from '../../../common/pagination/uiPagination';
import { ExpertListCardJSX } from '../../../common/expertListCard/expertListCard';


const ListOfExpertJsx = (props) => {
  const { t } = useTranslation();
  const {
    expertData,
    profileData,
    onPaginationButtonClick,page
  } = props;

 
  const itemsPerPage = 10
  const [currentPage, setCurrentPage] = useState(1);
  const currentItems = expertData?.data?.slice(
    (page - 1) * itemsPerPage,
    page * itemsPerPage
  );

  return (
    <>
      <section className="entireexpertppv listofoffermainsection">
        <BannerPpvStrip bannerBG={bannerBG} profileData={profileData} />
        <div class="container expppvofferinnersection">

          <div className="heading hedingpading">
            <p className="ads-hedding">{t("MYEXPERT.HEADING")}</p>
            <h5 className="h5_style">{t("MYEXPERT.SUBHEADING1")}</h5>
            <h5 className="h5_style">{t("MYEXPERT.SUBHEADING2")}</h5>
          </div>

        </div>

        <div className="container">
        <section className="institutesearchmainlistingsection">
            <div className="container">
            {expertData?.data?.length > 0 ?
             currentItems?.map(offer => {
                console.log("offeroffer==>",offer.expertSpecializations);
                return (
                  <ExpertListCardJSX
                  offerimageUrl={offer?.imageUrl}
                  offerbusinessPhoneNumber={offer.expertSpecializations[0].businessPhoneNumber}
                  offercity={offer.expertSpecializations[0].city}
                  offeremail={offer.email}
                  offerfirstName={offer?.firstName}
                  offerslugUrl={offer?.slugUrl}
                  offerlastName={offer?.lastName}
                  expertSpecializations={offer.expertSpecializations}
                  offerplanName={offer.planName}
                  offerstatus={offer.status}
                  offercategoryName={offer.expertSpecializations[0]?.parentInstitutionCategory}
                  categoryName={offer?.expertSpecializations[0]?.parentInstitutionCategory}
                  offername={offer?.name} 
                  isparentInstitutionCategory={true}
                /> 
                )
              })
              : <div className="dropdefault text-center">
                <h5 className="text-cenetr">{t("MYEXPERT.NOEXPERTFOUND")} </h5>
              </div>}

          </div> 
          </section>

           {expertData?.data?.length > 0 ?<section className="newpagination">
        <Pagination
          currentPage={currentPage}
          page={page}
          allOffersData={expertData.data}
          currentItems={currentItems}
          onPaginationButtonClick={onPaginationButtonClick}
          itemsPerPage={itemsPerPage}

        /></section> : null}
        </div>


      </section>
      <Footer />

    </>

  );
};

export default ListOfExpertJsx;
