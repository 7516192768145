import axios from 'axios';
import React from 'react'
import { Component } from 'react';
import { baseURL, SERVER_IP } from '../../../constants/applicationConstants.js';
import { emailRegex, fetchArticleDataFromAPI, nameRegex } from '../../../shared/helper.js';
import { Loader } from '../../common/loader/loader.jsx';
import HomeJsx from './home.jsx'

export default class Home extends Component {

  constructor(props) {
    super(props);
    this.state = {
      blogData: null,
      isProcessing: false,

      page: 1,
      blogType: 'Featured',
      quoteData: {
        type: null,
        name: null,
        email: null,
        phone: null,
        postalCode: null
      },
      formError: {},

      productData: [],
      offerData: [],
      sponsorAddsData: null,
      featureOffer: null,
      featureProduct: null,
      expertDataFull: null,
      instituteData: null,
      selectedOffer: 0,
      selecteddProduct: 0
    }
  }

  componentDidMount = () => {
    setTimeout(async () => {
      const fetchedData = await fetchArticleDataFromAPI("", this.getType[this.state.blogType],this.state.page);
      this.setState({ blogData: fetchedData, isProcessing: false });

    }, 500);

    this.getProductData();
    this.getSponsorAddsData();
    this.getFeatureCategoryOfferData();
    this.getFeatureCategoryProductData();

sessionStorage.removeItem("selectCategory")
    this.getIPData();
    this.getOfferData();
    this.getExpertDataFull();
    this.getInstituteData();
  }

  getInstituteData = () => {
    this.setState({ isProcessing: true })
    axios.get(`${baseURL}/featured-institutes`).then(res => {
      this.setState({ instituteData: res.data.data, isProcessing: false })
    })
  }





  getProductData = () => {
    this.setState({ isProcessing: true })
    axios.get(`${baseURL}/homeFeaturedProducts`).then(res => {
      this.setState({ productData: res.data.data, isProcessing: false, selecteddProduct: 0 })
    })
  }

  getFeatureCategoryOfferData = () => {
    this.setState({ isProcessing: true })
    axios.get(`${baseURL}/institutionCategory/getFeaturedSubcategories?isFeaturedOfferSubCategory=true&page=1&perPage=10`).then(res => {
      this.setState({ featureOffer: res.data.data, isProcessing: false })
    })
  }
  getFeatureCategoryProductData = () => {
    this.setState({ isProcessing: true })
    axios.get(`${baseURL}/institutionCategory/getFeaturedSubcategories?&isFeaturedProductSubCategory=true&page=1&perPage=10`).then(res => {
      this.setState({ featureProduct: res.data.data, isProcessing: false })
    })
  }

  getSponsorAddsData = () => {
    this.setState({ isProcessing: true })
    axios.get(`${baseURL}/sponsorOffers?page=1&perPage=5`).then(res => {
      this.setState({ sponsorAddsData: res.data.data, isProcessing: false })
    })
  }

  getOfferData = () => {
    this.setState({ isProcessing: true })

    axios.get(`${baseURL}/homeFeaturedOffers`).then(res => {
      this.setState({ offerData: res.data.data, isProcessing: false, selectedOffer: 0 })
    })
  }

  getOfferById = (offer) => {
    this.setState({ isProcessing: true })

    axios.get(`${baseURL}/search-offers?institutionCategoryId=${offer?.parent?.parentId}&institutionSubCategoryId=${offer?.institutionCategoryId}`).then(res => {
      this.setState({ offerData: res.data.data, isProcessing: false, selectedOffer: offer?.institutionCategoryId })
    })
  }

  getProductById = (offer) => {
    this.setState({ isProcessing: true })
    axios.get(`${baseURL}/search-products?institutionCategoryId=${offer?.parent?.parentId}&institutionSubCategoryId=${offer?.institutionCategoryId}`).then(res => {
      this.setState({ productData: res.data.data, isProcessing: false, selecteddProduct: offer?.institutionCategoryId })
    })
  }

  getExpertDataFull = (offer) => {
    this.setState({ isProcessing: true })
    axios.get(`${baseURL}/featuredExpertsByCategories`).then(res => {
      this.setState({ expertDataFull: res.data.data, isProcessing: false })
    })
  }

  // getBlogData = () => {
  //   var data = new FormData();
  //   data.append('limit', '9');
  //   data.append('device_token', '20.204.66.244');
  //   //   data.append('expertid', 'admin');
  //   data.append('paged', this.state.page)
  //   data.append('type', this.getType[this.state.blogType])
  //   this.setState({ isProcessing: true })

  //   axios.post(`${BLOG_URL}`, data,
  //     {
  //       headers: { 'Eba-access-api-base-token': BLOG_URL_TOKEN }
  //     }).then(res => {
  //       this.setState({ blogData: res.data, isProcessing: false });
  //     }).catch(err => {
  //       this.setState({ isProcessing: false })
  //     })
  // }

  // get system ip

  getIPData = () => {
    axios.get(`https://api64.ipify.org?format=json`).then(response => {
      // const serverArray = SERVER_IP.split(",")
      // serverArray.map(value => {
      // })

      const arrIP = SERVER_IP.split(","); //convert string to array to check

      if (arrIP.includes(response?.data?.ip)) //Current IP of user
      {
        // write your logic
      }
      else {
        // write your logic
      }


    })
  }

  getType = {
    Featured: 'main_news',
    Trending: "trending",
    Latest: "featured",
    Glossary: "glossory"
  }

  onInputChangeHandler = (e) => {
    let name = e.target.name;
    let value = e.target.value;

    if (value) {
      if (!this.inputRegexValidation(name, value)) {
        this.setState(
          {
            quoteData: {
              ...this.state.quoteData,
              [name]: value,
            },
            formError: {
              ...this.state.formError,
              [name]: "regx",
            },
          },
          () => this.isFromSubmissionBlock()
        );
      } else {
        this.setState(
          {
            quoteData: {
              ...this.state.quoteData,
              [name]: value,
            },
            formError: {
              ...this.state.formError,
              [name]: "",
            },
          },
          () => this.isFromSubmissionBlock()
        );
      }
    } else {
      this.setState(
        {
          quoteData: {
            ...this.state.quoteData,
            [name]: value,
          },
          formError: {
            ...this.state.formError,
            [name]: "",
          },
        },
        () => this.isFromSubmissionBlock()
      );
    }
  };

  isFromSubmissionBlock = () => {
    let { name, email, phone, phoneNumber, message } = this.state.quoteData;

    if (name && email && phone && phoneNumber && message) {
      if (
        this.inputRegexValidation('name', name) &&
        this.inputRegexValidation('email', email) &&
        this.inputRegexValidation('phone', phone) &&
        phoneNumber && message
      ) {
        this.setState({
          formSubmitButtonDisable: false
        });
      }
      else {
        this.setState({
          formSubmitButtonDisable: true
        });
      }
    } else {
      this.setState({
        formSubmitButtonDisable: true
      });
    }
  };

  onTouchHandler = (e) => {
    let name = e.target.name;
    let value = e.target.value;
    if (value === "") {
      this.setState({
        formError: {
          ...this.state.formError,
          [name]: "req",
        },
      });
    } else {
      if (!this.inputRegexValidation(name, value)) {
        this.setState({
          formError: {
            ...this.state.formError,
            [name]: "regx",
          },
        });
      }
    }
  };

  inputRegexValidation = (name, value) => {
    if (name === 'name')
      return nameRegex.test(value);
    else if (name === "email")
      return emailRegex.test(value);
    else if (name === "phone")
      return !this.getProperPhoneNumber(value).includes('_');

    return true;
  };

  getProperPhoneNumber = (value) => {

    if (!isNaN(value)) {
      return value
    }

    const number = value.split(' ');
    return number[0].replace('(', '').replace(')', '') + number[1] + number[2]
  }

  onSelectBlogType = (title) => {
    this.setState({ blogType: title }, () => fetchArticleDataFromAPI("", this.getType[this.state.blogType],this.state.page)
    );
  }

  onButtonClick = () => {
  }


  render() {
    return (
      <div>
        <HomeJsx
          blogData={this.state.blogData}

          onSelectBlogType={this.onSelectBlogType}
          quoteData={this.state.quoteData}
          onTouchHandler={this.onTouchHandler}
          onInputChangeHandler={this.onInputChangeHandler}
          formError={this.state.formError}
          onButtonClick={this.onButtonClick}


          productData={this.state.productData}
          offerData={this.state.offerData}
          featureProduct={this.state.featureProduct}
          featureOffer={this.state.featureOffer}
          getOfferById={this.getOfferById}
          getProductById={this.getProductById}

          getOfferData={this.getOfferData}

          getProductData={this.getProductData}
          expertDataFull={this.state.expertDataFull}
          sponsorAddsData={this.state.sponsorAddsData}
          instituteData={this.state.instituteData}
          selectedOfferCat={this.state.selectedOffer}
          selecteddProductCat={this.state.selecteddProduct}
        />
        {this.state.isProcessing && <Loader />}
      </div>
    )
  }
}