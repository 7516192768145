import React, { useEffect,useState } from 'react'

import Partial from "../myExpertUn/myExpert.js"
import Sidebar from "../../../../components/dashboard/institute/sidebar/sidebar.js";

import { useHistory } from "react-router-dom";
import { Trans, useTranslation } from 'react-i18next';

import "../myExpert/myExpert.model.css"
import { Footer } from '../../../common/footer/footer';

import myexpertbg from "../../../../assets/Instituteppvimages/myexpertbg.svg";

import swal from "sweetalert";
import { ExpertListCardJSX } from '../../../common/expertListCard/expertListCard';
import { Pagination } from '../../../common/pagination/uiPagination.js';

const MyExpertJsx = (props) => {
  const history = useHistory();
  const { t } = useTranslation();


  const {
    expertData,
    activeTab,
    onTabClick,
    onPaginationButtonClick,
    onDeactivateButtonClick,
    page
  } = props;
  const itemsPerPage = 10

  const [currentPage, setCurrentPage] = useState(1);

  const currentItems = expertData?.slice(
    (page - 1) * itemsPerPage,
    page * itemsPerPage
  );

  const loginData = JSON.parse(sessionStorage.getItem('loginData'));

  const getCategory = (abhi) => {
    const data = abhi?.expertSpecializations?.map(spec => '<span>' + spec.parentInstitutionCategory + '</span>');
    return data && data.join("")

  }

  const getInstitutionName = () => {
    let tempArr = [];

    expertData?.map(cat => {

      tempArr.push(cat?.slugUrl)
    })
    localStorage.setItem("slugSet", tempArr.join())

  }
  useEffect(() => {
    getInstitutionName()
  });

  const planType = sessionStorage.getItem("planName")
  const addAnotherExpert = () => {
    let token = sessionStorage.getItem("token")
    localStorage.setItem('AdminFlow', 'expert')
    localStorage.setItem("instituteExpertToken", token)
    localStorage.setItem("instituteExpert", true)
    localStorage.setItem("adInsExpert", true)
    sessionStorage.setItem("comingFromInstitute", true)
    history.push("/expertRegistration");
  }


  const deactivate = (expert) => {
    swal({
      title: expert.isActiveByInstitute?t("instituteExpertrs.DEACTIVATEMSG") :t("instituteExpertrs.ACTIVATEMSG") ,
      icon: "warning",
      buttons: [
        t('instituteExpertrs.CANCEL'),
        expert.isActiveByInstitute ? t("instituteExpertrs.DEACTIVATE") : t("instituteExpertrs.ACTIVATE")
      ],
      dangerMode: true,
    }).then(function (isConfirm) { 
      if (isConfirm) {
        onDeactivateButtonClick(expert);
      }
      
    })

  }

  return (
    <>
      <>
        {  activeTab === 'acntStng' ?
          <section className="main_institute_dashboard">
          <section class="entiredashboard maincustomerdash main institute_dashboard">
            <div className="container-fluid">
              <div className="row dashboard-decoration">
                <div className="col-lg-4 col-sm-4">
                  <Sidebar />
                </div>
                <div className="col-lg-8 col-sm-8 dashboard-data-exp">
                  <div className="dashboardrightpanel">
                    <div  >
                      <div className="row" >

                        <div className="col-lg-6 col-sm-6 p-0 mb-4">

                          <p className="dahsboardTitle">{t("instituteExpertrs.HEADING")}</p>
                          <p className="dahsboardSubTitle"> <Trans i18nKey="instituteExpertrs.SUBHEADING" ></Trans> </p>
                        </div>
                        
                      </div>
                      
                    </div>
                    <div className="row add-expert">
                      <div className="col-6 add-expert-data">
                        <h3 className="add-expert-title">{t("instituteExpertrs.ADDEXPERT")}</h3>
                        <p className="add-expert-subtitle">{t("instituteExpertrs.ADDEXPERTTITLE")}</p>
                        <p className="add-expert-limit">{t("instituteExpertrs.ADDEXPERTLIMIT")} {loginData?.totalExpertsLimit} </p>
                        <div className="addexpertright">
                          {planType && planType === "Premium" ? <button type="button" className="add-expert-button" onClick={addAnotherExpert} >{t("instituteExpertrs.ADDEXPERTBUTTON")}</button>
                            :
                            <button type="button" className="add-expert-button" onClick={addAnotherExpert} >{t("instituteExpertrs.ADDEXPERTBUTTON")}</button>

                          }
                        </div>
                      </div>
                      <div className="col-6 add-expert-img">
                        <img src={myexpertbg} alt={'Add'} />
                      </div>
                    </div>

                    <div className="myexpert-nav row">
                      <p className="registered-experts col-6">{t("instituteExpertrs.REGEXPERTS")}</p>
                      <p className="partially-registered col-6" onClick={() => onTabClick('partial')}>{t("instituteExpertrs.PARREGEXPERTS")}</p>
                    </div>

                    <div className="expert-section  institutesearchmainlistingsection">
                      {currentItems?.length > 0 ? (
                        currentItems.map(offer => (
                          
                            <ExpertListCardJSX
                              offerimageUrl={offer?.imageUrl}
                              offerbusinessPhoneNumber={offer.expertSpecializations[0].businessPhoneNumber}
                              offercity={offer.expertSpecializations[0].city}
                              offeremail={offer.email}
                              offerfirstName={offer?.firstName}
                              offerslugUrl={offer?.slugUrl}
                              offerlastName={offer?.lastName}
                              expertSpecializations={offer.expertSpecializations                              }
                              offerplanName={offer.planName}
                              isparentInstitutionCategory={true}
                              
                              status={offer.status}
                              categoryName={offer.expertSpecializations[0]?.parentInstitutionCategory}
                              offercategoryName={offer.expertSpecializations[0]?.parentInstitutionCategory}
                              offername={offer?.name}
                            // categoryName={cat.categoryName}
                            />
                            
//                            
                        ))
                      ) : (
                        <h3>{t("instituteExpertrs.NOEXPERTMSG")}</h3>
                      )}
                    </div>


                      { currentItems?.length ? <section className="newpagination"> <Pagination
          currentPage={currentPage}
          page={page}
          allOffersData={expertData}
          currentItems={currentItems}
          onPaginationButtonClick={onPaginationButtonClick}
          itemsPerPage={itemsPerPage}
        /></section> : null}


                  </div>
                </div>
              </div>
            </div>
        
          </section>
          <Footer />
          </section>
          : <Partial />}
      </>
    </>

  );
};

export default MyExpertJsx;
