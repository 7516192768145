
import { Link } from "react-router-dom";
import React, { useState } from 'react'
import dropbox from "../../../assets/images/dropbox.svg";
import { useTranslation } from "react-i18next";
import { HomeArticleCardJSX } from "../../common/homeArticleCard/homeArticleCard";


export const ArticleSectionJsx = (props) => {
    const { blogData,
        onSelectBlogType, profileData } = props;
    const { t } = useTranslation();
    const [openModal, setOpenModal] = useState(false);
    const [blog, setBlog] = useState({});

    const onSelectClick = (title) => {
        onSelectBlogType(title);
    }

    const [showAll, setShowAll] = useState(false);
    const handleShowAll = () => {

    };


    const initialArticlesCount = 5;

    return (
        <section className="articles">
            <div className="container">
                <div className="articles-text">
                    <h2>{t("HOMEPAGE.ARTICLESHEADING")}</h2>
                    <p>{t("HOMEPAGE.ARTICLESSUBTITLES")} <br/>{t("HOMEPAGE.ARTICLESSUBSUBTITLES")}</p>

                </div>


                <div className="articles-box ">
                    <div className="main-articles-box">

                        <div className="item1">
                            {
                                blogData?.body?.length > 0 ? (
                                    blogData.body.slice(0, showAll ? blogData.body.length : initialArticlesCount).map(blog => (

                                        <HomeArticleCardJSX
                                            blogslug={blog?.slug}
                                            blogimage={blog?.image}
                                            blogtitle={blog?.title}
                                            blogshort_content={blog?.short_content}
                                        />

                                    ))
                                ) : (
                                    <div className="dropdefault text-center">
                                        <div className="dropdefaultimage">
                                            <img src={dropbox} alt="No blogs" />
                                        </div>
                                        <div className="dropdefaulttext">
                                            <h5>{t("COMMON.TAGLINEDEFAULT")}</h5>
                                        </div>
                                    </div>
                                )
                            }

                        </div>
                        {
                            openModal &&
                            <div className="modalspecial blog-modal">
                                <div>
                                    <div className="mybtn">
                                        <button type="button" className="myadbtn" onClick={() => { setOpenModal(false) }}><div id="cross"></div></button>
                                    </div>
                                    <div className="modal-header">
                                        <h3>{blog?.title}</h3>
                                    </div>
                                    <div className="modal-body">
                                        <div className="blog-image-dv"><img src={blog.image} alt={' '} />
                                        </div>
                                        <div className="blog-content-dv" dangerouslySetInnerHTML={{ __html: blog.content }}></div>
                                    </div>
                                </div>
                            </div>

                        }


                    </div>
                </div>
                <div className="showallourbesttwo text-center mt-3">
                    <div className="showallourbesttwo  text-center mt-0 mb-5">
                        <Link to={{ pathname: "/listOfArticle", state: { data: profileData, frompage: 'ppvArticlesSection', pagePath: "/listOfArticle" } }} className="showallbtn mt-4 mb-3">{t("COMMON.SHOWALL")}</Link>
                    </div>
                </div>
            </div>
        </section>
    );
};
