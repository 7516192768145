import React, { useEffect, useState } from 'react'
import InputMask from 'react-input-mask';
import heroimage from "../../../../assets/search/search_expert.svg";
import ascending from "../../../../assets/productimage/ascending-bars.svg";
import descending from "../../../../assets/productimage/descending-bars.svg";
import locationpin from "../../../../assets/search/location-pin.svg";
import mobile_banner from "../../../../assets/images/searchexperts_mobile.svg";
import name from "../../../../assets/search/user.svg";
import { Trans, useTranslation } from 'react-i18next';
import { Footer } from '../../../common/footer/footer';
 import newest from "../../../../assets/images/newest.svg";
 import expire_svg from "../../../../assets/images/expire_svg.svg";
import "../globalMainSearchExpert/globalMainSearchExpert.css";
// Import css files
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import AOS from 'aos';
import 'aos/dist/aos.css';
import Filter from '../../../../shared/filter.jsx';
import { ExpertListCardJSX } from '../../../common/expertListCard/expertListCard';
import { Pagination } from '../../../common/pagination/uiPagination.js';


const GlobalMainSearchExpertJsx = (props) => {
  const {
    categoryData,
    onSubmitButtonClick,onSubmitButtonClick1,
    formData,
    onTouchHandler,
    onInputChange,
    formError,
    formSubmitButtonDisable,
    hitOldest,
    oldest,
    allOffersData,
    selectAll,
    selectAllHit,
    getAllFeauturedOffersData,
    selectCategory,
    selectSubCategory,
    subCategories,
    categories,
    onPaginationButtonClick,
    page

  } = props;
  const itemsPerPage = 10

  const [currentPage, setCurrentPage] = useState(1);
  const [filterrMenuOpen, setFilterrMenuOpen] = useState(false);
  const [sortMenuOpen, setSortMenuOpen] = useState(false);
  const [isMobile, setIsMobile] = useState(false);
  const { t } = useTranslation();

  const currentItems = allOffersData?.slice(
    (page - 1) * itemsPerPage,
    page * itemsPerPage
  );
  const checkMobileView = () => {
    setIsMobile(window.innerWidth <= 767);
  };

  useEffect(() => {
    checkMobileView(); // Initial check
    window.addEventListener('resize', checkMobileView); // Listen for window resize

    return () => {
      window.removeEventListener('resize', checkMobileView); // Clean up event listener
    };
    AOS.init();
    window.scrollTo(0, 0);
  }, [])


  // {t("PRINT.TITLE")}
  return (
    <>
      <section className="expertsearchmain">
        <section className="institutesearchmainherosection">
          <img src={heroimage} alt='banner image' className="d-none d-sm-block" />
          <img src={mobile_banner} alt='banner image' className="d-block d-lg-none d-sm-none d-md-none "/>
          <div className="container">
            <div className="herotext">
              <div className="hero_subtext">
                <h2><Trans i18nKey="SEARCHEXPERT.TEXT1" ></Trans></h2>
              </div>
              <div className="hero_subtext2">
                <h2 ><Trans i18nKey="SEARCHEXPERT.TEXT3" ></Trans> <h3>{t("SEARCHEXPERT.TEXT4")}</h3></h2>
              </div>
             
              <div className="hero_subtext3">
                <h5><Trans i18nKey="SEARCHEXPERT.TEXT6" ></Trans></h5>
              </div>
            </div>
          </div> </section>
        <div className="container">
          <div className="forminsti d-flex">
            <div className="input-container ts">
              <InputMask mask={'a9a 9a9'} value={formData.postalCode} name="postalCode" onChange={onInputChange} onBlur={onTouchHandler}>
                {(inputProps) => <input {...inputProps}
                  type="text"
                  className={
                    formError['postalCode']
                      ? "input form-control  is-invalid"
                      : "input form-control"
                  }
                  placeholder=" "
                  disableUnderline />}
              </InputMask>
              <div className="placeholder">{t("REGISTRATION.ENTERPOSTALCODE")}</div>
              <span><img src={locationpin} alt='' /></span>

            </div>
            <div className="input-container ts">
              <input
                type="text"
                className="form-control input"
                placeholder=" "
                name="name"
                onChange={onInputChange}
                onBlur={onTouchHandler}
                value={formData.name}
              />
              <div className="placeholder">{t("REGISTRATION.ENTERNAME")}</div>
              <span><img src={name} alt='' /> </span>

            </div>
            
            <div className="showallourbesttwo  text-center">
            <button type="submit"
              className={(!formData?.name?.length && !formData?.postalCode?.length) ? "contiue-button" : "showallourbesttoo mt-3"}
              disabled={!formData?.name?.length && !formData?.postalCode?.length}
              onClick={() => onSubmitButtonClick1()}>Reset</button>
            </div>
            <div className="showallourbesttwo  text-center">
            <button type="submit"
              className={(!formData?.name?.length && !formData?.postalCode?.length) ? "contiue-button" : "showallourbesttoo mt-3"}
              disabled={!formData?.name?.length && !formData?.postalCode?.length}
              onClick={() => onSubmitButtonClick()}>{t("COMMON.SEARCH")}</button>
            </div>
            
          </div>
        </div>
        <div className="container" id="searchexpert">
          <div className="instisearchheading">
            <h2>{t("SEARCHEXPERT.SEARCHEXPERTTITLE")}</h2>
            <h3>{t("SEARCHEXPERT.SEARCHEXPERTSUBTITLE")}</h3>
          </div>
          {isMobile ? <div className="mobile-filter sortingdiv">
          <div className="container">
            <div className="d-flex mobiledrop">
              <div class="dropdown dropfilter">
                <button class="btn btn-secondary dropdown-toggle" type="button" id="dropdownFilter" onClick={() => {setSortMenuOpen(!sortMenuOpen);setFilterrMenuOpen(false)}} >
                {t("COMMON.SORTBY")}
                </button>
                {sortMenuOpen &&
                  <div class="dropdown-menu-open" aria-labelledby="dropdownFilter">
                    <ul>
                      <li className={!oldest ? "active" : ""}><a onClick={() => hitOldest(false)}>
                        <div className="form-check">
                          <input className="form-check-input" type="checkbox" checked={!oldest} value="" id="Checkme1" /><span className="subcatimage"><img src={newest} /></span>  {t("COMMON.ASC")}</div></a></li>
                      <li className={oldest ? "active" : ""}><a onClick={() => hitOldest(true)}>
                        <div className="form-check">
                          <input className="form-check-input" type="checkbox" checked={oldest} value="" id="Checkme1" /><span className="subcatimage"><img src={expire_svg} /></span>  {t("COMMON.DESC")}</div></a></li>
                      {/* <a class="dropdown-item" href="#">Action</a> */}
                    </ul>
                  </div>
                }
              </div>
              <div class="dropshortby">
                <button class="btn btn-secondary dropdown-toggle"  onClick={() => {setFilterrMenuOpen(!filterrMenuOpen);setSortMenuOpen(false)}} type="button" id="dropdownShortBy">
                {t("COMMON.FILTER")}
                </button>
                <div class={`${filterrMenuOpen===true ? 'open-active' : ''} filter-wrap`} aria-labelledby="dropdownShortBy">
                  <Filter
                    categoryData={categoryData}
                    selectAll={selectAll}
                    selectAllHit={selectAllHit}
                    selectCategory={selectCategory}
                    selectSubCategory={selectSubCategory}
                    subCategories={subCategories}
                    categories={categories}

                  />
                </div>
              </div>
            </div>
          </div>
        </div> : <>
          <Filter
            categoryData={categoryData}
            selectAll={selectAll}
            selectAllHit={selectAllHit}
            getAllFeauturedOffersData={getAllFeauturedOffersData}
            selectCategory={selectCategory}
            selectSubCategory={selectSubCategory}
            subCategories={subCategories}
            categories={categories}
          />
          <section className="sortingdiv">
            <div className="container">
              <span className="shortby">{t("COMMON.SORTBY")}</span>
              <ul>
                <li className={!oldest ? "active" : ""}><a onClick={() => hitOldest(false)}><img src={ascending} className="descending" /> {t("COMMON.ASC")}</a></li>
                <li className={oldest ? "active" : ""}><a onClick={() => hitOldest(true)}><img src={descending} className="descending" /> {t("COMMON.DESC")}</a></li>
              </ul>
            </div>
          </section>
          </>}
          <section className="institutesearchmainlistingsection">
            <div className="container">
              {
                currentItems?.map(offer => {
                  return (
                    <ExpertListCardJSX
                      offerimageUrl={offer?.imageUrl}
                      offerbusinessPhoneNumber={offer.expertSpecializations[0].businessPhoneNumber}
                      offercity={offer.expertSpecializations[0].city}
                      offeremail={offer.email}
                      offerfirstName={offer?.firstName}
                      offerslugUrl={offer?.slugUrl}
                      offerlastName={offer?.lastName}
                      expertSpecializations={offer.expertSpecializations}
                      offerplanName={offer.planName}
                      status={offer.status}
                      offercategoryName={offer.expertSpecializations[0]?.categoryName}
                      offername={offer?.name}
                    // categoryName={cat.categoryName}
                    />
                    
                  )
                })
              }


            </div>
            {(!currentItems || currentItems.length < 1) &&
            <h4 className="text-center">{t('COMMON.NOSEARCHRESULT')}</h4>
            }
            {(currentItems && currentItems.length > 0) &&
            <section className="newpagination"> <Pagination
              currentPage={currentPage}
              page={page}
              allOffersData={allOffersData}
              currentItems={currentItems}
              itemsPerPage={itemsPerPage}
              onPaginationButtonClick={onPaginationButtonClick}
            /></section>
            }
            
          </section>
          <Footer />
        </div>
      </section>
    </>

  );
};

export default GlobalMainSearchExpertJsx;