import React from 'react'
import { useTranslation } from "react-i18next";
import moment from "moment";
import { useEffect, useState } from 'react';

import {OfferCardJSX} from '../../../common/offerCard/offerCard';
import 'react-awesome-slider/dist/styles.css';
import { onOfferClick } from '../../../../shared/helper';
import { NEW_URL } from '../../../../constants/applicationConstants';
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { Pagination } from '../../../common/pagination/uiPagination.js';

const SuggestedOfferJsx = (props) => {
  const { t } = useTranslation();
  const {

    offerData,onPaginationButtonClick, page
  } = props;

  const itemsPerPage = 10

  const [currentPage, setCurrentPage] = useState(1);

  const currentItems = offerData?.slice(
    (page - 1) * itemsPerPage,
    page * itemsPerPage
  );

  const getProperData = (date) => {
    if (date) {
      const date2 = date?.split('T');
      const date1 = date2[0]?.split('-');
      return `${date1[2]}/${date1[1]}/${date1[0]}`
    }
  }
  const sliderSettings = {
    dots: false,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
  };
  return (
    <>
      <section className="alldashboard cust-offers">
        <div className="dashboardrightpanel">
          <div className="suggestedoffercustomer">
          <div className="dashboard-ps-offer">
            <section className="limitedoffer ">
              {
                currentItems &&
                <div className="limitedofferinner">
                
                  <div className="offerslider ">
                  
                      {
                        currentItems?.map(offer => {
                          return <div>
                               <OfferCardJSX
                          offerimgurl={offer?.imageUrl}
                          offerparentInstitutionCategory={offer?.parentInstitutionCategory}
                          offervalidTo={moment(offer?.validTo).format('Do MMM YY')}
                          offerofferName={offer?.offerName}
                          offerdescription={offer?.description}
                          offerexternalUrl={offer?.externalUrl}
                          isDescription={true}
                        /> 
                           
                          </div>
                        })
                      }
                 <section className="newpagination"> <Pagination
                        currentPage={currentPage}
                        page={page}
                        allOffersData={offerData}
                        currentItems={currentItems}
                        onPaginationButtonClick={onPaginationButtonClick}
                        itemsPerPage={itemsPerPage}
                      /></section>
                  </div>
                </div>
              }
            
            </section>
</div>
          </div>
        </div>
      </section>
    </>

  );
};

export default SuggestedOfferJsx;