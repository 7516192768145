import React from 'react';
import { useTranslation } from "react-i18next";
import Phone from "../../../assets/search/phone.svg";
import location from "../../../assets/search/location.svg";
import profile_icon from "../../../assets/expertppvimage/profile_icon.svg";
import unverifiedinfo from "../../../assets/search/unverifiedinfo.svg";

import email from "../../../assets/search/email.svg";
import { NEW_URL } from '../../../constants/applicationConstants';
import styles from './institutiontListCard.module.css';
import { Link } from "react-router-dom";

export const InstitutiontListCardJSX = (props) => {

  const { t } = useTranslation();
  const {
    offerimageUrl, offername, offercity, offeremail, offerlegalName, offercontactNumber,
    offerinstituteCategories, offerplanName, offercategoryName, status

  } = props;

  return (
    <>
      <div className={`${styles.institutesearchmainlistingsectionps} ${offerplanName} ${offercategoryName} status${status}`}>

        <div className={styles.instilogoouter}>
          <div className={[styles.instilogo + " " + 'statuslogo']}>
            {status === "unverified" || status === "verified" ?
              <div className="imageborder">{status}</div>
              :
              <Link to={`/institute/${offerlegalName}`} target="_blank">

                <div className={styles.instilogoimage}><img src={!offerimageUrl
                  ? `https://ui-avatars.com/api/?name=${offername}+${offername}&rounded=false`
                  : `${NEW_URL}/${offerimageUrl}`} alt=''
                /></div>
              </Link>
            }
          </div>
          <div className={`${styles.plainde} expertoutershadow ${offerplanName}`}>{offerplanName}</div>
        </div>
        <div className={[styles.instisegricateouter + " " + 'd-flex']}>
          <div className={styles.instisegricateone}>
            <h4>{offername.toLowerCase()}</h4>
            <div className={`${styles.instisegricatetwo} instisegricatetwops d-block d-lg-none d-sm-none d-md-none `}>
            <ul>
              {
                offerinstituteCategories?.map(cat => {
                  return (
                    <li className={cat.name}>{cat.name}</li>

                  )
                })
              }

            </ul>
          </div>

            <div className={styles.condetails}>
              <ul>
                {offercity && <li><span><img src={location} className="imagefilter" alt={offername} /></span> {offercity}</li>}
                {offercontactNumber && <li><span><img src={Phone} className="imagefilter" alt={offername} /></span><a href={`tel:${offercontactNumber}`}>{offercontactNumber}</a> </li>}
                {offeremail && <li><span><img src={email} className="imagefilter" alt={offername} /></span> <a href={`mailto:${offeremail}`}>{offeremail}</a></li>}
              </ul>
            </div>
            {status === "unverified" || status === "verified" ? null :
              <div className={`${styles.basicdetails} d-flex`}>
                {offerplanName === "Premium" ?
              null :
                <Link to={`/institute/${offerlegalName}#contact`} className={[styles.bdetail + " " + 'bdeatailglobal']}><span><img src={Phone} alt={offername} /></span>{t("COMMON.CONTACTUS")}</Link>}
                <Link to={`/institute/${offerlegalName}`} className={[styles.bdetail + " " + 'bdeatailglobal']}><span><img src={profile_icon} alt={offername} /></span>{t("COMMON.VIEWPROFILE")}</Link>
              </div>
            }
            {status === "unverified" &&
              <span className="unverifiedinfo"><img src={unverifiedinfo} /> {t("COMMON.UNVERIFIEDINFO")}</span>
            }
          </div>
          <div className={`${styles.instisegricatetwo} instisegricatetwops d-none d-sm-block`}>
            <ul>
              {
                offerinstituteCategories?.map(cat => {
                  return (
                    <li className={cat.name}>{cat.name}</li>

                  )
                })
              }

            </ul>
          </div>
        </div>
      </div>
    </>
  )
}
