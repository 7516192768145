import React, { useEffect, useState } from 'react'
import { Footer } from '../../../common/footer/footer';
// Import css files
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import AOS from 'aos';
import 'aos/dist/aos.css';
import '../globalMainSearchArticles/globalMainSearchArticles.model.css';
import { Pagination } from '../../../common/pagination/uiPagination';
import { ArticlesCardJSX } from '../../../common/articlesCard/articlesCard';

import { useTranslation } from "react-i18next";
import { useHistory } from "react-router-dom";

const GlobalMainSearchArticlesJsx = (props) => {
  const {
    articleData,
    onPaginationButtonClick,
    hitSearch,
    searchOfferByKeyword,
    page
  } = props;
  const itemsPerPage = 10

  const [currentPage, setCurrentPage] = useState(1);


  const params = new Proxy(new URLSearchParams(window.location.search), {
    get: (searchParams, prop) => searchParams.get(prop),
  });
  useEffect(() => {
    AOS.init();
    window.scrollTo(0, 0);
  }, [])

  const { t } = useTranslation();
  const history = useHistory();

  const currentItems = articleData?.body?.slice(
    (page - 1) * itemsPerPage,
    page * itemsPerPage
  );
  const getProperName = (data) => {
    if (data == "featured") {
      return "Curated Wisdom"
    }else if(data == "main_news"){
      return "  Featured"
    }else if(data == "glossory"){
      return "Latest"
    }else if(data == "trending"){
      return "Trending "
    }
  }
  // featured - curated, main_news - featured, glossory - glossory, trending - latest
  console.log("params ==> ", params["type"]);
  return (
    <>
      <section className="homecustom homecustomsearchfull">
        <section className="offersearch">
          <div className="container">
            <div className="customsearchblock">
              <input type="search" defaultValue={params.search} placeholder="" onChange={searchOfferByKeyword}
              />
              <button class="headersearch" onClick={() => hitSearch()}>{t("COMMON.SEARCH")}</button>
            </div>
            <h2>{t("ARTICLES.SEARCHTITLE")}</h2>
            <h4>{t("ARTICLES.SEARCHDESH")}</h4>
          </div>
        </section>
        <section className="searchresultsection">
          <div className="expertsection martop30 mb-5">
            <div className="container articlemain">
              <div className="articlehading">
                <h2> {params["type"] != null ? `${getProperName(params["type"])} ${t("ARTICLES.EMPTYTITLE")}` : params.search === "" || params.search === null ? `${t("ARTICLES.EMPTYTITLE")}` : `“${params.search}” ${t("ARTICLES.TITLE")}`}</h2>
              </div>
              {
                currentItems?.length > 0 ?
                  currentItems?.map(article => {

                    return  <ArticlesCardJSX blogslug={article?.slug}
                    blogimage={article?.image}
                    blogtitle={article?.title}
                    blogcat={article?.cats.map((cat) => cat.name).join(',')}
                    blogshort_content={article?.short_content}
                    articleauthor={article?.author}
                    articledatepublish={article?.date_publish}
                    isSerchArticle={true} />
                    // <ArticlesCardJSX
                    //   blogslug={article?.slug}
                    //   blogimage={article?.image}
                    //   blogtitle={article?.title}
                    //   blogshort_content={article?.short_content}
                    //   articleauthor={article?.author}
                    //   articledatepublish={article?.date_publish}
                    //   blogauthor_image={article?.author_image}
                    //   isSerchArticle={true}
                     
                    // />

                  }) : <div className="dropdefault text-center">
                    <h5>{t("ARTICLES.NOARTICLEFOUND")} </h5>
                  </div>
              }
            </div>
            {currentItems?.length > 0 && <section className="newpagination" > <Pagination
              currentPage={currentPage}
              page={page}
              itemsPerPage={itemsPerPage}
              allOffersData={articleData?.body}
              currentItems={currentItems}
              onPaginationButtonClick={onPaginationButtonClick}

            /></section>}
          </div>
        </section>
        <Footer />
      </section>
    </>
  );
};

export default GlobalMainSearchArticlesJsx;