import React from 'react'
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Footer } from '../../common/footer/footer';
import { ArticlesCardJSX } from '../../common/articlesCard/articlesCard';
import { sliderSettingOne, trendingsection, trendingsectionunslick } from "../../../shared/sliderSetting.js"
import iconaskbtn from "../../../assets/sharedImages/iconaskbtn.svg";
import left_arrow from "../../../assets/blogimages/Black_arrow.svg"
import realestateicon from "../../../assets/askTheExpertImages/realestateicon.svg";
import insurenceicon from "../../../assets/askTheExpertImages/insurenceicon.svg";
import mortgageicon from "../../../assets/askTheExpertImages/mortgageicon.svg";
import bankingicon from "../../../assets/askTheExpertImages/bankingicon.svg";
import investmenticon from "../../../assets/askTheExpertImages/investmenticon.svg";
import realestateicontwo from "../../../assets/askTheExpertImages/realestateicontwo.svg";
import insurenceicontwo from "../../../assets/askTheExpertImages/insurenceicontwo.svg";
import mortgageicontwo from "../../../assets/askTheExpertImages/mortgageicontwo.svg";
import bankingicontwo from "../../../assets/askTheExpertImages/bankingicontwo.svg";
import investmenticontwo from "../../../assets/askTheExpertImages/investmenticontwo.svg";
import { NEW_URL } from '../../../constants/applicationConstants';
import 'react-awesome-slider/dist/styles.css';
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Filter from '../../../shared/filter.jsx';
import { Link } from "react-router-dom";
import Slider from "react-slick";
import AOS from 'aos';
import 'aos/dist/aos.css';
import { useLocation } from 'react-router-dom';
import "./articles.css";

export const getIconOne = (label) => {
    if (label === 'Real Estate')
        return realestateicon
    else if (label === 'Insurance')
        return insurenceicon
    else if (label === 'Mortgage')
        return mortgageicon
    else if (label === 'Investment')
        return investmenticon
    else if (label === 'Banking')
        return bankingicon

    else return ''
}


const ArticlesJsx = (props) => {
    const { blogData, blogauthor_image } = props;
    const { t } = useTranslation();
    const { hash } = useLocation()
    useEffect(() => {
        AOS.init();
        if (hash) {
            document.getElementById(hash.replace('#', '')).scrollIntoView()
        } else {
            //window.scrollTo(0, 0);
        }

    }, [])
    const params = new Proxy(new URLSearchParams(window.location.search), {
        get: (searchParams, prop) => searchParams.get(prop),
    });
    const {
        hitSearch,
        searchOfferByKeyword,
        categoryData,
        selectAll,
        selectAllHit,
        getAllFeauturedOffersData, 
        selectCategory,
        selectSubCategory, 
        subCategories, 
        categories,
        onCategoryClick,
        categoryName, hitShowAll, input
    } = props;

    const getIconTwo = (label) => {
        if (label === 'Real Estate')
            return realestateicontwo
        else if (label === 'Mortgage')
            return mortgageicontwo
        else if (label === 'Insurance')
            return insurenceicontwo
        else if (label === 'Banking')
            return bankingicontwo
        else if (label === 'Investment')
            return investmenticontwo
        else return ''
    }
    const [openModal, setOpenModal] = useState(false);
    const [sortMenuOpen, setSortMenuOpen] = useState(false);
    const [filterrMenuOpen, setFilterrMenuOpen] = useState(false);
    const [blog, setBlog] = useState({});






    return (
        <>
            <section className="container-rimib newblogdesign">
                <section className="blogcustommainpage">
                    <section className="herosectionoffer">
                        <div className="container">
                            <div className="row">
                                <div className="col-lg-6 col-sm-6">
                                    <div className="trendingsection">
                                        <Slider {...sliderSettingOne}>
                                            {
                                                blogData?.body?.trending?.map((blog, index) => {
                                                    if (index > 31 && index < 36) {
                                                        return (
                                                            <div className={`sliderblog  ${blog.cats[0]?.name}`}>
                                                                <div className="smblog">

                                                                    <div className="trending-blogimage">
                                                                        <img src={blog?.image} alt={`${blog?.title}`} />
                                                                        <div className="blog-upperstrip">
                                                                            <div className="d-flex upperstrip">
                                                                                <div className="d-flex basicuserdetails">
                                                                                    <div className="userimage">
                                                                                        <img src={!blogauthor_image

                                                                                            ? `https://ui-avatars.com/api/?name=${blog?.author}&rounded=true`
                                                                                            : `${NEW_URL}/${blogauthor_image}`} alt={`${blog?.title}`} />
                                                                                    </div>
                                                                                    <div className="usertext">
                                                                                        <div className="name">{blog?.author}</div>
                                                                                        <div className="date">{blog?.date_publish}</div>
                                                                                    </div>
                                                                                </div>
                                                                                <Link to={`/articleDetails/${blog.slug}`} target="_blank" className="blogreadmore">{t("COMMON.READMORE")} <img src={left_arrow} alt={`${blog?.title}`} /></Link>
                                                                            </div>
                                                                        </div>

                                                                        <div className="bloginnertext"> <button className="bloginnertag">{blog?.cats.map((cat) => cat.name).join(',')}</button>{blog?.title}</div>
                                                                    </div>


                                                                    {/* <div className="bloginnerdate">{blog?.views} views  .  {blog?.date_publish}</div>    */}

                                                                </div>
                                                            </div>
                                                        )
                                                    }
                                                })

                                            }</Slider>
                                        {/* <img src={Articles_banner_image} alt={`${blog?.title}`}  /> */}
                                    </div>
                                </div>
                                <div className="col-lg-6 col-sm-6">
                                    <div className="blogtext">
                                        <div className="bannerheading">{t("ARTICLECHILD.TOPTRENDING")}</div>
                                        <div className="bannergredientheading">{t("ARTICLECHILD.ARTICLES")}</div>
                                        <div className="bannertext">{t("ARTICLECHILD.BANNERSUBTEXT")}</div>
                                        <Link to={'/AskTheExpert'} style={{ textDecoration: "none" }}>
                                            <div className="askbutton">
                                                <p className="askres">{t("COMMON.ASKTHEEXPERTS")}</p>
                                                <img src={iconaskbtn} alt={`${blog?.title}`} />
                                            </div>
                                        </Link>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </section>

                    <section className="offersearch">
                        <div className="container">
                            <div className="customsearchblock">
                                <input type="search" placeholder="" defaultValue={params.search} onChange={searchOfferByKeyword}
                                />
                                <button disabled={!input.length} className="headersearch" onClick={() => hitSearch()}>{t("COMMON.SEARCH")}</button>
                            </div>

                        </div>
                    </section>

                    <div className="container">


                        <section className="catblog">
                            <div className="catstart clearfix">
                                <div className={`categoryonesetfirsttest`}>
                                    <div className="choose-main" >
                                        {
                                            categoryData?.map(catDT => {
                                                return <div className={`choose categoryonesettwo ${categoryName === catDT?.name ? 'active' : ''}`} onClick={() => onCategoryClick(catDT?.institutionCategoryId, catDT?.name)}>
                                                    <div className={`iconone ${categoryName === catDT?.name ? 'active' : ''}`} ><img src={getIconOne(catDT.name)} alt={''} /></div>
                                                    <div className="icontwo"><img src={getIconTwo(catDT.name)} alt={''} /></div>

                                                </div>
                                            })
                                        }
                                    </div>
                                   

                                    <div className="realblogcatwise">
                                        <h4 className="text-center text-heading">{t("ARTICLECHILD.TITLE")}</h4>
                                        <p className="text-center  text-subheading">{t("ARTICLECHILD.SUBTITLE")}</p>
                                        <div className="smblog d-none d-sm-block">
                                            <div className="realblogcatwise-inner">
                                                {
                                                    blogData?.body?.featured?.length > 0 ?
                                                        blogData?.body?.featured?.slice(0, 3).map((blog, index) => {
                                                            return (
                                                                <div className={`card  ${blog.cats[0]?.name}`}>
                                                                    <div className="row">
                                                                        <ArticlesCardJSX blogslug={blog?.slug}
                                                                            blogimage={blog?.image}
                                                                            blogtitle={blog?.title}
                                                                            blogcat={blog?.cats.map((cat) => cat.name).join(',')}
                                                                            blogshort_content={blog?.short_content}
                                                                            articleauthor={blog?.author}
                                                                            articledatepublish={blog?.date_publish}
                                                                            isSerchArticle={true} />

                                                                    </div>
                                                                </div>)

                                                        }) :
                                                        <div className="dropdefault text-center">
                                                            <div className="dropdefaultimage">

                                                            </div>
                                                            <div className="dropdefaulttext">
                                                                <h5>{t("COMMON.SHOWALL")} </h5>
                                                            </div>
                                                        </div>
                                                }
                                            </div>
                                        </div>
                                        <div>
                                            <div className="innerfeaturedblog d-block d-lg-none d-sm-none d-md-none">
                                                <Slider {...trendingsection}>
                                                {
                                                    blogData?.body?.featured?.length > 0 ?
                                                        blogData?.body?.featured?.slice(0, 3).map((blog, index) => {
                                                            return (
                                                                <div className={`smblog  ${blog.cats[0]?.name}`}>
                                                                        <div className="smbloginnerps">
                                                                        <ArticlesCardJSX blogslug={blog?.slug}
                                                                            blogimage={blog?.image}
                                                                            blogtitle={blog?.title}
                                                                            blogcat={blog?.cats.map((cat) => cat.name).join(',')}
                                                                            blogshort_content={blog?.short_content}
                                                                            articleauthor={blog?.author}
                                                                            articledatepublish={blog?.date_publish}
                                                                            isSerchArticle={true} />

                                                                    </div>
                                                                </div>)

                                                        }) :
                                                        <div className="dropdefault text-center">
                                                            <div className="dropdefaultimage">

                                                            </div>
                                                            <div className="dropdefaulttext">
                                                                <h5>{t("COMMON.SHOWALL")} </h5>
                                                            </div>
                                                        </div>
                                                }                                                 

                                    </Slider>
                                            </div>
                                        </div>
                                        <h1></h1>
                                        <div className="text-center">
                                            <button onClick={() => hitShowAll("featured")} className="showallourbesttoo blogbtn-show" style={{ textDecoration: "none" }}>{t("COMMON.SHOWALL")}</button>
                                        </div>


                                    </div>
                                </div>
                            </div>
                        </section>
                        <section className="featuredblog">
                            <h4 className="text-center  text-heading">{t("ARTICLECHILD.FEATURED")}</h4>
                            <p className="text-center  text-subheading">{t("ARTICLECHILD.FEATUREDSUBTITLE")}</p>

                            <div className="innerfeaturedblog">
                                <Slider {...trendingsection}>
                                    {
                                        blogData?.body?.main_news?.map((blog, index) => {
                                            if (index > 0 && index < 4) {
                                                return (
                                                    <div className={`smblog  ${blog.cats[0]?.name}`}>
                                                        <div className="smbloginnerps">

                                                            <ArticlesCardJSX blogslug={blog?.slug}
                                                                blogimage={blog?.image}
                                                                blogtitle={blog?.title}
                                                                blogcat={blog?.cats.map((cat) => cat.name).join(',')}
                                                                blogshort_content={blog?.short_content}
                                                                articleauthor={blog?.author}
                                                                articledatepublish={blog?.date_publish}
                                                                isSerchArticle={true} />

                                                        </div>
                                                    </div>
                                                )
                                            }
                                        })
                                    }</Slider>
                            </div>
                            <div className="text-center">
                                <button onClick={() => hitShowAll("main_news")} className="showallourbesttoo blogbtn-show" style={{ textDecoration: "none" }}>{t("COMMON.SHOWALL")}</button>
                            </div> </section>


                        <section className="latestblog">
                            <div className="row">
                                <h4 className="text-center  text-heading">{t("ARTICLECHILD.LATEST")}</h4>
                                <p className="text-center  text-subheading">{t("ARTICLECHILD.LATESTSUBTITLE")}</p>
{/* <div className="d-none d-sm-block">
                                <div className="row" style={{ padding: "10px 10px 10px 20px !important;" }}>
                               
                                    {
                                        blogData?.body?.glossory?.map((blog, index) => {
                                            
                                                return (
                                                    <div className="col smblog">
                                                        <div className={`basicstructure ${blog.cats[0]?.name}`}>
                                                            <ArticlesCardJSX blogslug={blog?.slug}
                                                                blogimage={blog?.image}
                                                                blogtitle={blog?.title}
                                                                blogcat={blog?.cats.map((cat) => cat.name).join(',')}
                                                                articleauthor={blog?.author}
                                                                articledatepublish={blog?.date_publish}
                                                                isSerchArticle={false} /></div>


                                                    </div>
                                                )
                                            
                                        })
                                    }


                                </div>
                              </div> */}
                              <div className="innerfeaturedblog d-none d-sm-block">
                              <div className="row">
                                <Slider {...trendingsectionunslick}>
                                    {
                                          blogData?.body?.glossory?.map((blog, index) => {
                                     
                                                return (
                                                    <div className="col smblog">
                                                    <div className={`basicstructure   ${blog.cats[0]?.name}`}>
                                                        <div className="smbloginnerps">

                                                            <ArticlesCardJSX blogslug={blog?.slug}
                                                                blogimage={blog?.image}
                                                                blogtitle={blog?.title}
                                                                blogcat={blog?.cats.map((cat) => cat.name).join(',')}
                                                                // blogshort_content={blog?.short_content}
                                                                articleauthor={blog?.author}
                                                                articledatepublish={blog?.date_publish}
                                                                isSerchArticle={false} />

                                                        </div>
                                                    </div>
                                                    </div>
                                                )
                                           
                                        })
                                    }</Slider>
                                    </div>
                            </div>
                            <div className="innerfeaturedblog d-block d-lg-none d-sm-none d-md-none ">
                              <div className="row">
                                <Slider {...trendingsectionunslick}>
                                    {
                                          blogData?.body?.glossory?.map((blog, index) => {
                                     
                                                return (
                                                    <div className="col smblog">
                                                    <div className={`basicstructure   ${blog.cats[0]?.name}`}>
                                                        <div className="smbloginnerps">

                                                            <ArticlesCardJSX blogslug={blog?.slug}
                                                                blogimage={blog?.image}
                                                                blogtitle={blog?.title}
                                                                blogcat={blog?.cats.map((cat) => cat.name).join(',')}
                                                                //blogshort_content={blog?.short_content}
                                                                articleauthor={blog?.author}
                                                                articledatepublish={blog?.date_publish}
                                                                isSerchArticle={true} />

                                                        </div>
                                                    </div>
                                                    </div>
                                                )
                                           
                                        })
                                    }</Slider>
                                    </div>
                            </div>
                                <div className="text-center"> <button onClick={() => hitShowAll("glossory")} className="showallourbesttoo blogbtn-show" style={{ textDecoration: "none" }}>{t("COMMON.SHOWALL")}</button>
                                </div>
                            </div>
                        </section>

                        <section className="trendingsection">

                            <h4 className="text-center  text-heading">{t("ARTICLECHILD.TRENDING")}</h4>
                            <p className="text-center  text-subheading">
                                {t("ARTICLECHILD.TRENDINGSUBTITLE")} <br />
                                {t("ARTICLECHILD.TRENDINGSUBTITLEONE")}
                            </p>
                            <div className="trendingsectioninner">
                                <Slider {...trendingsection}>
                                    {
                                        blogData?.body?.trending?.map((blog, index) => {
                                            if (index > 31 && index < 36) {
                                                return (
                                                    <div className={`sliderblog  ${blog.cats[0]?.name}`}>
                                                        <div className="smblog">

                                                            <ArticlesCardJSX blogslug={blog?.slug}
                                                                blogimage={blog?.image}
                                                                blogtitle={blog?.title}
                                                                blogshort_content={blog?.short_content}
                                                                articleauthor={blog?.author}
                                                                blogcat={blog?.cats.map((cat) => cat.name).join(',')}
                                                                articledatepublish={blog?.date_publish}
                                                                isSerchArticle={true} />
                                                        </div>
                                                    </div>
                                                )
                                            }
                                        })

                                    }</Slider>
                                <div className="text-center">  <button onClick={() => hitShowAll("trending")} className="showallourbesttoo blogbtn-show" style={{ textDecoration: "none" }}>{t("COMMON.SHOWALL")}</button>
                                </div>  </div>
                        </section>

                    </div>




                </section>
            </section>
            <Footer />
        </>
    )
};

export default ArticlesJsx;