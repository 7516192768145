import axios from "axios"; 
import React, { Component } from "react";
import { toast, ToastContainer } from "react-toastify";
import { baseURL } from "../../../../constants/applicationConstants.js";
import { Loader } from "../../../common/loader/loader.jsx";
import ListOfOffersJsx from "./listOfOffer.jsx"

export class ListOfOffersJsxclass extends Component {

  constructor(props) {
    super(props);
    this.state = {
      offerData: null,
      isProcessing: false,
      page: 1,
      instituteCategoryID: '',
      offerName: '',
      categoryData: [],
      legalName: '',
      profileData:null, 
    }
  }

  componentDidMount = () => { 
    if (this.props.location?.state?.data) {
      const { data } = this.props.location?.state; 
     
      data && this.setState({profileData:data, legalName: data.formattedLegalName }, () => this.getOfferData());
    }
  }

  getOfferData = () => { 
    this.setState({ isProcessing: true })
    axios.get(`${baseURL}/institute/${this.state.legalName}/offersPerPage?page=${this.state.page}&perPage=10&institutionCategoryId=${this.state.instituteCategoryID}&offerName=${this.state.offerName}`).then(res => {
      this.setState({ offerData: res.data, isProcessing: false });
    }).catch(err => {
      toast.error(err.response.data.error.message)
      this.setState({
        isProcessing: false
      })
    })

    axios.get(`${baseURL}/institute/${this.state.legalName}/institutionCategories`).then(res => {
      this.setState({ categoryData: res.data.data, isProcessing: false });
    }).catch(err => {
      toast.error(err.response.data.error.message)
      this.setState({
        isProcessing: false
      })
    })
  }

  onPaginationButtonClick = (page) => {
    window.scrollTo(0, 0);
    this.setState({ page: page }, () => this.getOfferData())
  }

  onCategoryChange = (e) => {
    this.setState({ instituteCategoryID: e.target.value })
  }

  onOfferNameChange = (e) => {
    this.setState({ offerName: e.target.value });
  }

  onSearchButtonClick = () => {
    this.setState({ isProcessing: true })

    this.setState({ page: 1 })
    setTimeout(() => {
      this.getOfferData();

    }, 1000);
  }

  render() {
    return (
      <div>
        <ListOfOffersJsx
          offerData={this.state.offerData}
          onCategoryChange={this.onCategoryChange}
          onOfferNameChange={this.onOfferNameChange}
          onSearchButtonClick={this.onSearchButtonClick}
          onPaginationButtonClick={this.onPaginationButtonClick}
          categoryData={this.state.categoryData}
          profileData={this.state.profileData}
        />
        {this.state.isProcessing && <Loader />}
        <ToastContainer />
      </div>
    );
  }
}

export default ListOfOffersJsxclass;