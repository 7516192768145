import React from 'react'
import fileuploadpopup from "../../../../assets/images/fileuploadpopup.svg";
import ViewSpecialInfo from "./viewSpecialInfo.jsx";

const SpecialInformationsJsx = (props) => {
  const {
    changeHideState,
    specialInfoDetails,
    onDeleteButtonClick,
    editParticularSpecialization
  } = props;

  return (
    <>
      <section className="listofoffermainsection d-none">
        <div className="container">
          

          <div className="specialinfoinner mt-5 mb-5">
            <div className="row">
              <div className="col-lg-7">
                
                {specialInfoDetails?.length ?
                  <ViewSpecialInfo
                    specialInfoDetails={specialInfoDetails}
                    changeHideState={changeHideState}
                    onDeleteButtonClick={onDeleteButtonClick}
                    editParticularSpecialization={editParticularSpecialization}
                  /> :
                  <>
                    
                      <div className="expertfileupload text-center">
                        <div className="button_outerlogo">
                          <div className="btn_uploadlogo" onClick={() => changeHideState(true)}><img src={fileuploadpopup} alt={''} /></div>
                        </div>
                        <h5 className="text-center h5_style">Add your Specializations</h5>
                        <p className="text-center">You can enter multiple specializations.</p>
                        <p className="text-center">Just click on  “+”</p>

                      </div>
                  </>
                }
                

              </div>
            </div>
          </div>
        </div>

      </section>


    </>

  );
};

export default SpecialInformationsJsx;