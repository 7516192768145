import React from 'react';
import { Link } from 'react-router-dom/cjs/react-router-dom.min';
import { sliderSettingTwoRowFourColumn } from "../../../shared/sliderSetting.js"
import Slider from 'react-slick';
import styles from "../instituteList/instituteList.module.css"
import { useTranslation } from "react-i18next";
import { NEW_URL } from '../../../constants/applicationConstants';
import toprealEttextline from "../../../assets/sharedImages/toprealEttextline.png";

export const InstituteList = (props) => {
    const { t } = useTranslation();

    const {
        instituteData,
        bestInstituteText,
        topRatedText,
        checkOutText,
        finalcialText,
        onInstituteClick,
        isCategory,
        categoryImg,
        topText,
        category,instituteID
    } = props;
    const hitShowALL = () => {
        instituteID && sessionStorage.setItem("selectCategory",instituteID)
      }

  
 
    return (
        isCategory ? <section className={`categorysectionthree ${category}-bgimg`}>
            <div className="container">
                <div className="row" >
                    <div className="col-lg-7 col-12 p-0 ">
                        <div className={styles.categorylogosection}>
                            {instituteData?.length > 0 &&
                                <Slider {...sliderSettingTwoRowFourColumn}>
                                    {
                                        instituteData.map(institute => {
                                            return <div className="psi">
                                                <div
                                                    className="colinstititeinner"
                                                    onClick={() => onInstituteClick(institute)}
                                                >
                                                    <div className="colinstititeinnerimage text-center">
                                                        <img
                                                            src={!institute?.imageUrl ? `https://ui-avatars.com/api/?name=${institute?.name}&rounded=false` : `${NEW_URL}/${institute?.imageUrl}`}
                                                            alt={institute?.name || "Institute Image"}
                                                        />
                                                    </div>
                                                    <div className="colinstititeinnername text-center institute-text">
                                                        {institute?.name}
                                                    </div>
                                                </div>
                                            </div>
                                        })
                                    }
                                </Slider>
                            }
                        </div>
                        <div className={`${styles.showallourbesttwo}` + " " + `mt-5` + " " + `text-center`} onClick={() => hitShowALL()}>
                            <Link to={'/globalMainSearchInstitutions#searchinstitute'} className={styles.showallourbesttoo}>{t("COMMON.SHOWALL")}</Link>
                        </div>
                    </div>
                    <div className="col-lg-5 col-12 p-0">
                        <div className="toprealErighttext">
                            <h2>{topText}<span>    <img className="toprealEtext" src={categoryImg} alt={categoryImg} />{topRatedText}</span></h2>
                            <img className="toprealEborder" src={toprealEttextline} alt="" />
                            <p><span className="topsubtext"> {checkOutText} </span> </p>
                            <span className="topsubtextfive">{finalcialText}</span>
                        </div>
                    </div>
                </div>
            </div>
        </section> : <section className={['Ourintitutions']}>
            <div className="container">
                <div className="row flexflow">
                    <div className="col-lg-7 paddleft3">
                        <div className={['ourbestimgslider']}>

                            <Slider {...sliderSettingTwoRowFourColumn}>
                                {

                                    instituteData?.map(offer => {
                                        return <div className={styles.ourbestimg}><Link to={`institute/${offer?.formattedLegalName}`} target="_blank">
                                            <div className={styles.ourbestimgbox}>
                                                <img src={!offer?.imageUrl ? `https://ui-avatars.com/api/?name=${offer?.name}&rounded=true` : `${NEW_URL}/${offer?.imageUrl}`} alt={''} />                                                </div>
                                        </Link>
                                        </div>
                                    })
                                }
                            </Slider>
                        </div>
                        <div className={`${styles.showallourbesttwo}` + " " + `mt-5` + " " + `text-center`} onClick={() => hitShowALL()}>
                            <Link to={'/globalMainSearchInstitutions#searchinstitute'} className={styles.showallourbesttoo}>{t("COMMON.SHOWALL")}</Link>
                        </div>

                    </div>
                    <div className={`${styles["our-best-alignment"]}` + " " + `col-lg-5`} >
                        <div className={styles.ourbestrighttext}>
                            <h2>{bestInstituteText}</h2>

                            <p>{topRatedText}</p>
                            <p>{checkOutText}</p>
                            <p>{finalcialText}</p>
                        </div>


                    </div>
                </div>
            </div>
        </section>
    )
}

export default InstituteList;