import React from 'react'
import { NEW_URL } from '../../../../constants/applicationConstants';
import Sign_out_squre from "../../../../assets/images/Sign_out_squre.svg";
import userimage from "../../../../assets/images/userimage.svg";
import { Footer } from '../../../common/footer/footer';
import Sidebar from "../sidebar/sidebar.js";

const ExpertQnaAnswerJsx = (props) => {
  const {
    data,
  } = props;

  return (
    <>
    <section className="main_customer_dashboard">
      <section className="entiredashboard maincustomerdash expert-dashboard">
        <div className="container-fluid">
          <div className="row">
            <div className="col-lg-4 col-sm-4">
              <div className="dashboardleftpanel martop40">
                <div className="dashboardlinkpop martop40">
                  <Sidebar />
                </div>
              </div>
            </div>
            <div className="col-lg-8 col-sm-8">
              <div className="dashboardrightpanel">
                <div className="dashboardrightpanelinner">
                  <div className="dashboardrightpannerinnerleft">
                    <h2 className="h2_style font-weight-600">QnA - Answered Questions</h2>
                    <h5 className="h5_style">All your answered questions are now conveniently located in one place for easy access.</h5>
                    <h5 className="h5_style">Take a moment to review your past responses. </h5>
                    <h5 className="h5_style fst-italic fw-light">Revisit any important insights or information that you may have missed.</h5>
                  </div>
                  <div className="dashboardrightpannerinnerright">
                    <div className="signout">
                      <a href="#logout">
                        <img src={Sign_out_squre} alt={''} /><span className="title">Logout</span>
                      </a>
                    </div>
                    <div id="logout" className="modal-window">
                      <div>
                        <a  title="Close" className="modal-close">Close</a>
                        <div className="modal-header">

                        </div><div className="modal-body mb-4">
                          <div className="modal-body-inner">
                            <h2 className="h2_style text-center">Are you sure you want to
Logout ?</h2>
                          </div>
                        </div>
                        <div className="modal-footer">
                          <button type="button" className="ctaonebutton"> Save</button>
                          <button type="button" className="ctatwobutton"> Save</button>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="myexpertnav myaccountnav qnanav martop40">
                  <ul>
                    <li>Pending Questions<span>30</span></li>
                    <li className="active">Answered Questions<span>30</span></li>
                  </ul>
                </div>

                <div className="questioninner mt-4">

                  <div className="questioninnerin mb-3">
                    <h5 className="h5_style">What are the different types of investments available to me in Canada, and which ones are best suited for my financial goals and risk tolerance? </h5>

                    <div className="d-flex questioninnerinbottom">
                      <div className="expertquestiondetails">
                        <div className="expertuser"><img src={!data?.imageUr ? `https://ui-avatars.com/api/?name=${data?.firstName}+${data?.lastName}&rounded=true` : `${NEW_URL}/${data?.imageUr}`}  alt={''} /></div>
                        <div className="questionid"><span>Question ID -</span> n5veg43<div className="questiondate">17- 12 -2022</div></div>

                      </div>
                      <div className="expertquestiondetailscategory"><span>Banking</span></div>
                    </div>
                    <p>Lorem ipsum sit amet Lorem ipsum sit amet Lorem ipsum sit amet Lor ipsum Lorem ipsum sit amet Lorepsum sit amet Lorem ipsu amet Lorem ipsum Lorem ipsum sit amet Lorem ipsum sit amet Lorem ipsum sit amet Lorem ipsum et Lorem ipsum Lorem ipsum sit amet Lor........<a href="">Read More</a></p>
                  </div>
                  <div className="questioninnerin mb-3">
                    <h5 className="h5_style">What are the different types of investments available to me in Canada, and which ones are best suited for my financial goals and risk tolerance? </h5>

                    <div className="d-flex questioninnerinbottom">
                      <div className="expertquestiondetails">
                        <div className="expertuser"><img src={userimage}  alt={''}/></div>
                        <div className="questionid"><span>Question ID -</span> n5veg43<div className="questiondate">17- 12 -2022</div></div>

                      </div>
                      <div className="expertquestiondetailscategory"><span>Banking</span></div>
                    </div>
                    <p>Lorem ipsum sit amet Lorem ipsum sit amet Lorem ipsum sit amet Lor ipsum Lorem ipsum sit amet Lorepsum sit amet Lorem ipsu amet Lorem ipsum Lorem ipsum sit amet Lorem ipsum sit amet Lorem ipsum sit amet Lorem ipsum et Lorem ipsum Lorem ipsum sit amet Lor........<a href="">Read More</a></p>
                  </div>
                  <div className="questioninnerin mb-3">
                    <h5 className="h5_style">What are the different types of investments available to me in Canada, and which ones are best suited for my financial goals and risk tolerance? </h5>

                    <div className="d-flex questioninnerinbottom">
                      <div className="expertquestiondetails">
                        <div className="expertuser"><img src={userimage}  alt={''}/></div>
                        <div className="questionid"><span>Question ID -</span> n5veg43<div className="questiondate">17- 12 -2022</div></div>

                      </div>
                      <div className="expertquestiondetailscategory"><span>Banking</span></div>
                    </div>
                    <p>Lorem ipsum sit amet Lorem ipsum sit amet Lorem ipsum sit amet Lor ipsum Lorem ipsum sit amet Lorepsum sit amet Lorem ipsu amet Lorem ipsum Lorem ipsum sit amet Lorem ipsum sit amet Lorem ipsum sit amet Lorem ipsum et Lorem ipsum Lorem ipsum sit amet Lor........<a href="">Read More</a></p>
                  </div>
                  <div className="questioninnerin mb-3">
                    <h5 className="h5_style">What are the different types of investments available to me in Canada, and which ones are best suited for my financial goals and risk tolerance? </h5>

                    <div className="d-flex questioninnerinbottom">
                      <div className="expertquestiondetails">
                        <div className="expertuser"><img src={userimage} alt={''} /></div>
                        <div className="questionid"><span>Question ID -</span> n5veg43<div className="questiondate">17- 12 -2022</div></div>

                      </div>
                      <div className="expertquestiondetailscategory"><span>Banking</span></div>
                    </div>
                    <p>Lorem ipsum sit amet Lorem ipsum sit amet Lorem ipsum sit amet Lor ipsum Lorem ipsum sit amet Lorepsum sit amet Lorem ipsu amet Lorem ipsum Lorem ipsum sit amet Lorem ipsum sit amet Lorem ipsum sit amet Lorem ipsum et Lorem ipsum Lorem ipsum sit amet Lor........<a href="">Read More</a></p>
                  </div>

                </div>
              </div>
            </div>
          </div>
        </div>
      </section> <Footer />
      </section>
     

    </>

  );
};

export default ExpertQnaAnswerJsx;