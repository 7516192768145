import React from 'react';
import { NEW_URL } from '../../../constants/applicationConstants';
import styles from "./homeArticleCard.module.css";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import { decodeHtmlEntities } from '../../../shared/helper';
export const HomeArticleCardJSX = (props) => {
  const { t } = useTranslation();
  const {
    blogimage,
    blogtitle,
    blogshort_content,
    blogslug,
    blogauthorimage,
    blogimageUrl,
    blogauthor,
    blogdatepublish,
    blog
  } = props;
  return (
    <>
      <div className={[styles.itemtts + " " + 'bloglist' + " " + 'd-flex']}>
        <Link to={`/articleDetails/${blogslug}`} target="_blank">
          <div className={[styles['item-img'] + " " + 'blogimage']}>
            <img src={!blogimage
            ? `https://ui-avatars.com/api/?name=${blogtitle}&rounded=true`
            : `${blogimage}`} alt={blogtitle} />
          </div>
          <div className={[styles['item-text'] + " " + 'blogtext']}>
            <div className="d-flex basicuserdetails">
              <div className="userimage">
                <img src={!blogauthorimage

                  ? `https://ui-avatars.com/api/?name=${blogauthor}&rounded=true`
                  : `${NEW_URL}/${blogimageUrl}`} alt='' />
              </div>
              <div className="usertext">
                <div className="name">{blogauthor}</div>
                <div className="date">{blogdatepublish}</div>
              </div>
            </div>
            <h5>{blogtitle}</h5>
            <p>{decodeHtmlEntities(blogshort_content)}</p>
            
          </div>
        </Link>
      </div>
    </>
  )
}
