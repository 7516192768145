import React from 'react'
import uploadfile from '../../../../assets/Instituteppvimages/uploadfile.svg';
import planpricingtooltip from "../../../../assets/images/planpricingtooltip.svg";
import datecalender from "../../../../assets/images/datecalender.svg";
import { useEffect, useState } from 'react';

import DatePicker3 from "react-modern-calendar-datepicker";
import { formatInputValue } from '../../../../shared/helper';
import { TagsInput } from "react-tag-input-component";
import "react-modern-calendar-datepicker/lib/DatePicker.css";
import dropbox from "../../../../assets/images/dropbox.svg";
import { NEW_URL } from '../../../../constants/applicationConstants';
import { useTranslation } from "react-i18next";
import gridetooltip from "../../../../assets/images/planpricingtooltips.svg";
import "../myOffer/myoffer.model.css"
import "../../institute/institute.model.css"
import { MyAdCard } from '../../../common/myAdCard/myAdCard';
import { Pagination } from '../../../common/pagination/uiPagination';

const MyOfferJsx = (props) => {
  const {
    blogFormData,
    formError,
    onDateChange,
    onInputChangeHandler,
    showAddModal,
    onModalClose,
    onSubmitButtonclick,
    onTouchHandler,
    formSubmitButtonDisable,
    categoryData,
    subCategoryData,
    onMetaTagChange,
    onEditButtonClick,
    onDeleteButtonClick,
    adData,
    onScrollerChange,
    profileImagePreviewUrl,
    imgURL,
    editMode,
    onFileChange,page,onPaginationButtonClick
  } = props;

  const { t } = useTranslation();
  const limitData = JSON.parse(sessionStorage.getItem('loginData'));
  const itemsPerPage = 10

  const [currentPage, setCurrentPage] = useState(1);
  const currentItems = adData?.slice(
    (page - 1) * itemsPerPage,
    page * itemsPerPage
  );
  return (<section>    
    {!editMode &&
    <div className="craete-offer-containt">

      <div className="row">
        <div className="col-8">
          <span className="sub-title"> {t("instituteoffer.TITLE")} </span><br />
          <div className="userguide">


            <div className="dropdown-toggle userdropdown-toggle " id="dropdownMenu2" data-bs-toggle="dropdown" aria-expanded="false"><img src={gridetooltip} alt={'gride'} /> <span className="guide-text">{t("instituteoffer.HOWTOCREATEOFFER")}</span></div>
            <ul className="dropdown-menu userdropdown-menu" aria-labelledby="dropdownMenu2">
              <li><a className="dropdown-item" type="button">{t("instituteoffer.HOWTOCREATE")}</a>
                <ul className="dropdown-menu dropdown-submenu">
                  <li><a className="dropdown-item" type="button">{t("instituteoffer.UPLOADADIMAGE")} </a></li>
                  <li><a className="dropdown-item" type="button">{t("instituteoffer.ENTERADCAPTION")}</a></li>
                  <li><a className="dropdown-item" type="button">{t("instituteoffer.EXPIREDATE")}</a> </li>
                  <li><a className="dropdown-item" type="button">{t("instituteoffer.NEVEREXPIRE")}</a> </li>
                  <li><a className="dropdown-item" type="button">{t("instituteoffer.CLICKSUBMIT")}</a> </li>
                  <li><a className="dropdown-item" type="button">{t("instituteoffer.DELETEIT")}</a> </li>


                </ul>
              </li>
              <li><a className="dropdown-item" type="button">{t("instituteoffer.EDITAD")} </a>
                <ul className="dropdown-menu dropdown-submenu">
                  <li><a className="dropdown-item" type="button">{t("instituteoffer.CLICKEDIT")} </a> </li>
                  <li><a className="dropdown-item" type="button">{t("instituteoffer.POPUPEDITFIELD")}</a>   </li>
                  <li><a className="dropdown-item" type="button">{t("instituteoffer.POPUPINFO")} </a>  </li>
                </ul>
              </li>
              <li><a className="dropdown-item" type="button">{t("instituteoffer.DELETEEXISTINGAD")}  </a>
                <ul className="dropdown-menu dropdown-submenu">
                  <li><a className="dropdown-item" >{t("instituteoffer.DELETEOPTION")}  </a></li>


                </ul>
              </li>
              <li><a className="dropdown-item" type="button">{t("instituteoffer.ADDMOEAD")} </a>
                <ul className="dropdown-menu dropdown-submenu">
                  <li><a className="dropdown-item" >{t("instituteoffer.ADLIMIT")} </a></li>

                </ul>
              </li>
              <li><a className="dropdown-item" type="button">{t("instituteoffer.ADSTATUS")}</a>
                <ul className="dropdown-menu dropdown-submenu">
                  <li><a href="#" className="dropdown-item">{t("instituteoffer.SWITCH")}</a></li>
                  <li><a href="#" className="dropdown-item">{t("instituteoffer.ACTIVETOGGLE")}</a></li>
                  <li><a href="#" className="dropdown-item">{t("instituteoffer.INACTIVETOGGLE")}</a></li>


                </ul>
              </li>
            </ul>
          </div>
        </div>

        <button type="button" disabled={adData?.length === limitData?.totalAdsLimit} className="col-4 craete-ads-button" onClick={() => onModalClose(true)}>Create New Offer</button>

      </div>
    </div>
    }

    <div>
      <p className="offertitle">{t("instituteoffer.MYAD")}</p>
      <p className="offersubtitle">{t("instituteoffer.VIIBLEAD")}</p>
      <p className="offersubtitle">{t("instituteoffer.BELOWAD")} {limitData?.totalOffersLimit} {t("instituteoffer.BELOWAD1")}  </p>
      <p className="offertitle">{t("instituteoffer.MYACCOUNTLISTINGS")}</p>
    </div>



    <section classNames="addexpertformsec">
      <div className="expertformmain">

        {showAddModal && <div className="expertinnerformmain offernewform">
          <div className="expertformmainheader">
            <div className="expertformmainheaderleft">
            <h3>{editMode ? t("instituteoffer.CREATEOFFER") : "Create New Offer"}</h3>
            <div className="small">{editMode ? t("instituteoffer.CREATEOFFERTAG") : "How to create an Offer?"}</div>
            </div>


            <div className="expertformmainheaderright">
              <button type="button" className="myadbtn" onClick={() => onModalClose(false)}><svg version="1.0" xmlns="http://www.w3.org/2000/svg"
                width="64.000000pt" height="64.000000pt" viewBox="0 0 64.000000 64.000000"
                preserveAspectRatio="xMidYMid meet">

                <g transform="translate(0.000000,64.000000) scale(0.100000,-0.100000)"
                  fill="#ffffff" stroke="none">
                  <path d="M156 484 c-9 -8 -16 -20 -16 -26 0 -5 28 -39 62 -74 l61 -64 -61 -64
c-34 -35 -62 -69 -62 -74 0 -14 28 -42 42 -42 5 0 39 28 74 62 l64 61 64 -61
c35 -34 69 -62 74 -62 14 0 42 28 42 42 0 5 -28 39 -62 74 l-61 64 61 64 c34
35 62 69 62 74 0 14 -28 42 -42 42 -5 0 -39 -28 -74 -62 l-64 -61 -64 61 c-35
34 -69 62 -74 62 -6 0 -18 -7 -26 -16z"/>
                </g>
              </svg></button>
            </div>
          </div>

          <div className="row">
            <div className="col-lg-6 col-sm-6">
              <div className="uploadexpert">
                <div className="d-flex border-div">
                  <div className="upload-image"> <img src={uploadfile} alt={''} /></div>
                  <div className="brows-part">
                    {!(editMode || profileImagePreviewUrl) ? <><p className="uplod-title"><b>{t("instituteoffer.UPLOADLOGO")}</b> <span className="divider-text">Or</span></p>

                    </>
                      :
                      <div className="uploadlogo chnageupload">
                        <div className="brows-part">
                          <input type="file" id="upload_file" name="" onChange={onFileChange} />
                          <label for="upload_file">{"Change Image"}</label>
                        </div>
                      </div>
                    }
                    {
                      profileImagePreviewUrl ? <img
                        src={profileImagePreviewUrl}
                        className="select-img"
                        alt="edit profile"
                      /> : <div className="uploadlogo">
                        <input type="file" id="upload_filelogo" name="" onChange={onFileChange} />
                        <label for="upload_filelogo">

                          {editMode ?
                            <img
                              src={`${NEW_URL}/${imgURL}`}
                              className="select-img"
                              alt="edit profile"
                            />
                            : <p>Browse</p>
                          }

                        </label>
                      </div>}
                  </div>
                </div>
              </div>

              <div className="row">
                <div className="col-lg-6">
                  <DatePicker3 className="form-control" name="startDate"
                    onChange={(date) => { onDateChange('validFrom', date) }}
                    inputPlaceholder="dd/mm/yyyy"
                    shouldHighlightWeekends
                    wrapperClassName="input-container form-group"
                    maximumDate={{
                      year: blogFormData?.validTill?.year,
                      month: blogFormData?.validTill?.month,
                      day: blogFormData?.validTill?.day
                    }}
                    renderInput={({ ref }) => (
                      <> <label className="placeholder">{'Valid From*'}</label>
                        <input
                          ref={ref}
                          placeholder=" "
                          type="text"
                          required
                          className="input form-control"
                          value={formatInputValue(blogFormData?.validFrom)}
                        />
                        <div className="cut"></div>

                        <span className="dateim"><img src={datecalender} alt={''} /></span>
                      </>
                    )}
                    formatInputText={() => formatInputValue(blogFormData?.validFrom)}
                  />
                </div>
                <div className="col-lg-6">
                  <DatePicker3 className="form-control" name="startDate"
                    onChange={(date) => { onDateChange('validTill', date) }}
                    inputPlaceholder="dd/mm/yyyy"
                    shouldHighlightWeekends
                    wrapperClassName="input-container form-group"
                    minimumDate={{
                      year: blogFormData?.validFrom?.year,
                      month: blogFormData?.validFrom?.month,
                      day: blogFormData?.validFrom?.day
                    }}
                    renderInput={({ ref }) => (
                      <> <label className="placeholder">{'Valid Till*'}</label>
                        <input
                          ref={ref}
                          placeholder=" "
                          type="text"
                          required
                          className="input form-control"
                          value={formatInputValue(blogFormData?.validTill)}
                        />
                        <div className="cut"></div>

                        <span className="dateim"><img src={datecalender} alt={''} /></span>
                      </>
                    )}
                    formatInputText={() => formatInputValue(blogFormData?.validTill)}
                  />
                </div>
              </div>
              

            </div>
            <div className="col-lg-6 col-sm-6">
              <div className="input-container form-group"> <label className="placeholder">{t("instituteoffer.PRODNAME")}</label>
                <input
                  onBlur={onTouchHandler}
                  className={'input form-control '}
                  placeholder=" "
                  value={blogFormData.product}
                  name="product"
                  onChange={onInputChangeHandler}
                  maxLength={50} />

                <span className="text-danger">
                  {formError['product'] &&
                    (formError['product'] === "req"
                      ? 'Offer Name is Required'
                      : '')}
                </span>
              </div>

              <div className="input-container form-group"><label className="placeholder">{t("instituteoffer.DESCRIPTION")}</label>
                <textarea
                  onBlur={onTouchHandler}
                  className={"input form-control textareaform"}
                  placeholder=" "
                  value={blogFormData.caption}
                  name="caption"
                  onChange={onInputChangeHandler}
                  maxLength={150}></textarea>

                <span className="text-danger">
                  {formError['caption'] &&
                    (formError['caption'] === "req"
                      ? 'Caption is required'
                      : '')}
                </span>
              </div>
              <div className="row">
                <div className="col-lg-6 col-sm-6">

                  <div className="input-container form-group"><label className="placeholder">{t("instituteoffer.CATEGORY")}</label>
                    <select className="input form-control form-select" onChange={onInputChangeHandler} name="category">
                      <option value="">{t("instituteoffer.SELECTCATEGORY")}</option>
                      {categoryData.map((item) => {
                        return (
                          <>
                            <option value={item.institutionCategoryId} selected={item.institutionCategoryId == blogFormData.category}>{item.name}</option>
                          </>
                        )
                      })}
                    </select>

                  </div>
                </div>
                <div className="col-lg-6 col-sm-6">
                  <div className="input-container form-group"><label className="placeholder">{t("instituteoffer.RELATEDPROD")}</label>
                    <select className="input form-control form-select" onChange={onInputChangeHandler} name="relatedProduct">
                      <option value="" >{t("instituteoffer.SELECT")}</option>
                      {subCategoryData?.map((item) => {
                        return (
                          <>
                            <option value={item.institutionCategoryId} selected={item.institutionCategoryId == blogFormData.relatedProduct}>{item.name}</option>
                          </>
                        )
                      })}
                    </select>

                  </div>
                </div>
              </div>









            </div>
            <div className="col-lg-12">
              
            <div className="input-caption form-group">
                <label className="placeholder">{t("instituteoffer.METATAG")}<span className="plantooltip custooltip" ><img src={planpricingtooltip} alt={''} className="tooltipim" />
                  <div className="tooltipcontent"> {t("instituteoffer.METATAGTOOLTIP")}</div>
                </span></label>

                <div className="input-caption-border" id="tagInput" titleLbl={'Offer Meta Tag'}
                  onFocus={() => {
                    let elem = document.getElementById("tagInput");
                    elem.classList.add("my-classfocus");
                  }}
                  onBlur={() => {
                    let elem = document.getElementById("tagInput");
                    elem.classList.remove("my-classfocus");
                  }}

                >

                  <TagsInput
                    value={blogFormData.metaTag}
                    onChange={onMetaTagChange}
                    label="xxxxxxxxx"
                    name="metaTags"
                    placeHolder=""
                    classNames={{ tag: 'tag-clsxxxx', input: 'pt' }}
                  />
                </div>

              </div>
              
              <div className="input-container form-group"><label className="placeholder">{'External Link*'}</label>
                <input
                  onBlur={onTouchHandler}
                  className={'input form-control '}
                  placeholder=" "
                  value={blogFormData.url}
                  name="url"
                  onChange={onInputChangeHandler}
                  maxLength={100} />

                <span className="text-danger">
                  {formError['url'] &&
                    (formError['url'] === "req"
                      ? 'URL is Required'
                      : 'Enter valid URL')}
                </span>
              </div>

              <div className="mb-4 input-container form-group">
                <button type="button" disabled={formSubmitButtonDisable} className="showallourbesttoo  ebutton" onClick={() => onSubmitButtonclick()}>{t("instituteoffer.SAVE")}</button>
              </div>
            </div>
          </div>
        </div>
        }
      </div>
    </section>


    <div className="row ">
      {
        adData?.length > 0 ?
        currentItems?.map(data => {
            return < MyAdCard
              data={data}
              onEditButtonClick={() => onEditButtonClick(data)}
              onDeleteButtonClick={() => onDeleteButtonClick(data)}
              onScrollerChange={() => onScrollerChange(data)}
            />
          }) :
          <div className="dropdefault text-center mb-5">
            <div className="dropdefaultimage">
              <img src={dropbox} alt={'img'} />
            </div>
            <div className="dropdefaulttext">
              <h5>{t("instituteoffer.LIVEAD")}   </h5>
              <h5>{t("instituteoffer.CREATENEWAD")} </h5>
            </div>

          </div>
      }
      {currentItems?.length > 0 && <section className="newpagination" > <Pagination
              currentPage={currentPage}
              page={page}
              itemsPerPage={itemsPerPage}
              allOffersData={adData}
              currentItems={currentItems}
              onPaginationButtonClick={onPaginationButtonClick}

            /></section>}
    </div>


  </section>
  );
};

export default MyOfferJsx;
