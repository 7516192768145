import React, { useEffect, useState } from 'react'
import dropbox from "../../../../assets/images/dropbox.svg";
import Sidebar from "../sidebar/sidebar.js";
import { useTranslation } from "react-i18next";
import '../dashBoard/dashBoard.model.css';
import { Footer } from '../../../common/footer/footer';

import { format, parse } from 'date-fns';
import { Pagination } from '../../../common/pagination/uiPagination.js';

import { OfferCardListJSX } from '../../../common/offerCardList/offerCardList';
import { ArticlesCardJSX } from '../../../common/articlesCard/articlesCard';

const DashBoardJsx = (props) => {
  const {
    blogData,
    offerData,
    expertData,
    onPaginationButtonClick,
    onPaginationExButtonClick
  } = props;
  const { t } = useTranslation();

  const [openModal, setOpenModal] = useState(false);
  const [blog, setBlog] = useState({});


  useEffect(() => {

  }, []);




  const formattedDate = (value) => {
    const date = parse(value, 'dd MMM yyyy', new Date());
    return format(date, 'MMMM d, yyyy');
  };

  const settings = {
    dots: false,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1
  };

  return (
    <>
      <section className="main_institute_dashboard">
        <section class="entiredashboard maincustomerdash main institute_dashboard">

          <div className="container-fluid">
            <div className="row dashboard-decoration">
              <div className="col-lg-4 col-sm-4" style={{ margin: "0px" }}>
                <Sidebar />
              </div>
              <div className="col-lg-8 col-sm-8 dashboard-data-inst">
                <div className="dashboardrightpanel">
                

                        <p className="dahsboardTitle">{t("instituteDashboard.HEADING")}</p>
                        <h5 className="dahsboardSubTitle mb-2">{t("instituteDashboard.SUBHEADING")}</h5>
                   
        


                  <section className="offer-list">
                    <OfferCardListJSX
                      SubCatTitle=""
                      SubCatText=""
                      SubCatTextLineOne=""
                      redirectUrl=""
                      offerData={offerData}
                      isDescription={true}
                      isSlider={true}
                      slider={1}
                    />
                  </section>

                  <div className="dashboardblog ">
                    <p className="dahsboardTitle">{t("instituteDashboard.EXPERTAUTHOR")}</p>
                    <p className="dahsboardSubTitle">{t("instituteDashboard.REGISTERED")} </p>

                    <div className="expert-all-blog">
                      {

                        expertData?.length > 0 ?
                          expertData?.map(blog => {
                            return <div className={`col-lg-3 col-md-4 col-sm-6 p-0 blogblogmain `} key={blog.id}>
                              <div className="blog-margin">   <div className={`basicstructure`}>
                                <ArticlesCardJSX blogslug={blog?.slug}
                                  blogimage={blog?.image}
                                  blogtitle={blog?.title}
                                  articleauthor={blog?.author}
                                  blogcat={blog?.cats?.[0].name}
                                  articledatepublish={blog?.date_publish}
                                  
                                  isSerchArticle={false} />

                              </div>
                              </div>
                            </div>
                          }) :
                          <div className="empthy-blog">
                            <div>
                              <img src={dropbox} alt={''} />
                            </div>
                            <div>
                              <p>{t("instituteDashboard.NOBLOGS")}</p>

                            </div>
                          </div>
                      }
                   
                      {expertData?.length > 8 && <section className="newpagination"><Pagination
                        limit={8}
                        totalCount={expertData?.length}
                        onPaginationButtonClick={onPaginationExButtonClick}
                      /></section>}
                    </div>
                  </div>



                  <div className="dashboardblog dashboardexpert">
                    <p className="dahsboardTitle">{t("instituteDashboard.PRODUCTS")}</p>
                    <p className="dahsboardSubTitle">{t("instituteDashboard.PRODUCTSDESC")} </p>

                    <div className="expert-all-blog">
                      {
                        blogData?.length > 0 ?
                          blogData?.map(blog => {

                            return <div className={`col-lg-3 col-md-4 col-sm-6 p-0 blogblogmain ${blog.cats[0].cat_name?.replace(/\s+/g, '')}`} key={blog.id}>
                              <div className="blog-margin">   <div className={`basicstructure ${blog.cats[0]?.name}`}>
                                <ArticlesCardJSX blogslug={blog?.slug}
                                  blogimage={blog?.image}
                                  blogtitle={blog?.title}
                                  articleauthor={blog?.author}
                                  blogcat={blog?.cats?.[0].name}
                                  articledatepublish={blog?.date_publish}
                                  isSerchArticle={false} />

                              </div>
                              </div>
                            </div>

                          }) :
                          <div className="empthy-blog">
                            <div>
                              <img src={dropbox} alt={''} />
                            </div>
                            <div>
                              <p>{t("instituteDashboard.NOBLOGS")}</p>

                            </div>
                          </div>

                      }
                    </div>
                    {blogData?.length > 8 && <section className="newpagination"><Pagination
                      limit={8}
                      totalCount={blogData?.length}
                      onPaginationButtonClick={onPaginationButtonClick}
                    /></section>}
                  </div>
                </div>
              </div>
            </div>
          </div>
        

        </section>
        <Footer />
      </section>
    </>

  );
};

export default DashBoardJsx;
