import { useTranslation } from "react-i18next";
import React from 'react';
import servicebg from "../../../assets/Instituteppvimages/service-offered-bg.svg";
import servicebgmortgage from "../../../assets/Instituteppvimages/insppvServicesmortgage.svg";
import servicebgrealestate from "../../../assets/Instituteppvimages/insppvServicesrealestate.svg";
import servicebginvestment from "../../../assets/Instituteppvimages/insppvServicesinvestment.svg";
import servicebginsurance from "../../../assets/Instituteppvimages/insppvServicesinsurance.svg";
import servicebgbanking from "../../../assets/Instituteppvimages/insppvServicesbanking.svg";
import servicebgmortgagedesktop from "../../../assets/Instituteppvimages/Mortgagewebservicesbg.svg";
import servicebgrealestatedesktop from "../../../assets/Instituteppvimages/RealEstatewebservicesbg.svg";
import servicebginvestmentdesktop from "../../../assets/Instituteppvimages/Insvestmentwebservicesbg.svg";
import servicebginsurancedesktop from "../../../assets/Instituteppvimages/Insurancewebservicesbg.svg";
import servicebgbankingdesktop from "../../../assets/Instituteppvimages/Bankingwebservicesbg.svg";
import { useState } from "react";

export const ServiceSection = (props) => {
  const { t } = useTranslation();
  const { profileData } = props;
  const [showMore, setShowMore] = useState(false);

  return <section className={`service-offer ${showMore && 'show-all-active'}`}>
    <div className="container-fluid">
      <div className="heading">

        <img src={servicebgmortgagedesktop} alt={t("PPVINSTITUTE.SERVICETITLE")} className="bgmortgagedesktop" width="100%"/>
        <img src={servicebgrealestatedesktop} alt={t("PPVINSTITUTE.SERVICETITLE")} className="bgrealestatedesktop" width="100%"/>
        <img src={servicebginvestmentdesktop} alt={t("PPVINSTITUTE.SERVICETITLE")} className="bginvestmentdesktop" width="100%"/>
        <img src={servicebginsurancedesktop} alt={t("PPVINSTITUTE.SERVICETITLE")} className="bginsurancedesktop" width="100%"/>
        <img src={servicebgbankingdesktop} alt={t("PPVINSTITUTE.SERVICETITLE")} className="bgbankingdesktop" width="100%"/>

        <img src={servicebgmortgage} alt={t("PPVINSTITUTE.SERVICETITLE")} className="bgmortgage" width="100%"/>
        <img src={servicebgrealestate} alt={t("PPVINSTITUTE.SERVICETITLE")} className="bgrealestate" width="100%"/>
        <img src={servicebginvestment} alt={t("PPVINSTITUTE.SERVICETITLE")} className="bginvestment" width="100%"/>
        <img src={servicebginsurance} alt={t("PPVINSTITUTE.SERVICETITLE")} className="bginsurance" width="100%"/>
        <img src={servicebgbanking} alt={t("PPVINSTITUTE.SERVICETITLE")} className="bgbanking" width="100%"/>
        <h2>{t("PPVINSTITUTE.SERVICEHEADING")}</h2>
        <h3>{t("PPVINSTITUTE.SERVICESUBSUBTITLEONE")}</h3>
        <h3>{t("PPVINSTITUTE.SERVICESUBTITLE")}</h3>
      </div>
      <div className="serviceoffer-inner">
        <div className="disgrid grid-8 gap-0 mt-5">

          {
            profileData?.instituteServices?.map(service => {
              return <li>
                <img src={`/serviceicon/${service?.institutionService?.name.toLowerCase().replace(/[^a-z0-9]/g, "")}.svg`}  alt="Service Icon" />
                <h5>{service?.institutionService?.name}</h5>
              </li>
            })
          }
  
          {(profileData?.instituteServices && profileData?.instituteServices.length > 6 ) && <button className="d-block d-lg-none d-sm-none d-md-none  serviceshowhide" onClick={() => setShowMore(!showMore)}> {showMore ? 'Show Less' : 'Show More'}</button> }
        </div>
        {/* <div className={`showallourbesttwo mt-5 text-center`}>
             
                  <Link
                    to={{ pathname: "/listOfExpert", state: { data: profileData, frompage: 'ppvExpertsSection', pagePath: "/listOfExpert"} }}
                    className="showallourbesttoo"
                    style={{ textDecoration: "none" }}
                  >
                    {t("COMMON.SHOWALL")}
                  </Link>

            </div> */}
      </div>
    </div>
  </section>
}