import React from 'react'
import { Component } from 'react';
import ChangePlaneJsx from './changePlane.jsx'
import axios from "axios";
import { baseURL } from "../../../../../constants/applicationConstants"
import swal from "sweetalert";
import { toast } from "react-toastify";

export default class ChangePlane extends Component {

  constructor(props) {
    super(props);
    this.state = {
      planDetail: null,
      bilingPeriod: 'Annual Billing',
      planType: 'Elite',
      isInstitutionExpet: false,
      isProcessing: false,
      termAndCondition: false
    }
  }

  componentDidMount = () => {
    const flowType = localStorage.getItem("flowType")

    const planData = JSON.parse(sessionStorage.getItem('planData'));
    const instituteId = JSON.parse(sessionStorage.getItem("masterID"));
    const expertId = JSON.parse(sessionStorage.getItem("expertId"));

    if (planData) {
      this.setState({
        bilingPeriod: planData.period,
        planType: planData.planType,
        isInstitutionExpet: planData.isInstitutionExpet,
        termAndCondition: planData.termAndCondition
      })
    }
    this.setState({ isProcessing: true })
    let instituteUrl = `subscriptionPlans?subscriberType=institute&instituteId=${instituteId}`
    let expertUrl = `subscriptionPlans?subscriberType=expert&expertId=${expertId}`

    axios.get(`${baseURL}/${flowType === "expert" ? expertUrl : instituteUrl}`).then(res => {
      this.setState({ planDetail: res.data.data, isProcessing: false })
    })
    let token = JSON.parse(sessionStorage.getItem('token'));
    if (!token) {
      this.props.history.push("/");
    }
  }

  onBilingButtonClick = (data) => {
    this.setState({ bilingPeriod: data })
  }

  onPlanTypeButtonClick = (plan) => {
    this.setState({ planType: plan })
  }

  onInstitutionExpertClick = (data) => {
    this.setState({ isInstitutionExpet: data })
  }

  onTermsAndConditionClick = (value) => {
    this.setState({ termAndCondition: value })
  }

  onNextButtonClick = () => {
    const planTypeData = this.state.planDetail?.filter(data => data.planName === this.state.planType);
    let plantypeID = ''
    let rate = ''

    let subscriptionPlanId = planTypeData?.[0]?.subscriptionPlanId
    planTypeData?.[0].subscriptionPlanTypes?.map(data => {

      if (data.planType === "monthly" && this.state.bilingPeriod === 'Monthly Billing') {
        plantypeID = data.subscriptionPlanTypeId
        rate = data.price
      }
      else if (data.planType === "annually" && this.state.bilingPeriod === 'Annual Billing') {
        plantypeID = data.subscriptionPlanTypeId
        rate = data.price
      }
    })


    this.onViaCard(subscriptionPlanId,plantypeID)
return
    swal({
      title: "success",
      text: 'Are you sure you want to change the plan?',
      icon: "success",
    });
    this.setState({
      isLoading: false
    })


    // sessionStorage.setItem('planData', JSON.stringify({ planType: this.state.planType, rate, period: this.state.bilingPeriod, isInstitutionExpet: this.state.isInstitutionExpet, termAndCondition: this.state.termAndCondition }));
    // sessionStorage.setItem('planTypeID', JSON.stringify(plantypeID))
    // sessionStorage.setItem('subscriptionPlanId', JSON.stringify(planTypeData?.[0].subscriptionPlanId))
    // const type = localStorage.getItem('flowName');
    // this.props.history.push(getRoute(type, 'plans'))
  }

  onInvoiceButtonClick = () => {
    this.setState({ isProcessing: true });
    let flowName = localStorage.getItem('AdminFlow');
    const adminToken = sessionStorage.getItem('adminOldToken');

    let subscriptionPlanId = JSON.parse(sessionStorage.getItem("subscriptionPlanId"));
    let token = JSON.parse(sessionStorage.getItem("token"));
    let planTypeID = JSON.parse(sessionStorage.getItem("planTypeID"));

    let subURL = '';
    let instituteId;
    if (flowName === 'institute') {
      subURL = 'institute'
      instituteId = JSON.parse(sessionStorage.getItem("instituteId"));
    }
    else {
      subURL = 'expert'
      instituteId = JSON.parse(sessionStorage.getItem("expertId"));
    }

    const payload = {
      "subscriptionPlanTypeId": planTypeID,
      "tokenValidate": token,
      "subscriptionPlanPromotionId": this.state.promotionId,
      "subscriptionPlanId": subscriptionPlanId
    }

    axios.post(`${baseURL}/${subURL}/${instituteId}/subscriptionInvoice`, payload).then(res => {
      this.setState({ isProcessing: false });
      swal({
        title: "success",
        text: res.data.data.status,
        icon: "success",
      }).then(res => {
        if (res) {
          // back to institute if coming from institute //////////////////
          let comingFromInstitute = sessionStorage.getItem("comingFromInstitute")
          if (comingFromInstitute && comingFromInstitute) {
            // alert("from ins")
            // return
            let oldToken = localStorage.getItem("instituteExpertToken")
            sessionStorage.setItem("token", oldToken);
            localStorage.removeItem("instituteExpertToken");
            localStorage.removeItem("adInsExpert");
            localStorage.removeItem("instituteExpert");
            // localStorage.removeItem("AdminFlow");
            localStorage.setItem('flowName', 'institute')
            localStorage.setItem('flowType', 'institute')
            sessionStorage.removeItem("resumeOnBoard");

            sessionStorage.removeItem("expertId");
            sessionStorage.removeItem("description");
            sessionStorage.removeItem("expertData");
            sessionStorage.removeItem("subscriptionPlanId");
            sessionStorage.removeItem("planTypeID");
            sessionStorage.removeItem("planData");
            this.props.history.push("/dashBoard");
            sessionStorage.removeItem("comingFromInstitute");

          } else {
            // alert("from admin")
            // return
            sessionStorage.setItem("token", JSON.stringify(adminToken));
            sessionStorage.removeItem("adminOldToken")
            //sessionStorage.removeItem("loginData")
            sessionStorage.removeItem("expertId");
            sessionStorage.removeItem("description");
            sessionStorage.removeItem("expertData");
            sessionStorage.removeItem("subscriptionPlanId");
            sessionStorage.removeItem("planTypeID");
            sessionStorage.removeItem("planData");
            sessionStorage.removeItem("resumeOnBoard");

            this.props.history.push('./admin/opsDashboardHome')
          }

        }
      })
    }).catch((err) => {
      toast.error(err.response.data.error.message);
      this.setState({ isProcessing: false })
    })
  }

  onViaCard = (planId,typeId) => {

    this.setState({ isProcessing: true });
    let flowName = localStorage.getItem('AdminFlow');

    let subscriptionPlanId = planId;
    let token = JSON.parse(sessionStorage.getItem("token"));
    let planTypeID = typeId;

    let subURL = '';
    let instituteId=null;
    let expertId=null;
    if (flowName === 'institute') {
      subURL = 'institute'
      instituteId = JSON.parse(sessionStorage.getItem("instituteId"));
    }
    else {
      subURL = 'expert'
      expertId = JSON.parse(sessionStorage.getItem("expertId"));
    }

    const payload = {
      "subscriptionPlanTypeId": planTypeID,
      "tokenValidate": token,
      // "subscriptionPlanPromotionId": this.state.promotionId,
      "instituteId": instituteId ?? undefined,
      "expertId": expertId ?? undefined,
    }

    axios.post(`${baseURL}/subscriptionPlan/${subscriptionPlanId}/upgrade`, payload).then(resp => {
      this.setState({ isProcessing: false });
      swal({
        title: "succes",
        text: "You will be redirected to stripe payment page to complete the payment.",
        icon: "success",
        allowOutsideClick: false,
        allowEscapeKey: false
      }).then(res => {
        if (res) {
          
          window.location.href = expertId ? "expertPayment" : "myPayment"
        }
      })
    }).catch((err) => {
      toast.error(err.response.data.error.message);
      this.setState({ isProcessing: false })
    })
  }

  render() {
    return (
      <div>
        <ChangePlaneJsx
          bilingPeriod={this.state.bilingPeriod}
          onBilingButtonClick={this.onBilingButtonClick}
          planType={this.state.planType}
          onPlanTypeButtonClick={this.onPlanTypeButtonClick}
          isInstitutionExpet={this.state.isInstitutionExpet}
          onInstitutionExpertClick={this.onInstitutionExpertClick}
          termAndCondition={this.state.termAndCondition}
          onTermsAndConditionClick={this.onTermsAndConditionClick}
          onNextButtonClick={this.onNextButtonClick}
          planDetail={this.state.planDetail}
        />
      </div>
    )
  }
}