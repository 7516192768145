import React, { useEffect } from 'react' 

import Sidebar from "../../institute/sidebar/sidebar.js"; 
import Registered from "../myExpert/myExpert.js"

import { Trans, useTranslation } from 'react-i18next';
import { useHistory } from "react-router-dom";

import "../myExpert/myExpert.model.css"
import { Footer } from '../../../common/footer/footer';
import myexpertbg from "../../../../assets/Instituteppvimages/myexpertbg.svg";

import { ExpertListCardJSX } from '../../../common/expertListCard/expertListCard';

const MyExpertJsx = (props) => {
  const history = useHistory();
  const { t } = useTranslation();


  const {
    expertData,
    activeTab,
    onTabClick,
    onFirstLoginClick
  } = props;

  const loginData = JSON.parse(sessionStorage.getItem('loginData'));


  const getInstitutionName = () => {
    let tempArr = [];

    expertData?.map(cat => {

      tempArr.push(cat?.slugUrl)
    })
    localStorage.setItem("slugSet", tempArr.join())




  }
  useEffect(() => {
    getInstitutionName()
  });

  const planType = sessionStorage.getItem("planName")
  const addAnotherExpert = () => {
    let token = sessionStorage.getItem("token")
    localStorage.setItem("instituteExpertToken", token)
    localStorage.setItem("instituteExpert", true)
    localStorage.setItem("adInsExpert", true)

    history.push("/expertRegistration");
  }
  return (
    <>
      <>

        {  activeTab === 'partial' ?
          <section className="main_institute_dashboard">
          <section class="entiredashboard maincustomerdash main institute_dashboard">
            <div className="container-fluid">
              <div className="row dashboard-decoration">
                <div className="col-lg-4 col-sm-4">
                  <Sidebar />
                </div>
                <div className="col-lg-8 col-sm-8 dashboard-data-exp">
                  <div className="dashboardrightpanel">
                  <div  >
                      <div className="row" >

                        <div className="col-lg-6 col-sm-6 p-0 mb-4">

                          <p className="dahsboardTitle">{t("instituteExpertrs.HEADING")}</p>
                          <p className="dahsboardSubTitle"> <Trans i18nKey="instituteExpertrs.SUBHEADING" ></Trans> </p>
                        </div>
                        
                      </div>
                      
                    </div>
                    <div className="row add-expert">
                      <div className="col-6 add-expert-data">
                        <h3 className="add-expert-title">{t("instituteExpertrs.ADDEXPERT")}</h3>
                        <p className="add-expert-subtitle">{t("instituteExpertrs.ADDEXPERTTITLE")}</p>
                        <p className="add-expert-limit">{t("instituteExpertrs.ADDEXPERTLIMIT")} {loginData?.totalExpertsLimit} </p>
                        <div className="addexpertright">
                          {planType && planType === "Premium" ? <button type="button" className="add-expert-button" onClick={addAnotherExpert} >{t("instituteExpertrs.ADDEXPERTBUTTON")}</button>
                            :
                            <button type="button" className="add-expert-button" onClick={addAnotherExpert} >{t("instituteExpertrs.ADDEXPERTBUTTON")}</button>

                          }
                        </div>
                      </div>
                      <div className="col-6 add-expert-img">
                        <img src={myexpertbg} alt={'Background'} />
                      </div>
                    </div>


                    
                    <div className="myexpert-nav row">
                      <p className="partially-registered col-6" onClick={() => onTabClick('acntStng')}>{t("instituteExpertrs.REGEXPERTS")}</p>
                      <p className="registered-experts col-6">{t("instituteExpertrs.PARREGEXPERTS")}</p>
                    </div>
                    <div className="expert-section  unver institutesearchmainlistingsection">
                      {props?.expertData?.length > 0 ? (
                        props.expertData.map(offer => (
                            <>
                            <ExpertListCardJSX
                              offerimageUrl={offer?.imageUrl}
                              offerbusinessPhoneNumber={offer.expertSpecializations[0]?.businessPhoneNumber??""}
                              offercity={offer.expertSpecializations[0]?.city??""}
                              offeremail={offer.email}
                              offerfirstName={offer?.firstName}
                              offerslugUrl={offer?.slugUrl}
                              offerlastName={offer?.lastName}
                              expertSpecializations={offer.institutionCategory                              }
                              offerplanName={offer.planName}
                              status={offer.status}
                              offercategoryName={offer.expertSpecializations[0]?.parentInstitutionCategory??"partial"}
                              offername={offer?.name} 
                              onFirstLoginClick={()=> onFirstLoginClick(offer)}
                              isResumeShowAndContactHide={true}
                            />
                            </>
                           
                        ))
                      ) : (
                        <h3>{t("instituteExpertrs.NOEXPERTMSG")}</h3>
                      )}
                    </div>

                      
                    </div>
                    {/* {props?.expertData?.length > 6 && <div className="pagination-center"><PreNexPagination
                    limit={6}
                    totalCount={props?.expertData?.length}
                    onPaginationButtonClick={onPaginationButtonClick}/> </div>
                      } */}
                  </div>
                </div>
              </div>
            
          </section>
          <Footer/>
          </section>
          : <Registered />}

      </>
    </>

  );
};

export default MyExpertJsx;
