import React, { useState } from 'react'
import { Link } from "react-router-dom";
import { Trans, useTranslation } from "react-i18next";
import { NEW_URL } from '../../../constants/applicationConstants';
import SubCatIcons from '../subCategoryCard/subCategoryCard.jsx'
import styles from './subCategoryCardList.module.css';




const SubCategoryCardList = (props) => {
    const { t } = useTranslation();

    const {
        showMoreData, selectedData, getData, getById, cat1, feature, setShowMore,
        heading, subHeading
    } = props;


    return (
        <>

            <section className="whatareyou ">
                <div className="container">
                    <h2>{heading} <span>{subHeading}</span></h2>
                    <div className={`whatareyoucat  ${showMoreData ? 'showEnable' : ''}`}>
                        <div className={`whatarecatbox what-res ${selectedData === 0 ? 'active' : ''}`} onClick={() => getData()}>
                            <img src={cat1} alt="icons" />
                            <p className="hover-none">All</p>
                        </div>
                        {
                            feature?.map(data => {
                                return (
                                    <SubCatIcons
                                        iconsData={data}
                                        selectedData={selectedData}
                                        getById={getById}
                                    />
                                )
                            })
                        }


                    </div><div className="d-block d-sm-none smmore" onClick={(e) => { setShowMore(!showMoreData) }}>{showMoreData ? 'Show Less...' : 'Show More...'} </div>
                </div>
            </section>




        </>

    );
};

export default SubCategoryCardList;
