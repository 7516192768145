import React from 'react';
import moment from "moment";
import { useTranslation } from "react-i18next";
import Slider from "react-slick";
import { OfferCardJSX } from '../offerCard/offerCard';
import dropbox from "../../../assets/images/dropbox.svg";
import styles from './offerCardList.module.css';
import { Link } from "react-router-dom";
import { StyleSheetManager } from 'styled-components';

export const OfferCardListJSX = (props) => {

  const { t } = useTranslation();
  const {
    SubCatTitle,
    SubCatText,
    offerparentInstitutionCategory,
    SubCatTextLineOne,
    SubCatTextLineTwo,
    offerData,
    redirectUrl,
    isDescription,
    isSlider,
    profileData,instituteID,
    slider = 2
  } = props;

  const hitShowALL = () => {
    instituteID && sessionStorage.setItem("selectCategory",instituteID)
  }
  const settings = isSlider ? 
  {
    dots: false,
    infinite: false,
    speed: 500,
    arrow:true,
    adaptiveHeight: true,
    slidesToShow: slider,
    slidesToScroll: 1,
    centerMode: false,
    centerPadding: '20px',
    //  initialSlide:2    
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          infinite: true,
          arrow:true,
          dots: false
        }
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          initialSlide: 2,
          arrow:true,
          dots: false
        }
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          arrow:true,
          dots: false
        }
      }
    ]
  } : {
    infinite: true,
    speed: 500,
    slidesToShow: 2,
    slidesToScroll: 1,
    arrow:true,
    responsive: [
      {
        settings: "unslick",
        breakpoint: 5000,
      },
      
      
    ],
  } ;
  return (
    <>

      <div className="container">
        <div className={
          isDescription
            ? `${styles.textalign}`
            : `${offerparentInstitutionCategory}`
        }>
           { SubCatTitle==""?<div></div>: <h2>{SubCatTitle}</h2>}
         { SubCatTitle==""?<div></div>:<h6>{SubCatText}  <br/>{SubCatTextLineTwo} {SubCatTextLineOne}
          </h6>}
        </div>

        <div className="row offersss">
          {offerData?.length > 0 ? (
            <Slider {...settings}>
              {offerData.map((offer) => {
                return (
                  <div key={offer.id} className={isSlider ? "col-lg-12 col-md-12 col-sm-12 col-xs-12" : `${[styles.gridblock]} col-lg-6 col-md-6 col-sm-12 col-xs-12`}>
                    <OfferCardJSX
                      offerimgurl={offer?.imageUrl}
                      offerparentInstitutionCategory={offer?.parentInstitutionCategory}
                      offervalidTo={moment(offer?.validTo).format('Do MMM YYYY')}
                      offerofferName={offer?.offerName}
                      offerdescription={offer?.description}
                      offerexternalUrl={offer?.externalUrl}
                      isDescription={isDescription}
                      
                    />
                  </div>
                );
              })}
            </Slider>
          ) : (
            <div className="dropdefault text-center">
              <div className="dropdefaultimage">
                <img src={dropbox} alt={"dropbox"} />
              </div>
              <div className="dropdefaulttext">
                <h5 className="opacity0">{t("COMMON.NOTFOUND")}</h5>
              </div>
            </div>
          )}
         { isSlider
          ?<div className="latestbutton margintop dasof" onClick={() => hitShowALL()}>
            <Link  to={{
                pathname: redirectUrl,
                state: { data: profileData, frompage: 'ppvAdsSection', pagePath: redirectUrl }
              }} className="showallourbesttoo " style={{ textDecoration: "none" }}>{t("COMMON.SHOWALL")}</Link>
          </div>
          :<div></div>}
        </div>
      </div> 
    </>
  )
}
