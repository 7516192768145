import React, { useState } from 'react'
import { useEffect, } from 'react';
import { useTranslation } from "react-i18next";
import { sliderSettingFour } from "../../../../shared/sliderSetting.js"
import { Footer } from '../../../common/footer/footer';
// Import css files
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { Link } from "react-router-dom";
import Slider from "react-slick";
import AOS from 'aos';
import 'aos/dist/aos.css';
import "../globalMainSearch/globalMainSearch.css";
import "../globalMainSearch/globalMainSearchresponsive.css";
import { baseURL } from '../../../../constants/applicationConstants';
import axios from 'axios';
import { useLocation, useHistory } from 'react-router-dom';
import { Loader } from '../../../common/loader/loader';
import { onOfferClick } from '../../../../shared/helper';
import { InstitutionCardList } from '../../../common/institutionCardList/institutionCardList.jsx';
import ExpertCardJsx from "../../../common/expertCard/expertCard.jsx"
import { ProductCardList } from "../../../common/productCardList/productCardList.jsx";
import { OfferCardListJSX } from '../../../common/offerCardList/offerCardList';
import { HomeArticleCardJSX } from '../../../common/homeArticleCard/homeArticleCard'

const GlobalMainSearchJsx = (props) => {
    const location = useLocation(); // Hook to access the location object
    const history = useHistory();

    const { t } = useTranslation();
    useEffect(() => {

        AOS.init();
    }, [])



    const [searchedData, setSearchedData] = useState('');
    const [allOffersData, setAllOffersData] = useState([]);
    const [overallOffers, setOverallOffers] = useState([]);
    const [isProcessing, setIsProcessing] = useState(true);
    const [blank, setBlank] = useState(false);

    const searchParams = new URLSearchParams(location.search);

    const search = searchParams.get('search') || '';
    const activeInstitutes = allOffersData?.institutes?.filter(institute => institute.status === 'active');
    const activeExperts = allOffersData?.experts?.filter(experts => experts.status === 'active');

    const [productDetails, setProductDetails] = useState({});
    const closeFunction = () => {
        setProductDetails({})
    }


    useEffect(() => {
        setIsProcessing(true);
        if (search) {
            setSearchedData(search);
            axios.get(`${baseURL}/search-content?keyword=${search}`)
                .then(res => {
                    setAllOffersData(res.data.data);
                    setOverallOffers(res.data.data);
                    setIsProcessing(false);
                });
        } else {
            setIsProcessing(false); // Handle the case where there's no search value
            history.push("/");

        }
    }, [location.search]);
    useEffect(() => {
        if (allOffersData?.offers?.length === 0 && allOffersData?.products?.length === 0 && allOffersData?.institutes?.length === 0 && allOffersData?.experts?.length === 0 && allOffersData?.articles?.body?.length === 0) {
             
        }
    }, [allOffersData])

    // Dependency array to re-run when search changes



    return (
        <>
            { 
                <section className="searchcustom">
                    {allOffersData?.offers?.length>0 &&
                        <section className="exclusive searchtopproduct">
                            <div className="container">

                                <OfferCardListJSX
                                    SubCatTitle={`“${searchedData}” ${t("COMMON.INOFFERS")}`}
                                    isGlobleMainSearch={true}
                                    offerData={allOffersData?.offers}
                                    redirectUrl="/offers"
                                    isDescription={false}
                                    isSlider={true}
                                />

                            </div>
                        </section>
                    }
                    {allOffersData?.experts?.length>0 &&
                        <section className="ourbest ourbestexpertsearch">
                            <div className="container">
                                <div className="ourbexttext ourbestmargin" >
                                    <h2>“{searchedData}” in Experts</h2>
                                </div>
                                <Slider {...sliderSettingFour}>
                                    {
                                        activeExperts?.map(data => {
                                            return <>
                                                <ExpertCardJsx data={data} CategoryID={data?.institutionCategoryId} />

                                            </>
                                        })
                                    }
                                </Slider>
                            </div>
                            <div className="showall  text-center">
                                <Link to={'/globalMainSearchExpert#searchexpert'} className="showallbtn mt-2 mb-3">{t("COMMON.SHOWALL")}</Link>
                            </div>
                        </section>
                    }

{
                    allOffersData?.products?.length>0 && <ProductCardList
                        productData={allOffersData?.products}
                        isGlobleMainSearch={true}
                        searchText={`“${searchedData}” ${t("COMMON.INPRODUCTS")}`}
                        subCategoryLayout={false}
                        onProductImageClick={onOfferClick}
                        isCategorySubCategory={false}
                        isProductName={false}
                        isViewButton={false}
                    />
            }
                    {allOffersData?.institutes?.length>0 &&
                        <InstitutionCardList searchedData={searchedData} search={search} activeInstitutes={activeInstitutes} />
                    }

                    {allOffersData?.articles?.body?.length>0 &&
                        <section className="searcharticle">
                            <h2>“{searchedData}” in Articles</h2>
                            <div className="container">
                                <div className="item1">
                                    {
                                        allOffersData?.articles?.body?.map(data => {
                                            return <>
                                                <HomeArticleCardJSX
                                                    blogslug={data?.slug}
                                                    blogimage={data?.image}
                                                    blogtitle={data?.title}
                                                    blogshort_content={data?.short_content}
                                                />
                                            </>
                                        })
                                    }

                                </div>
                                <div className="showall text-center">
                                    <Link to={`/searchArticles?search=${search}`} className="showallbtn mt-3 mb-3">{t("COMMON.SHOWALL")}</Link>
                                </div>
                            </div>
                        </section>
                    }

                </section> 
                
            }
            {
                allOffersData?.offers?.length === 0 && allOffersData?.products?.length === 0 && allOffersData?.institutes?.length === 0 && allOffersData?.experts?.length === 0 && allOffersData?.articles?.body?.length === 0 && <h1 className="text-center mt-4 mb-4">{t("COMMON.NORECORDFOUND")}</h1>
            }

            {isProcessing && <Loader />}
            <Footer />

        </>

    );
};

export default GlobalMainSearchJsx;