import React from 'react';
import styles from "./myPaymentCard.module.css";
import btndownloadicon from "../../../assets/images/btndownloadicon.svg";
import transactionic from "../../../assets/images/transactionic.png";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import { useHistory } from "react-router-dom";
import { format } from 'date-fns';
import { decrypt } from '../../../shared/helper';
import leftarrow from "../../../assets/images/back_svg.svg";

export const MyPaymentCard = (props) => {
  const { t } = useTranslation();
  const {
    isShowCard,
    isCardSummary,
    isPaymentSummary,
    paymentData,
    onReplaceCardClick,
    formData,
    onInputChangeHandler,
    onTouchHandler,
    invoiceDownload,
    onBackCardClick,
    formError,
    formSubmitButtonDisable,
    formSubmitHandler,
    isLoading,
    question,
    Sign_out_squre,
    cardDetails,
    backToAddCard,
    onCompletePayment,
    backToPayment
  } = props;

  const history = useHistory(); 

  return <div>
    {!isShowCard && !isCardSummary && !isPaymentSummary && <section>
      <div className="d-flex mt-3">
        <div className="cardsubscriptionleft">
          <h4>{t("institutePayments.YOURCARD")}</h4>
          <p>{t("institutePayments.SAVECARDTITILE")}</p>
          <div className="previewcardinner mb-4">
            <div className="namecardholder text-white">{paymentData?.name}</div>
            <div className="cardnumber text-white"><div>{t("institutePayments.CARDDEMONUMBER")}{paymentData?.cardLast4Digits}</div><div className="cardde"></div></div>
            <div className="carddate text-white">{paymentData?.expiryMonth}/{paymentData?.expiryYear}</div>
          </div>
          <div className="replacelogo2 ctaonebutton" onClick={onReplaceCardClick}>
            {t("institutePayments.REAPLACECARD")}
          </div>
        </div>
        <div className="cardsubscriptionright">
          <h4>{t("institutePayments.SUBSRIPTIONTITLE")}</h4>
          <p>{t("institutePayments.SUBSRIPTIONSUBTITLE")}</p>
          <div className="subscribtionsummery">
            <div className="subscribtionsummeryheading">
              <h4>Customer since {paymentData && format(new Date(paymentData?.customerJoiningDate), 'dd MMM yyyy')}</h4>
            </div>
            <div className="subscriptiondetailsmiddle">
              <div className="currentplan text-center">
            <h4 className="text-center">{t("institutePayments.CURRENTPLAN")} <span>{paymentData?.planName}</span></h4>
                </div> 
            </div>
            <div className="d-flex subscriptiondetails">
              <div className="subscriptiondetailsleft text-center">
                <div className="substartdates">
                  {t("institutePayments.SUBSRIPTIONSTART")}
                </div>
                <div className="substartdatesdetail">
                  <h4>{paymentData && format(new Date(paymentData?.subscriptionStartDate), 'dd MMM yyyy')}</h4>
                </div>
                
              </div>
              <div className="subscriptiondetailsright text-center">
                <div className="substartdates">
                  {t("institutePayments.SUBSRIPTIONEND")}
                </div>
                <div className="substartdatesdetail">
                  <h4>{paymentData && format(new Date(paymentData?.subscriptionEndDate), 'dd MMM yyyy')}</h4>
                </div>
                
              </div>
            </div>
           
          </div>
          <div className="currentplan mt-4 text-center">
               
               {paymentData?.planName === 'Premium' &&
                 <Link
                   to={{
                     pathname: "/changeplan"
                   }}
                   className="replacelogo2 ctaonebutton">
                     
                   {t("institutePayments.CHANGEPLAN")}
                 </Link>}
             </div>
        </div>
      </div>
      <div className="paymenthistory mt-5">
        <h3>{t("institutePayments.PAYMENTTITLE")}</h3>
        <p>{t("institutePayments.PAYMENTSUBTITLE")}</p>
        <ul className="paymentstructure">
          <li><span>{t("institutePayments.PERIOD")}</span> <span>{t("institutePayments.AMOUNT")}</span> <span>{t("institutePayments.STATUS")}</span> <span>{t("institutePayments.INVOICE")}</span></li>
          {paymentData?.subscriptions.map((item) => {
            return (
              <>
                <li>
                  <span>{format(new Date(item.startDate), ' MMM-yyyy')} - {format(new Date(item.endDate), ' MMM-yyyy')}</span>
                  <span>${parseFloat((item?.amount ?? 0) / 100).toFixed(2)}</span>
                  <span>{t("institutePayments.PAID")}</span>
                  <span><button type="button" className="paybtn" onClick={() => invoiceDownload(item)}>{t("institutePayments.DOWNLOADINVOICE")}<span><img src={btndownloadicon}/></span></button></span>
                </li>                                      </>
            )
          })}


        </ul>
      </div>
    </section>}
    <section>
                 

                 {
                   isShowCard ?

                     <div className="replacecard">
                       <div className="d-flex align-items-center">
                       <div className="back_btn_new" onClick={() => onBackCardClick()}><img src={leftarrow} alt={'icon'} /></div>
<div className="dsblock">
                       <h2>Card Replacement</h2>
                           </div></div>
                           <h2 className="font-we-400">Replace your Existing Card</h2>
                       <h4 className="">Do you wish to add another credit card? Do it easily from below!
                           Please make sure you are correctly entering the card details.</h4>
                       <div className="detailscardenter">
                         <p>Please enter the following card information:</p>
                         <div className="cardsubscription_details d-flex">
                   <div className="cardsubscriptionleft">
                     <h4>{t("expertDashboard.CARD")}</h4>
                     <p>{t("expertDashboard.CARD_text")}</p>
                     <div className="previewcardinner  previewcardback mb-4">
                       {/* <div className="namecardholder text-white">{paymentData?.name}</div> */}
                       {/* <div className="cardnumber text-white"><div>{paymentData?.cardLast4Digits}</div></div> */}
                       {/* <div className="carddate text-white">{paymentData?.expiryMonth}/{paymentData?.expiryYear}</div> */}
                     </div>
                    
                   </div>
                   <div className="cardsubscriptionright">
                         <div className="mt-4  input-container form-group">
                         <div className="placeholder">Name of the Cardholder</div>
                           <input
                             onChange={onInputChangeHandler}
                             onBlur={onTouchHandler}
                             required
                             type="text"
                             value={(formData) ? formData.name : null}
                             placeholder=" "
                             maxLength="50"
                             className={
                               formError["name"]
                                 ? "input form-control  is-invalid"
                                 : "input form-control"
                             }
                             id="name"
                             name="name"
                           />
                         
                           <span className="text-danger">

                             {formError["name"] &&
                               (formError["name"] === "req"
                                 ? "Card member name is required!"
                                 : "Please enter a proper name!")}
                           </span>
                         </div>
                         <div className="mb-4 input-container form-group">
                         <div className="placeholder">Card Number</div>
                           <input
                             onChange={onInputChangeHandler}
                             onBlur={onTouchHandler}
                             required
                             type="text"
                             maxLength="16"
                             value={(formData) ? formData.card : null}
                             placeholder=" "
                             className={
                               formError["card"]
                                 ? "input form-control  is-invalid"
                                 : "input form-control"
                             }
                             id="card"
                             name="card"
                           />
                          
                           <span className="text-danger">
                             {formError["card"] &&
                               (formError["card"] === "req"
                                 ? "Card Number is required!"
                                 : "Please enter a proper Card Number!")}
                           </span>
                         </div>
                         <div className="disgrid grid-2 cardvalid">

                           <div className="inlinecard">
                             <div className="input-container form-group">
                               <span className="validth">Valid Thru</span>
                              <div className="placeholder">Month</div> <input
                                 onChange={onInputChangeHandler}
                                 onBlur={onTouchHandler}
                                 value={(formData) ? formData.month : null}
                                 required
                                 type="text"
                                 placeholder=" "
                                 className={
                                   formError["month"]
                                     ? "input form-control  is-invalid"
                                     : "input form-control"
                                 }
                                 id="month"
                                 name="month"
                                 maxLength="2"
                               />
                               
                               <span className="text-danger">
                                 {formError["month"] &&
                                   (formError["month"] === "req"
                                     ? "Month is required!"
                                     : "Please enter a proper Month!")}
                               </span>
                             </div>
                             <div className="input-container form-group">
                               <div className="placeholder">Year</div> <input
                                 onChange={onInputChangeHandler}
                                 onBlur={onTouchHandler}
                                 required
                                 type="text"
                                 placeholder=" "
                                 value={(formData) ? formData.year : null}
                                 maxLength="4"
                                 className={
                                   formError["year"]
                                     ? "input form-control  is-invalid"
                                     : "input form-control"
                                 }
                                 id="year"
                                 name="year" />
                              
                               <span className="text-danger">
                                 {formError["year"] &&
                                   (formError["year"] === "req"
                                     ? "year  is required!"
                                     : "Please enter a proper year !")}
                               </span>
                             </div>
                           </div>
                           <div className="input-container form-group">
                            <div className="placeholder">CVC</div>  <input
                               onChange={onInputChangeHandler}
                               onBlur={onTouchHandler}
                               required
                               type="text"
                               placeholder=" "
                               maxLength="3"
                               value={(formData) ? formData.cvc : null}
                               className={
                                 formError["cvc"]
                                   ? "input form-control  is-invalid"
                                   : "input form-control"
                               }
                               id="cvc"
                               name="cvc" />
                            
                             <span className="text-danger">
                               {formError["cvc"] &&
                                 (formError["cvc"] === "req"
                                   ? "cvc  is required!"
                                   : "Please enter a proper cvc !")}
                             </span>
                           </div>
                         </div>
                         <button disabled={formSubmitButtonDisable} onClick={formSubmitHandler} type="button" className={
                           formSubmitButtonDisable
                             ? "btn-secondary btn mt-4 ctatwobutton"
                             : "mt-4 showallourbesttoo "
                         }> {isLoading ? t("COMMON.PROCESSING") : t("COMMON.ADDCARD")}</button>
                       </div>
                     </div>
</div>
</div>
                     : null
                 }
               </section>

               {isCardSummary && <section>
                  <div className="dashboardrightpannerinner">
                    <div className="dashboardrightpannerinnerleft">
                    <div className="d-flex align-items-center">
                      <div className="back_btn_new" onClick={() => backToAddCard()}><img src={leftarrow} alt={'icon'} /></div>
                        <h2 className="h2_style font-weight-600">Make Payment</h2>
                       </div>
                      <div className="leftpad">
                      <h5 className="font-we-400">Pay Easily with Your Added Credit Card</h5>
                      <h5 className="">Just tap on the card below to make payment</h5>
                      </div>

                    </div>
                    <div className="dashboardrightpannerinnerright">
                      <div className="signout">
                        <a href="#logout">
                          <img src={question} alt={''} /><span className="title">Help</span>
                        </a>
                      </div>
                      <div className="signout">
                        <a href="#logout">
                          <img src={Sign_out_squre} alt={''} /><span className="title">Logout</span>
                        </a>
                      </div>
                      <div id="logout" className="modal-window">
                        <div>
                          <a  title="Close" className="modal-close">Close</a>
                          <div className="modal-header">

                          </div><div className="modal-body mb-4">
                            <div className="modal-body-inner">
                              <h2 className="h2_style text-center">Are you sure you want to
Logout ?</h2>
                            </div>
                          </div>
                          <div className="modal-footer">
                            <button type="button" className="ctaonebutton"> Save</button>
                            <button type="button" className="ctatwobutton"> Save</button>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  {cardDetails &&
                    <div className="sectioncardadd mt-5">
                    
                      
                      <div className="paymentchange psirelative">
                        <div className="previewcardinner">
                          <div className="namecardholder">{cardDetails?.name}</div>
                          <div className="cardnumber"><div>{cardDetails && decrypt(cardDetails?.card).replace(/[^a-z0-9]+/gi, '').replace(/(.{4})/g, '$1 ')}</div><div className="cardde"></div></div>
                          <div className="carddate">{cardDetails?.month}/{cardDetails?.year}</div>
                        </div>
                        <button type="button" className="showallourbesttoo " onClick={onCompletePayment}>COMPLETE PAYMENT</button>
                      </div>

                    </div>}
                </section>}

    {isPaymentSummary && <section>
      <div className="dashboardrightpannerinner">
        <div className="dashboardrightpannerinnerleft">
          <h2 className="h2_style font-weight-600">{t("institutePayments.PAYMENTSUMMARY")}</h2>

        </div>
        <div className="dashboardrightpannerinnerright">
          <div className="signout">
            <a href="#logout">
              <img src={question} alt={''} /><span className="title">{t("institutePayments.HELP")}</span>
            </a>
          </div>
          <div className="signout">
            <a href="#logout">
              <img src={Sign_out_squre} alt={''} /><span className="title">{t("institutePayments.LOGOUT")}</span>
            </a>
          </div>
          <div id="logout" className="modal-window">
            <div>
              <a title="Close" className="modal-close">{t("institutePayments.CLOSE")}</a>
              <div className="modal-header">

              </div><div className="modal-body mb-4">
                <div className="modal-body-inner">
                  <h2 className="h2_style text-center">{t("institutePayments.AREYOULOGOUT")}</h2>
                </div>
              </div>
              <div className="modal-footer">
                <button type="button" className="ctaonebutton"> {t("institutePayments.SAVE")}</button>
                <button type="button" className="ctatwobutton"> {t("institutePayments.SAVE")}</button>
              </div>
            </div>
          </div>
        </div>
      </div>
      {<div className="sectioncardadd">
      <div className="d-flex align-items-center">
        <div className="back_btn_new" onClick={() => backToPayment()}><img src={leftarrow} alt={'icon'} /></div>
<h2 className="font-we-400 voila">Pay Easily with your added Credit Card<br/>Just tap on the card below to make payment</h2>
</div>
        <div className="payimage text-center mt-4 mb-4">
          <img src={transactionic}/>
        </div>

        <div className="Paymentsummeryinner">
          <div className="paymentsummery">
          <li>{t("institutePayments.STATUSS")}<span>{t("institutePayments.SUCCESS")}</span></li>
            <li>{t("institutePayments.TRANSCATIONID")}<span>{t("institutePayments.CARDDEMONUMBESRS")}</span></li>
            <li>{t("institutePayments.TRANSCATIONID")}<span>{t("institutePayments.DETEFORMAT")}</span></li>
            <li>{t("institutePayments.AMOUNTPAID")}<span>$1000</span></li>
            <li> {t("institutePayments.PAIDVIOCARDENDING")} </li>
          </div>
         

        </div> 
        <div className="text-center"><button type="button" className="paybtn  mt-4">{t("institutePayments.DOWNLOADINVOICE")} </button>
          <button type="button" className="paybtn mt-2" onClick={() => backToPayment()}>{t("institutePayments.CONTINUETOPAYMENT")}</button>
      </div></div>}
    </section>
    }
  </div>
}
