import React, { useState } from 'react'
import { Link } from "react-router-dom";
import { Trans, useTranslation } from "react-i18next";
import { NEW_URL } from '../../../constants/applicationConstants';
import styles from './subCategoryCard.module.css';
const SubCategoryCard = (props) => {
    const { t } = useTranslation();

    const {
        selectedData, getById, iconsData
    } = props;

    return (
        <>

            <div className={`${styles.whatarecatbox} what-res ${selectedData === iconsData?.institutionCategoryId ? 'active' : ''}`} onClick={() => getById(iconsData)}>

                <img src={`${NEW_URL}/${iconsData?.icon}`} className="mb-1" alt="sub icons" />
                <p >{iconsData.name}</p>
            </div>




        </>

    );
};

export default SubCategoryCard;
