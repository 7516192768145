import React, { useEffect, useState } from 'react';
import './pagination.css';

export const Pagination = (props) =>{
    const {limit,totalCount,onPaginationButtonClick,resetPagi=false} = props;
    const [activePage,setActive] = useState(1);

    const onNextButtonClick = () =>{
        setActive(activePage + 1)
        onPaginationButtonClick(activePage+1)
    }

    const onPreviousButtonClick = () =>{
        setActive(activePage - 1)
        onPaginationButtonClick(activePage-1)
    }

    useEffect(()=>{
        setActive(1);
    },[totalCount])

    
    useEffect(()=>{
        resetPagi && setActive(1);
    },[resetPagi])

    return(
        <div className='pagination-section'>
            <div style={{display:'flex'}}>
                <button className="prev" disabled={activePage === 1} onClick={()=>onPreviousButtonClick()}>{'prev'}</button>
                <div className='active-page'>{activePage}</div>
                <button className="next" disabled={activePage >= (Math.ceil(totalCount/limit))} onClick={()=>onNextButtonClick()}>{'next'}</button>
            </div>
        </div>
    )
}