import React from "react";
import { useEffect } from "react";
import { InstituteDetail } from "./instituteDetails";
import { OfferSection } from "./offerSection";
import { AdsSection } from "./adsSection";
import { ServiceSection } from "./serviceSection";
import { ProductSection } from "./productSection";
import { GetInTouchSection } from "../ppvCommon/getInTouchSection/getInTouchSection.jsx";

import MyExpertJsx from "./myExpert.jsx";
import "../institute/instituteppv.css";
import "../institute/instituteppvresponsive.css";
import { ArticleSectionJsx } from "./articleSection";

const PublicProfileViewsJsx = (props) => {
  const {
    profileData,
    categoryData,
    expertData,
    userData,
    formError,
    onInputChangeHandler,
    onInputChange,
    onTouchHandler,
    onSendMessageClick,
    legalName,
    blogData,
    formSubmitButtonDisableForBlog,
    formData,
    onSubmitButtonClick,
    onViewProfieClick,
  } = props;

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <>
    
      <section className={`container-rimib institutenewdesignppv ${profileData?.instituteCategories?.[0]?.institutionCategory?.name}`}>
        <section className="institutenewdesignppvinner">
          <InstituteDetail profileData={profileData} />

          {profileData?.instituteOffers?.length > 0 && (
            <OfferSection profileData={profileData} legalName={legalName} />
          )}

          {profileData?.advertisements?.length > 0 && (
            <AdsSection profileData={profileData} legalName={legalName} />
          )}
        </section>

        {profileData?.instituteServices.length > 0 && (
          <ServiceSection profileData={profileData} />
        )}

        {expertData && expertData.length > 0 &&
          <MyExpertJsx
            categoryData={categoryData}
            formError={formError}
            expertData={expertData}
            formData={formData}
            onSubmitButtonClick={onSubmitButtonClick}
            onInputChange={onInputChange}
            onViewProfieClick={onViewProfieClick}
            profileData={profileData}
          />
        }

        {profileData?.instituteServices.length > 0 && (
          <GetInTouchSection
            profileData={profileData}
            userData={userData}
            formError={formError}
            planName={profileData?.payments[0]?.subscriptionPlan?.planName}
            onInputChangeHandler={onInputChangeHandler}
            onTouchHandler={onTouchHandler}
            onSendMessageClick={onSendMessageClick}
            formSubmitButtonDisable={formSubmitButtonDisableForBlog}
          />
        )}


        {profileData?.instituteProducts?.categories.length > 0 && (
          <ProductSection profileData={profileData} legalName={legalName} />
        )}

        {blogData?.body.length > 0 && (
          <ArticleSectionJsx profileData={profileData} blogData={blogData} />
        )
        }
      </section>
    </>
  );
};

export default PublicProfileViewsJsx;
