import React, { useEffect, useState } from 'react'
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import iconaskbtn from "../../../assets/sharedImages/iconaskbtn.svg";
import realestateicon from "../../../assets/askTheExpertImages/realestateicon.svg";
import insurenceicon from "../../../assets/askTheExpertImages/insurenceicon.svg";
import mortgageicon from "../../../assets/askTheExpertImages/mortgageicon.svg";
import bankingicon from "../../../assets/askTheExpertImages/bankingicon.svg";
import investmenticon from "../../../assets/askTheExpertImages/investmenticon.svg";
import realestateicontwo from "../../../assets/askTheExpertImages/realestateicontwo.svg";
import insurenceicontwo from "../../../assets/askTheExpertImages/insurenceicontwo.svg";
import mortgageicontwo from "../../../assets/askTheExpertImages/mortgageicontwo.svg";
import bankingicontwo from "../../../assets/askTheExpertImages/bankingicontwo.svg";
import investmenticontwo from "../../../assets/askTheExpertImages/investmenticontwo.svg";
import ask_the_expert_banner from "../../../assets/askTheExpertImages/asktheexpertban.svg"
import ask_the_expert_banner_mobile from "../../../assets/askTheExpertImages/ask_the_experts_mobbanner.svg"
import { Footer } from '../../common/footer/footer';
import 'react-awesome-slider/dist/styles.css';
import InputMask from 'react-input-mask';
import AOS from 'aos';
import 'aos/dist/aos.css';
import { NEW_URL } from '../../../constants/applicationConstants';
import howitworkicon1 from "../../../assets/images/image183.png";
import howitworkicon2 from "../../../assets/images/image184.png";
import howitworkicon3 from "../../../assets/images/image186.png";
import Slider from "react-slick";
import questionicon1 from "../../../assets/images/questioniocn1.svg"; 
import fullname from "../../../assets/askTheExpertImages/FullName.svg";
import postal from "../../../assets/askTheExpertImages/location.png";
import email from "../../../assets/askTheExpertImages/EmailAddress.svg";
import call from "../../../assets/askTheExpertImages/call.png";
import feequentlyicon1 from "../../../assets/askTheExpertImages/image196.png";
import feequentlyicon2 from "../../../assets/askTheExpertImages/faqsearch.svg";
import feequentlyicon3 from "../../../assets/askTheExpertImages/faquser.svg";
import feequentlyicon4 from "../../../assets/askTheExpertImages/faqquestion.svg";
import feequentlyicon5 from "../../../assets/askTheExpertImages/faqlaptop.svg";
import feequentlyicon6 from "../../../assets/askTheExpertImages/faq3user.svg";
import feequentlyicon7 from "../../../assets/askTheExpertImages/faqqa.svg";
import feequentlyicon8 from "../../../assets/askTheExpertImages/faqcat.svg";
import chooseicon1 from "../../../assets/askTheExpertImages/purchasemobile.png";
import chooseicon2 from "../../../assets/askTheExpertImages/sale.png";
import chooseicon3 from "../../../assets/askTheExpertImages/rentals.png";
import chooseicon4 from "../../../assets/askTheExpertImages/precon.png";
import chooseicon5 from "../../../assets/askTheExpertImages/housesvgrepo.png";
import chooseicon6 from "../../../assets/askTheExpertImages/alternative.png"; 
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import './askTheExpert.style.css';
import './askresponsive.css';
import faqicon1 from "../../../assets/askTheExpertImages/faqicon1.png";
import faqicon2 from "../../../assets/askTheExpertImages/faqicon2.svg";
import faqicon3 from "../../../assets/askTheExpertImages/faqicon3.svg";
import faqicon4 from "../../../assets/askTheExpertImages/faqicon4.svg";
import faqicon5 from "../../../assets/askTheExpertImages/faqicon5.svg";
import faqicon6 from "../../../assets/askTheExpertImages/faqicon6.svg";
import faqicon7 from "../../../assets/askTheExpertImages/faqicon7.svg";
import faqicon8 from "../../../assets/askTheExpertImages/faqicon8.svg";
import ConfirmAskTheExpertJsx from './confirmAskTheExpert/confirmAskTheExpert';
import FinalAskTheExpertJsx from './finalAsktheExpertScreen/finalAskTheExpertScreen';



export const getIconOne = (label) => {
  if (label === 'Real Estate')
    return realestateicon
  else if (label === 'Insurance')
    return insurenceicon
  else if (label === 'Mortgage')
    return mortgageicon
  else if (label === 'Investment')
    return investmenticon
  else if (label === 'Banking')
    return bankingicon

  else return ''
}


const AskTheExpertJsx = (props) => {
  const { t } = useTranslation();
  const {
    categoryData,
    onCategoryClick,
    closeModalEdit,
    subsOpen,
    openFinal,
    reviewOpen,
    categoryName,
    subCategoryData,
    onSubcategoryClick,
    subCategoryName,
    questionAsked,
    resetState,
    formData,
    onInputChange,
    onTextAreaChange,
    onTouchHandler,
    formError,
    formSubmitButtonDisable,
    onQuestionClick,
    onSubmitButtonClick,
    expertQuestions,
    onclickFocus
  } = props;
  
  useEffect(() => {
    AOS.init();
    window.scrollTo(0, 0);
  }, [])

  const getMuskPattern = (name) =>
    name === 'phone' ? '(999) 999 9999' : 'a9a 9a9'

  const getIconTwo = (label) => {
    if (label === 'Real Estate')
      return realestateicontwo
    else if (label === 'Mortgage')
      return mortgageicontwo
    else if (label === 'Insurance')
      return insurenceicontwo
    else if (label === 'Banking')
      return bankingicontwo
    else if (label === 'Investment')
      return investmenticontwo
    else return ''
  }

  const [isPopupOpen, setIsPopupOpen] = useState("close");

  // useEffect(() => {
  //   setIsPopupOpen("open");
  // }, []);

  const handleClose = () => {
    setIsPopupOpen("close");
  };
  const handleOpenPopup = () => {
    setIsPopupOpen("open");
    // onclickFocus("ask");
  };
  document.addEventListener('DOMContentLoaded', function () {
    const inputGroups = document.querySelectorAll('.input-group-reg');

    inputGroups.forEach(function (inputGroup) {
      const inputField = inputGroup.querySelector('.form-control');

      inputField.addEventListener('focus', function () {
        inputGroup.classList.add('focused');
      });

      inputField.addEventListener('blur', function () {
        inputGroup.classList.remove('focused');
      });
    });
  });
  const [choosecat, setChoosecat] = useState(
    [
      {
        chooseicon: chooseicon1,
        choosetext: "Purchase",
      },
      {
        chooseicon: chooseicon2,
        choosetext: "Sale",
      },
      {
        chooseicon: chooseicon3,
        choosetext: "Rentals",
      },
      {
        chooseicon: chooseicon4,
        choosetext: "Pre-construction properties",
      },
      {
        chooseicon: chooseicon5,
        choosetext: "Custom homes",
      },
      {
        chooseicon: chooseicon6,
        choosetext: "Alternative real estate",
      },
    ]
  )
  const [frequently, setFrequently] = useState(
    [
      {
        frequentlyicon: feequentlyicon1,
        frequentlyheading: "What is Ask The Expert?",
        frequentlytext: "It's a feature that allows you to ask financial questions. All your queries are resolved by accredited domain experts without any charge or fee.",
      },
      {
        frequentlyicon: feequentlyicon2,
        frequentlyheading: "How to submit a question through Ask the Experts?",
        frequentlytext: "Go to “Ask the Expert”",
        frequentlytextli1: "Select the “Main Category” for your query",
        frequentlytextli2: "Select the “Subcategory” of your query",
        frequentlytextli3: "Type your specific question or modify the selected common question as per your need",
        frequentlytextli4: "Click on Submit.",
      },
      {
        frequentlyicon: feequentlyicon3,
        frequentlyheading: "Can we choose our preferred experts to get answers?",
        frequentlytext: "NO. This option is not available in “Ask the Experts”.However, you can search for your preferred experts using “Search the Expert”. All you have to do is enter your postal code. Post this, you will get a complete list of experts located near you.",
      },
      {
        frequentlyicon: feequentlyicon4,
        frequentlyheading: "What information do I  have to give while asking questions?",
        frequentlytext: "You are only required to submit:",
        frequentlytextli1: "Your Name",
        frequentlytextli2: "Email address",
        frequentlytextli3: "Phone No.and",
        frequentlytextli4: "Postal Code",
      },
      {
        frequentlyicon: feequentlyicon5,
        frequentlyheading: "How will I know if my question has been submitted?",
        frequentlytext: "You will be notified via email that your query has been submitted.",
      },
      {
        frequentlyicon: feequentlyicon6,
        frequentlyheading: "Who are experts answering our questions?",
        frequentlytext: "All your queries are answered by experts, who are certified finance professionals with extensive experience in their fields. They have requisite knowledge, skills and understanding to offer you the best resolutions.",
      },
      {
        frequentlyicon: feequentlyicon7,
        frequentlyheading: "Do I need to pay to get my questions answered?",
        frequentlytext: "NO. We do not charge anything from our customers for using “Ask the Experts”. You can seek answers to all your financial queries for free.",
      },
      {
        frequentlyicon: feequentlyicon8,
        frequentlyheading: "Can I submit questions in more than one category?",
        frequentlytext: "YES. Our customers can ask multiple queries in different categories. There is no limit on number of query submissions.",
      },
    ]
  );


  const Subcatslider = {

    infinite: false,
    speed: 500,
    slidesToShow: 5,
    slidesToScroll: 1,
    arrows: true,
    vertical: true,
    verticalSwiping: true,
    dot: true,
  };
  return (
    <>
    <section className="container-rimib"> 
      {isPopupOpen === "open" && (
        <div className="popupouter">
          <div className="popup">
            <div className="popup-inner">
              <div className="popup-inner-text">
                <h2>{t("ASKTHEEXPERT.POPUPHEADING")}</h2>
                <p>{t("ASKTHEEXPERT.POPUPSUBTEXT")}<span>{t("ASKTHEEXPERT.POPUPSUBTEXT1")}</span>{t("ASKTHEEXPERT.POPUPSUBTEXT2")}</p>
                <h3>{t("ASKTHEEXPERT.POPUPTEXT1")}<Link to="/customerRegistration" className="signuptext text-decoration-underline" style={{ textDecoration: 'underline' }}>{t("ASKTHEEXPERT.POPUPTEXT2")}</Link></h3>
              </div>
              <div className="closebtnicon" onClick={handleClose}> 
              </div>
              <div className="popupbutton text-center d-flex  justify-content-evenly">
                <Link to="/login/customer" className="popupbtn1">{t("ASKTHEEXPERT.ASKBUTTON")}</Link>
                <a href="#askfree" className="popupbtn2"  onClick={handleClose}>{t("ASKTHEEXPERT.CONTINUEBUTTON")}</a>
              </div>
            </div>
          </div>
        </div>
      )}
      <div className={isPopupOpen === "open" ? "blurred" : ""}>
        <div className="content-popup">
          <div className="inner-askexpertnewlayout">
          <section className="herosection heroasktheexpert heronewaskexpert">
            <img src={ask_the_expert_banner} alt='askbanner' className="d-none d-sm-block"/>
            <img src={ask_the_expert_banner_mobile} alt='askbanner' className="d-block d-lg-none d-sm-none d-md-none "/>
            <div className="container">
            
                  <div className="heroleftpanel">
                    <h2 className="heroheading">{t("ASKTHEEXPERT.HEADING")} </h2>
                    <a href={'#askfree'} style={{ textDecoration: "none" }}>
                  <div className="askbutton">
                    <p className="askres">{t("COMMON.ASKTHEEXPERTS")}</p>
                    <img src={iconaskbtn} alt="" />
                  </div>
                </a>
                    <p className="text-start">{t("ASKTHEEXPERT.SUBHEADING")}<b className="ask-bold-text">{t("ASKTHEEXPERT.SUBHEADINGT1")}</b></p>
                    <p className="subh1">{t("ASKTHEEXPERT.SUBHEADING1")}</p>
                    <div className="heroleftbutton d-flex">
                      <Link to className="ctaonebutton" onClick={handleOpenPopup}>{t("ASKTHEEXPERT.IWANTTOBUTTON")}</Link>
                      <a href="#newfaq" className="ctatwobutton d-none d-sm-block">{t("ASKTHEEXPERT.FAQBUTTON")}</a>
                      <a href="#newfaqmobile" className="ctatwobutton d-block d-lg-none d-sm-none d-md-none">{t("ASKTHEEXPERT.FAQBUTTON")}</a>
                    </div>
                  </div>
                
            </div>
          </section>
          <section className="Howitworks">
            <div className="container">
              <div className="howitworkinner">
                <div className="howitworkinner-text">
                  <h2>{t("ASKTHEEXPERT.SUBHEADING2")}</h2>
                </div>
                <div className="row">
                  <div className="col-sm-4 col-12 resrrrow ">
                    <div className="howitwork-box  ">
                      <div className="howitworkbox-inner">
                        <img src={howitworkicon1} alt="" />
                      </div>
                      <p>{t("ASKTHEEXPERT.ULLIHEADING1")}</p>
                    </div>
                  </div>
                  <div className="col-sm-4 col-12 resrrrow ">
                    <div className="howitwork-box " id="howitworkarrow">
                      <div className="howitworkbox-inner" id="howitworkbox2color">
                        <img src={howitworkicon2} alt="" />
                      </div>
                      <p>{t("ASKTHEEXPERT.ULLIHEADING2")}</p>
                    </div>
                  </div>
                  <div className="col-sm-4 col-12 resrrrow">
                    <div className="howitwork-box  marginres" id="howitworkarrow">
                      <div className="howitworkbox-inner" id="howitworkbox3color">
                        <img src={howitworkicon3} alt="" />
                      </div>
                      <p>{t("ASKTHEEXPERT.ULLIHEADING3")}</p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>
          <section className="joincanadians">
            <div className="container">
              <div className="row justify-content-center">
                <div className="col-12 text-center">
                  <div className="jioncanadianstext">
                    <h2 className="mainheadingtext mb-3">{t("ASKTHEEXPERT.SUBHEADING3")}<span>{t("ASKTHEEXPERT.SUBHEADINGTEXT1")}</span>{t("ASKTHEEXPERT.SUBHEADINGTEXT2")}<span>{t("ASKTHEEXPERT.SUBHEADINGTEXT3")}</span>{t("ASKTHEEXPERT.SUBHEADINGTEXT4")}<span>{t("ASKTHEEXPERT.SUBHEADINGTEXT5")}</span></h2>
                    <p className="smalllinespan" id="askfree">{t("ASKTHEEXPERT.SUBHEADING4")}<span> {t("ASKTHEEXPERT.SUBHEADINGQUE4")}</span><br/> <span className="psnew">{t("ASKTHEEXPERT.SUBHEADING5")}</span><span className="psnew">{t("ASKTHEEXPERT.SUBHEADINGASK5")}</span></p>
                   
                  </div>
                </div>
              </div>
            </div>
          </section>
         
          <section className="SelectMainCategory select-main-cat" id="ask" style={{ marginBottom: "10px 0" }}>
            <div className="container-fluid" style={{padding:"0px", overflow:"hidden"}}>
              <div className="asktheexpertcategorysectioninner asktheexpertcategorysectioninnerline asktheexpertcategorysectioninnerlinenew">
                <div className="row">
                  <div className="col-12">
                    <div className="categorytext">
                      <h2>{t("ASKTHEEXPERT.SELECT")} <span>{t("ASKTHEEXPERT.CATEGORY")}</span></h2>
                      <p className="catetext2">{t("ASKTHEEXPERT.TEXT1")}</p>
                      <p className="catetext2">{t("ASKTHEEXPERT.TEXT2")}</p>
                    </div>
                  </div>
                </div>
                <div className="catstart clearfix" data-aostest="fade-up-right">
                  <div className={`categoryonesetfirst ${categoryName && 'categoryoneset'}`}>
                    <div className="choose-main" >
                      {/* <div className="categoryonesetone" >
                    <p>{t("ASKTHEEXPERT.SELECT")}</p>
                    <h4>{t("ASKTHEEXPERT.CATEGORY")}</h4>
                    <p className="categoryonesetonetext"><b>{t("ASKTHEEXPERT.TEXT1")}</b> {t("ASKTHEEXPERT.TEXT2")}</p>
                  </div> */}
                      {
                        categoryData?.map(catDT => {
                          return <div className={`choose categoryonesettwo ${categoryName === catDT?.name ? 'active' : ''}`} onClick={() => onCategoryClick(catDT?.institutionCategoryId, catDT?.name)}>
                            <div className={`iconone ${categoryName === catDT?.name ? 'active' : ''}`} ><img src={getIconOne(catDT.name)} alt={''} /></div>
                            <div className="icontwo"><img src={getIconTwo(catDT.name)} alt={''} /></div>
                            {/* <h5>{catDT.name}</h5> */}
                          </div>
                        })
                      }
                    </div>
                  </div>
                </div>
                {categoryName && <div className="catstart clearfix" data-aostest="fade-up-left">
                  <div className={`categorytwosetfirsttest ${subCategoryName && 'categorytwoset'}`}>

                    <div id="askts" onClick={(e) => onclickFocus("askQus")}>

                      <div className="subcategoryinneraskexpert">

                        {
                          subCategoryData?.map(subCatDT => {
                            return <div id="askqw" className={`categoryonesettwo categoryonesetwidth ${subCategoryName === subCatDT?.name ? 'active' : ''}`} onClick={() => onSubcategoryClick(subCatDT.institutionCategoryId, subCatDT.name,subCatDT.icon)}>
                              <div className="iconone"><img src={`${NEW_URL}/${subCatDT.icon}`}
                                alt={''} /></div>
                              <div className="icontwo"><img src={`${NEW_URL}/${subCatDT.icon}`}
                                alt={''} /></div>
                              <h5>{subCatDT.name}</h5>
                            </div>
                          })
                        }

                      </div>
                    </div>
                  </div>
                </div>
                }
                {subCategoryName && <div id="askQus">
                  <div className="container">
                    <div className="row catborderbottom">
                      <div className="col-lg-6 mb-3 d-none d-sm-block">
                        <div className="catstart clearfix" data-aostest="fade-up-bottom">
                          <div className="categoryfourset">
                            <div className="categoryonesetone">
                              <div className="question-text">
                                <h2>{t("ASKTHEEXPERT.QUESTIONHEADING")}<span>{t("ASKTHEEXPERT.QUESTIONHEADING-1")}</span></h2>
                              </div>
                            </div>
                            {/* <AwesomeSlider>
                                {
                                  expertQuestions.length > 0 ? expertQuestions.map(que => {
                                    return <div onClick={() => onQuestionClick(que)}>{que.question}</div>

                                  }) :

                                    <div>{t("ASKTHEEXPERT.NOQUEBUTTON")}</div>
                                }
                                  </AwesomeSlider> */}

                            <Slider {...Subcatslider}>

                              {expertQuestions.length > 0 ? (
                                expertQuestions.map(que => (
                                  <div className="main-question questionslider">
                                    <div className="questionslider-text">
                                      <p>{que.question}</p> </div>
                                    <div className="questiontext-icon">
                                      <img src={questionicon1} key={que.id} onClick={() => onQuestionClick(que)} alt="" />
                                    </div>

                                  </div>

                                ))
                              ) : (
                                <div>{t("ASKTHEEXPERT.NOQUEBUTTON")}</div>
                              )}

                            </Slider>
                          </div>
                        </div>
                      </div>
                      <div className="col-lg-6 mb-3 mobileblockscroll d-block d-sm-none">
                        <div className="catstart clearfix" data-aostest="fade-up-bottom">
                          <div className="categoryfourset">
                            <div className="categoryonesetone">
                              <div className="question-text">
                                <h2>{t("ASKTHEEXPERT.QUESTIONHEADING")}<span>{t("ASKTHEEXPERT.QUESTIONHEADING-1")}</span></h2>
                              </div>
                            </div>
                            <Slider {...Subcatslider}>
                            {expertQuestions.length > 0 ? (
                              expertQuestions.map(que => (
                                <div className="main-question questionslider">
                                  <div className="questionslider-text">
                                    <p>{que.question}</p> </div>
                                  <div className="questiontext-icon">
                                    <img src={questionicon1} key={que.id} onClick={() => onQuestionClick(que)} alt="" />
                                  </div>

                                </div>

                              ))
                            ) : (
                              <div>{t("ASKTHEEXPERT.NOQUEBUTTON")}</div>
                            )}
                            </Slider>
                          </div>
                        </div>
                      </div>
                      <div className="col-lg-6 mb-3 queastio-main">
                        <div className="textarecs mt-5" data-aostest="fade-bottom">
                          <div className="question-text-right">
                            <h2>{t("ASKTHEEXPERT.QUESTIONHEADING1")}<span>{t("ASKTHEEXPERT.QUESTIONTEXT3")}</span></h2>
                            <p className="question-right-ptext">{t("ASKTHEEXPERT.QUESTIONTEXT4")} {t("ASKTHEEXPERT.QUESTIONTEXTSUBTEXT4")}</p>
                            <p className="question-right-text-too">{t("ASKTHEEXPERT.QUESTIONTEXT5")}</p>
                          </div>
                          <div className="input-container form-group m-2">
                            <div className="input-question">
                              <textarea className="input form-control textareaform text-placeholder" value={questionAsked} name="message" onChange={onTextAreaChange} placeholder=" " maxLength={1000}></textarea>
                              <div className="placeholder">{t("ASKTHEEXPERT.PLEASETYPE")} </div>
                            </div>
                            <div className="nextbtn">
                              {!questionAsked ?                     <button className="proceedbtn proceed-too-btn" disabled>{t("ASKTHEEXPERT.NEXTBUTTON")}</button>

                                : <button className="proceedbtn proceed-too-btn"><a  href="#nextregister">{t("ASKTHEEXPERT.NEXTBUTTON")}</a></button>
                              }
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>}
              </div>
            </div>
          </section>
          <section className="register pb-4" id="nextregister">
            <div className="container">
              <div className="register-main">
                <div className="register-main-text">
                  <h2>{t("ASKTHEEXPERT.REGISTERHEADING")}<span>{t("ASKTHEEXPERT.REGISTERHEADING1")}</span></h2>
                  <h3>{t("ASKTHEEXPERT.SIGNUPTEXT")}</h3>
                  <p>{t("ASKTHEEXPERT.SIGNUPTEXT1")}</p>
                  <div className="regbtn text-center">
                    <Link to="/customerRegistration" className="registernowbutton" >{t("ASKTHEEXPERT.REGBUTTON")}</Link>
                  </div>
                </div>
                <div className="mainexpforminner register-fill">
                  <div className="row" style={{ justifyContent: "center" }}>
                    <div className="input-container form-group input-group-reg col-md-6 mb-5  ">
                      <span className="input-group-text"><img src={fullname} alt="" /></span>
                      <input
                        type="text"
                        name='name'
                        value={formData.name}
                        onChange={onInputChange}
                        onBlur={onTouchHandler}
                        className={
                          formError['name']
                            ? "input form-control "
                            : "input form-control"
                        }
                        placeholder=" " />
                      <div className="placeholder font-place">{t("ASKTHEEXPERT.FULLNAME")}</div>
                      <span className="text-danger">
                        {formError['name'] &&
                          (formError['name'] === "req"
                            ? 'Name is required'
                            : 'Enter a valid Name')}
                      </span>
                    </div>
                    <div className="input-container form-group input-group-reg col-md-6 mb-5">
                      <span className="input-group-text"><img src={email} alt="" /></span>
                      <input
                        type="email"
                        name='email'
                        value={formData.email}
                        onChange={onInputChange}
                        onBlur={onTouchHandler}
                        className={
                          formError['email']
                            ? "input form-control  "
                            : "input form-control"
                        }
                        placeholder=" " />
                      <div className="placeholder font-place">{t("ASKTHEEXPERT.EMIALADDRESS")} </div>
                      <span className="text-danger">
                        {formError['email'] &&
                          (formError['email'] === "req"
                            ? 'Email is required'
                            : 'Enter a valid Email')}
                      </span>
                    </div>
                    <div className="input-container form-group input-group-reg col-md-6 mb-5">
                      <span className="input-group-text"><img src={postal} alt="" /></span>
                      <InputMask mask={getMuskPattern('postalCode')} value={formData.postalCode} name="postalCode" onChange={onInputChange} onBlur={onTouchHandler}>
                        {(inputProps) => <input {...inputProps}
                          type="text"
                          className={
                            formError['postalCode']
                              ? "input form-control  "
                              : "input form-control"
                          }
                          placeholder=" "
                          disableUnderline />}
                      </InputMask>
                      <div className="placeholder font-place">{t("ASKTHEEXPERT.POSTALCODE")} </div>
                      <span className="text-danger">
                        {formError['postalCode'] &&
                          (formError['postalCode'] === "req"
                            ? 'Postal code is required'
                            : 'Enter a valid Postal Code')}
                      </span>
                    </div>
                    <div className="input-container form-group input-group-reg mb-5 col-md-6">
                      <span className="input-group-text"><img src={call} alt="" /></span>
                      <InputMask mask={getMuskPattern('phone')} value={formData.phoneNumber} name="phoneNumber" onChange={onInputChange} onBlur={onTouchHandler}>
                        {(inputProps) => <input {...inputProps}
                          type="tel"
                          className={
                            "input form-control"
                          }
                          placeholder=" "
                          disableUnderline />}
                      </InputMask>
                      <div className="placeholder font-place">{t("ASKTHEEXPERT.PHONENUMBER")}</div>
                      <span className="text-danger">
                        {formError['phoneNumber'] &&
                          (formError['phoneNumber'] === "req"
                            ? ''
                            : 'Enter a valid Phone Number')}
                      </span>
                    </div>
                  </div>
                  <div className="proceed-main-btn   mb-3 text-center ">
                    <button className="proceedbtn " disabled={formSubmitButtonDisable} onClick={onSubmitButtonClick}>{t("ASKTHEEXPERT.FAQBOXTEXT27")}</button>
                  </div>
                </div>
              </div>
            </div>
          </section>
          <section className="frequently  d-none d-sm-block " id="newfaq">
            <div className="container">
              <div className="row">
                <h2 className="frequently-text">
                  {t("ASKTHEEXPERT.FREQUENTLYHEADING")}
                  <span>{t("ASKTHEEXPERT.FREQUENTLYHEADING1")}</span>
                </h2>
                {frequently.map((items, index) => {
                 
                  return (
                    <div className="col-lg-3 col-12" key={index}>
                      <div className="frequently-box">
                        <div className="frequenty-box-up">
                          <img src={items.frequentlyicon} className="mb-3" alt="" />
                          <h6>{items.frequentlyheading}</h6>
                          <div className="icon-down" > 

                          </div>
                        </div>
                        <div className="frequenty-box-down">
                          <div className="frequently-box-down-text">
                            <p>{items.frequentlytext}</p>
                            <ul>
                              {items.frequentlytextli1 && <li>{items.frequentlytextli1}</li>}
                              {items.frequentlytextli2 && <li>{items.frequentlytextli2}</li>}
                              {items.frequentlytextli3 && <li>{items.frequentlytextli3}</li>}
                              {items.frequentlytextli4 && <li>{items.frequentlytextli4}</li>}
                            </ul>
                          </div>
                        </div>
                      </div>
                    </div>
                  );
                })}
              </div>
            </div>
          </section>
          <section className="faqsection d-block d-lg-none d-sm-none d-md-none " style={{ backgroundColor: "#DAF0FF" }} id="newfaqmobile">
            <div className="container">
              <h2 className="frequently-text mb-5">
                {t("ASKTHEEXPERT.FREQUENTLYHEADING")}
                <span>{t("ASKTHEEXPERT.FREQUENTLYHEADING1")}</span>
              </h2>
              <div className="accordion" id="accordionExample">
                <div className="accordion-item faqmobile">
                  <h2 className="accordion-header " id="headingOne">
                    <button className="accordion-button faqmobileborder bgnone" type="button" data-bs-toggle="collapse" data-bs-target="#collapseOne" aria-expanded="true" aria-controls="collapseOne">
                      <img src={faqicon1} className="me-3" alt="" /> {t("ASKTHEEXPERT.WHATTEXT")}
      </button>
                  </h2>
                  <div id="collapseOne" className="accordion-collapse collapse show" aria-labelledby="headingOne" data-bs-parent="#accordionExample">
                    <div className="accordion-body">
                      <p>{t("ASKTHEEXPERT.FAQTEXT")}</p>
                    </div>
                  </div>
                </div>
                <div className="accordion-item faqmobile">
                  <h2 className="accordion-header" id="headingTwo">
                    <button className="accordion-button collapsed faqmobileborder" type="button" data-bs-toggle="collapse" data-bs-target="#collapseTwo" aria-expanded="false" aria-controls="collapseTwo">
                      <img src={faqicon2} className="me-3" alt="" /> {t("ASKTHEEXPERT.FAQTEXT2")}
      </button>
                  </h2>
                  <div id="collapseTwo" className="accordion-collapse collapse" aria-labelledby="headingTwo" data-bs-parent="#accordionExample">
                    <div className="accordion-body">
                      <p>
                      {t("ASKTHEEXPERT.FAQBOXTEXT1")} </p>
                      <ul>
                        <li>{t("ASKTHEEXPERT.FAQBOXTEXT2")}</li>
                        <li>{t("ASKTHEEXPERT.FAQBOXTEXT3")}</li>
                        <li>{t("ASKTHEEXPERT.FAQBOXTEXT4")}</li>
                        <li>{t("ASKTHEEXPERT.FAQBOXTEXT5")}</li>
                        <li>{t("ASKTHEEXPERT.FAQBOXTEXT6")}</li>
                      </ul>
                    </div>
                  </div>
                </div>
                <div className="accordion-item faqmobile">
                  <h2 className="accordion-header" id="headingThree">
                    <button className="accordion-button collapsed faqmobileborder" type="button" data-bs-toggle="collapse" data-bs-target="#collapseThree" aria-expanded="false" aria-controls="collapseThree">
                      <img src={faqicon3} className="me-3" alt="" /> {t("ASKTHEEXPERT.FAQBOXTEXT7")}</button>
                  </h2>
                  <div id="collapseThree" className="accordion-collapse collapse" aria-labelledby="headingThree" data-bs-parent="#accordionExample">
                    <div className="accordion-body">
                      <p><strong>{t("ASKTHEEXPERT.FAQBOXTEXT8")}</strong> {t("ASKTHEEXPERT.FAQBOXTEXT9")} <br />
                      {t("ASKTHEEXPERT.FAQBOXTEXT10")} </p>
                    </div>
                  </div>
                </div>
                <div className="accordion-item faqmobile">
                  <h2 className="accordion-header" id="headingFour">
                    <button className="accordion-button collapsed faqmobileborder" type="button" data-bs-toggle="collapse" data-bs-target="#collapseFour" aria-expanded="false" aria-controls="collapseFour">
                      <img src={faqicon4} className="me-3" alt="" /> {t("ASKTHEEXPERT.FAQBOXTEXT11")}</button>
                  </h2>
                  <div id="collapseFour" className="accordion-collapse collapse" aria-labelledby="headingFour" data-bs-parent="#accordionExample">
                    <div className="accordion-body">
                      <p>{t("ASKTHEEXPERT.FAQBOXTEXT12")}</p>
                      <ul>
                        <li>{t("ASKTHEEXPERT.FAQBOXTEXT13")}</li>
                        <li>{t("ASKTHEEXPERT.FAQBOXTEXT14")}</li>
                        <li>{t("ASKTHEEXPERT.FAQBOXTEXT15")}</li>
                        <li>{t("ASKTHEEXPERT.FAQBOXTEXT16")}</li>
                      </ul>
                    </div>
                  </div>
                </div>
                <div className="accordion-item faqmobile">
                  <h2 className="accordion-header" id="headingFive">
                    <button className="accordion-button collapsed faqmobileborder" type="button" data-bs-toggle="collapse" data-bs-target="#collapseFive" aria-expanded="false" aria-controls="collapseFive">
                      <img src={faqicon5} className="me-3" alt="" /> {t("ASKTHEEXPERT.FAQBOXTEXT17")}
      </button>
                  </h2>
                  <div id="collapseFive" className="accordion-collapse collapse" aria-labelledby="headingFive" data-bs-parent="#accordionExample">
                    <div className="accordion-body">
                      <p>{t("ASKTHEEXPERT.FAQBOXTEXT18")}</p>
                    </div>
                  </div>
                </div>
                <div className="accordion-item faqmobile">
                  <h2 className="accordion-header" id="headingSix">
                    <button className="accordion-button collapsed faqmobileborder" type="button" data-bs-toggle="collapse" data-bs-target="#collapseSix" aria-expanded="false" aria-controls="collapseSix">
                      <img src={faqicon6} className="me-3" alt="" />{t("ASKTHEEXPERT.FAQBOXTEXT19")}

      </button>
                  </h2>
                  <div id="collapseSix" className="accordion-collapse collapse" aria-labelledby="headingSix" data-bs-parent="#accordionExample">
                    <div className="accordion-body">
                      <p>{t("ASKTHEEXPERT.FAQBOXTEXT20")} </p>
                    </div>
                  </div>
                </div>
                <div className="accordion-item faqmobile">
                  <h2 className="accordion-header" id="headingSeven">
                    <button className="accordion-button collapsed faqmobileborder" type="button" data-bs-toggle="collapse" data-bs-target="#collapseSeven" aria-expanded="false" aria-controls="collapseSeven">
                      <img src={faqicon7} className="me-3" alt="" />  {t("ASKTHEEXPERT.FAQBOXTEXT21")}

      </button>
                  </h2>
                  <div id="collapseSeven" className="accordion-collapse collapse" aria-labelledby="headingSeven" data-bs-parent="#accordionExample">
                    <div className="accordion-body">
                      <p><strong>{t("ASKTHEEXPERT.FAQBOXTEXT22")}</strong> {t("ASKTHEEXPERT.FAQBOXTEXT23")}</p>
                    </div>
                  </div>
                </div>
                <div className="accordion-item faqmobile">
                  <h2 className="accordion-header" id="headingEight">
                    <button className="accordion-button collapsed faqmobileborder bgnone" type="button" data-bs-toggle="collapse" data-bs-target="#collapseEight" aria-expanded="false" aria-controls="collapseEight">
                      <img src={faqicon8} className="me-3" alt="" /> {t("ASKTHEEXPERT.FAQBOXTEXT24")}

      </button>
                  </h2>
                  <div id="collapseEight" className="accordion-collapse collapse" aria-labelledby="headingEight" data-bs-parent="#accordionExample">
                    <div className="accordion-body">
                      <p><strong>{t("ASKTHEEXPERT.FAQBOXTEXT25")} </strong>{t("ASKTHEEXPERT.FAQBOXTEXT26")}</p>
                    </div>
                  </div>
                </div>
              </div>

            </div>
          </section>
        </div>
      </div>
      </div>
      {subsOpen &&
        <ConfirmAskTheExpertJsx
          resetState={resetState}
          closeModalEdit={closeModalEdit}
          openFinal={openFinal}

        />

      }
      {reviewOpen &&
        <FinalAskTheExpertJsx />
      }
 </section> 
      <Footer />
     
    </>

  );
};

export default AskTheExpertJsx;