import React, { useState, useEffect } from 'react'
import dropbox from "../../../../assets/images/dropbox.svg";
import minus from "../../../../assets/images/minus.svg";
import plus from "../../../../assets/Instituteppvimages/plus-circle.svg"
import ItemDetails from './itemDetails.jsx';

import "../myProducts/products.model.css"
import "../../institute/institute.model.css"
import { useTranslation } from "react-i18next";
import { ProductCard } from "../../../common/productCard/productCard.jsx"

const ProductsJsx = (props) => {
    const {
        produtsList, getProducts, getParticularProducts, openEditBox
    } = props;
    const [showData, setShowData] = useState(produtsList);
    const [itemData, setItemData] = useState("");
    const [totalData, setTotalData] = useState("");
    const { t } = useTranslation();
    const onPlusButtonClick = (value, subValue, action) => {
        let tempArray = [...showData]
        tempArray.map((tempDT) => {
            if (tempDT.parentInstitutionCategory === value)
                tempDT.subInstitutionCategories.map((subDT, index) => {
                    if (subDT.name === subValue)
                        tempDT.subInstitutionCategories[index] = { ...tempDT.subInstitutionCategories[index], isOpen: action }
                })
        })
        setShowData(tempArray)
        if (action === false) { 
            window.location.href = `${window.location.origin}${window.location.pathname}?page=myProduct`; 
        }
    }

    const onProductClick = (value, subValue, productId, action, totalData) => {
        
        setItemData(productId)
        setTotalData(totalData)
        let tempArray = [...showData]
        tempArray.map((tempDT) => {
            if (tempDT.parentInstitutionCategory === value)
                tempDT.subInstitutionCategories.map((subDT, index) => {
                    if (subDT.name === subValue)
                        tempDT.subInstitutionCategories[index] = { ...tempDT.subInstitutionCategories[index], activeID: !!productId }
                })
        })
        setShowData(tempArray);
    }

    useEffect(() => {
        setShowData(produtsList)
    }, [produtsList])

    const limitData = JSON.parse(sessionStorage.getItem('loginData'));

    return (
        <>


            <p className="producttitle">{t("instituteproduct.TITLE")}</p>
            <p className="productsubtitle">{t("instituteproduct.SUBTITILE")}</p>
            <p className="productsubtitles">{t("instituteproduct.SUBTITILELIMIT")}{limitData?.totalProductsLimit} {t("instituteproduct.SUBTITILES")}</p>
            <div className="myproduct-div">
                <div >
                    {
                        showData?.length > 0 ?
                            showData?.map(product => {
                                return (
                                    <div >
                                        <p className={`product-category ${product.parentInstitutionCategory.toLowerCase().replace(" ", "_")}`}>{product.parentInstitutionCategory} {t("instituteproduct.NAME")}</p>

                                        <div className="subcategory-headings">
                                            <ul>
                                                {
                                                    product.subInstitutionCategories?.map((mainItem, index) => {
                                                        return (
                                                            <li >
                                                                <>
                                                                    {!mainItem.isOpen ? <p className="product-subcategory"><p className={`count-number ${product.parentInstitutionCategory.toLowerCase().replace(" ", "_")}-bg`} >{index + 1}</p>  {mainItem.name}</p> : <p className="myproduct-open">{mainItem.name}</p>}
                                                                    {!mainItem.isOpen ? <img src={plus} className={`plus ${product.parentInstitutionCategory.toLowerCase().replace(" ", "_")}-filter`} alt={''} onClick={() => onPlusButtonClick(product.parentInstitutionCategory, mainItem.name, true)} /> :
                                                                        <img src={minus} alt={''} className="minus" onClick={() => onPlusButtonClick(product.parentInstitutionCategory, mainItem.name, false)} />}

                                                                    {mainItem.isOpen &&
                                                                        <>

                                                                            <div className="row bottom-margin">

                                                                                <>
                                                                                 {mainItem?.activeID ? <div className=" bottom-margins"> <ItemDetails
                                                                                        openEditBox={openEditBox}
                                    
                                                                                        itemData={itemData}
                                                                                        getProducts={getProducts}
                                                                                        onProductcloseClick={() => { onProductClick(product.parentInstitutionCategory, mainItem.name, "", false, "") }}
                                                                                                         
                                                                                        getParticularProducts={getParticularProducts}
                                                                                        totalData={totalData}
                                                                                    /></div> :
                                                                                        <section className="topexpertproduct">

                                                                                            <div className="productslider">
                                                                                                <div className="row">
                                                                                                    {
                                                                                                        
                                                                                                        mainItem.products?.map(productData => {
 console.log("product==>> ",product.subInstitutionCategories[0].name );
                                                                                                            return <div class={`sliouter ${product?.parentInstitutionCategory} col-4`}>

                                                                                                                <ProductCard product={productData}
                                                                                                                    viewbuttonURL={(e) => { onProductClick(product.parentInstitutionCategory, mainItem.name, productData.institutionProductId, true, productData) }}
                                                                                                                    isCategorySubCategory={true}
                                                                                                                    catname={product?.parentInstitutionCategory}
                                                                                                                    isProductName={true}
                                                                                                                    isViewButton={true}
                                                                                                                    subInstitutionCategory={product.subInstitutionCategories[0].name}
                                                                                                                    institutionCategory={product?.parentInstitutionCategory}
                                                                                                                /> </div>
                                                                                                        })
                                                                                                    }
                                                                                                </div>
                                                                                            </div>

                                                                                        </section>
                                                                                    }
                                                                                </>
                                                                            </div>

                                                                        </>

                                                                    }
                                                                </>


                                                            </li>
                                                        )

                                                    })
                                                }
                                            </ul>
                                        </div>


                                    </div>
                                )

                            }) :
                            <div className="dropdefault text-center mb-5">
                                <div className="dropdefaultimage">
                                    <img src={dropbox} alt={''} />
                                </div>
                                <div className="dropdefaulttext">
                                    <h5>{t("instituteproduct.NOPRODUCTFOUND")}</h5>
                                    <h5>{t("instituteproduct.CREATENEWPRODUCTNOW")}</h5>
                                </div>

                            </div>
                    }
                </div>
            </div>

        </>

    );
};

export default ProductsJsx;
