import axios from "axios";
import React, { Component } from "react"; 
import { Loader } from "../../../common/loader/loader.jsx";
import ListOfArticlesJsx from "./listOfArticles.jsx"
import {BLOG_URL,BLOG_URL_TOKEN} from "../../../../constants/applicationConstants"

export default class ListOfArticles extends Component {
  constructor(props){
    super(props);
    this.state={
      articleData:null,
      page:1,
      profileData:null,
    }
  }

  componentDidMount = () =>{ 
    if (this.props.location?.state?.data) {
      const { data } = this.props.location?.state; 
      data && this.setState({profileData:data}, () => this.getArticlesData());
    }
    // this.getArticlesData();
  }

  getArticlesData = () => {
    const instituteId = JSON.parse(sessionStorage.getItem("masterID"));  
    var data = new FormData();
    // data.append('limit', '10');
    data.append('device_token', '20.204.66.244');
    data.append('expertid', this.state?.profileData?.instituteId); 
    data.append('paged', this.state.page)
    this.setState({ isProcessing: true })


    axios.post(`${BLOG_URL}`, data,
        {
            headers: { 'Eba-access-api-base-token': BLOG_URL_TOKEN}
        }).then(res => {
          
            this.setState({ articleData: res.data, isProcessing: false });
        }).catch(err => {
            this.setState({ isProcessing: false })
        })
        
}

onPaginationButtonClick = (page) => {
  window.scrollTo(0, 0);
  this.setState({ page: page })
}

  
 

    render() {
      return (
        <div>
          <ListOfArticlesJsx 
          articleData={this.state.articleData}
          onPaginationButtonClick={this.onPaginationButtonClick}
          page={this.state.page}
          profileData={this.state.profileData}
          />
          {this.state.isProcessing && <Loader />}
        </div>
      );
    }
  }
  
 