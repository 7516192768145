import React from 'react'
import Sign_out_squre from "../../../../assets/images/Sign_out_squre.svg";
import question from "../../../../assets/images/question.svg"
import { Footer } from '../../../common/footer/footer';
import Sidebar from "../sidebar/sidebar.js";
import { useHistory } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { MyPaymentCard } from "../../../common/myPaymentCard/myPaymentCard";

const MyPaymentsJsx = (props) => {
  const { t } = useTranslation();
  const {
    paymentData,
    formData,
    onInputChangeHandler,
    onTouchHandler,
    formSubmitButtonDisable,
    formError,
    formSubmitHandler,
    backToAddCard,
    isLoading,
    isShowCard,
    onReplaceCardClick,
    onBackCardClick,
    isCardSummary,
    isPaymentSummary, backToPayment,
    onCompletePayment, invoiceDownload
  } = props;
  const history = useHistory();


  const cardDetails = JSON.parse(sessionStorage.getItem("addCard"))

  return (
    <>
      <section className="main_customer_dashboard">
        <section className="entiredashboard maincustomerdash expert-dashboard">
          <div className="container-fluid">
            <div className="row">
              <div className="col-lg-4 col-sm-4">
                <Sidebar />
              </div>
              <div className="col-lg-8 col-sm-8">
                <div className="dashboardrightpanel martop40">
                  <div className="dashboardrightpannerinner">
                    <div className="dashboardrightpannerinnerleft">
                      <h2 className="h2_style font-weight-600">Expert Payments</h2>
                      <h5 className="h5_style">This section shows your added cards, subscription details & payment history</h5>
                    </div>
                    <div className="dashboardrightpannerinnerright">
                      <div className="signout">
                        <a href="#logout">
                          <img src={question} alt={''} /><span className="title">Help</span>
                        </a>
                      </div>
                      <div className="signout">
                        <a href="#logout">
                          <img src={Sign_out_squre} alt={''} /><span className="title">Logout</span>
                        </a>
                      </div>
                      <div id="logout" className="modal-window">
                        <div>
                          <a title="Close" className="modal-close">Close</a>
                          <div className="modal-header">

                          </div><div className="modal-body mb-4">
                            <div className="modal-body-inner">
                              <h2 className="h2_style text-center">Are you sure you want to
Logout ?</h2>
                            </div>
                          </div>
                          <div className="modal-footer">
                            <button type="button" className="ctaonebutton"> Save</button>
                            <button type="button" className="ctatwobutton"> Save</button>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>



                  <MyPaymentCard
                    isShowCard={isShowCard}
                    isCardSummary={isCardSummary}
                    isPaymentSummary={isPaymentSummary}
                    paymentData={paymentData}
                    onReplaceCardClick={onReplaceCardClick}
                    formData={formData}
                    onInputChangeHandler={onInputChangeHandler}
                    onTouchHandler={onTouchHandler}
                    invoiceDownload={invoiceDownload}
                    onBackCardClick={() => onBackCardClick()}
                    formError={formError}
                    formSubmitButtonDisable={formSubmitButtonDisable}
                    formSubmitHandler={formSubmitHandler}
                    isLoading={isLoading}
                    question={question}
                    Sign_out_squre={Sign_out_squre}
                    cardDetails={cardDetails}
                    backToAddCard={backToAddCard} 
                    onCompletePayment={onCompletePayment}
                    backToPayment={backToPayment}
                  />

                </div>
              </div>
            </div>
          </div>
        </section>
        <Footer/>
      </section> 
    </>

  );
};

export default MyPaymentsJsx;