
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom/cjs/react-router-dom.min";
import './adsCard.css';


export const AdsCard = (props) => {

  const {
    imageUrl,
    description,
    categoryname,
    externalLink
  } = props;

  const { t } = useTranslation(); 
  
  return (
    <div className={`sliderContent category-id${categoryname}`}>
      <img
        className="d-block w-100 slideImg"
        src={imageUrl}
        alt={"ads image"}
      />
      <div className="pb-4">
        <h5 className="fw-bold text-capitalize mb-4 mt-4 text-center">{description}</h5>
        <a href={externalLink} className="applybutton" target="_blank" rel="noopener noreferrer">
          {t("COMMON.KNOWMORE")}
        </a>
      </div>
    </div>
  );
};
