
const sliderSettingTwoRowFourColumn = {
  infinite: false,
  rows: 2,
  speed: 500,
  slidesToShow: 4,
  slidesToScroll: 1,
  dots: true,
  adaptiveHeight: false,
  arrows: true,
  responsive: [
      {
          breakpoint: 1024,
          settings: {
              slidesToShow: 2,
              rows: 2,
              infinite: true,
              dots: false
          }
      },
      {
          breakpoint: 600,
          settings: {
              slidesToShow: 2,
              rows: 2,
              dots: false,
              infinite: false,
              autoplay:true

          }
      },
      {
          breakpoint: 480,
          settings: {
              slidesToShow: 2,
              rows: 2,
              dots: false,
              infinite: false,
              autoplay:true

          }
      },
      {
          breakpoint: 320,
          settings: {
              slidesToShow: 2,
              rows: 2,
              dots: false,
              infinite: false,
              autoplay:true

          }
      }
  ]
};


const trendingsection = {
  infinite: false,
  speed: 500,
  slidesToShow: 2,
  arrow: true,
  adaptiveHeight: true,
  slidesToScroll: 1,
  dots: false,
  responsive: [
      {
          breakpoint: 1024,
          settings: {
              slidesToShow: 2,
              slidesToScroll: 1,
              infinite: false,
              arrow:true,
              dots: false
          }
      },
      {
          breakpoint: 600,
          settings: {
             
              slidesToShow: 1,
              // mobileFirst: true,
              infinite: true,
              arrow:true,
              dots:false,
              autoplay:true

          }
      },
      {
          breakpoint: 480,
          settings: {
              dots: false,
              slidesToShow: 1,
              mobileFirst: true,
              infinite: true,
              arrow:true,
              autoplay:true

          }
      },
      {
          breakpoint: 320,
          settings: {
            dots: false,
            slidesToShow: 1,
            mobileFirst: true,
            infinite: true,
            arrow:true,
            autoplay:true

          }
      }
  ]


};


const trendingsectionunslick = {
  infinite: false,
  speed: 500,
  slidesToShow: 5,
  arrow: true,
  adaptiveHeight: true,
  slidesToScroll: 1,
  dots: false,
  responsive: [
  
      {
          breakpoint: 1024,
          settings: {
              slidesToShow: 2,
              slidesToScroll: 1,
              infinite: false,
              arrow:true,
              dots: false
          }
      },
      {
          breakpoint: 600,
          settings: {
             
              slidesToShow: 1,
              mobileFirst: true,
              infinite: true,
              arrow:true,
              dots:false,
              autoplay:true

          }
      },
      {
          breakpoint: 480,
          settings: {
              dots: false,
              slidesToShow: 1,
              mobileFirst: true,
              infinite: true,
              arrow:true,
              autoplay:true

          }
      },
      {
          breakpoint: 320,
          settings: {
              dots: false,
              infinite: true,
              slidesToShow: 1,
              autoplay:true,
              arrow:true,
          }
      }
  ]


};


const sliderSettingExpertIndivituals = {
  infinite: false,
  speed: 700,
  slidesToShow: 1,
  slidesToScroll: 1,
  autoplay: true,
  autoplaySpeed: 2000,
  adaptiveHeight: true,
  cssEase: 'cubic-bezier(0.7, 0, 0.3, 1)',
  arrows: false,
  dots: false,
  responsive: [
      {
          breakpoint: 1024,
          settings: {
              slidesToShow: 1,
              slidesToScroll: 1,
              infinite: false,
              dots: false
          }
      },
      {
          breakpoint: 600,
          settings: {
              dots: false,

          }
      },
      {
          breakpoint: 480,
          settings: {
              dots: false,

          }
      },
      {
          breakpoint: 320,
          settings: {
              dots: false,

          }
      }
  ]
}




const sliderSettingOne = {
  dots: true,
  arrows: true,
  infinite: true,
  speed: 500,
  slidesToShow: 1,
  slidesToScroll: 1,
  adaptiveHeight: true,
  // initialSlide:2  
  responsive: [
    {
      breakpoint: 1024,
      settings: {
        slidesToShow: 1,
        slidesToScroll: 1,
        infinite: true,
        dots: false
      }
    },
    {
      breakpoint: 600,
      settings: {
        slidesToShow: 1,
        slidesToScroll: 1,
        dots: false,
        centerPadding: '0px',
        initialSlide: 1
      }
    },
    {
      breakpoint: 480,
      settings: {
        slidesToShow: 1,
        centerPadding: '0px',
        slidesToScroll: 1,
        dots: false
      }
    }
  ]
};

const sliderSettingFour = {
  infinite: false,
  speed: 700,
  slidesToShow: 4,
  slidesToScroll: 1,
  autoplay: true,
  autoplaySpeed: 2000,
  // adaptiveHeight: true,
  arrows: true,
  dots: false,
  responsive: [
      {
          breakpoint: 1024,
          settings: {
              slidesToShow: 2,
              slidesToScroll: 1,
              infinite: false,
              arrows: true,
              dots: false
          }
      },
      {
          breakpoint: 600,
          settings: {
              slidesToShow: 1,
              slidesToScroll: 1,
              arrows: true,
              dots: false

          }
      },
      {
          breakpoint: 480,
          settings: {
              slidesToShow: 1,
              slidesToScroll: 1,
              arrows: true,
              dots: false,

          }
      },
      {
          breakpoint: 320,
          settings: {
              slidesToShow: 1,
              slidesToScroll: 1,
              arrows: true,
              dots: false,

            }
          }
        ]
      };


 
const sliderSettingThree = {
  dots: false,
  arrows: true,
  infinite: false,
  speed: 500,
  slidesToShow: 2,
  slidesToScroll: 1,
  adaptiveHeight: true,
  margin: 10,
  // initialSlide:2  
  responsive: [
    {
      breakpoint: 1024,
      settings: {
        slidesToShow: 1,
        slidesToScroll: 1,
        infinite: true,
        dots: false
      }
    },
    {
      breakpoint: 600,
      settings: {
        slidesToShow: 1,
        slidesToScroll: 1,
        centerPadding: '0px',
        initialSlide: 1
      }
    },
    {
      breakpoint: 480,
      settings: {
        slidesToShow: 1,
        centerPadding: '0px',
        slidesToScroll: 1
      }
    }
  ]
};
const expslider = {
  dots: false,
  infinite: false,
  arrows: false,
  speed: 500,
  adaptiveHeight: true,
  slidesToShow: 5,
  slidesToScroll: 1,
  centerMode: false,
  //centerPadding: '20px',
  //  initialSlide:2    
  responsive: [
    {
      breakpoint: 1024,
      settings: {
        slidesToShow: 2,
        slidesToScroll: 1,
        arrows: true,
        infinite: true,
        dots: false
      }
    },
    {
      breakpoint: 600,
      settings: {
        slidesToShow: 2,
        slidesToScroll: 1,
        arrows: true,
        initialSlide: 2
      }
    },
    {
      breakpoint: 480,
      settings: {
        slidesToShow: 1,
        arrows: true,
        slidesToScroll: 1
      }
    }
  ]
};
export { expslider, trendingsection,trendingsectionunslick, sliderSettingTwoRowFourColumn, sliderSettingThree, sliderSettingOne, sliderSettingExpertIndivituals, sliderSettingFour };