import axios from 'axios';
import React from 'react'
import { Component } from 'react';
import { ToastContainer,toast } from 'react-toastify';

import { baseURL ,uploadURL} from '../../../../constants/applicationConstants.js';
import { allRegex, postalCodeRegex } from '../../../../shared/helper.js';
import { Loader } from '../../../common/loader/loader.jsx';
import CustomerAskTheExpertJsx from './adminCategory.jsx'

export default class AskTheExpert extends Component {
    constructor(props) {
        super(props);
        this.state = {
            categoryData: null,
            subCategoryData: null,
            formData: {
                name: '',
                category: '',
                subCategory: ''
            },
            editCategoryId:'',
            formError: {},
            formSubmitButtonDisable: true,
            isLoading: false,
            expertData: [],
            editOn:false,
            imageUrl: "",

            meta: {},
            page: 1,
            image: null,
            profileImagePreviewUrl: null,

        }
    }
    validFileName = (name) => name.includes('.jpeg') || name.includes('.jpg') || name.includes('.png');

    onFileChange = (e) => {
        let file, img;
        if (e.target.files[0]?.size) {
          if (e.target.files[0]?.size < 512000) {
            if (e.target.files[0] && this.validFileName(e.target.files[0].name)) {
              // if ((file = e.target.files[0])) {
              //     img = new Image();
              //     img.onload = function () {
              //         if (this.height > 800) {
              //             toast.error("big height")
              //         }
              //         else{
              //             toast.error("small height")
              //             img.src = this.state._URL.createObjectURL(file);
              //             this.setState({ fileInfo: e.target.files[0] })
              //             this.setState({ profileImagePreviewUrl: URL.createObjectURL(e.target.files[0]) })
              //             this.setState({ imageName: e.target.files[0].name },()=> this.isFromSubmissionBlock())
              //         }
              //     };
              //     img.onerror = function () {
              //         toast.error("not a valid file: " + file.type);
              //     };
              // }
              this.setState({ fileInfo: e.target.files[0] }, () => this.isFromSubmissionBlock())
              this.setState({ profileImagePreviewUrl: URL.createObjectURL(e.target.files[0]) })
              this.setState({ imageName: e.target.files[0].name })
            }
            else
              toast.error('.jpeg/.jpg/.png format required')
          }
          else
            toast.error('File should be less than 500 kb.')
        }
      }

    onNextButtonClick = () => {
        this.setState({
          isLoading: true
        })
        let formData1 = new FormData();
    
        if (this.state.fileInfo) {
          formData1.append('image', this.state.fileInfo)
          formData1.append('imageCategory', "category")
    
          axios.post(`${uploadURL}/upload-file`, formData1).then(resp => {
            if (resp.status === 200) {
               
                this.setState({ image: resp.data.filename }, () => this.isFromSubmissionBlock())

              toast.success("Image uploaded successfully");
              this.setState({
                isLoading: false
              })
            //   setTimeout(() => {
            //     this.onNextButtonClick1()
            //   }, 2000)
            }
          }).catch(() => {
            this.setState({
              isLoading: false
            })
          })
        }
        else this.onNextButtonClick1();
      };

    componentDidMount = () => {
        this.setState({ isLoading: true })
        let token = JSON.parse(sessionStorage.getItem('token'));
        axios.get(`${baseURL}/institutionCategoriesAndSubCategories`, {
            headers: { 'x-access-token': token }
        }).then(res => {
            this.setState({ categoryData: res.data.data, isLoading: false })
        })
        this.getSegment()
        this.getCat()
    }

    getSegment = ()=>{
        this.setState({ isLoading: true })
        let token = JSON.parse(sessionStorage.getItem('token'));
        axios.get(`${baseURL}/instituteSegments`, {
            headers: { 'x-access-token': token }
        }).then(res => {
            this.setState({ subCategoryData: res.data.data, isLoading: false })
        })
    }

    onCatgoryClick = (value) => {
        // const subData = this.state.categoryData.filter(itm => itm.institutionCategoryId == value)
        // this.setState({ subCategoryData: subData[0]?.subInstitutionCategories })
    }

    onInputChange = (e) => {
        let name = e.target.name;
        let value = e.target.value;
        if (name === 'category') {
            this.onCatgoryClick(value)
            this.setState(
                {
                    formData: {
                        ...this.state.formData,
                        'subCategory': '',
                    }
                }
            )

        }


        if (value) {
            if (!this.inputRegexValidation(name, value)) {
                this.setState(
                    {
                        formData: {
                            ...this.state.formData,
                            [name]: value,
                        },
                        formError: {
                            ...this.state.formError,
                            [name]: "regx",
                        },
                    },
                    () => this.isFromSubmissionBlock()
                );
            } else {
                this.setState(
                    {
                        formData: {
                            ...this.state.formData,
                            [name]: value,
                        },
                        formError: {
                            ...this.state.formError,
                            [name]: "",
                        },
                    },
                    () => this.isFromSubmissionBlock()
                );
            }
        } else {
            this.setState(
                {
                    formData: {
                        ...this.state.formData,
                        [name]: value,
                    },
                    formError: {
                        ...this.state.formError,
                        [name]: "",
                    },
                },
                () => this.isFromSubmissionBlock()
            );
        }
    }

    inputRegexValidation = (name, value) => {
        if (name === 'name')
            return allRegex.test(value);
        if (name === 'postalCode')
            return postalCodeRegex.test(value);

        return true;
    };

    isFromSubmissionBlock = () => {
        let { name,subCategory } = this.state.formData;
let image = this.state.image;
        if (name  && subCategory && image) {
            if (
                this.inputRegexValidation('name', name)
            ) {
                this.setState({
                    formSubmitButtonDisable: false
                });
            }
            else {
                this.setState({
                    formSubmitButtonDisable: true
                });
            }
        } else {
            this.setState({
                formSubmitButtonDisable: true
            });
        }
    };

    onTouchHandler = (e) => {
        let name = e.target.name;
        let value = e.target.value;

        if (name === 'category') {
            this.onCatgoryClick(value)
            this.setState(
                {
                    formData: {
                        ...this.state.formData,
                        'subCategory': '',
                    }
                }
            )

        }

        if (value === "") {
            this.setState({
                formError: {
                    ...this.state.formError,
                    [name]: "req",
                },
            });
        } else {
            if (!this.inputRegexValidation(name, value)) {
                this.setState({
                    formError: {
                        ...this.state.formError,
                        [name]: "regx",
                    },
                });
            }
        }
    };
    onPaginationButtonClick = (page) => {
        window.scrollTo(0, 0);
        this.setState({ page: page }, () => this.getCat())
    }

    onSubmitButtonClick = () => {
        let token = sessionStorage.getItem("token") && JSON.parse(sessionStorage.getItem("token"))
        let image = this.state.image;

        this.setState({ isLoading: true });
        const { name, category, subCategory } = this.state.formData;
        const payload = {
            "institutionSegmentId": subCategory,
            "name": name,
            "icon": image
        }

        const pay1 ={
            "name":name,
    "institutionCategoryId": this.state.editCategoryId,
    "institutionSegmentId": subCategory,
    "icon": image
        }

        if (category) {
            payload.parentId = category
          }
        
        if(this.state.editOn){
            axios.put(`${baseURL}/admin/categories`, pay1, {
                headers: { 'x-access-token': token }
              }).then(res => {
                this.setState({ isLoading: false })
                toast.success("Category added succuessfully!")
                this.setState({ profileImagePreviewUrl: null })
    
                setTimeout(() => {
                    window.location.reload()
                }, 2000);
            }).catch(err => {
                this.setState({ isLoading: false })
                toast.error(err?.response.data.erfrowsror.message);
            })
        }else{
            axios.post(`${baseURL}/admin/categories`, payload, {
                headers: { 'x-access-token': token }
              }).then(res => {
                this.setState({ isLoading: false })
                toast.success("Category added succuessfully!")
                this.setState({ profileImagePreviewUrl: null })
    
                setTimeout(() => {
                    // this.getCat()

                    window.location.reload()
    
                }, 2000);
            }).catch(err => {
                this.setState({ isLoading: false })
                toast.error(err?.response.data.error.message);
            })
        }
    }
    deleteOffer = (data) => {
        let token = JSON.parse(sessionStorage.getItem('token'));
        this.setState({ isLoading: true })
    
        axios.delete(`${baseURL}/admin/categories/${data}`, {
          headers: { 'x-access-token': token }
        }).then(res => {
          this.setState({
            isLoading: false
          })
          this.getCat();
        }).catch(err=>{
          this.setState({ isLoading: false })
        })
      }
      editCategory = (data) => {
        let token = JSON.parse(sessionStorage.getItem('token'));
        this.setState({ isLoading: true,editCategoryId:data })
        this.setState({editOn:true})
    
        axios.get(`${baseURL}/admin/categories/${data}`, {
          headers: { 'x-access-token': token }
        }).then(res => {
            let result = res.data.data
            this.setState({image:result?.icon,imageUrl:result?.icon})

            this.setState({
                formData: {
                    name: result?.name,
                     category: result?.parentId,
                     subCategory: result?.institutionSegmentId
                }
            },() => this.isFromSubmissionBlock())
          this.setState({
            isLoading: false
          })
        //   this.getCat();
        }).catch(err=>{
          this.setState({ isLoading: false })
        })
      }
    getCat = () => {
        let token = sessionStorage.getItem("token") && JSON.parse(sessionStorage.getItem("token"))
        this.setState({ isLoading: true });
       
        axios.get(`${baseURL}/admin/categories?page=${this.state.page}&perPage=10`, {
            headers: { 'x-access-token': token }
          }).then(res => {
                        this.setState({expertData:res.data.data,meta:res.data.meta,isLoading:false});

            toast.success("Category fetched succuessfully!")
           
        }).catch(err => {
            this.setState({ isLoading: false })
            toast.error(err?.response?.data?.error?.message);
        })
    }


    onViewProfieClick = (url) => {
        this.props.history.push(`/expertPpv/${url}`)
    }
    handleChange = (e,id) => {
        const { checked } = e.target
        
        this.setState({
          checked: checked
        })
        this.makeFeature(id, checked,e.target.name)
      }

    makeFeature = (id, action,name) => {
        const token = JSON.parse(sessionStorage.getItem('token'));

        this.setState({ isLoading: true });
        let data = {
            "isFeaturedOfferSubCategory": action
        }
        let dataProd = {
            "isFeaturedProductSubCategory": action
        }


        axios.put(`${baseURL}/admin/institutionCategory/markFeaturedSubcategory/${id}`,name === "offer" ? data: dataProd, {
            headers: {
              'x-access-token': token
            }
          }).then(res => {
            this.getCat()
        }).catch(err => {
            this.setState({ isLoading: false });
            toast.error(err?.response.data.error.message);
        })
    }

    render() {
        return (
            <div>
                <CustomerAskTheExpertJsx
                    categoryData={this.state.categoryData}
                    subCategoryData={this.state.subCategoryData}
                    formData={this.state.formData}
                    onInputChange={this.onInputChange}
                    formError={this.state.formError}
                    onTouchHandler={this.onTouchHandler}
                    formSubmitButtonDisable={this.state.formSubmitButtonDisable}
                    onSubmitButtonClick={this.onSubmitButtonClick}
                    expertData={this.state.expertData}
                    meta={this.state.meta}
                    editOn={this.state.editOn}
                    deleteOffer={this.deleteOffer}
                    editCategory={this.editCategory}
                    onViewProfieClick={this.onViewProfieClick}
                    onPaginationButtonClick={this.onPaginationButtonClick}
                    profileImagePreviewUrl={this.state.profileImagePreviewUrl}
                    onFileChange={this.onFileChange}
                    onNextButtonClick={this.onNextButtonClick}
                    imgURL={this.state.imageUrl}
                    handleChange={this.handleChange}

                />
                {this.state.isLoading && <Loader />}
                <ToastContainer />            </div>
        )
    }
}
