import React from 'react'
import { useTranslation } from "react-i18next";
import { useEffect } from 'react';
import selectall from "../../../../assets/offersimage/Select_all.svg";
import editicon from "../../../../assets/images/edit-icon.png";
import 'react-awesome-slider/dist/styles.css';
import AOS from 'aos';
import 'aos/dist/aos.css';
import { NEW_URL } from '../../../../constants/applicationConstants';
import { useLocation } from 'react-router-dom/cjs/react-router-dom.min';
import cross from '../../../../assets/images/cross.svg';


export const FilterGlobal = (props) => {
  const { t } = useTranslation();
  const { pathname } = useLocation()

  const {
    categoryData,
    changeOtherState,
    selectAll,
    selectAllHit,
    selectCategory,
    selectSubCategory,
    subCategories,
    categories, filteredData, submitInterest, changeInterestState, editInterest,wholeData
  } = props;



  useEffect(() => {
    AOS.init();
    window.scrollTo(0, 0);
  }, [])

  return (


    <>
      {!editInterest ? <div className="myaccountsection institutedetails martop60 selectcategory">
        <div className="myaccountsectionheading"> <h3 className="h3_style">Interest Section</h3>

          <div className="mybtn">
            <button type="button" className="edit-button" onClick={() => changeInterestState(true)}>{t("customeroccupation.EDITBUTTON")} <img src={editicon} alt="" /></button>
          </div>
        </div>

        
        <div className="catsubcatsection">
          <div className="maincat">
          <div className="subcat">
              <div>
          {wholeData?.interests?.map((item) => {
              return (
                <h4 className="mt-3">{item?.institutionCategory?.name}</h4>
              )
            })}
            </div>
            </div>
            {/* <div className="subcat">
              <ul>
                <li><span><img src={selectall} /></span>Purchase</li>  <li><span><img src={selectall} /></span>Purchase</li>  <li><span><img src={selectall} /></span>Purchase</li>
              </ul>
            </div> */}
          </div>
        </div>
      </div>
        : null}
      { editInterest ? <div className="myaccountsection institutedetails martop60 selectcategory">
        <div className="myaccountsectionheading"> <h3 className="h3_style">Interest Section</h3>


        <div className="mybtn">
            <button type="button" className="myadbtn cross_btn" onClick={() => changeInterestState(false)}><img src={cross} /></button>
          </div>
        </div>

        <div className="selectallinnercheckbox select-all">
          <ul>
            {categoryData?.map((item) => {
              return (
                <>
                  <li className={`selectinner ${item.name}`} >
                    <div className="dropdown">

                      <div className="cstick">
                        <input className="form-check-input"
                          checked={categories?.includes(item.institutionCategoryId)}

                          type="checkbox" value="" id="Checkmerealestate" onChange={(e) => selectCategory(item, e)} />
                      </div>
                      <div
                        className={pathname == "/globalMainSearchexpert" || pathname == "/globalMainSearchInstitutions" ? 'form-check' : 'form-check dropdown-toggle'}
                        type="button" id="dropdownMenuButtoninvest" data-bs-toggle="dropdown" aria-expanded="false">

                        <span className="maincheckboxicon">
                          <img src={`${NEW_URL}/${item.icon}`} alt='' />
                        </span><label className="form-check-label mainchecklabel" for="Checkme4">{item.name}</label>

                      </div>

                    </div>
                  </li>
                </>
              )
            })}
          </ul>
        </div>
        <button className="showallourbesttoo" disabled={!categories?.length} onClick={() => submitInterest()}>Submit</button>
        
      </div> : null}

    </>
  );
};

export default FilterGlobal;