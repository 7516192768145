import React, { useState, useEffect } from 'react'

import swal from "sweetalert";
import axios from "axios";
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { baseURL, NEW_URL } from "../../../../constants/applicationConstants.js";
import { Loader } from "../../../common/loader/loader.jsx";
import "../myProducts/products.model.css";
import deleteimg from "../../../../assets/Instituteppvimages/delete_white.svg";
import closeimg from "../../../../assets/Instituteppvimages/close.svg";
import edit from "../../../../assets/Instituteppvimages/edit.svg";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next"; 

const ItemDetails = (props) => {
    const {
        getProducts, getParticularProducts, openEditBox,onProductcloseClick
    } = props;
    const [itemData, setItemData] = useState("");
    const [loading, setLoading] = useState(false)
    useEffect(() => {
        getProductsData();

    }, [])
    const { t } = useTranslation();
    const getProductsData = () => {
        setLoading(true)
        let token = JSON.parse(sessionStorage.getItem('token'));

        axios.get(`${baseURL}/institutionProduct/${props?.totalData?.institutionProductId}`, {
            headers: { 'x-access-token': token }
        }).then(res => {
            setItemData(res.data.data)
            setLoading(false)
        }).catch(err => {
            setLoading(false)
        })
    }
    const deleteItem = () => {
        swal({
            title: "Are you sure?",
            text: "You will not be able to recover this product",
            icon: "warning",
            buttons: [
                'Do not cancel it!',
                'Yes, Delete it!'
            ],
            dangerMode: true,
        }).then(function (isConfirm) {
            if (isConfirm) {
                deleteProduct()
            } else {
                //   swal("Cancelled", "Your imaginary file is safe :)", "error");
            }
        })
    }

    const deleteProduct = () => {
        setLoading(true)        
        let token = JSON.parse(sessionStorage.getItem('token'));
        axios.delete(`${baseURL}/institutionProduct/${props?.totalData?.institutionProductId}`, {
            headers: { 'x-access-token': token }
        }).then((res) => {

            // swal({
            //   title: "success",
            //   text: "product added successfully",
            //   icon: "success",
            // });
            setLoading(false)
            
            toast.success("Product deleted successfully");
            props.onProductClick()            
            getProducts()
            window.location.reload()

        }).catch((res) => {
            toast.error(res?.response?.data?.error?.message)
            setLoading(false)
            window.location.reload()

        })
    }
    const multiple = () => {
        openEditBox();
        getParticularProducts(props?.totalData?.institutionProductId);
    }
    
 
    return (
        <>

            <section className="product-details-section">
                <div className="product-details"><img src={`${NEW_URL}/${itemData.imageUrl}`} alt={'background'} /></div>
                <div className="close-img" onClick={onProductcloseClick}><img src={closeimg} alt={'close'} /></div>
                <div>
                    <div className="headeing">
                        <div className="row ">
                            <div className="col-9">
                                <p className="product-details-title">{itemData.productName}</p>
                                <p className="product-details-category">{itemData.institutionCategoryName} | {itemData?.subInstitutionCategory?.name}</p>
                            </div>
                            <div className="mybtn col-3 button-section">
                                <button type="button" className="detail-edit-btn" onClick={() => multiple()}>{t("instituteproduct.EDIT")}<img className="detail-edit-img" src={edit} alt={'edit'} /> </button><br/>
                                <button type="button" className="detail-delet-btn" onClick={() => deleteItem()}>{t("instituteproduct.DELETE")}<img className="detail-delet-img " src={deleteimg} alt={'delete'} />
                                </button>

                            </div>
                        </div>
                        <div className="details-tag">
                            {
                                
                                itemData?.metaTags?.map(product => {
                                    return (
                                        <p className="product-details-categorytag">{product}</p>

                                    )

                                })}
                        </div>
                        <p className="detail-intreast">{t("instituteproduct.INTRESTRATE")} : <strong>{itemData.interestRate}%</strong></p>
                    </div>

                </div>
                <div className=" ">
                    <p className="detail-description-heading">{t("instituteproduct.DESCRIPTION")}</p> 
                    <p className="detail-description">{itemData.description}</p>

                    <div className="row">
                        <div className="col-5">
                            <p className="detail-description-heading">{t("instituteproduct.BENIFITS")}</p>
                            <p className="detail-description">{itemData.benefits}</p>
                        </div>
                        <div className="col-2 divider-div"><div className="detail-divider"></div></div>
                        <div className="col-5">
                            <p className="detail-description-heading">{t("instituteproduct.FEATURES")}</p>
                            <p className="detail-description">{itemData.features}</p>
                        </div>
                         
                    </div>
                    <p className="detail-description-heading">{t("instituteproduct.LINKTOPRODUCT")}</p>
                      
                    <Link  to={{pathname:itemData.externalLink}} target="_blank">{itemData.externalLink}</Link>
                </div>
            </section>
            {loading && <Loader />}
        </>

    );
};

export default ItemDetails;
