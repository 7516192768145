import React from 'react'
import LogoSection from "./logo.jsx"
// import InstituteDetails from "./instituteDetails.jsx"
import CustomerDetails from "./customerDetails.jsx"
import close_btn from '../../../../assets/images/white_cross.svg';

import Filter from './interest.jsx';
import { useTranslation } from "react-i18next";

import OccupationInfo from './occupation.jsx'

// import AddSpecialInfo from '../specialInformation/specialInformation.js'

const AccountSettingJsx = (props) => {
  const { onInputChangeHandler, onSubmitButtonClick,
    segmentList, categoryList, handleChange, sizeList, onDateChange,
    editInstitite, editLogo, changeLogoState, editOther, changeOtherState,changeInterestState,editInterest,
    changeHideState, onInstituteButtonClick, changeContactState, formError,
    onTouchHandler, formSubmitButtonDisable, occupationBlock, imgURL,
    onFileChange, imageName, profileImagePreviewUrl,
    onNextButtonClick, onOthersButtonClick, selectAll, selectAllHit, getAllFeauturedOffersData,
    selectCategory, selectSubCategory, subCategories, categories, categoryData,submitInterest,showSupportModal,showSupportModalHit,onQueryButtonClick
  } = props;
  const { t } = useTranslation();
  const wholeData = props.formData
  const responseData = props.responseData



  // {t("PRINT.TITLE")}
  return (
    <>
      <section className="account-settings account-setting">
        <div className="container">
          <div className="row">
            <div className="account-settings-inner">
              <div >
                <section className="addexpertformsec">

                  <LogoSection
                    wholeData={wholeData}
                    responseData={responseData}
                    onSubmitButtonClick={onSubmitButtonClick}
                    handleChange={handleChange}
                    editLogo={editLogo}
                    changeLogoState={changeLogoState}
                    imgURL={imgURL}
                    onNextButtonClick={onNextButtonClick}
                    onFileChange={onFileChange}
                    imageName={imageName}
                    profileImagePreviewUrl={profileImagePreviewUrl}
                  />
                  
                  <CustomerDetails
                    wholeData={wholeData}
                    onInputChangeHandler={onInputChangeHandler}
                    responseData={responseData}
                    handleChange={handleChange}
                    sizeList={sizeList}
                    onDateChange={onDateChange}
                    segmentList={segmentList}
                    categoryList={categoryList}
                    editInstitite={editInstitite}
                    changeHideState={changeHideState}
                    onInstituteButtonClick={onInstituteButtonClick}
                    formError={formError}
                    onTouchHandler={onTouchHandler}
                    formSubmitButtonDisable={formSubmitButtonDisable}
                    provinceList={props?.provinceList}
                    countryList={props?.countryList}

                  />
                  <OccupationInfo
                    wholeData={wholeData}
                    onInputChangeHandler={onInputChangeHandler}
                    responseData={responseData}
                    changeOtherState={changeOtherState}
                    onOthersButtonClick={onOthersButtonClick}
                    handleChange={handleChange}
                    editOther={editOther}
                    changeContactState={changeContactState}
                    formError={formError}
                    onTouchHandler={onTouchHandler}
                    occupationBlock={occupationBlock}

                  />

                  <Filter
                  wholeData={wholeData}
                    categoryData={categoryData}
                    selectAll={selectAll}
                    selectAllHit={selectAllHit}
                    getAllFeauturedOffersData={getAllFeauturedOffersData}
                    selectCategory={selectCategory}
                    selectSubCategory={selectSubCategory}
                    subCategories={subCategories}
                    categories={categories}
                    submitInterest={submitInterest}
                    changeInterestState={changeInterestState}
                    editInterest={editInterest}
                  />
                  {/* <div className="myaccountsection institutedetails martop60 categorycustomeraccount">
                  <div className="myaccountsectionheading">
          <h3 className="h3_style">Interest</h3>
          <div className="mybtn">
            <button type="button" className="edit-button" onClick={() => changeOtherState(true)}>{t("customeroccupation.EDITBUTTON")} <img src={editicon} alt="" /></button>
          </div>
        </div>
                    <div className="selectallinnercheckbox select-all">
                     <ul>
                     <li className="selectinner Real Estate">
                       <div className="dropdown">
                       <div className="cstick">
                      <input className="form-check-input" type="checkbox" value="" id="Checkmerealestate"  />
                         </div>
                         <div class="form-check dropdown-toggle" type="button" id="dropdownMenuButtoninvest" data-bs-toggle="dropdown" aria-expanded="false">
                           <span class="maincheckboxicon"><img src="https://rimib-storage.us-east-1.linodeobjects.com/rimib-dev/b0c95c5b-d0f3-49d0-a1a0-dc8012372f53.png" alt=""/></span>
                           <label class="form-check-label mainchecklabel" for="Checkme4">Insurance</label></div>
                           <ul class="dropdown-menu" aria-labelledby="dropdownMenuButtoninvest">
                             <li><a class="dropdown-item">
                               <div class="form-check">
                                 <input class="form-check-input" type="checkbox" id="Checkme1" value="" />
                                 <span class="subcatimage">
                                   <img src="https://rimib-storage.us-east-1.linodeobjects.com/rimib-dev/ffcdd506-09a4-47ba-ac0a-8f60e7097a75.png" alt="" /></span> 
                                   <label class="form-check-label" for="Checkme1">Life</label></div></a></li>
                           </ul>
                       </div>
                       </li>

                       <li className="selectinner Investment">
                       <div className="dropdown">
                       <div className="cstick">
                      <input className="form-check-input" type="checkbox" value="" id="Checkmerealestate"  />
                         </div>
                         <div class="form-check dropdown-toggle" type="button" id="dropdownMenuButtoninvest" data-bs-toggle="dropdown" aria-expanded="false">
                           <span class="maincheckboxicon"><img src="https://rimib-storage.us-east-1.linodeobjects.com/rimib-dev/b0c95c5b-d0f3-49d0-a1a0-dc8012372f53.png" alt=""/></span>
                           <label class="form-check-label mainchecklabel" for="Checkme4">Insurance</label></div>
                           <ul class="dropdown-menu" aria-labelledby="dropdownMenuButtoninvest">
                             <li><a class="dropdown-item">
                               <div class="form-check">
                                 <input class="form-check-input" type="checkbox" id="Checkme1" value="" />
                                 <span class="subcatimage">
                                   <img src="https://rimib-storage.us-east-1.linodeobjects.com/rimib-dev/ffcdd506-09a4-47ba-ac0a-8f60e7097a75.png" alt="" /></span> 
                                   <label class="form-check-label" for="Checkme1">Life</label></div></a></li>
                           </ul>
                       </div>
                       </li>

                       <li className="selectinner Mortgage">
                       <div className="dropdown">
                       <div className="cstick">
                      <input className="form-check-input" type="checkbox" value="" id="Checkmerealestate"  />
                         </div>
                         <div class="form-check dropdown-toggle" type="button" id="dropdownMenuButtoninvest" data-bs-toggle="dropdown" aria-expanded="false">
                           <span class="maincheckboxicon"><img src="https://rimib-storage.us-east-1.linodeobjects.com/rimib-dev/b0c95c5b-d0f3-49d0-a1a0-dc8012372f53.png" alt=""/></span>
                           <label class="form-check-label mainchecklabel" for="Checkme4">Insurance</label></div>
                           <ul class="dropdown-menu" aria-labelledby="dropdownMenuButtoninvest">
                             <li><a class="dropdown-item">
                               <div class="form-check">
                                 <input class="form-check-input" type="checkbox" id="Checkme1" value="" />
                                 <span class="subcatimage">
                                   <img src="https://rimib-storage.us-east-1.linodeobjects.com/rimib-dev/ffcdd506-09a4-47ba-ac0a-8f60e7097a75.png" alt="" /></span> 
                                   <label class="form-check-label" for="Checkme1">Life</label></div></a></li>
                           </ul>
                       </div>
                       </li>

                       <li className="selectinner Insurance">
                       <div className="dropdown">
                       <div className="cstick">
                      <input className="form-check-input" type="checkbox" value="" id="Checkmerealestate"  />
                         </div>
                         <div class="form-check dropdown-toggle" type="button" id="dropdownMenuButtoninvest" data-bs-toggle="dropdown" aria-expanded="false">
                           <span class="maincheckboxicon"><img src="https://rimib-storage.us-east-1.linodeobjects.com/rimib-dev/b0c95c5b-d0f3-49d0-a1a0-dc8012372f53.png" alt=""/></span>
                           <label class="form-check-label mainchecklabel" for="Checkme4">Insurance</label></div>
                           <ul class="dropdown-menu" aria-labelledby="dropdownMenuButtoninvest">
                             <li><a class="dropdown-item">
                               <div class="form-check">
                                 <input class="form-check-input" type="checkbox" id="Checkme1" value="" />
                                 <span class="subcatimage">
                                   <img src="https://rimib-storage.us-east-1.linodeobjects.com/rimib-dev/ffcdd506-09a4-47ba-ac0a-8f60e7097a75.png" alt="" /></span> 
                                   <label class="form-check-label" for="Checkme1">Life</label></div></a></li>
                           </ul>
                       </div>
                       </li>
                       <li className="selectinner Banking">
                       <div className="dropdown">
                       <div className="cstick">
                      <input className="form-check-input" type="checkbox" value="" id="Checkmerealestate"  />
                         </div>
                         <div class="form-check dropdown-toggle" type="button" id="dropdownMenuButtoninvest" data-bs-toggle="dropdown" aria-expanded="false">
                           <span class="maincheckboxicon"><img src="https://rimib-storage.us-east-1.linodeobjects.com/rimib-dev/b0c95c5b-d0f3-49d0-a1a0-dc8012372f53.png" alt=""/></span>
                           <label class="form-check-label mainchecklabel" for="Checkme4">Insurance</label></div>
                           <ul class="dropdown-menu" aria-labelledby="dropdownMenuButtoninvest">
                             <li><a class="dropdown-item">
                               <div class="form-check">
                                 <input class="form-check-input" type="checkbox" id="Checkme1" value="" />
                                 <span class="subcatimage">
                                   <img src="https://rimib-storage.us-east-1.linodeobjects.com/rimib-dev/ffcdd506-09a4-47ba-ac0a-8f60e7097a75.png" alt="" /></span> 
                                   <label class="form-check-label" for="Checkme1">Life</label></div></a></li>
                           </ul>
                       </div>
                       </li>
                    </ul> 
                    </div>
                  </div> */}
                  <button onClick={() => showSupportModalHit(true)} className="support-button">RIMIB Support</button>


                  {showSupportModal && 
                  <div className="modalspecial productdetail-modal">
                    <div>
                    <div className="dashboardrightpannerinnerleft"><button className="close_btn" onClick={() => showSupportModalHit(false)} ><img src={close_btn} alt={t("CUSTOMERINTEREST.CLOSEBUTTON")} /></button>  
                        <h2 className="h2_style">{t("customeraccountsetting.HEADING")}</h2>
                        <h5 className="h5_style">{t("customeraccountsetting.PARAGRAPH")} </h5>
                      </div>
                      <div className="boxsh dashboardshaddow">
                        <h3>We are RIMIB Support</h3>
                        <p>
                        We will make every effort to respond to you as quickly as possible. Your inquiry is important to us, and we strive to prioritize all requests for assistance.
                        </p>
                        <textarea placeholder="Please enter your query here (Maximum character limit: 1500)" name="query" onChange={onInputChangeHandler} className="textps"></textarea>
                        <button  disabled={!wholeData?.query?.length} className="showallourbesttoo mt-3" onClick={() => onQueryButtonClick()}>Send Now</button>
                      </div>
                      </div>
                    
                  </div>
                  }
                </section>
              </div>
            </div>
          </div>
        </div>
      </section>

    </>


  );
};

export default AccountSettingJsx;
