import axios from 'axios';
import React from 'react'
import { Component } from 'react';
import { baseURL } from '../../../constants/applicationConstants.js';
import { Loader } from '../../common/loader/loader.jsx';
import AnswerQuestionJsx from './answerQuestion.jsx'

export default class AnswerQuestion extends Component{
    constructor(props){
        super(props);
        this.state={
            answeredQuestion:null,
            pendingQuestions:null,
            isProcessing:false,page: 1,
            meta1: {},
            meta2:{}
        }
    }

    componentDidMount = () =>{
        this.getData();
        const token = JSON.parse(sessionStorage.getItem('token'));

        if (!token) {
            this.props.history.push("/admin");
        }
    }

    getData = ()=>{
        this.setState({isProcessing:true})
        axios.get(`${baseURL}/askExpertQuestionUserResponses?status=accepted&page=${this.state.page}&perPage=10`).then(res=>{
            this.setState({answeredQuestion:res.data.data,meta2: res.data.meta,isProcessing:false})
        }).catch(err=>{
            this.setState({isProcessing:false})
        })

        axios.get(`${baseURL}/askExpertQuestionUserResponses?status=pending&page=${this.state.page}&perPage=10`).then(res=>{
            this.setState({pendingQuestions:res.data.data,meta1: res.data.meta,isProcessing:false})
        }).catch(err=>{
            this.setState({isProcessing:false})
        })
    }


    onPendingQuestionClick = () =>{
        this.props.history.push('./pendingQuestion')
    }
    onPaginationButtonClick = (page) => {
        window.scrollTo(0, 0);
        this.setState({ page: page }, () => this.getData())
    }

    onViewProfileClick = (url) =>{
        this.props.history.push(`/expertPpv/${url}`)
    }

    render(){
        return(
            <div>
                 <AnswerQuestionJsx 
                    answeredQuestion={this.state.answeredQuestion}
                    pendingQuestions={this.state.pendingQuestions}
                    onPendingQuestionClick={this.onPendingQuestionClick}
                    onViewProfileClick={this.onViewProfileClick}
                    meta1={this.state.meta1}
                    meta2={this.state.meta2}

                    onPaginationButtonClick={this.onPaginationButtonClick}
                 />
                 {this.state.isProcessing && <Loader />}
            </div>
        )
    }
}