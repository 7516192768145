import React, { useEffect, useState } from 'react'
import { useTranslation } from "react-i18next";
import { Footer } from '../../../common/footer/footer';
import { HomeArticleCardJSX } from '../../../common/homeArticleCard/homeArticleCard'
import AOS from 'aos';
import 'aos/dist/aos.css';
import { Pagination } from '../../../common/pagination/uiPagination.js';
import { BannerPpvStrip } from "../../../common/bannerPpvStrip/bannerPpvStrip.jsx";
import bannerBG from "../../../../assets/Instituteppvimages/bannerbg.svg";
const ExpertPpvBlogJsx = (props) => {
  const { t } = useTranslation();
  const {
    blogData,
    profileData,
    legalName,
    allOffersData,
    page,
    onPaginationButtonClick
  } = props;
  const itemsPerPage = 10

  const [currentPage, setCurrentPage] = useState(1);

  const currentItems = blogData?.body?.slice(
    (page - 1) * itemsPerPage,
    page * itemsPerPage
  );

  useEffect(() => {
    AOS.init();
  }, [])

  const [openModal, setOpenModal] = useState(false);
  const [blog, setBlog] = useState({});


  return (
    <>

      <section className="blogexpert">
      <BannerPpvStrip bannerBG={bannerBG} profileData={profileData} />  
        <div className="container">
          <h2 className="text-center mb-0"><b>{t("PPVEXPERT.ARTICLETITLE")}</b></h2>
          <h6 className="text-center">
            {t("PPVEXPERT.ARTICLESUBTITLE")}<br />
            {t("PPVEXPERT.ARTICLELINEONE")}<br />
            {t("PPVEXPERT.ARTICLELINETWO")}
          </h6>

          <div className="blogexpertinnerps mt-5 mb-5">

            <div className="blogppvdetails">
              {
                currentItems?.length > 0 &&
                currentItems?.map(blog => {
                  return <HomeArticleCardJSX
                    blogslug={blog?.slug}
                    blogimage={blog?.image}
                    blogtitle={blog?.title}
                    blogshort_content={blog?.short_content}
                    blogauthorimage={blog?.author_image}
                    blogimageUrl={blog?.imageUrl}
                    blogauthor={blog?.author}
                    blogdatepublish={blog?.date_publish}
                  />


                })
              }
            </div>

            <section className="newpagination"> {currentItems?.length > 0 && <Pagination
                        currentPage={currentPage}
                        page={page}
                        itemsPerPage={itemsPerPage}
                        allOffersData={blogData?.body}
                        currentItems={currentItems}
                        onPaginationButtonClick={onPaginationButtonClick}
                      />}
</section>

          </div>
        </div>
      </section>
      <Footer />
    </>
  );
};

export default ExpertPpvBlogJsx;