import React, { useState } from 'react'
import { useHistory, useLocation } from "react-router-dom";
import { Footer } from '../../../common/footer/footer'
import { BannerPpvStrip } from "../../../common/bannerPpvStrip/bannerPpvStrip.jsx";
import bannerBG from "../../../../assets/Instituteppvimages/bannerbg.svg";
import styles from "../listOfProduct/listOfProduct.module.css";
import { useTranslation } from "react-i18next";
import { ProductCard } from "../../../common/productCard/productCard.jsx"
import { Pagination } from '../../../common/pagination/pagination';

import DetailedProductsJsx from '../../../common/detailedProduct/detailedProduct.jsx';

const ListOfProductsJsx = (props) => {
  const history = useHistory();
  const location = useLocation()
  const { state: locationState } = location

  const {
    data,
    profileData,
    onPaginationButtonClick
  } = props;

  const [productDetails, setProductDetails] = useState({});
  const closeFunction = () => {
    setProductDetails({})
}

  const { t } = useTranslation();

  return (
    <>
      <section className="listofoffermainsection">

        <BannerPpvStrip bannerBG={bannerBG} profileData={profileData} />

        <div className="container">
          <div className={[styles['letest-inner'] + " " + styles.mainproducts]}>

            <div className={`${styles['letest-inner']} `}>
              <h2>{t("PPVINSTITUTE.PRODUCTTITLE")}</h2>
              <h6>{t("PPVINSTITUTE.PRODUCTSUBTITLE")} </h6>
              <h6 className="mb-5">{t("PPVINSTITUTE.PRODUCTSUBSUBTITLE")} </h6>
            </div>

            <section className="entireofferfiltercustomised">
          <div className="container">
            {data &&
              <section className="topexpertproduct">

                <div className="productslider mt-4">
                  <div className="row">
                    {
                       data?.data?.map(product => {
                        return   <div class={`sliouter ${product?.parentInstitutionCategory} col-lg-4 col-md-4 col-sm-4`}> 
                        <ProductCard 
                        product={product} 
                        viewbuttonURL={(e) => { setProductDetails({ data: product, category: product?.parentInstitutionCategory, subCategory: product?.subInstitutionCategory?.name})}}
                        isCategorySubCategory={true}
                        isProductName={true} 
                        isViewButton={true}/> </div>
                      })
                    }
                  </div>
                </div>

              </section>
            }
          </div>
        </section>  



            {data?.data?.length === 0 && <div>{t("COMMON.NORECORDFOUND")}</div>}
          </div>
          { data?.data?.length > 0 &&  <section className="newpagination"> <Pagination
                                limit={12} 
                                totalCount={ data?.meta?.totalCount}
                                onPaginationButtonClick={onPaginationButtonClick}
                            />  </section>}
        </div>
      </section>

      {productDetails?.data &&
                        <DetailedProductsJsx data={productDetails?.data} category={productDetails?.category} subCategory={productDetails.subCategory} closFunction={closeFunction} />
                    }
      <Footer />
    </>

  );
};

export default ListOfProductsJsx;