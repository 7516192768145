import React, { useState } from 'react';
import { Trans, useTranslation } from "react-i18next";
import { NEW_URL } from '../../../constants/applicationConstants';
import close_btn from '../../../assets/images/white_cross.svg';
import styles from './questionAnswerCard.module.css';
import { Link } from "react-router-dom";
export const QuestionAnswerCardJSX = (props) => {

  const { t } = useTranslation();
  const {
    imgurl,
    listexpertimageUrl,
    listexpertfirstName,
    listexpertlastName,isAnswer,
    listfirstName,
    listlastName,
    dataOpen,
    listaskExpertQuestionUserResponsequestion,
    listanswer,
    openModal,
    
    isOpenModal,
    questiondate,
    givenAnswer,
    sendAnswer,
    onInputChangeHandler,
    giveAnswer,
    index,
    listaskExpertCategory,singleData, listaskExpertCategoryone
  } = props;
  const [isExpandedQuestion, setIsExpandedQuestion] = useState(false);
  const [isExpandedAnswer, setIsExpandedAnswer] = useState(false);
  const wordsQuestion = listaskExpertQuestionUserResponsequestion?.trim().split(/\s+/).filter(Boolean);

  const wordsAnswer = listanswer?.trim().split(/\s+/).filter(Boolean);

  return (
    <>
      <div className={`questioninnerin mb-3  ${listaskExpertCategory}`}>
        <div className="category_icon">
          <img src={`${NEW_URL}/${imgurl}`} alt={''} />
        </div>

        <div className="d-flex questioninnerinbottom">
          <div className="expertquestiondetails">
            <div className="expertuser"><img src={!listexpertimageUrl ? `https://ui-avatars.com/api/?name=${listfirstName}+${listlastName}&rounded=true` : `${NEW_URL}/${listexpertimageUrl}`} alt={''} /></div>
            <div className="expName"><span>{`${listexpertfirstName} ${listexpertlastName}`}</span>
              <div className="questiondate">{questiondate}</div></div>
            <div className="widthfull">
              <h5 className="h5_style questionstext"><span>{t("customerqna.QUESTION")}</span>{wordsQuestion?.length > 9 ? <b>{isExpandedQuestion ? listaskExpertQuestionUserResponsequestion : listaskExpertQuestionUserResponsequestion?.slice(0, 40)}</b> : <b>{listaskExpertQuestionUserResponsequestion}</b>}
                {wordsQuestion?.length > 9 ? <span><button onClick={() => setIsExpandedQuestion(!isExpandedQuestion)}>
                  {isExpandedQuestion ? 'Read Less' : 'Read More'}
                </button></span> : null}
              </h5>
              {listanswer && <h5 className="h5_style questionstext"><span>{t("customerqna.ANSWER")}</span>{wordsAnswer?.length > 9 ? <b>{isExpandedAnswer ? listanswer : listanswer?.slice(0, 40)}</b> : <b>{listanswer}</b>}
                {wordsAnswer?.length > 9 ? <span><button onClick={() => setIsExpandedAnswer(!isExpandedAnswer)}>
                  {isExpandedAnswer ? 'Read Less' : 'Read More'}
                </button></span> : null}
              </h5>}

            </div>
          </div>

          <div className="expertquestiondetailscategory"><span>{listaskExpertCategoryone}</span></div>

        </div>
        {!isAnswer && !dataOpen ? <button type="button" className="ctaonebutton qnabutton" onClick={() => giveAnswer(index, true)}>Answer Now</button> : null}
        {/* answer part */}
        {dataOpen ?
          <>
            <div className="mb-4 mt-3 input-container form-group">
              <label className="placeholder">Answer Now </label>
              <textarea className="input form-control textareaform"
                onChange={onInputChangeHandler}
                name={`answer${singleData?.askExpertQuestionUserResponseId}`}

                className="input form-control textareaform"
                placeholder=" "
                maxLength={1000}
              />

            </div>
            <div className="d-flex questioninnerinbottom">
              <div className="expertquestiondetails">
                <div>
                  <h5 className="h5_style"><a href="#questionmodal" onClick={() => openModal(true)}><Trans i18nKey="expertDashboard.CLICK_HERE" ></Trans></a> <Trans i18nKey="expertDashboard.CLICK_HERE_TEXT" ></Trans></h5>
                  {isOpenModal &&
                    <>
                      <div className="modalspecial qmodal" style={{}}>
                        <div>
                        <div className="backnorm">
                          <button className="close_btn" onClick={() => openModal(false)} ><img src={close_btn} alt={t("CUSTOMERINTEREST.CLOSEBUTTON")} /></button>
                          {/* <div className="mybtn">
                            <button className="myadbtn" onClick={() => openModal(false)}>X</button>
                          </div> */}
                          <h2><Trans i18nKey="expertDashboard.QNA_SUGGESTED_TEXT_HEADING" ></Trans></h2>
                          <Trans i18nKey="expertDashboard.QNA_SUGGESTED_TEXT" ></Trans>
                          <ul>
                            <li><Trans i18nKey="expertDashboard.QNA_SUGGESTED_TEXT_LISTONE" ></Trans></li>
                            <li><Trans i18nKey="expertDashboard.QNA_SUGGESTED_TEXT_LISTTWO" ></Trans></li>
                            <li><Trans i18nKey="expertDashboard.QNA_SUGGESTED_TEXT_LISTTHREE" ></Trans></li>
                            <li><Trans i18nKey="expertDashboard.QNA_SUGGESTED_TEXT_LISTFOUR" ></Trans></li>
                            <li><Trans i18nKey="expertDashboard.QNA_SUGGESTED_TEXT_LISTFIVE" ></Trans></li>
                            <li><Trans i18nKey="expertDashboard.QNA_SUGGESTED_TEXT_LISTSIX" ></Trans></li>
                          </ul>
                          <p> <small><Trans i18nKey="expertDashboard.QNA_DISCLAIMER" ></Trans></small></p>
                        </div>
                        </div>
                      </div>
                    </>
                  }
                  <h5 className="h5_style"><Trans i18nKey="expertDashboard.CLICK_TAG_LINE" ></Trans></h5>
                </div>
              </div>
              <div className="qbtn">
                <button type="button" disabled={!givenAnswer} className="showallourbesttoo" onClick={() => sendAnswer(singleData?.askExpertQuestionUserResponseId, singleData?.allocateAskExpertQuestionResponseId)}>Send</button>
                <button type="button" className="showallourbesttoo showallourbestone" onClick={() => giveAnswer(index, false)}>Cancel</button>
              </div>
            </div></> : null
        }
      </div>
    </>
  )
}
