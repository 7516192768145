import React from 'react'
import { Component } from 'react';
import CustomerQnaAnswerJsx from './customerQnaAnswer.jsx'
import axios from 'axios';
import { baseURL } from '../../../../constants/applicationConstants';

export default class CustomerQnaAnswer extends Component {
    constructor(props) {
        super(props)
        this.state = {
            data: [],
            page: 1,
            meta: {},
            isProcessing: false
        }
    }
    onPaginationButtonClick = (page) => {
        window.scrollTo(0,0);
        this.setState({ page: page })
    }
    getData = () =>{
        this.setState({ isProcessing: true })
        const customerId = sessionStorage.getItem("masterID") && JSON.parse(sessionStorage.getItem("masterID"))
        const token = sessionStorage.getItem("token") && JSON.parse(sessionStorage.getItem("token"))
        axios.get(`${baseURL}/customer/${customerId}/QuestionsResponse?status=answered&page=1&perPage=100000`,{
            headers: { 'x-access-token': token }
          }).then(res => {
            this.setState({ data: res.data.data, meta:res.data.meta, isProcessing: false });
        })
    }


    componentDidMount = () => {
        this.getData(); 
      }

    render() {
        return (
            <div>
                <CustomerQnaAnswerJsx
                    data={this.state.data}
                    meta={this.state.meta}
page={this.state.page}
                    onPaginationButtonClick={this.onPaginationButtonClick}

                />
            </div>
        )
    }
}