
import React from 'react'
import { useTranslation } from 'react-i18next';
import {
    NEW_URL
} from "../../../../constants/applicationConstants.js";
import "../accountSetting/accountSetting.css";
const LogoJsx = (props) => {
    const { t } = useTranslation();
    const {
        imgURL, onFileChange, profileImagePreviewUrl, onNextButtonClick
    } = props;

    return (
        <>
            {/* <div className="myaccountsection institutedetails my-account" >
                <div className="myaccountsectionheading">

                 
                </div>
                <div className="logoform">
                    <div className="form-fieldinner">
                     
                        {
                            profileImagePreviewUrl ?
                                <img
                                    src={profileImagePreviewUrl}
                                    height="170px"
                                    width="250px"
                                    alt="edit profile"
                                /> :

                                <img
                                    src={`${NEW_URL}/${imgURL}`}
                                    height="170px"
                                    width="250px"
                                    alt="edit profile"
                                />

                        }

                        <div className="uploaddetails">
                            <p>{t("customerlogo.FILENAMETEXT")}</p>
                            <p className="full_name">{profileImagePreviewUrl
                                ? profileImagePreviewUrl.split("/").pop()
                                : imgURL.split("/").pop()}</p>

                

                            <div className="main-uplod-section">

                                <div className="btn_upload ">
                                    <input type="file" id="upload_file" name="" onChange={onFileChange} accept="image/png, image/gif, image/jpeg" />
                                    <label className="change_button" for="upload_file">{t("customerlogo.CHANGELOGO")}</label>
                                </div>
                                <div className="b">

                                    <button type="button" className="delete-button" disabled={!profileImagePreviewUrl} onClick={() => onNextButtonClick()}>{t("customerlogo.DELETEBUTTON")}</button>
                                </div>
                            </div>
                         
                        </div>
                    </div>
                </div>
            </div> */}
        </>
    );
};

export default LogoJsx;
