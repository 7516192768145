
import dropbox from "../../../../assets/images/dropbox.svg";
import { Pagination } from '../../../common/pagination/uiPagination';
import moment from 'moment';
import { QuestionAnswerCardJSX } from '../../../common/questionAnswerCard/questionAnswerCard';

import "../customerQnaAnswer/customerQnaAnswer.css";
import { useState } from 'react';
import { useTranslation } from 'react-i18next';


const CustomerQnaAnswerJsx = (props) => {
  const { t } = useTranslation()
  const {
    data, onPaginationButtonClick, meta, allOffersData, page
  } = props;

  const [currentPage, setCurrentPage] = useState(1);
  const itemsPerPage = 10
  const currentItems = data?.slice(
    (page - 1) * itemsPerPage,
    page * itemsPerPage
  );


  return (
    <>
      <section className="alldashboard allcustans">
       
        <div className="dashboardrightpanelinner">
          <div className="questioninner ">
            {currentItems.length ? (
              currentItems.map(list => {
                return <div>
                <QuestionAnswerCardJSX 
                   imgurl={list?.askExpertQuestionUserResponse?.institutionCategoryIcon}
                   listexpertimageUrl={list?.expert?.imageUrl}
                   listexpertfirstName={list?.expert?.firstName}
                   listexpertlastName={list?.expert?.lastName}
                   listfirstName={list?.expert?.firstName}
                   listlastName={list?.expert?.firstName}
                   listaskExpertQuestionUserResponsequestion={list.askExpertQuestionUserResponse.question}
                   listanswer={list.answer}
                   questiondate= {moment(list?.answeredAt).format('MMM DD , YYYY')}
                   listaskExpertCategoryone={list.askExpertQuestionUserResponse.parentInstitutionCategory}
                   listaskExpertCategory={list.askExpertQuestionUserResponse.parentInstitutionCategory?.replace(/\s+/g, '')}
                />
                </div>
           
              })
            ) : (
              <div className="dropdefault text-center">
                <div className="dropdefaultimage">
                  <img src={dropbox} alt="Default" />
                </div>
                <div className="dropdefaulttext">
                  <h5>{t("customerqnaanswer.DEFAULTTEXT")}</h5>
                </div>
              </div>
            )}
            {data.length > itemsPerPage &&
              <section className="newpagination"> <Pagination
                currentPage={currentPage}
                page={page}
                allOffersData={data}
                currentItems={currentItems}
                onPaginationButtonClick={onPaginationButtonClick}
                itemsPerPage={itemsPerPage}
              /></section>
            }

          </div>

        </div>
      </section>
    </>

  );
};

export default CustomerQnaAnswerJsx;