import React from 'react'
import Select from 'react-select';
import DatePicker3 from "react-modern-calendar-datepicker";
import { formatInputValue } from '../../../../shared/helper';
import datecalender from "../../../../assets/images/datecalender.svg"
import { getProperDateMonth, formatCanadianPhoneNumber } from "../../../../shared/helper.js";
import cross from '../../../../assets/images/cross.svg';
import InputMask from 'react-input-mask';
import editicon from "../../../../assets/images/edit-icon.png";
import { useTranslation } from 'react-i18next';
const InstituteDetailsJsx = (props) => {
    const { t } = useTranslation();

    const genderList = [
        {
            "label": "Select",
            "value": null
        },
        {
            "label": "Male",
            "value": "Male"
        },
        {
            "label": "Female",
            "value": "Female"
        },
        {
            "label": "Others",
            "value": "Others"
        }
    ]
 

    const { onInputChangeHandler, handleChange, onDateChange,
        onInstituteButtonClick, editInstitite, changeHideState, formError, onTouchHandler , provinceList , countryList
    } = props;
    const isRequired = true
    const information = props?.wholeData
    const getMuskPattern = (name) =>
        name === 'phone' || name === "secondaryPhone" ? '(999) 999 9999' : 'a9a 9a9'

        
    return (
        <>
            {!editInstitite ? <div className="myaccountsection institutedetails personal-details">
                <div className="myaccountsectionheading">
                    <h3 className="h3_style">{t("customerdetails.HEADING")}</h3>
                    <div className="mybtn ">
                        <button type="button" className="edit-button" onClick={() => changeHideState(true)}>{t("customerdetails.EDITBUTTON")} <img src={editicon} alt="" /></button>

                    </div>
                </div>
                <div className="form-field">
                    {/* <div className="form-fieldinner">
                        <label>About Me*</label>
                        <div className="fieldans">{information?.description}</div>
                    </div> */}
                    <div className="disgrid grid-2">
                        <div className="form-fieldinner">
                            <label>{t("customerdetails.FIRSTNAME")}</label>
                            <div className="fieldans">{information?.firstName}</div>
                        </div>
                        <div className="form-fieldinner">
                            <label>{t("customerdetails.LASTNAME")}</label>
                            <div className="fieldans">{information?.lastName}</div>
                        </div>
                        <div className="form-fieldinner">
                            <label>{t("customerdetails.EMAIL")} </label>
                            <div className="fieldans">{information?.email}</div>
                        </div>
                        {/* <div className="form-fieldinner">
                        <label>Alternative Email  </label>
                        <div className="fieldans">{information?.alternativeEmail}</div>
                    </div> */}
                        <div className="form-fieldinner col50">
                            <label>{t("customerdetails.PHONENO")}  </label>
                            <div className="fieldans">{information?.phone && formatCanadianPhoneNumber(information?.phone)}</div>
                        </div>
                        {/* <div className="form-fieldinner">
                        <label>Secondary Phone no. </label>
                        <div className="fieldans">{information?.secondaryPhone}</div>
                    </div> */}
                        <div className="form-fieldinner col50">
                            <label>{t("customerdetails.DOB")} </label>
                            <div className="fieldans">{getProperDateMonth(information?.dateOfBirth?.day)}-{getProperDateMonth(information?.dateOfBirth?.month)}-{information?.dateOfBirth?.year}</div>
                        </div>
                        <div className="form-fieldinner col50">
                            <label>{t("customerdetails.GENDER")} </label>
                            <div className="fieldans">{information?.gender}</div>
                        </div>
                        <div className="form-fieldinner col50">
                            <label>{t("customerdetails.P_CODE")}</label>
                            <div className="fieldans">{information?.postalCode}</div>
                        </div>
                        <div className="form-fieldinner col50">
                            <label>{t("customerdetails.PROVIENCE")}</label>
                            <div className="fieldans">{information?.state?.name}</div>
                        </div>
                        <div className="form-fieldinner col50">
                            <label>{t("customerdetails.CITY")}</label>
                            <div className="fieldans">{information?.city}</div>
                        </div>
                        <div className="form-fieldinner">
                            <label>{t("customerdetails.COUNTRY")}</label>
                            <div className="fieldans">{information?.country?.name}</div>
                        </div>
                        <div className="form-fieldinner">
                            <label>{t("customerdetails.S_ADDRESS")}</label>
                            <div className="fieldans">{information?.address}</div>
                        </div>
                    </div>


                </div>
            </div> : null}
            {editInstitite ? <div className="myaccountsection institutedetails personal-details martop60">
                <div className="myaccountsectionheading">
                    <h3 className="h3_style">{t("customerdetails.HEADING")}</h3>
                    <div className="mybtn">
                        <button type="button" className="myadbtn cross_btn" onClick={() => changeHideState(false)}></button>
                    </div>
                </div>
                <div className="form-field">
                    <div className="disgrid grid-2">
                        <div className="form-fieldinner">
                            <div className="input-container form-group">
                                <label className="placeholder">{t("customerdetails.FIRSTNAME")}</label>
                                <input type="text"
                                    // className="input form-control"

                                    className={
                                        isRequired && formError["firstName"]
                                            ? "input form-control  is-invalid"
                                            : "input form-control"
                                    }
                                    maxLength={30}
                                    name="firstName" placeholder=" " value={information.firstName} onChange={onInputChangeHandler} onBlur={onTouchHandler} />

                                <span className="text-danger">
                                    {isRequired &&
                                        formError["firstName"] &&
                                        (formError["firstName"] === "req"
                                            ? "firstName is required!"
                                            : "Please enter a proper firstName!")}
                                </span>
                            </div>
                        </div>
                        <div className="form-fieldinner">
                            <div className="input-container form-group">
                                <label className="placeholder">{t("customerdetails.LASTNAME")}</label>
                                <input type="text"
                                    className={
                                        isRequired && formError["lastName"]
                                            ? "input form-control  is-invalid"
                                            : "input form-control"
                                    }
                                    name="lastName" placeholder=" " value={information.lastName} onChange={onInputChangeHandler} onBlur={onTouchHandler} />

                                <span className="text-danger">
                                    {isRequired &&
                                        formError["lastName"] &&
                                        (formError["lastName"] === "req"
                                            ? "lastName is required!"
                                            : "Please enter a proper lastName!")}
                                </span>
                            </div>
                        </div>
                        <div className="form-fieldinner">
                            <div className="input-container form-group">
                                <label className="placeholder">{t("customerdetails.EMAIL")}</label>
                                <input type="text" className={
                                    isRequired && formError["email"]
                                        ? "input form-control  is-invalid"
                                        : "input form-control"
                                } name="email" disabled placeholder=" " value={information.email} onChange={onInputChangeHandler} onBlur={onTouchHandler} />

                                <span className="text-danger">
                                    {isRequired &&
                                        formError["email"] &&
                                        (formError["email"] === "req"
                                            ? "email is required!"
                                            : "Please enter a proper email!")}
                                </span>
                            </div>
                        </div>

                        <div className="form-fieldinner col50">
                            <div className="input-container form-group">
                                <label className="placeholder">{t("customerdetails.PHONENO")}  </label>
                                <InputMask mask={getMuskPattern('phone')} name="phone" value={information.phone} onChange={onInputChangeHandler} onBlur={onTouchHandler}>
                                    {(inputProps) => <input {...inputProps} type="tel" className={
                                        isRequired && formError["phone"]
                                            ? "input form-control  is-invalid"
                                            : "input form-control"
                                    } placeholder=" " disableUnderline />}
                                </InputMask>
                                <div className="cut"></div>

                                <span className="text-danger">
                                    {isRequired &&
                                        formError["phone"] &&
                                        (formError["phone"] === "req"
                                            ? "phone is required!"
                                            : "Please enter a proper phone!")}
                                </span>
                            </div>
                        </div>






                        <div className="form-fieldinner col50">
                            <div className="input-container form-group">
                                <div className="placeholder">{t("customerdetails.GENDER")}</div> 
                                <Select className={`selectbox`}
                                    closeMenuOnSelect={true}
                                    placeholder={" "}
                                    name="gender"
                                    options={genderList}
                                    onChange={(e) => handleChange(e, 'gender')}
                                    value={genderList.filter(ite => ite.value === information.gender)}

                                />

                            </div>
                        </div>
                        <div className="form-fieldinner col50">
                            <div className="input-container form-group">
                                <label className="placeholder">{t("customerdetails.DOB")}</label>
                                <DatePicker3 className="form-control" name="dateOfBirth"
                                    onChange={(date) => { onDateChange(date) }}
                                    inputPlaceholder="dd/mm/yyyy"
                                    shouldHighlightWeekends
                                    maximumDate={{
                                        year: new Date().getFullYear(),
                                        month: new Date().getMonth() + 1,
                                        day: new Date().getDate()
                                    }}
                                    wrapperClassName="input-container form-group"
                                    renderInput={({ ref }) => (
                                        <>
                                            <input
                                                ref={ref}
                                                placeholder=" "
                                                type="text"
                                                required
                                                className="input form-control"
                                                value={formatInputValue(information.dateOfBirth)}
                                            />
                                            <div className="cut"></div>

                                            <span className="dateim"><img src={datecalender} alt={''} /></span>
                                        </>
                                    )}
                                    formatInputText={() => formatInputValue(information.dateOfBirth)}
                                />
                            </div>
                        </div>
                        <div className="form-fieldinner col50">
                            <div className="input-container form-group">
                                <div className="placeholder">Postal Code*</div> 
                                <input type="text" className={
                                    isRequired && formError["email"]
                                        ? "input form-control  is-invalid"
                                        : "input form-control"
                                } name="postalCode"   placeholder=" " value={information.postalCode} onChange={onInputChangeHandler} onBlur={onTouchHandler} />

                            </div>
                        </div>

                        <div className="form-fieldinner col50">
                            <div className="input-container form-group">
                                <div className="placeholder">Province*</div> 
                                <Select className={`selectbox`}
                                    closeMenuOnSelect={true}
                                    placeholder={" "}
                                    disabled
                                    name="stateCode"
                                    options={provinceList}
                                    onChange={(e) => handleChange(e, 'stateCode')}
                                    value={provinceList.filter(ite => ite.value === information.stateCode)}

                                />

                            </div>
                        </div>


                        <div className="form-fieldinner col50">
                            <div className="input-container form-group">
                                <div className="placeholder">City*</div> 
                                <input type="text" className={
                                    isRequired && formError["city"]
                                        ? "input form-control  is-invalid"
                                        : "input form-control"
                                } name="city"  placeholder=" " value={information.city} onChange={onInputChangeHandler} onBlur={onTouchHandler} />

                            </div>
                        </div>

                        <div className="form-fieldinner">
                            <div className="input-container form-group disabled">
                                <div className="placeholder">Country*</div> 
                                <Select className={`selectbox`}
                                    closeMenuOnSelect={true}
                                    placeholder={" "}
                                    disabled
                                    name="countryCode"
                                    options={countryList}
                                    onChange={(e) => handleChange(e, 'countryCode')}
                                    value={countryList.filter(ite => ite.value === information.countryCode)}

                                />
                            </div>
                        </div>

                        <div className="form-fieldinner">
                            <div className="input-container form-group">
                                <div className="placeholder">Street Address*</div> 
                                <input type="text" className={
                                    isRequired && formError["streetAddress"]
                                        ? "input form-control  is-invalid"
                                        : "input form-control"
                                } name="streetAddress"   placeholder=" " value={information.streetAddress} onChange={onInputChangeHandler} onBlur={onTouchHandler} />

                            </div>
                        </div>
                        
                    </div>
                    <button type="button" className="ctaonebutton widthhalf" onClick={() => onInstituteButtonClick()}>{t("customerdetails.SAVEBUTTON")}</button>
                </div>
            </div> : null}
        </>
    );
};

export default InstituteDetailsJsx;
