import React from 'react'
import { Trans, useTranslation } from 'react-i18next';
import Sidebar from "../sidebar/sidebar.js";
import AccountSetting from "../accountSetting/accountSetting.js";
import ManageUser from "../../dashboardCommon/manageUser/manageUser.js";
import { Footer } from '../../../common/footer/footer'
import ChangePassword from "../../../common/changePassword/changePassword.js";
import accountsettingdashboard from "../../../../assets/images/accountsettingdashboard.svg";
import Change_password from "../../../../assets/images/Change_password.svg"
import mypaymentdashboard from "../../../../assets/images/mypaymentdashboard.svg"
import MyPlansJsxclass from '../../dashboardCommon/myPlane/myPlane';
import "../expertDashboard.css";

const MyAccountsJsx = (props) => {
  const { t } = useTranslation();
  const {
    activeTab,
    onTabClick
  } = props;

  const data = JSON.parse(sessionStorage.getItem("auth"))

  return (
    <>
      <section className="main_customer_dashboard">
        <section className="entiredashboard maincustomerdash expert-dashboard">
          <div className="container-fluid">
            <div className="row">
              <div className="col-lg-4 col-sm-4">
                <div className="dashboardleftpanel">
                  <div className="dashboardlinkpop">
                    <Sidebar />
                  </div>
                </div>
              </div>
              <div className="col-lg-8 col-sm-8">
                <div className="dashboardrightpanel">
                  <section classNames="addexpertformsec">
                    <div className="dashboardrightpannerinner">
                      <div className="dashboardrightpannerinnerleft">
                        <h2 className="h2_style font-weight-600">{t("expertDashboard.MyAccount")}</h2>
                        <h5 className="h5_style"><Trans i18nKey="expertDashboard.MY_ACCOUNT_TEXT_TWO" > </Trans></h5>
                      </div>

                    </div>

                    <div className="myexpertnav myaccountnav qnanav martop40">
                      <ul>

                        <li className={`${activeTab === 'acntStng' ? 'active' : ''}`} onClick={() => onTabClick('acntStng')}><div className="micon"><img src={accountsettingdashboard} alt='account'/></div>Account Settings</li>
                        {data.isPrimaryUser && <li className={`${activeTab === 'mngUser' ? 'active' : ''}`} onClick={() => onTabClick('mngUser')}><img src={mypaymentdashboard} alt='payment'/><div className="micon"><img src={accountsettingdashboard} alt='dashboard'/></div> Manage Users</li>}
                        <li className={`${activeTab === 'myPlans' ? 'active' : ''}`} onClick={() => onTabClick('myPlans')}><div className="micon"><img src={mypaymentdashboard} alt='plan'/></div> My Plans</li>
                        <li className={`${activeTab === 'newPass' ? 'active' : ''}`} onClick={() => onTabClick('newPass')}><div className="micon"><img src={Change_password} alt='password'/></div>Change Password</li>
                      </ul>
                    </div>
                    {
                      activeTab === 'acntStng' ? <AccountSetting /> : activeTab === 'mngUser' ? <ManageUser /> : activeTab === 'newPass' ? <div className="accountchangepass"><h2 className="textchange">Change Password</h2><ChangePassword /></div> : <MyPlansJsxclass />
                    }
                  </section>
                </div>
              </div>
            </div>
          </div>
        </section><Footer />
      </section>
      
    </>

  );
};

export default MyAccountsJsx;
