import moment from 'moment';
import React from 'react'
import Sign_out_squre from "../../../assets/images/Sign_out_squre.svg";
import { NEW_URL } from '../../../constants/applicationConstants';
import Sidebar from "../../dashboard/opsDashboard/sidebar/sidebar.js";
import { Footer } from '../../common/footer/footer';
import { Pagination } from '../../common/pagination/pagination';

const AnswerQuestionJsx = (props) => {
  const {
    data,meta1,meta2,
    answeredQuestion,
    onPendingQuestionClick,
    onViewProfileClick,onPaginationButtonClick
  } = props;

  return (
    <>
      <section className="entiredashboard">
        <div className="container">
          <div className="row">
            <div className="col-lg-3 col-sm-3">


              <div className="dashboardleftpanel martop40">

                <div className="dashboardlink martop40">
                <Sidebar />
                </div>
              </div>
            </div>
            <div className="col-lg-9 col-sm-9">
              <div className="dashboardrightpanel martop40 mb-5">
                <div className="dashboardrightpannerinner">
                  <div className="dashboardrightpannerinnerleft">
                    {/* <h2 className="h2_style font-weight-600">QnA - Pending Questions</h2>
                    <h5 className="h5_style">Providing quality answers enhances your reputation and increases the likelihood of attracting new clients
</h5>
                    <h5 className="h5_style">Also, it helps you gain insight into customer’s needs, pain points, and interests.</h5>
                    <h5 className="h5_style fst-italic fw-light">Don’t miss the chances of securing new business. Answer all your pending questions now!</h5> */}
                  </div>
                  <div className="dashboardrightpannerinnerright">
                    <div className="signout">
                      <a href="#logout">
                        <img src={Sign_out_squre}  alt={' '}/><span className="title">Logout</span>
                      </a>
                    </div>
                    <div id="logout" className="modal-window">
                      <div>
                        <a  title="Close" className="modal-close">Close</a>
                        <div className="modal-header">

                        </div><div className="modal-body mb-4">
                          <div className="modal-body-inner">
                            <h2 className="h2_style text-center">Are you sure you want to
Logout ?</h2>
                          </div>
                        </div>
                        <div className="modal-footer">
                          <button type="button" className="ctaonebutton"> Save</button>
                          <button type="button" className="ctatwobutton"> Save</button>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="myexpertnav myaccountnav qnanav martop40">
                  <ul>
                    <li onClick={()=> onPendingQuestionClick()}>Pending Questions<span>{meta1?.totalCount}</span></li>
                    <li className="active">Approved Questions<span>{meta2?.totalCount}</span></li>
                  </ul>
                </div>

                <div className="questioninner mt-4">
                  {
                    answeredQuestion?.map(ansQue => {
                      return <div className="questioninnerin mb-3">
                        <div className="d-flex questioninnerinbottom">
                          <div className="expertquestiondetails">
                            <div className="expertuser"><img src={!data?.imageUr ? `https://ui-avatars.com/api/?name=${ansQue?.fullName}&rounded=true` : `${NEW_URL}/${data?.imageUr}`}  alt={' '}/></div>
                            <div className="questionid"><span>Question ID -</span> {ansQue?.askExpertQuestionUserResponseId}
                            <div className="questiondate">{moment(ansQue?.createdAt).format('MM-DD-YYYY')}</div></div>

                          </div>
                          <div className="expertquestiondetails catspecial">
                            <span>{`Category: ${ansQue?.parentInstitutionCategory}`}</span>  <span>{`Subcategory: ${ansQue?.subInstitutionCategory?.name}`}</span>

                          </div>
                        </div>
                        <p>{ansQue?.question}</p>
                        <div className="questiondate text-right">{moment(ansQue?.createdAt).format('MM-DD-YYYY')}</div>
                        {ansQue?.allocatedExperts.length>0 && <><h5 className="h5_style">Assigned To</h5>
                        <div className="d-flex questioninnerinbottom mt-3">
                          {
                            ansQue?.allocatedExperts.map(exp=>{
                              return  <div className="expertquestiondetails" onClick={()=> onViewProfileClick(exp?.expert?.slugUrl)}>
                              <div className="expertuser"><img src={!exp?.expert?.imageUrl ? `https://ui-avatars.com/api/?name=${exp?.expert?.firstName}+${exp?.expert?.lastName}&rounded=true` : `${NEW_URL}/${exp?.expert?.imageUrl}`}  alt={' '}/></div>
                              <div className="questionid"> {`${exp?.expert?.firstName} ${exp?.expert?.lastName}`}</div>
                            </div>
                            })
                          }
                           </div></>}
                      </div>
                    })
                  }

                </div>
                {answeredQuestion?.length > 0 && <Pagination
            limit={10}
            totalCount={meta2?.totalCount}
            onPaginationButtonClick={onPaginationButtonClick}
          />}
              </div>
            </div>
          </div>
        </div>
      </section>

      <Footer />

    </>

  );
};

export default AnswerQuestionJsx;