import React, { useState } from 'react'
import { Footer } from '../../common/footer/footer';
import { OfferCardListJSX } from '../../common/offerCardList/offerCardList';
import { ArticlesCardJSX } from '../../common/articlesCard/articlesCard';
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Slider from "react-slick";
import { Link } from "react-router-dom";
import 'aos/dist/aos.css';

import "../realEstate/realEstate.css";
import "../realEstate/realEstateresponsive.css";
import {expslider} from "../../../shared/sliderSetting.js"
import realEaskicon from "../../../assets/sharedImages/iconaskbtn.svg"
import { Trans, useTranslation } from 'react-i18next';
import RealEtext from "../../../assets/realEstateimages/RealEtext.png";
import dropbox from "../../../assets/images/dropbox.svg";
import Realestatebanner from "../../../assets/realEstateimages/RealEstateimage.svg";
import Realestatebannerone from "../../../assets/realEstateimages/realim.svg";
import DetailedProductsJsx from '../../common/detailedProduct/detailedProduct.jsx';
import ExpertCardJsx from "../../common/expertCard/expertCard.jsx"
import { InstituteList } from "../../common/instituteList/instituteList.jsx"
import { ProductCardList } from "../../common/productCardList/productCardList.jsx"

const RealEstateJsx = (props) => {
  const { t } = useTranslation();
  const {
    offerData,
    productData,
    expertData,
    instituteData,
    blogData,
    onInstituteClick,instituteID
  } = props;

  const [visibleBlogs, setVisibleBlogs] = useState(4);
  const [productDetails, setProductDetails] = useState({});
  const closeFunction = () => {
    setProductDetails({})
  }
 
  const hitShowALL = () => {
    instituteID && sessionStorage.setItem("selectCategory",instituteID)
  }

  const [openModal, setOpenModal] = useState(false);
  const [blog, setBlog] = useState(null);

  // {t("PRINT.TITLE")}



  



  return (
    <>
      <section className="container-rimib newrealestateui">
        <section className="categorybanner">
          {/* <img src={Realestatebanner}/> */}
          <div className="container makethecontainer">
            <div className="row">
              <div className="col-lg-6 col-sm-6">
                <div className="bn-image">
                  <img src={Realestatebanner} alt='' className="d-none d-sm-block" />
                  <img src={Realestatebannerone} alt='' className="d-block d-lg-none d-sm-none d-md-none " />
                </div>
              </div>
              <div className="col-lg-6 col-sm-6">
                <div className="categorybannertext">
                  <div className="bannerfont borderbottom ">
                    <h2 >{t("HOMEPAGE.REALESTATE")}</h2>
                    <p>{t("REALESTATE.TEXT4")}</p>
                    <h6>
                      <Trans i18nKey="REALESTATE.HEADING_TEXT_BANNER" ></Trans>
                    </h6>
                    <p className="mb-0 mt-3 realestateherotext"><Trans i18nKey="REALESTATE.TEXTSIX5"></Trans></p>
                    <br></br>
                    <Link to={'/AskTheExpert'} style={{ textDecoration: "none" }}>
                      <div className="askbutton">
                        <p className="askres">{t("COMMON.ASKTHEEXPERTS")}</p>
                        <img src={realEaskicon} alt="" />
                      </div>
                    </Link>
                  </div>
                </div>
              </div>
            </div>


          </div>
        </section>

        <section className="categorysectionone">
          <OfferCardListJSX
            SubCatTitle={t("COMMON.OFFERHEADING")}
            SubCatText={t("REALESTATE.INSTITUTESUBTEXTONE")}
            SubCatTextLineOne={t("REALESTATE.INSTITUTESUBTEXTTWO")}
            offerData={offerData}
            redirectUrl="/offers"
            isDescription={true}
            instituteID={instituteID}
            isSlider={true}
          />

        </section>

        <ProductCardList
          productData={productData}
          productTitle={t("REALESTATE.PRODUCTSTITLE")}
          productsubTitleText={t("REALESTATE.PRODUCTSLIDERSUBTITLE")}
          productsubTitleTextTwo={t("REALESTATE.PRODUCTSLIDERSUBTITLES")}
          subCategoryLayout={true}
          isCategorySubCategory={false}
          isProductName={true}
          isViewButton={true}
          instituteID={instituteID}
          category="realEstate"
          viewbuttonURL={setProductDetails} 
        />

        <InstituteList
          instituteData={instituteData}
          topRatedText={t("REALESTATE.INSTITUIONINCANEDA")}
          checkOutText={t("REALESTATE.TOPINSTITUDESUBTITLE")}
          finalcialText={t("REALESTATE.TOPINSTITUDESUBTITLEOFFER")}
          onInstituteClick={onInstituteClick}
          isCategory={true}
          categoryImg={RealEtext}
          topText={t("COMMON.TOP")}
          instituteID={instituteID}
          category={"realEstate"} />


        <section className="categorysectionfour bestdeals Experts">
          <div className="container-fluid">
            <h2 className="text-center expertheading">{t("REALESTATE.TEXT17")}</h2>
            <h5 className="text-center  h5_style expertsub1">{t("REALESTATE.TEXTONE18")}<br />
            </h5>
            <p className="expertsub2 text-center">{t("REALESTATE.TEXTTWO18")}</p>
            <div className=" p bestdealsbox bestexperts">
              {expertData?.length > 0 ?
                <Slider {...expslider} >
                  {expertData?.map(data => {
                    return <ExpertCardJsx data={data} instituteID={instituteID} CategoryID={data?.expertSpecializations[0]?.institutionCategoryId} />

                  })}
                </Slider>
                :
                <div className="dropdefault text-center">
                  <div className="dropdefaultimage">
                    <img src={dropbox} alt={' '} />
                  </div>
                  <div className="dropdefaulttext">
                    <h5 className="opacity0">No Record Found !! </h5>
                  </div>
                </div>

              }
              <div className="latestbutton  marginbottom" onClick={() => hitShowALL()}>
                <Link to="/globalMainSearchExpert" className="showallourbesttoo " style={{ textDecoration: "none" }}>{t("COMMON.SHOWALL")}</Link>
              </div>
            </div>

          </div>
        </section>
        <section className="categorysectionfive">
          <div className="container-fluid">
            <div className="realEquestion">
              <div className="realEquestiontext">
                <h4>{t("REALESTATE.TEXT19")}</h4>
                <h2><span>{t("REALESTATE.TEXTTWO19")}</span> {t("REALESTATE.TEXTTHREE19")}<span>{t("REALESTATE.TEXTFOUR19")}</span>{t("REALESTATE.TEXTFIVE19")}</h2>
                <h3 className="">{t("REALESTATE.TEXT20")}</h3>
                <Link to={'/AskTheExpert'} style={{ textDecoration: "none" }}>
                  <div className="realEbuttonmain realEmargin askbuttoncomm">
                    <p className="realEbutton">{t("COMMON.ASKTHEEXPERTS")}</p>
                    <img src={realEaskicon} alt="" />
                  </div>
                </Link>
              </div>
            </div>
          </div>
        </section>
        <section className="categorysectionsix blogdetailsexpertppv">
          <div className="container-fluid">
            <div className="realEbloginner">
              <h2 className="text-center">{t("REALESTATE.TEXT21")}</h2>
              <p className="text-center realEblogtext">{t("REALESTATE.TEXT22")}
              </p>

              <p> {t("REALESTATE.TEXT23")} {t("REALESTATE.TEXTONE23")}</p>
            </div>
            <div className="row realEbloglog">
              {
                blogData?.body?.length > 0 ?
                  blogData?.body?.slice(0, visibleBlogs).map(blog => {
                    return <div className="col-lg-6 col-md-6 col-sm-6 minheightrealestate">
                      <ArticlesCardJSX
                        blogslug={blog?.slug}
                        blogimage={blog?.image}
                        blogtitle={blog?.title}
                        blogshort_content={blog?.short_content}
                      />
                      {/* <Link to={/articleDetails/${blog.slug}}>
                      <div className="bloginner  blogsmain text-center">
                        <div className="bloginnerimage "><img className="blogsimgimg" src={blog?.image} alt={' '} /></div>
                        <div className="bloginnertag  displaynone">{blog?.cats.map((cat) => cat.name).join(',')}</div>
                        <div className="bloginnertext ">{blog?.title}</div>
                        <div className="bloginnerdate ">{blog?.short_content} </div>
                      </div>

                    </Link> */}

                    </div>
                  }) :

                  <div className="dropdefault text-center">
                    <div className="dropdefaultimage">
                      <img src={dropbox} alt={' '} />
                    </div>
                    <div className="dropdefaulttext">
                      <h5>You don't have any Blogs yet. </h5>
                    </div>
                  </div>
              }



              <div className="latestbutton margintop">
                <Link to={{ pathname: `/articles`, search: '?category=real-estate' }} className="showallourbesttoo">
                  {t("COMMON.SHOWALL")}
                </Link>
              </div>

            </div>
          </div>
        </section>
        <section>

        </section></section>
      <Footer />

      {
        openModal &&
        <div className="modalspecial blog-modal">
          <div>
            <div className="mybtn">
              <button type="button" className="myadbtn" onClick={() => { setOpenModal(false) }}><svg version="1.0" xmlns="http://www.w3.org/2000/svg"
                width="64.000000pt" height="64.000000pt" viewBox="0 0 64.000000 64.000000"
                preserveAspectRatio="xMidYMid meet">

                <g transform="translate(0.000000,64.000000) scale(0.100000,-0.100000)"
                  fill="#ffffff" stroke="none">
                  <path d="M156 484 c-9 -8 -16 -20 -16 -26 0 -5 28 -39 62 -74 l61 -64 -61 -64
c-34 -35 -62 -69 -62 -74 0 -14 28 -42 42 -42 5 0 39 28 74 62 l64 61 64 -61
c35 -34 69 -62 74 -62 14 0 42 28 42 42 0 5 -28 39 -62 74 l-61 64 61 64 c34
35 62 69 62 74 0 14 -28 42 -42 42 -5 0 -39 -28 -74 -62 l-64 -61 -64 61 c-35
34 -69 62 -74 62 -6 0 -18 -7 -26 -16z"/>
                </g>
              </svg></button>
            </div>
            <div className="modal-header text-center">
              <h3>{blog?.title}</h3>
            </div>
            <div className="modal-body text-center">
              <div className="blog-image-dv"><img src={blog.image} alt={' '} />
              </div>
              <div className="blog-content-dv" dangerouslySetInnerHTML={{ __html: blog.content }}></div>
            </div>
          </div>
        </div>

      }

      {productDetails?.data &&
        <DetailedProductsJsx data={productDetails?.data} category={productDetails?.category} subCategory={productDetails.subCategory} closFunction={closeFunction} />
      }


    </>

  );
};

export default RealEstateJsx;