import React from 'react'
import { useEffect, useState } from 'react';
import { Footer } from '../../common/footer/footer';
import {expslider} from "../../../shared/sliderSetting.js"
import { ArticlesCardJSX } from '../../common/articlesCard/articlesCard';
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Slider from "react-slick";
import { Link } from "react-router-dom";
import AOS from 'aos';
import 'aos/dist/aos.css';
import dropbox from "../../../assets/images/dropbox.svg";

import { useLocation } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { OfferCardListJSX } from '../../common/offerCardList/offerCardList';
import "../mortgage/mortgage.css"
import "../mortgage/mortgageresponsive.css"
import Mortgagebanner from "../../../assets/mortgageimges/Mortgage_image.svg";

import iconaskbtn from "../../../assets/sharedImages/iconaskbtn.svg";
import RealEtext from "../../../assets/mortgageimges/RealEtext.png";
import DetailedProductsJsx from '../../common/detailedProduct/detailedProduct.jsx';
import ExpertCardJsx from "../../common/expertCard/expertCard.jsx"
import { InstituteList } from "../../common/instituteList/instituteList.jsx"
import { ProductCardList } from "../../../components/common/productCardList/productCardList.jsx";

const MortgageJsx = (props) => {
  const { t } = useTranslation();
  const {
    offerData,
    expertData,
    blogData,
    productData,
    instituteData,
    onInstituteClick,
    instituteID

  } = props;

  const { hash } = useLocation()
  
  useEffect(() => {
    AOS.init();
    if (hash) {
      document.getElementById(hash.replace('#', '')).scrollIntoView()
    } else {
      //window.scrollTo(0, 0);
    }

  }, [])
  
  const hitShowALL = () => {
    instituteID && sessionStorage.setItem("selectCategory",instituteID)
  }
  
  

  const [openModal, setOpenModal] = useState(false);
  const [blog, setBlog] = useState(null);
  const [visibleBlogs, setVisibleBlogs] = useState(4);
  const [productDetails, setProductDetails] = useState({});
  const closeFunction = () => {
    setProductDetails({})
  }
  const handleViewAll = () => {
    setVisibleBlogs(blogData?.body?.length);
  };
  // {t("PRINT.TITLE")}
  return (
    <>
      <section className="container-rimib mortgagenewui">
        <section className="categorybanner">

          <div className="container makethecontainer">
            <div className="row align-items-center">
              <div className="col-lg-6 col-sm-6">
                <div className="bn-image">
                  <img src={Mortgagebanner} alt='' />
                </div>
              </div>
              <div className="col-lg-6 col-sm-6">
                <div className="categorybannertext">
                  <div className="bannerfont borderbottom ">
                    <h2>{t("MORTGAGE.TEXT1")}</h2>
                    <p>{t("MORTGAGE.TEXT2")} {t("MORTGAGE.TEXTONE2")} {t("MORTGAGE.TEXTTWO2")} {t("MORTGAGE.TEXTTHREE2")} {t("MORTGAGE.TEXTFOUR2")}</p>
                    <h6 className="h6_style ">{t("MORTGAGE.TEXT3")} {t("MORTGAGE.TEXTONE3")} {t("MORTGAGE.TEXTTWO3")}</h6>
                    <p className="bannersubtext">{t("MORTGAGE.TEXT4")} {t("MORTGAGE.TEXTTWO4")} {t("MORTGAGE.TEXTTHREE4")} {t("MORTGAGE.TEXTFOUR4")}  </p>
                    <Link to={'/AskTheExpert'} style={{ textDecoration: "none" }}>
                      <div className="askbutton">
                        <p className="askres"> {t("COMMON.ASKTHEEXPERTS")}</p>
                        <img src={iconaskbtn} alt="" />
                      </div>
                    </Link>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
        <section className="categorysectionone">
          <OfferCardListJSX
            SubCatTitle={t("COMMON.OFFERHEADING")}
            SubCatText={t("MORTGAGE.MORTGAGESUBHEADINGONE")}
            SubCatTextLineOne={t("MORTGAGE.MORTGAGESUBHEADINGTWO")}
            offerData={offerData}
            isDescription={true}
            isSlider={true}
            redirectUrl="/offers"
            instituteID={instituteID}
          />

        </section>


        <ProductCardList
          productData={productData}
          productTitle={t("MORTGAGE.PRODUCTSTITLE")}
          productsubTitleText={t("MORTGAGE.PRODUCTSLIDERSUBTITLE")}
          productsubTitleTextTwo={t("MORTGAGE.PRODUCTSLIDERSUBTITLES")}
          subCategoryLayout={true}
          isCategorySubCategory={false}
          isProductName={true}
          isViewButton={true}
          category="mortgage"
          instituteID={instituteID}
          viewbuttonURL={setProductDetails} 
        />

        <InstituteList
          instituteData={instituteData}
          topRatedText={t("MORTGAGE.INSTITUIONINCANEDA")}
          checkOutText={t("MORTGAGE.TOPINSTITUDESUBTITLE")}
          finalcialText={t("MORTGAGE.TOPINSTITUDESUBTITLEOFFER")}
          onInstituteClick={onInstituteClick}
          isCategory={true}
          instituteID={instituteID}
          categoryImg={RealEtext}
          topText={t("COMMON.TOP")}
          category={"mortgage"} />


        <section className="categorysectionfour bestdeals Experts">
          <div className="container-fluid">
            <h2 className="text-center expertheading">{t("MORTGAGE.TEXT18")}</h2>
            <h5 className="text-center  h5_style expertsub1">{t("MORTGAGE.TEXTONE18")}<br />
            </h5>
            <p className="expertsub2  text-center">{t("MORTGAGE.TEXTTWO18")}</p>

            <div className=" p bestdealsbox bestexperts">
              {expertData?.length > 0 ?
                <Slider {...expslider}>
                  {expertData?.map(data => {
                    return (
                      <ExpertCardJsx data={data} instituteID={instituteID} CategoryID={data?.expertSpecializations[0]?.institutionCategoryId} />
                    )
                  })}
                </Slider>
                :
                <div className="dropdefault text-center">
                  <div className="dropdefaultimage">
                    <img src={dropbox} alt={' '} />
                  </div>
                  <div className="dropdefaulttext">
                    <h5 className="opacity0">No Record Found !! </h5>
                  </div>
                </div>

              }
              <div className="latestbutton  marginbottom"  onClick={() => hitShowALL()}>
                <Link to={'/globalMainSearchExpert'} className="showallourbesttoo " style={{ textDecoration: "none" }}>{t("COMMON.SHOWALL")}</Link>
              </div>
            </div>

          </div>
        </section>
        <section className="categorysectionfive">
          <div className="container-fluid">
            <div className="realEquestion">
              <div className="realEquestiontext ">
                <h4>{t("MORTGAGE.TEXT19")}</h4>
                <h2 className="resstext"><span>{t("MORTGAGE.TEXTTWO19")} </span>{t("MORTGAGE.TEXTTHREE19")}<span>{t("MORTGAGE.TEXTFOUR19")} </span>{t("MORTGAGE.TEXTFIVE19")}</h2>
                <h3 className=""
                >{t("MORTGAGE.TEXT20")}</h3>
                <Link to={'/AskTheExpert'} style={{ textDecoration: "none" }}>
                  <div className="realEbuttonmain realEmargin askbuttoncomm">
                    <p className="realEbutton"> {t("COMMON.ASKTHEEXPERTS")}</p>
                    <img src={iconaskbtn} alt="" />
                  </div>
                </Link>
              </div>
            </div>
          </div>
        </section>
        <section className="categorysectionsix blogdetailsexpertppv">
          <div className="container-fluid">
            <div className="realEbloginner resfont">
              <h2 className="text-center"
              >{t("MORTGAGE.TEXT21")}</h2>
              <p className="text-center mt-3 realEblogtext">{t("MORTGAGE.TEXT22")}
              </p>
              <p> {t("MORTGAGE.TEXT23")}
                {t("MORTGAGE.TEXTONE23")}</p>
            </div>
            <div className="row mt-5 realEbloglog">
              {
                blogData?.body?.length > 0 ?
                  blogData?.body?.slice(0, visibleBlogs).map(blog => {
                    return <div className="col-lg-6 col-md-6 col-sm-6 minheightrealestate">
                      <ArticlesCardJSX
                        blogslug={blog?.slug}
                        blogimage={blog?.image}
                        blogtitle={blog?.title}
                        blogshort_content={blog?.short_content}
                      />
                      {/* <Link to={`/articleDetails/${blog.slug}`}>
                      <div className="bloginner blogsmain text-center">
                        <div className="bloginnerimage"><img className="blogsimgimg" src={blog?.image} alt={' '} /></div>
                        <div className="bloginnertag displaynone">{blog?.cats.map((cat) => cat.name).join(',')}</div>
                        <div className="bloginnertext ">{blog?.title}</div>
                        <div className="bloginnerdate ">{blog?.short_content}</div>
                      </div>
                    </Link> */}

                    </div>
                  }) :

                  <div className="dropdefault text-center">
                    <div className="dropdefaultimage">
                      <img src={dropbox} alt={' '} />
                    </div>
                    <div className="dropdefaulttext">
                      <h5>You don't have any Blogs yet. </h5>
                    </div>
                  </div>
              }


              <Link to={{ pathname: `/articles`, search: '?category=mortgage' }} className="showallourbesttoo">
                {t("COMMON.SHOWALL")}
              </Link>
            </div>
          </div>
        </section>
      </section>

      <section className="homecustom">
        <Footer />

        {
          openModal &&
          <div className="modalspecial blog-modal">
            <div>
              <div className="mybtn">
                <button type="button" className="myadbtn" onClick={() => { setOpenModal(false) }}><svg version="1.0" xmlns="http://www.w3.org/2000/svg"
                  width="64.000000pt" height="64.000000pt" viewBox="0 0 64.000000 64.000000"
                  preserveAspectRatio="xMidYMid meet">

                  <g transform="translate(0.000000,64.000000) scale(0.100000,-0.100000)"
                    fill="#ffffff" stroke="none">
                    <path d="M156 484 c-9 -8 -16 -20 -16 -26 0 -5 28 -39 62 -74 l61 -64 -61 -64
c-34 -35 -62 -69 -62 -74 0 -14 28 -42 42 -42 5 0 39 28 74 62 l64 61 64 -61
c35 -34 69 -62 74 -62 14 0 42 28 42 42 0 5 -28 39 -62 74 l-61 64 61 64 c34
35 62 69 62 74 0 14 -28 42 -42 42 -5 0 -39 -28 -74 -62 l-64 -61 -64 61 c-35
34 -69 62 -74 62 -6 0 -18 -7 -26 -16z"/>
                  </g>
                </svg></button>
              </div>
              <div className="modal-header text-center">
                <h3>{blog?.title}</h3>
              </div>
              <div className="modal-body text-center">
                <div className="blog-image-dv"><img src={blog.image} alt={' '} />
                </div>
                <div className="blog-content-dv" dangerouslySetInnerHTML={{ __html: blog.content }}></div>
              </div>
            </div>
          </div>

        }


      </section>
      {productDetails?.data &&
        <DetailedProductsJsx data={productDetails?.data} category={productDetails?.category} subCategory={productDetails.subCategory} closFunction={closeFunction} />
      }
    </>

  );
};

export default MortgageJsx;