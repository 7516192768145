import React, { useEffect, useState } from 'react'
import homedashboard from "../../../../assets/images/homedashboard.svg";
import articleexpertdashboard from "../../../../assets/images/articleexpertdashboard.svg";
import mypaymentdashboard from "../../../../assets/images/mypaymentdashboard.svg"
import asktheexpertdashboard from "../../../../assets/images/asktheexpertdashboard.svg";
import articledashboard from "../../../../assets/images/articledashboard.svg";
import logoutdashboard from "../../../../assets/images/logoutdashboard.svg";
import accountsettingdashboard from "../../../../assets/images/accountsettingdashboard.svg";
import { useTranslation } from "react-i18next";

import { Link } from "react-router-dom";
import { NEW_URL, baseURL } from '../../../../constants/applicationConstants';
import swal from "sweetalert";
import { useHistory } from "react-router-dom";
import axios from 'axios'
import '../sidebar/sidebar.model.css';

const SideBarsJsx = (props) => {
  const history = useHistory();
  const { t } = useTranslation();
  const [state, setState] = useState()

  const {
    response
  } = props;

  useEffect(() => {
    callDetails()
  }, []);

  const callDetails = () => {
    let token = sessionStorage.getItem("token") && JSON.parse(sessionStorage.getItem("token"))
    let instituteId = sessionStorage.getItem("masterID") && JSON.parse(sessionStorage.getItem("masterID"))
    const instUserId = sessionStorage.getItem('instUserId') ?? '';

    let url = `${baseURL}/institute/${instituteId}/details`
    let secondary_url = `${baseURL}/detailsInstituteUser/${instUserId}`

    const adminLoginData = sessionStorage.getItem('adminLoginData');


    axios.get(instUserId ? secondary_url : url, {
      headers: { 'x-access-token': token }
    }).then(res => {

      setState(res.data.data)
      if (!adminLoginData && !res.data?.data?.isPermanentPasswordSet) {
        history.push("/saveNewPassword");
        // window.location.reload()
      }

    }).catch(err => {
    })
  }
  const logout = () => {
    swal({
      title: "Are you sure you want to Logout ?",
      icon: "warning",
      buttons: [
        'Continue',
        'Logout'
      ],
      dangerMode: true,
    }).then(function (isConfirm) {
      if (isConfirm) {
        sessionStorage.removeItem("token");
        sessionStorage.removeItem("loginAs");
        sessionStorage.removeItem("auth");
        sessionStorage.removeItem("masterID");
        sessionStorage.removeItem("expertId");
        sessionStorage.removeItem("token");
        sessionStorage.removeItem("email");
        sessionStorage.removeItem("loginData");
        sessionStorage.removeItem("expertData");
        sessionStorage.removeItem("customerId");

        sessionStorage.removeItem("planName");
        sessionStorage.removeItem("legalName");
        sessionStorage.removeItem("isLoggedIn");
        sessionStorage.removeItem("description");
        sessionStorage.removeItem("flowName");
        sessionStorage.removeItem("flowType");
        sessionStorage.removeItem("subscriptionPlanId");
        sessionStorage.removeItem("planTypeID");
        sessionStorage.removeItem("planData");
        localStorage.removeItem("flowName");
        localStorage.removeItem("flowType");
        localStorage.removeItem("verificationStatus");
        localStorage.removeItem('VerifyInstituteID');
        localStorage.removeItem('AdminFlow');
        localStorage.removeItem('VerifyExpertID');
        sessionStorage.removeItem("comingFrom");

        history.push("/");
        window.location.reload()
      } else {
        //   swal("Cancelled", "Your imaginary file is safe :)", "error");
      }
    })

  }

  
  return (
    <>
     <div className="dashboard-container maincustomerdashboard">
      <div className="dashboardleftpanel">
        <div className="dashboardlinkpop">
          <div className="dashboard-container maincustomerdashboard main">
            <div className="dashboardleftpanel">

              <div className="text-column">
                <div className="userdetails">
                  <div className="dashboardlogo">
                    <img
                      src={!response?.imageUrl
                        ? `https://ui-avatars.com/api/?name=${response?.firstName}+${response?.lastName}&rounded=true`
                        : `${NEW_URL}/${response?.imageUrl}`}
                      alt={''}
                    />
                  </div>
                  <div className="responsename"> {`${response?.name}`} <Link to={`/institute/${response?.formattedLegalName}`} target="_blank" rel="noreferrer" className="viewprofilebtn">{t("COMMON.VIEWPROFILE")}</Link></div>
                </div>

                <div className="dashboardlink dashboardtext mydash">
                  <ul className="dashboardinner">
                    <li >
                      <Link to={'/dashBoard'}>
                        <span className="menusideimage"><img className="leftborder" src={homedashboard} alt={''} /></span>  <p className={` ${window.location.href?.toLowerCase().includes('dashboard') ? 'activetext' : ""}`}>Dashboard</p>
                      </Link>
                    </li>
                    <li >
                      <Link to={'/myExpert'}>
                        <span className="menusideimage"><img className="leftborder" src={asktheexpertdashboard} alt={''} /></span><p className={`${window.location.href?.toLowerCase().includes('myexpert') || window.location.href?.toLowerCase().includes('unregistered') ? 'activetext' : ''}`}>My Experts</p>
                      </Link>
                    </li>
                    <li >
                      <Link to={'/myWorkspace'}>
                        <span className="menusideimage"><img className="leftborder" src={articleexpertdashboard} alt={''} /></span> <p className={`${window.location.href?.toLowerCase().includes('myworkspace') ? 'activetext' : ''}`}>My Workspace</p>
                      </Link>
                    </li>
                    <li >
                      <Link to={'/myBlog'}>
                        <span className="menusideimage"><img className="leftborder" src={articledashboard} alt={''} /></span> <p className={`${window.location.href?.toLowerCase().includes('myblog') ? 'activetext' : ''}`}>My Articles</p>
                      </Link>
                    </li>
                    <li >
                      <Link to={'/myAccounts'}>
                        <span className="menusideimage"><img className="leftborder" src={accountsettingdashboard} alt={''} /></span> <p className={`${window.location.href?.toLowerCase().includes('myaccounts') ? 'activetext' : ''}`}>My Account</p>
                      </Link>
                    </li>
                    <li >
                      <Link to={'/myPayment'}>
                        <span className="menusideimage"><img className="leftborder" src={mypaymentdashboard} alt={''} /></span> <p className={`${window.location.href?.toLowerCase().includes('mypayment') ? 'activetext' : ''}`}>My Payment</p>
                      </Link>
                    </li>
                    <li onClick={logout}>
                      <a>
                        <span className="menusideimage"><img className="leftborder" src={logoutdashboard} alt={''} /></span><p>Logout</p>
                      </a>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
</div>
        </div>
      </div>
    </>

  );



};

export default SideBarsJsx;