import React, { useState } from 'react'
import Sidebar from "../../../../components/dashboard/institute/sidebar/sidebar.js"
import { Footer } from '../../../common/footer/footer';
import { Pagination } from '../../../common/pagination/uiPagination.js';
import dropbox from "../../../../assets/images/dropbox.svg";
import { Trans, useTranslation } from "react-i18next";
import "../myBlogs/blogs.model.css";
import { ArticlesCardJSX } from '../../../common/articlesCard/articlesCard';

export const BlogJSX = (props) => {
    const { blogData, onPaginationButtonClick,onPaginationButtonClick1, expertData,page,page1 } = props;

    const [openModal, setOpenModal] = useState(false);
    const [blog, setBlog] = useState({});

    const itemsPerPage = 12

    const [currentPage, setCurrentPage] = useState(1);

    const currentItems = blogData?.body?.slice(
        (page - 1) * itemsPerPage,
        page * itemsPerPage
    );

    const currentItems1 = expertData?.slice(
        (page1 - 1) * itemsPerPage,
        page1 * itemsPerPage
    );

    const { t } = useTranslation();
    return (

        <section className="main_institute_dashboard">``
            <section class="entiredashboard maincustomerdash main institute_dashboard">
                <div className="container-fluid">
                    <div className="row">
                        <div className="col-lg-4 col-sm-4">
                            <Sidebar />
                        </div>
                        <div className="col-lg-8 col-sm-8 dashboard-data-inst">


                            <div className="dashboardrightpanel">
                                <div className="sectionheader martop20 marbot20 blogsectionpos">
                                    <p className="dahsboardTitle">{t("BLOG.TITLE")}</p>
                                    <p className="dahsboardSubTitle"><Trans i18nKey="BLOG.WRITTENARTICAL"></Trans> </p>
                                </div>


                                <div className="dashboardblog">
                                    <p className="dahsboardTitle">{t("instituteDashboard.EXPERTAUTHOR")}</p>
                                    <p className="dahsboardSubTitle">{t("instituteDashboard.REGISTERED")} </p>

                                    <div className="expert-all-blog">
                                        {

                                            expertData?.length > 0 ?
                                                currentItems1?.map(blog => {
                                                    return <div className={`col-lg-3 col-md-6 col-sm-6 p-0 blogblogmain `} key={blog.id}>
                                                        <div className="blog-margin">   <div className={`basicstructure`}>
                                                            <ArticlesCardJSX blogslug={blog?.slug}
                                                                //() => { setBlog(blog); setOpenModal(true)
                                                                blogimage={blog?.image}
                                                                blogtitle={blog?.title}
                                                                articleauthor={blog?.author}
                                                                blogcat={blog?.cats?.[0].name}
                                                                articledatepublish={blog?.date_publish}
                                                                isSerchArticle={false} />

                                                        </div>
                                                        </div>
                                                    </div>
                                                }) :
                                                <div className="empthy-blog">
                                                    <div>
                                                        <img src={dropbox} alt={''} />
                                                    </div>
                                                    <div>
                                                        <p>{t("instituteDashboard.NOBLOGS")}</p>

                                                    </div>
                                                </div>
                                        }

                                    </div>  {expertData?.length && <section className="newpagination"> <Pagination
                                        currentPage={currentPage}
                                        page={page1}
                                        allOffersData={expertData}
                                        currentItems={currentItems1}
                                        onPaginationButtonClick={onPaginationButtonClick1}
                                        itemsPerPage={itemsPerPage}
                                    /></section>}
                                </div>


                                <div className="dashboardblog dashboardexpert">
                                    <p className="dahsboardTitle">{t("instituteDashboard.PRODUCTS")}</p>
                                    <p className="dahsboardSubTitle">{t("instituteDashboard.PRODUCTSDESC")} </p>

                                    <div className="expert-all-blog">
                                        {
                                            blogData?.body?.length > 0 ?
                                                currentItems?.map(blog => {

                                                    return <div className={`col-lg-3 col-md-3 col-sm-6 p-0 blogblogmain ${blog.cats[0].cat_name?.replace(/\s+/g, '')}`} key={blog.id}>
                                                        <div className="blog-margin">   <div className={`basicstructure ${blog.cats[0]?.name}`}>
                                                            <ArticlesCardJSX blogslug={blog?.slug}
                                                                blogimage={blog?.image}
                                                                blogtitle={blog?.title}
                                                                articleauthor={blog?.author}
                                                                blogcat={blog?.cats?.[0].name}
                                                                articledatepublish={blog?.date_publish}
                                                                isSerchArticle={false} />

                                                        </div>
                                                        </div>
                                                    </div>

                                                }) :
                                                <div className="empthy-blog">
                                                    <div>
                                                        <img src={dropbox} alt={''} />
                                                    </div>
                                                    <div>
                                                        <p>{t("instituteDashboard.NOBLOGS")}</p>

                                                    </div>
                                                </div>

                                        }
                                    </div>
                                    {blogData?.body?.length && <section className="newpagination"> <Pagination
                                        currentPage={currentPage}
                                        page={page}
                                        allOffersData={blogData?.body}
                                        currentItems={currentItems}
                                        onPaginationButtonClick={onPaginationButtonClick}
                                        itemsPerPage={itemsPerPage}
                                    /></section>}
                                </div>



                            </div>
                        </div>
                    </div>
                </div>



            </section>
            <Footer />
        </section>
    )
}