import React, { useEffect, useState } from 'react';
import { useTranslation } from "react-i18next";
import './product.css';
import styles from './productSection.module.css';
import ProductCard from '../../common/productCard/productCard';
import DetailedProductsJsx from '../../common/detailedProduct/detailedProduct.jsx';
import { Link } from "react-router-dom";
import { NEW_URL } from '../../../constants/applicationConstants';

export const ProductSection = (props) => {

    const { t } = useTranslation();
    const { profileData } = props;

    const [productDetails, setProductDetails] = useState({});
    const closeFunction = () => {
        setProductDetails({})
    }

    const categories = profileData?.instituteProducts?.categories
    const [activeCategory, setActiveCategory] = useState(null);
    const [activeSubCategory, setActiveSubCategory] = useState(null);
    useEffect(() => {
        handleCategoryClick(categories[0])
        handleSubCategoryClick(categories[0].subInstitutionCategories[0])
    }, [categories])

    // Handling category click
    const handleCategoryClick = (category) => {
        // setCatName(`${JSON.stringify(category.parentInstitutionCategory)}`);
        setCatName(category.parentInstitutionCategory);


        setActiveCategory(category);
        setActiveSubCategory(category.subInstitutionCategories[0]);


    };

    // Handling sub-category click
    const handleSubCategoryClick = (subCategory) => {



        setActiveSubCategory(subCategory);
    };

    const [activeCategoryId, setActiveCategoryId] = useState(null);

    const handleClick = (categoryId) => {
        setActiveCategoryId(categoryId);
    };
    const [catName, setCatName] = useState("");

    return <section className="product-offer service-offer" id="ppvProductSection">
        <div className={styles.ppvproduction_inner}>
            <div className="container">
                <div className={styles.heading}>
                    <h2>{t("PPVINSTITUTE.PRODUCTTITLE")}</h2>
                    <h5>{t("PPVINSTITUTE.PRODUCTSUBTITLE")}</h5>
                    <h6>{t("PPVINSTITUTE.PRODUCTSUBSUBTITLE")}</h6>
                </div>
                <div className="product-tabs-container">
                    <div className={`${styles.myExpertNav} ${activeCategory ? styles.active : ''}`}>
                        <ul className={styles.product_tab_inner}>
                            {
                                categories?.map((category, index) => {
                                    return (
                                        <div
                                            key={index}
                                            className={`${styles.categoryTab} ${styles.categorytab_name} ${activeCategory === category ? `active ${styles.Mortgagebg}` : ''} ${catName.replace(" ", " ")}border parentInstitutionCategory-id${category.parentInstitutionCategoryId} ppvproducttab`}
                                            onClick={() => handleCategoryClick(category)}
                                        >
                                            <div>
                                            {/* <span className={`caticonpoptext d-block d-lg-none d-sm-none d-md-none`}>
                                                    {category.parentInstitutionCategory} 
                                                </span> */}
                                                 <span className={`caticonpop d-block d-lg-none d-sm-none d-md-none`}>
                                                    <img src={`${NEW_URL}/${category.parentInstitutionCategoryIcon}`} alt={category.parentInstitutionCategory} />                                                 
                                                </span>
                                                <span className={`${styles.categoryText} catext`}>
                                                    {category.parentInstitutionCategory} 
                                                </span>
                                               
                                            </div>
                                        </div>
                                    );
                                })
                            }
                        </ul>
                        {/* {JSON.stringify(activeCategory)} */}
                        <div

                            className={`${styles.subcategoriesTabs_main} tabmain parentInstitutionCategory-id${activeCategory?.parentInstitutionCategoryId}`}
                        >
                            <div className="sticky-top sticky-offset vh-100">
                                {categories?.map((category, index) => {
                                    return (
                                        <div key={category.id || index} className={`${styles.subcategoriesTabs} ${activeCategory === category ? `active` : ''} ${catName.replace(" ", " ")}bg`}>
                                            {activeCategory === category &&
                                                category.subInstitutionCategories?.map((subCategory, subIndex) => {
                                                   
                                                    return (
                                                        <div
                                                            key={subCategory.id || subIndex}
                                                            className={`${styles.subcategoryTab} ${activeSubCategory === subCategory ? styles.active : ''} ${styles.subcategories} ${catName.replace(" ", " ")}ps ${styles[category?.parentInstitutionCategory?.toLowerCase().replace(/\s+/g, '-')]}`}

                                                            onClick={() => handleSubCategoryClick(subCategory)}>
                                                            <div className={styles.subcategory_name}>
                                                                <span className={styles.subcategory_text}>
                                                               <div className={`iconone  ${styles.pstab} d-block d-lg-none d-sm-none d-md-none`}><img src={`${NEW_URL}/${subCategory.icon}`} alt={subCategory.icon} /></div> 
                                                                   <div className="ss-cat">{subCategory.name}</div> 
                                                                </span>
                                                            </div>
                                                        </div>
                                                    );
                                                })}
                                        </div>
                                    );
                                })}
                            </div>
                            <div className="products-list">
                                <div className="row">
                                    {activeSubCategory &&
                                        activeSubCategory.products.slice(0, 10).map((product, index) => (                                            
                                            <div className="col-lg-6 col-md-6 col-sm-12 col-xs-12">
                                                
                                                <ProductCard
                                                    product={product}
                                                    subCategoryLayout={true}
                                                    isCategorySubCategory={false}
                                                
                                                    isProductName={true}
                                                    isViewButton={true}
                                                    viewbuttonURL={(e) => { setProductDetails({ data: {...product,institutionCategoryId:activeCategory?.parentInstitutionCategoryId}, frompage: 'realestateProduct', pagePath: '/banking' }) }}
                                                />
                                            </div>

                                        ))}
                                        <div className="showallourbesttwo mt-3 text-center">
                                            
                                    <Link
                                        to={{
                                            pathname: "/listOfProduct",
                                            state: { data: activeCategory?.parentInstitutionCategoryId,profileData:profileData, legalName: profileData?.formattedLegalName, category: 1, frompage: 'ppvAdsSection', pagePath: window.location.pathname }
                                            // state: { data: product.parentInstitutionCategoryId, legalName: legalName, category: product.parentInstitutionCategory, subCategory: subCat.name, frompage: 'ppvProductSection' ,pagePath:window.location.pathname }
                                        }}
                                        className="showallourbesttoo"
                                        onClick={() => window.scrollTo(0, 0)}>
                                       {t("COMMON.SHOWALL")}
                                                             </Link></div>

                                </div>
                            </div>
                        </div>
                    </div>  </div>  </div>
        </div>
        {productDetails?.data &&
            <DetailedProductsJsx data={productDetails?.data} category={productDetails?.category} subCategory={productDetails.subCategory} closFunction={closeFunction} />
        }
    </section >


}