import React, { useState } from 'react'
import { Link } from "react-router-dom";
import { Trans, useTranslation } from "react-i18next";
import searchbg from "../../../assets//homeimages/searchexprtbg.svg";
import searchbgmobile from "../../../assets//homeimages/Imageexpertsection.svg";
import mobilesearchexpert from "../../../assets/homeimages/mobilesearchexpert.svg";
import mobilesearchinstitute from "../../../assets/homeimages/search_top_institution_image.svg";
import Topinstitution from "../../../assets/homeimages/Top institution.png";


const SearchNowJsx = (props) => {
    const { t } = useTranslation();

    const { type } = props;


    return (
        <>
        {type === "expert" ? 
        <section className="search mt-5">
        <div className="container" style={{ backgroundColor: "#C9F7D4" }}>
            <div className="row" style={{ alignItems: 'center' }}>
                <div className="col-lg-6  col-9">
                    <div className="main-search">
                        <h2>{t("HOMEPAGE.SEARCHEXPERT")}</h2>
                        <p>
                            <Trans i18nKey="HOMEPAGE.SEARCHEXPERTSUBTITLE" >
                            </Trans></p>
                        <div className="buttonsearch mt-4" >
                            <Link to={'/globalMainSearchExpert'} className="searchnow  mb-4">{t("HOMEPAGE.SEARCHNOW")}</Link>
                        </div>
                    </div>
                </div>
                <div className="col-lg-6 d-none d-sm-block nopadright">
                    <div className="searchimg">
                        <img src={searchbg} alt="" className="w-100" alt='' />
                      
                    </div>
                </div>
                <div className="searchbackcs d-block d-sm-none">
                    {/* <img src={mobilesearchexpert} alt='' /> */}
                    <img src={searchbgmobile} alt=""  alt='' />
                </div>
            </div>
        </div>
    </section>

    :
    
    <section className="searchtop">
        <div className="container">
            <div className="row paddingzero" style={{ backgroundColor: '#EEF7FF', alignItems: 'center' }}>
                <div className="col-lg-6 col-xs-12 d-block d-sm-none p-0">
                    <img className="searchtop-images" src={mobilesearchinstitute} alt="" />
                </div>
                <div className="col-lg-6 col-xs-12">
                    <div className="searchtop-text" >
                        <h2>{t("HOMEPAGE.SEARCHINSTITUTE")}</h2>
                        <p>{t("HOMEPAGE.SEARCHINSTITUTETITLE")}</p>
                    </div>
                    <div className=" buttonsearchmaim  mt-4">
                        <Link to={'/globalMainSearchInstitutions'} className="searchnow mb-4">{t("HOMEPAGE.SEARCHNOW")}</Link>
                    </div>
                </div>
                <div className="col-lg-6 col-6 d-none d-sm-block nopadright">
                    <img src={Topinstitution} alt="" />
                </div>
            </div>
        </div>
    </section>
    }
            
        </>

    );
};

export default SearchNowJsx;
