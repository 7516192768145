import axios from 'axios';
import React from 'react'
import { Component } from 'react';
import { toast, ToastContainer } from 'react-toastify';
import { baseURL, BLOG_URL_TOKEN, BLOG_URL } from '../../../constants/applicationConstants.js';
import { emailRegex, nameRegex } from '../../../shared/helper.js';
import { Loader } from '../../common/loader/loader.jsx';
import ExpertPpvJsx from './expertPpv.jsx'
import swal from 'sweetalert'

export default class ExpertPpv extends Component {
    constructor(props) {
        super(props);
        this.state = {
            isProcessing: false,
            slugUrl: null,
            profileData: null,
            blogData: null,
            page: 1,
            userData: {
                name: '',
                email: "",
                phone: "",
                phoneNumber: "",
                message: ""
            },
            formError: {},
            formSubmitButtonDisable: true,
        }
    }

    componentDidMount = () => {
        this.getPublicData()
    }

    getPublicData = () => {
        this.setState({ isProcessing: true })
        this.setState({ slugUrl: window.location.pathname.replace('/expertPpv', '') }, () => {
            axios.get(`${baseURL}/expert${this.state.slugUrl}/publicProfile`).then(res => {
                this.setState({ profileData: res.data.data, isProcessing: false }, () => {
                    sessionStorage.setItem('currentExpertId', this.state.profileData.expertId)
                    this.getBlogData()
                    if (window.location.hash) {
                        const element = document.getElementById(window.location.hash.replace("#", ""));
                        element && element.scrollIntoView();
                    }
                });
            }).catch(err => {
                toast.error('Slug URL is invalid');
                this.setState({ isProcessing: false });
            })
        })
    }

    getBlogData = () => {
        var data = new FormData();
        data.append('limit', '10');
        data.append('device_token', '20.204.66.244');
        data.append('expertid', this.state.slugUrl.replace('/', ''));
        data.append('paged', this.state.page)
        this.setState({ isProcessing: true })

        axios.post(`${BLOG_URL}`, data,
            {
                headers: { 'Eba-access-api-base-token': BLOG_URL_TOKEN }
            }).then(res => {
                this.setState({ blogData: res.data, isProcessing: false });
            }).catch(err => {
                this.setState({ isProcessing: false })
            })
    }

    onInputChangeHandler = (e) => {
        let name = e.target.name;
        let value = e.target.value;

        if (value) {
            if (!this.inputRegexValidation(name, value)) {
                this.setState(
                    {
                        userData: {
                            ...this.state.userData,
                            [name]: value,
                        },
                        formError: {
                            ...this.state.formError,
                            [name]: "regx",
                        },
                    },
                    () => this.isFromSubmissionBlock()
                );
            } else {
                this.setState(
                    {
                        userData: {
                            ...this.state.userData,
                            [name]: value,
                        },
                        formError: {
                            ...this.state.formError,
                            [name]: "",
                        },
                    },
                    () => this.isFromSubmissionBlock()
                );
            }
        } else {
            this.setState(
                {
                    userData: {
                        ...this.state.userData,
                        [name]: value,
                    },
                    formError: {
                        ...this.state.formError,
                        [name]: "",
                    },
                },
                () => this.isFromSubmissionBlock()
            );
        }
    };

    isFromSubmissionBlock = () => {
        let { name, email, phone, phoneNumber, message } = this.state.userData;

        if (name && email && phone && phoneNumber && message) {
            if (
                this.inputRegexValidation('name', name) &&
                this.inputRegexValidation('email', email) &&
                this.inputRegexValidation('phone', phone) &&
                phoneNumber && message
            ) {
                this.setState({
                    formSubmitButtonDisable: false
                });
            }
            else {
                this.setState({
                    formSubmitButtonDisable: true
                });
            }
        } else {
            this.setState({
                formSubmitButtonDisable: true
            });
        }
    };

    onTouchHandler = (e) => {
        let name = e.target.name;
        let value = e.target.value;
        if (value === "") {
            this.setState({
                formError: {
                    ...this.state.formError,
                    [name]: "req",
                },
            });
        } else {
            if (!this.inputRegexValidation(name, value)) {
                this.setState({
                    formError: {
                        ...this.state.formError,
                        [name]: "regx",
                    },
                });
            }
        }
    };

    inputRegexValidation = (name, value) => {
        if (name === 'name')
            return nameRegex.test(value);
        else if (name === "email")
            return emailRegex.test(value);
        else if (name === "phone")
            return !this.getProperPhoneNumber(value).includes('_');

        return true;
    };

    getProperPhoneNumber = (value) => {

        if (!isNaN(value)) {
            return value
        }

        const number = value.split(' ');
        return number[0].replace('(', '').replace(')', '') + number[1] + number[2]
    }

    onSendMessageClick = () => {
        this.setState({ isProcessing: true });
        const { name, email, phone, phoneNumer, message } = this.state.userData;
        const payload = {
            "name": name,
            "phoneNumber": this.getProperPhoneNumber(phone),
            "email": email,
            "message": message,
            "formattedLegalName": this.state.slugUrl.replace('/', ''),
            "institutionCategoryId": phoneNumer,
            "category": "expert"
        }
        axios.post(`${baseURL}/contactUs`, payload).then(res => {
            this.setState({ isProcessing: false })
            // toast.success('Message sent successfully');
            this.setState({
                userData: {
                    name: '',
                    email: "",
                    phone: "",
                    phoneNumber: "",
                    message: "",
                },
            })
            this.setState({
                profileData: null,
            })
            swal({
                text: "Message Sent Successfully !",
                 
                successMode: true,
            });
            // swal({
            //     title: "",
            //     text: "Message Sent Successfully !",
            //     icon: "success",
            //     successMode: true,
            //   })
            //   .then(res => {
            //     if (res) {
            //       window.location.reload()
            //     }
            //   });
            this.getPublicData()
        }).catch(err => {
            this.setState({ isProcessing: false })
            toast.error(err.response.data.error.message);
        })
    }

    render() {
        return (
            <div>
                <ExpertPpvJsx
                    profileData={this.state.profileData}
                    slugUrl={this.state.slugUrl}
                    blogData={this.state.blogData}
                    userData={this.state.userData}
                    formError={this.state.formError}
                    onInputChangeHandler={this.onInputChangeHandler}
                    onTouchHandler={this.onTouchHandler}
                    onSendMessageClick={this.onSendMessageClick}
                    formSubmitButtonDisable={this.state.formSubmitButtonDisable}
                />
                {this.state.isProcessing && <Loader />}
                <ToastContainer />
            </div>
        )
    }
}