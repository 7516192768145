import React, { useEffect } from 'react'
import { useTranslation } from "react-i18next";

import close_btn from '../../../../assets/images/white_cross.svg';
import realestateicon from "../../../../assets/images/realestateicon.svg";
import insurenceicon from "../../../../assets/images/insurenceicon.svg";
import mortgageicon from "../../../../assets/images/mortgageicon.svg";
import bankingicon from "../../../../assets/images/bankingicon.svg";
import investmenticon from "../../../../assets/images/investmenticon.svg";
import realestateicontwo from "../../../../assets/images/realestateicontwo.svg";
import insurenceicontwo from "../../../../assets/images/insurenceicontwo.svg";
import mortgageicontwo from "../../../../assets/images/mortgageicontwo.svg";
import bankingicontwo from "../../../../assets/images/bankingicontwo.svg";
import investmenticontwo from "../../../../assets/images/investmenticontwo.svg";
import asktheexpertdashboard from "../../../../assets/images/asktheexpertdashboard.svg";

import { Footer } from '../../../common/footer/footer';
import "../customerAskTheExpert/customerAskTheExpert.css";
import 'react-awesome-slider/dist/styles.css';
import AOS from 'aos';
import Sidebar from "../sidebar/sidebar.js";
import 'aos/dist/aos.css';
import Slider from 'react-slick';
import { NEW_URL } from '../../../../constants/applicationConstants';
import questionicon1 from "../../../../assets/images/questioniocn1.png";

export const getIconOne = (label) => {
    if (label === 'Real Estate')
        return realestateicon
    else if (label === 'Mortgage')
        return mortgageicon
    else if (label === 'Insurance')
        return insurenceicon
    else if (label === 'Banking')
        return bankingicon
    else if (label === 'Investment')
        return investmenticon
    else return ''
}

const CustomerAskTheExpertJsx = (props) => {
    const { t } = useTranslation();
    const {
        categoryData,
        onCategoryClick,
        categoryName,
        subCategoryData,
        onSubcategoryClick,
        subCategoryName,
        questionAsked,
        onTextAreaChange,
        onQuestionClick,
        onSubmitButtonClick,
        expertQuestions,
        onclickFocus,
        closFunction,
        showConfirmModal,

        formSubmitButtonDisable,

        onConfirmAndSubmitButtonClick,
    } = props;

    useEffect(() => {
        AOS.init();
        window.scrollTo(0, 0);
    }, [])
    const getMuskPattern = (name) =>
        name === 'phone' ? '(999) 999 9999' : 'a9a 9a9';

    const getIconTwo = (label) => {
        if (label === 'Real Estate')
            return realestateicontwo
        else if (label === 'Mortgage')
            return mortgageicontwo
        else if (label === 'Insurance')
            return insurenceicontwo
        else if (label === 'Banking')
            return bankingicontwo
        else if (label === 'Investment')
            return investmenticontwo
        else return ''
    }
    const Subcatslider = {

        infinite: false,
        speed: 500,
        slidesToShow: 4,
        slidesToScroll: 1,
        arrows: true,
        vertical: true,
        verticalSwiping: true,
        dot: false,
       

    };

    return (
        <>
            <section className="main_customer_dashboard">


                <section className="entiredashboard maincustomerdash customer-dashboard">

                    <div className="container-fluid">
                        <div className="row">
                            <div className="col-lg-4 col-sm-4 ">
                                <div className="dashboardleftpanel ">
                                    <div className="dashboardlinkpop ">
                                        <Sidebar />
                                    </div>
                                </div>
                            </div>
                            <div className="SelectMainCategory col-lg-8 col-sm-8 p-0" id="ask" style={{ marginBottom: "10px 0" }}>

                                <div className="dashboardrightpannerinnerleft customask">
                                    <h2 className="h2_style font-weight-600"><span className="headingicon d-block d-lg-none d-sm-none d-md-none"><img src={asktheexpertdashboard} /></span>{t("customerasktheexpert.HEADING")}</h2>
                                    <h5 className="h5_style">{t("customerasktheexpert.PARAGRAPH")}</h5>
                                </div>

                                <div className="asktheexpertcategorysectioninner asktheexpertcategorysectioninnerline asktheexpertcategorysectioninnerlinenew mainglogsask">
                                    <div className="catstart clearfix blogsasktheexpert customertASK" data-aostest="fade-up-right">
                                        <div className="dashboard-ps-offer">
                                            <div className="categorytext">
                                                <h2>{t("ASKTHEEXPERT.SELECT")} <span>{t("ASKTHEEXPERT.CATEGORY")}</span></h2>
                                                <p className="catetext1 mb-0">{t("ASKTHEEXPERT.TEXT1")}</p>
                                                <p className="catetext2 cutomerptext">{t("ASKTHEEXPERT.TEXT2")}</p>
                                            </div>
                                            <div className={`categoryonesetfirst wcustomerask `}>
                                                <div className="choose-main" >
                                                    {
                                                        categoryData?.map(catDT => {

                                                            return <div className={`choose categoryonesettwo ${categoryName === catDT?.name ? 'active' : ''}`} onClick={() => onCategoryClick(catDT?.institutionCategoryId, catDT?.name)}>
                                                                <div className={`iconone ${categoryName === catDT?.name ? 'active' : ''}`} ><img src={getIconOne(catDT.name)} alt={''} /></div>
                                                                <div className="icontwo"><img src={getIconTwo(catDT.name)} alt={''} /></div>
                                                                {/* <h5>{catDT.name}</h5> */}
                                                            </div>
                                                        })
                                                    }
                                                </div>
                                            </div>

                                            {categoryName && <div className="catstart clearfix" data-aostest="fade-up-left">
                                                <div className={`categorytwosetfirsttest ${subCategoryName && 'categorytwoset'}`}>

                                                    <div id="askts" onClick={(e) => onclickFocus("askQus")}>

                                                        <div className="subcategoryinneraskexpert pt-3 pb-3 subcat_inner">

                                                            {
                                                                subCategoryData?.map(subCatDT => {

                                                                    return <div id="askqw" className={`categoryonesettwo categoryonesetwidth ${subCategoryName === subCatDT?.name ? 'active' : ''}`} onClick={() => onSubcategoryClick(subCatDT.institutionCategoryId, subCatDT.name)}>
                                                                        <div className="iconone"><img src={`${NEW_URL}/${subCatDT.icon}`}
                                                                            alt={''} /></div>
                                                                        <div className="icontwo"><img src={`${NEW_URL}/${subCatDT.icon}`}
                                                                            alt={''} /></div>
                                                                        <h5 className="subcatname">{subCatDT.name}</h5>
                                                                    </div>
                                                                })
                                                            }

                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            }
                                            {subCategoryName && <div id="askQus">
                                                <div className="container">
                                                    <div className="row  maincustosection">

                                                        <div className="col-lg-12 mb-3 d-none d-sm-block">
                                                            <div className="catstart clearfix catinnercustomer" data-aostest="fade-up-bottom">
                                                                <div className="categoryfourset catcustomer">
                                                                    <div className="categoryonesetone">

                                                                        <div className="question-text textcenter">
                                                                            <h2>{t("ASKTHEEXPERT.QUESTIONHEADING")}<span>{t("ASKTHEEXPERT.QUESTIONHEADING-1")}</span></h2>
                                                                        </div>
                                                                    </div>
                                                                    <Slider {...Subcatslider}>

                                                                        {expertQuestions.length > 0 ? (
                                                                            expertQuestions.map(que => (

                                                                                <div className="main-question questionslider mainquestioncust">
                                                                                    <div className="questionslider-text">
                                                                                        <p>{que.question}</p></div>
                                                                                    <div className="questiontext-icon">
                                                                                        <img src={questionicon1} key={que.id} onClick={() => onQuestionClick(que)} alt="" />
                                                                                    </div>

                                                                                </div>

                                                                            ))
                                                                        ) : (
                                                                            <div className="text-center">{t("ASKTHEEXPERT.NOQUEBUTTON")}</div>
                                                                        )}

                                                                    </Slider>



                                                                </div>
                                                            </div>
                                                        </div>


                                                        <div className="col-lg-12  mb-3 mobileblockscroll d-block d-sm-none maincatcustomer">
                                                            <div className="catstart clearfix" data-aostest="fade-up-bottom">
                                                                <div className="categoryfourset catcustomer">
                                                                    <div className="categoryonesetone">
                                                                        <div className="question-text textcenter">
                                                                            <h2>{t("ASKTHEEXPERT.QUESTIONHEADING")}<span>{t("ASKTHEEXPERT.QUESTIONHEADING-1")}</span></h2>
                                                                        </div>
                                                                    </div>
                                                                    <Slider {...Subcatslider}>
                                                                    {expertQuestions.length > 0 ? (
                                                                        expertQuestions.map(que => (
                                                                            <div className="main-question questionslider mainquestioncust">
                                                                                <div className="questionslider-text">
                                                                                    <p>{que.question}</p> </div>
                                                                                <div className="questiontext-icon">
                                                                                    <img src={questionicon1} key={que.id} onClick={() => onQuestionClick(que)} alt="" />
                                                                                </div>

                                                                            </div>

                                                                        ))
                                                                    ) : (
                                                                        <div>{t("ASKTHEEXPERT.NOQUEBUTTON")}</div>
                                                                    )}

</Slider>


                                                                </div>
                                                            </div>
                                                        </div>


                                                        <div className="col-lg-12 mb-3 queastio-main questioncust">
                                                            <div className="textarecs" data-aostest="fade-bottom">
                                                                <div className="question-text-right questioncustomer">
                                                                    <h2>{t("ASKTHEEXPERT.QUESTIONHEADING1")}<span className="question_span">{t("ASKTHEEXPERT.QUESTIONTEXT3")}</span></h2>
                                                                    <p className="question-right-ptext">{t("ASKTHEEXPERT.QUESTIONTEXT4")}{t("ASKTHEEXPERT.QUESTIONTEXTSUBTEXT4")}</p>
                                                                    <p className="question-right-text-too">{t("ASKTHEEXPERT.QUESTIONTEXT5")}</p>
                                                                </div>
                                                                <div className="input-container form-group m-2 ">
                                                                    <div className="input-question ">
                                                                        <textarea className="input form-control textareaform maincustomertextarea" value={questionAsked} name="message" onChange={onTextAreaChange} placeholder={t("ASKTHEEXPERT.PLEASETYPE")} maxLength={1000}></textarea>
                                                                       
                                                                    </div>
                                                                    <div className="nextbtn">
                                                                        <button className="nextbutton" disabled={!questionAsked?.length} onClick={onSubmitButtonClick}>{t("ASKTHEEXPERT.SUBMITBUTTON")}</button>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                   
                                                    </div>
                                                </div>
                                            </div>}


                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>  <Footer />
            </section>
            {/* confirm modal */}
            {showConfirmModal &&
           <section className="main_customer_dashboard">
                <div className="modalspecial asktheexpertconfirmpopup popupouter customerpopupmn">
                    
                        <div className="popupnew">
                            <div className="row">
                                <div className="cliparttext text-center">
                                <button className="close_btn" onClick={onConfirmAndSubmitButtonClick} ><img src={close_btn} alt={t("CUSTOMERINTEREST.CLOSEBUTTON")} /></button>
                                    <div className="clipartmain">
                                        <div className="cliparttextbox">
                                            <p className="text-categ text-center">{t("customerhome.ASKEXPERTPOPUPCONTENT")}</p>
                                        </div>
                                    </div>
                                    <a onClick={onConfirmAndSubmitButtonClick} className="showallourbesttoo">Continue</a>
                                </div>
                            </div>
                        </div>
                   
                    </div>
                    </section>
            }

    
          

        </>

    );
};

export default CustomerAskTheExpertJsx;