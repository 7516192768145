import React from 'react';
import styles from "./articlesCard.module.css";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import { NEW_URL } from '../../../constants/applicationConstants';
import left_arrow from "../../../assets/blogimages/Black_arrow.svg"
import { useHistory } from "react-router-dom";
import {
  decodeHtmlEntities
} from '../../../shared/helper.js'

export const ArticlesCardJSX = (props) => {
  const { t } = useTranslation();
  const {
    blogimage,
    blogtitle,
    blogshort_content,
    blogslug,
    blogcat,
    articleauthor,
    articledatepublish,
    blogauthor_image,
    blog,
    isSerchArticle
  } = props;

  const history = useHistory();
  

  return isSerchArticle ? <div className={`${[styles.articlecard + " " + 'smbloginnerps']} ${blogcat} `} 

  >
<Link to={{pathname: `/articleDetails/${blogslug}`}} target="_blank" className="pathpop">
<div className="d-flex upperstrip d-block d-lg-none d-sm-none d-md-none upperstripmobile">
          <div className="d-flex basicuserdetails">
          <div className="userimage">
          <img src={!blogauthor_image
            ? `https://ui-avatars.com/api/?name=${articleauthor}&rounded=true`
            : `${NEW_URL}/${blogauthor_image}`} alt={`${blogtitle}`} />
        </div>
            <div className="usertext">
            <div className="name">{articleauthor}</div>
          <div className="date">{articledatepublish}</div>
            </div>
          </div>

        </div>
  <div className="row">
    <div className="col-2"><div className={[styles.articlecardimg + " " + 'blog-image']}><img src={!blogimage
            ? `https://ui-avatars.com/api/?name=${articleauthor}&rounded=true`
            : `${blogimage}`} alt={blogtitle} /><div className="bloginnertag">{blogcat}</div></div>
    </div>
    <div className="col-10">
    
      <div className="blog-upperstrip blog-upperstriptrending">
      <div className={[styles.bloginnertext + " " + 'bloginnertext']}>{blogtitle}</div>
        <div className="d-flex upperstrip">
          <div className="d-flex basicuserdetails">
          <div className="userimage">
          <img src={!blogauthor_image
            ? `https://ui-avatars.com/api/?name=${articleauthor}&rounded=true`
            : `${NEW_URL}/${blogauthor_image}`} alt={`${blogtitle}`} />
        </div>
            <div className="usertext">
            <div className="name">{articleauthor}</div>
          <div className="date">{articledatepublish}</div>
            </div>
          </div>

        </div>
      </div>
      <p className={[styles.articlesubtitle + " " + 'bloginnertextfe']}>{decodeHtmlEntities(blogshort_content)}</p>
     
      <Link to={{pathname: `/articleDetails/${blogslug}`}} target="_blank" className="blogreadmore">{t("COMMON.READMORE")} <img src={left_arrow} alt={`${blogtitle}`} /></Link>
   
    </div>
    <div className="fmblog">
   <p className={[styles.articlesubtitle + " " + 'bloginnertextfe']}>{decodeHtmlEntities(blogshort_content)}</p>
     
     <Link to={{pathname: `/articleDetails/${blogslug}`}} target="_blank" className="blogreadmore">{t("COMMON.READMORE")} <img src={left_arrow} alt={`${blogtitle}`} /></Link>
   </div>
   <div className="bloginnertagnew">{blogcat}</div>
   </div></Link></div> : 
    <div className={[styles.bloginner + " " + styles.blogsmain + " " + styles['text-center'] + " " + 'nblogin']}>
      <div className={[styles.bloginnerimage + " " + 'blogimage']}><img className="blogs-user-image" src={!blogimage
            ? `https://ui-avatars.com/api/?name=${articleauthor}&rounded=true`
            : `${blogimage}`} alt={blogtitle} /><div className="bloginnertag">{blogcat}</div></div>
      <div className={[styles.bloginnertext + " " + 'bloginnertext']}>{blogtitle}</div>
      <div className={styles.bloginnerdate}><Link to={{pathname: `/articleDetails/${blogslug}`}} target="_blank">{decodeHtmlEntities(blogshort_content)}</Link></div>
      <div className="d-flex basicuserdetails">
        <div className="userimage">
          <img src={!blogauthor_image

            ? `https://ui-avatars.com/api/?name=${articleauthor}&rounded=true`
            : `${NEW_URL}/${blogauthor_image}`} alt={`${blogtitle}`} />
        </div>
        <div className="usertext">
          <div className="name">{articleauthor}</div>
          <div className="date">{articledatepublish}</div>
        </div>
      </div>
      <Link to={{pathname: `/articleDetails/${blogslug}`}} target="_blank" className="blogreadmore">{t("COMMON.READMORE")} <img src={left_arrow} alt={`${blog?.title}`} /></Link>


    </div>




}
