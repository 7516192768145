import axios from 'axios';
import React from 'react'
import { Component } from 'react';
import { ToastContainer,toast } from 'react-toastify';

import { baseURL } from '../../../../constants/applicationConstants.js';
import { allRegex, postalCodeRegex } from '../../../../shared/helper.js';
import { Loader } from '../../../common/loader/loader.jsx';
import CustomerAskTheExpertJsx from './expertQuery.jsx'

export default class AskTheExpert extends Component {
    constructor(props) {
        super(props);
        this.state = {
            categoryData: null,
            subCategoryData: null,
            formData: {
                name: '',
                category: '',
                subCategory: ''
            },
            formError: {},
            formSubmitButtonDisable: true,
            isLoading: false,
            expertQueryData: [],
            meta: {},
            page: 1,
        }
    }

    componentDidMount = () => {
    
        this.getCat()
    }

    getSegment = ()=>{
        this.setState({ isLoading: true })
        let token = JSON.parse(sessionStorage.getItem('token'));
        axios.get(`${baseURL}/instituteSegments`, {
            headers: { 'x-access-token': token }
        }).then(res => {
            this.setState({ subCategoryData: res.data.data, isLoading: false })
        })
    }

    onCatgoryClick = (value) => {
        // const subData = this.state.categoryData.filter(itm => itm.institutionCategoryId == value)
        // this.setState({ subCategoryData: subData[0]?.subInstitutionCategories })
    }

    onInputChange = (e) => {
        let name = e.target.name;
        let value = e.target.value;
        if (name === 'category') {
            this.onCatgoryClick(value)
            this.setState(
                {
                    formData: {
                        ...this.state.formData,
                        'subCategory': '',
                    }
                }
            )

        }


        if (value) {
            if (!this.inputRegexValidation(name, value)) {
                this.setState(
                    {
                        formData: {
                            ...this.state.formData,
                            [name]: value,
                        },
                        formError: {
                            ...this.state.formError,
                            [name]: "regx",
                        },
                    },
                    () => this.isFromSubmissionBlock()
                );
            } else {
                this.setState(
                    {
                        formData: {
                            ...this.state.formData,
                            [name]: value,
                        },
                        formError: {
                            ...this.state.formError,
                            [name]: "",
                        },
                    },
                    () => this.isFromSubmissionBlock()
                );
            }
        } else {
            this.setState(
                {
                    formData: {
                        ...this.state.formData,
                        [name]: value,
                    },
                    formError: {
                        ...this.state.formError,
                        [name]: "",
                    },
                },
                () => this.isFromSubmissionBlock()
            );
        }
    }

    inputRegexValidation = (name, value) => {
        if (name === 'name')
            return allRegex.test(value);
        if (name === 'postalCode')
            return postalCodeRegex.test(value);

        return true;
    };

    isFromSubmissionBlock = () => {
        let { name,category,subCategory } = this.state.formData;

        if (name && category && subCategory) {
            if (
                this.inputRegexValidation('name', name)
            ) {
                this.setState({
                    formSubmitButtonDisable: false
                });
            }
            else {
                this.setState({
                    formSubmitButtonDisable: true
                });
            }
        } else {
            this.setState({
                formSubmitButtonDisable: true
            });
        }
    };

    onTouchHandler = (e) => {
        let name = e.target.name;
        let value = e.target.value;

        if (name === 'category') {
            this.onCatgoryClick(value)
            this.setState(
                {
                    formData: {
                        ...this.state.formData,
                        'subCategory': '',
                    }
                }
            )

        }

        if (value === "") {
            this.setState({
                formError: {
                    ...this.state.formError,
                    [name]: "req",
                },
            });
        } else {
            if (!this.inputRegexValidation(name, value)) {
                this.setState({
                    formError: {
                        ...this.state.formError,
                        [name]: "regx",
                    },
                });
            }
        }
    };
    onPaginationButtonClick = (page) => {
        window.scrollTo(0, 0);
        this.setState({ page: page })
    }

    onSubmitButtonClick = () => {
        let token = sessionStorage.getItem("token") && JSON.parse(sessionStorage.getItem("token"))
        this.setState({ isLoading: true });
        const { name, category, subCategory } = this.state.formData;
        const payload = {
            "parentId": category,
            "institutionSegmentId": subCategory,
            "name": name
        }
        axios.post(`${baseURL}/admin/categories`, payload, {
            headers: { 'x-access-token': token }
          }).then(res => {
            this.setState({ isLoading: false })
            toast.success("Category added succuessfully!")
            setTimeout(() => {
                window.location.reload()

            }, 2000);
        }).catch(err => {
            this.setState({ isLoading: false })
            toast.error(err?.response.data.error.message);
        })
    }
    deleteOffer = (data) => {
        let token = JSON.parse(sessionStorage.getItem('token'));
        this.setState({ isLoading: true })
    
        axios.delete(`${baseURL}/admin/categories/${data}`, {
          headers: { 'x-access-token': token }
        }).then(res => {
          this.setState({
            isLoading: false
          })
          this.getCat();
        }).catch(err=>{
          this.setState({ isLoading: false })
        })
      }
      editCategory = (data) => {
        let token = JSON.parse(sessionStorage.getItem('token'));
        this.setState({ isLoading: true })
    
        axios.post(`${baseURL}/admin/categories/${data}`, {
          headers: { 'x-access-token': token }
        }).then(res => {
          this.setState({
            isLoading: false
          })
          this.getCat();
        }).catch(err=>{
          this.setState({ isLoading: false })
        })
      }
    getCat = () => {
        let token = sessionStorage.getItem("token") && JSON.parse(sessionStorage.getItem("token"))
        this.setState({ isLoading: true });
       
        axios.get(`${baseURL}/admin/ExpertSupport`, {
            headers: { 'x-access-token': token }
          }).then(res => {
                        this.setState({expertQueryData:res.data.data,isLoading:false});

            toast.success("Queries fetched succuessfully!")
           
        }).catch(err => {
            this.setState({ isLoading: false })
            toast.error(err?.response.data.error.message);
        })
    }


    onViewProfieClick = (url) => {
        this.props.history.push(`/expertPpv/${url}`)
    }

    render() {
        return (
            <div>
                <CustomerAskTheExpertJsx
                    categoryData={this.state.categoryData}
                    subCategoryData={this.state.subCategoryData}
                    formData={this.state.formData}
                    onInputChange={this.onInputChange}
                    formError={this.state.formError}
                    onTouchHandler={this.onTouchHandler}
                    formSubmitButtonDisable={this.state.formSubmitButtonDisable}
                    onSubmitButtonClick={this.onSubmitButtonClick}
                    expertQueryData={this.state.expertQueryData}
                    meta={this.state.meta}
                    deleteOffer={this.deleteOffer}
                    editCategory={this.editCategory}
                    onViewProfieClick={this.onViewProfieClick}
                    onPaginationButtonClick={this.onPaginationButtonClick}
                    page={this.state.page}
                />
                {this.state.isLoading && <Loader />}
                <ToastContainer />            </div>
        )
    }
}
