import React from 'react';
import { useTranslation } from "react-i18next";
import expiryclock from '../../../assets/images/expiryclock.svg';
import { NEW_URL } from '../../../constants/applicationConstants';
import styles from './offerCard.module.css';
import { Link } from "react-router-dom";

export const OfferCardJSX = (props) => {

  const { t } = useTranslation();
  const {
    offerimgurl,
    offerparentInstitutionCategory,
    offervalidTo,
    offerofferName,
    offerdescription,
    offerexternalUrl,
    isDescription
  } = props;

  return (
    <>
      <div className={`${styles['offer-inner']} ${offerparentInstitutionCategory} homepageofferblock carddecoration`}>
        <Link to={{ pathname: offerexternalUrl }} target="_blank"><div className={styles['list-image']}>
          <img src={`${NEW_URL}/${offerimgurl}`} alt={`${offerofferName}`} />
        </div></Link>
        {isDescription ?
          <div className={[styles.categotyinpop]}>
            <div className={[styles.categotyinpopfl + " " + 'd-flex']}>
              <div className={[styles.pscat + " " + 'pscatbottom']}>{offerparentInstitutionCategory}</div>
              <div className={[styles.pscat + " " + 'pscatbottom']}><span><img src={expiryclock} alt={"clock"} /> </span> {t("COMMON.EXPIRING")} : {offervalidTo}</div>
            </div>
            <div className={[styles.disfontsmall + " " + styles['list-name']]}> {offerofferName} </div>
            <div className={[styles.offerdes + " " + styles['list-des']]}>{offerdescription}</div>
            <div className={[styles.posbuttontf + " " + 'd-flex']}>

              <Link to={{ pathname: offerexternalUrl }} className={[styles.applynowbuttoncomm + " " + 'text-center' + " " + 'applybutton']} target="_blank">
                {t("COMMON.APPLYNOW")}
              </Link>
            </div>
          </div> : <div></div>
        }
      </div>
    </>
  )
}
