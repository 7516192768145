import React from 'react'

import Sidebar from "../sidebar/sidebar.js"; 
import { Footer } from '../../../common/footer/footer';
import AccountSetting from "../accountSetting/accountSetting.js"
import ManageUser from "../../dashboardCommon/manageUser/manageUser.js"
import ChangePassword from "../../../common/changePassword/changePassword.js"
import MyPlansJsxclass from '../../dashboardCommon/myPlane/myPlane';

import '../myAccount/myAccount.model.css';
import { useTranslation } from 'react-i18next';


import help from "../../../../assets/Instituteppvimages/help.svg";
import accountSetting from "../../../../assets/images/accountsettingdashboard.svg";
import changePassword from "../../../../assets/images/Change_password.svg";
import manageUser from "../../../../assets/images/asktheexpertdashboard.svg";
import myPlans from "../../../../assets/images/mypaymentdashboard.svg";


const MyAccountsJsx = (props) => {


  const {
    activeTab,
    onTabClick
  } = props;


  const data = JSON.parse(sessionStorage.getItem("auth"))
  const { t } = useTranslation();

  // {t("PRINT.TITLE")}
  return (
    <>
       <section className="main_institute_dashboard">
        <section class="entiredashboard maincustomerdash main institute_dashboard">
        <div className="container-fluid">
          <div className="row dashboard-decoration">
            <div className="col-lg-4 col-sm-4">
              <Sidebar />
            </div>
            <div className="col-lg-8 col-sm-8 dashboard-data">
              <div className="dashboardrightpanel">
              <div className="row" >
                <div className="col-11">
                  <p className="title">{t("instituteAccount.HEADING")}</p>
                  <p className="subTitle">{t("instituteAccount.SUBHEADING")}</p>
                  
                </div>
                <div class="col-1"  >
                  <div class="helpbutton">
                    <img class="helpImg" src={help} alt={''} />
                    <p className="logout ">{t("instituteAccount.HELP")} </p>
                  </div>
                </div>
               
              </div>
              <div className=" ">
                <section classNames=" ">
                  <div className="myaccountnav">
                    <ul>          
                       <li class={`${activeTab === 'acntStng' ? 'active' : ''}`} onClick={() => onTabClick('acntStng')}><img class={`${activeTab === 'acntStng' ? 'active' : ''}`} src={accountSetting} alt={''} />Account Settings</li>
                      {data.loggedInAs==='institute' && <li class={`${activeTab === 'mngUser' ? 'active' : ''}`} onClick={() => onTabClick('mngUser')}><img class={`${activeTab === 'mngUser' ? 'active' : ''}`} src={manageUser} alt={''} />Manage Users</li>}
                  <li class={`${activeTab === 'myPlans' ? 'active' : ''}`} onClick={() => onTabClick('myPlans')}><img class={`${activeTab === 'myPlans' ? 'active' : ''}`} src={myPlans} alt={''} />My Plans</li>
                  <li class={`${activeTab === 'newPass' ? 'active' : ''}`} onClick={() => onTabClick('newPass')}><img class={`${activeTab === 'newPass' ? 'active' : ''}`} src={changePassword} alt={''} />Change Password</li>

                    </ul>
                  </div>
                  {
                    activeTab === 'acntStng' ? <AccountSetting /> : activeTab === 'mngUser' ? <ManageUser /> : activeTab === 'newPass' ? <div className="accountchangepass"><h2 className="textchange">Password Creation</h2><ChangePassword /></div> : <MyPlansJsxclass />
                  }
                </section>
              </div>
            </div>
            </div>
          </div>
        </div>
      </section>
      <Footer/>
      </section>
    </>

  );
};

export default MyAccountsJsx;
