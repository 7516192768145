import React, { useState } from 'react'
import { Trans, useTranslation } from 'react-i18next';
import moment from 'moment';
import Sign_out_squre from "../../../../assets/images/Sign_out_squre.svg";
import { QuestionAnswerCardJSX } from '../../../common/questionAnswerCard/questionAnswerCard';

import { Footer } from '../../../common/footer/footer';
import Sidebar from "../sidebar/sidebar.js";
import { Link } from "react-router-dom";
import dropbox from "../../../../assets/images/dropbox.svg";
import { Pagination } from '../../../common/pagination/uiPagination.js';

const ExpertQnaJsx = (props) => {
  const { t } = useTranslation();
  const {
    giveAnswer, questionData, sendAnswer, onInputChangeHandler, answerData, openModal, isOpenModal, givenAnswer
    , onPaginationButtonClick, page } = props;
  const itemsPerPage = 10

  const [currentPage, setCurrentPage] = useState(1);

  const currentItems = questionData?.slice(
    (page - 1) * itemsPerPage,
    page * itemsPerPage
  );
  return (
    <>
      <section className="main_customer_dashboard">
        <section className="entiredashboard maincustomerdash expert-dashboard">
          <div className="container-fluid">
            <div className="row">
              <div className="col-lg-4 col-sm-4">
                <div className="dashboardleftpanel">
                  <div className="dashboardlinkpop">
                    <Sidebar />
                  </div>
                </div>
              </div>
              <div className="col-lg-8 col-sm-8">
                <div className="dashboardrightpanel martop40 mb-5">
                  <div className="dashboardrightpanelinner">
                    <div className="dashboardrightpannerinnerleft">
                      <h2 className="h2_style font-weight-600"><Trans i18nKey="expertDashboard.QNA_PENDING_HEADING" ></Trans></h2>
                      <h5 className="h5_style"><Trans i18nKey="expertDashboard.QNA_PENDING_TEXT" ></Trans></h5>
                    </div>
                    <div className="dashboardrightpannerinnerright">
                      <div className="signout">
                        <a href="#logout">
                          <img src={Sign_out_squre} alt={''} /><span className="title">Logout</span>
                        </a>
                      </div>
                      <div id="logout" className="modal-window">
                        <div>
                          <a title="Close" className="modal-close">Close</a>
                          <div className="modal-header">

                          </div><div className="modal-body mb-4">
                            <div className="modal-body-inner">
                              <h2 className="h2_style text-center">Are you sure you want to
Logout ?</h2>
                            </div>
                          </div>
                          <div className="modal-footer">
                            <button type="button" className="ctaonebutton"> Save</button>
                            <button type="button" className="ctatwobutton"> Save</button>
                          </div>
                        </div>
                      </div>
                    </div>

                    <div className="myexpertnav myaccountnav qnanav martop40">
                      <ul>

                        <li className="active" > <Link to={'/expertQna'}><Trans i18nKey="expertDashboard.QNATABONE" ></Trans><span>{questionData?.length}</span></Link></li>
                        <li><Link to={'/expertAnswer'}><Trans i18nKey="expertDashboard.QNATABTWO" ></Trans><span>{answerData?.length}</span></Link></li>
                      </ul>
                    </div>

                    <div className="questioninner mt-4">


                      {currentItems && currentItems.length ?
                        <>
                          {
                            currentItems?.map((data, index) => {
                              const globalIndex = (page - 1) * itemsPerPage + index; // Global index with page offset

                              return <div>
                                <QuestionAnswerCardJSX
                                  singleData={data}
                                  imgurl={data?.askExpertQuestionUserResponse?.icon}
                                  listexpertimageUrl={data?.expert?.imageUrl}
                                  listexpertfirstName={data?.expert?.firstName}
                                  listexpertlastName={data?.expert?.lastName}
                                  listfirstName={data?.expert?.firstName}
                                  listlastName={data?.expert?.firstName}
                                  listaskExpertQuestionUserResponsequestion={data.askExpertQuestionUserResponse.question}
                                  listanswer={data.answer}
                                  dataOpen={data.isOpen}
                                  questiondate={moment(data?.createdAt).format('MMM DD , YYYY')}
                                  giveAnswer={giveAnswer}
                                  index={globalIndex}
                                  questionData={questionData}
                                  sendAnswer={sendAnswer}
                                  onInputChangeHandler={onInputChangeHandler}
                                  answerData={answerData}
                                  openModal={openModal}
                                  isOpenModal={isOpenModal}
                                  givenAnswer={givenAnswer}
                                  listaskExpertCategory={data.askExpertQuestionUserResponse.parentInstitutionCategory?.replace(/\s+/g, '')}
                                  listaskExpertCategoryone={data.askExpertQuestionUserResponse.parentInstitutionCategory}

                                  isAnswer={false}
                                />
                              </div>
                             
                            })
                          }
                          <section className="newpagination"> <Pagination
                          currentPage={currentPage}
                          page={page}
                          allOffersData={questionData}
                          currentItems={currentItems}
                          onPaginationButtonClick={onPaginationButtonClick}
                          itemsPerPage={itemsPerPage}
                        /></section>
                        </>
                        :
                        <div className="dropdefault text-center">
                          <div className="dropdefaultimage">
                            <img src={dropbox} alt={''} />
                          </div>
                          <div className="dropdefaulttext">
                            <h5>You don't have any Questions. </h5>
                          </div>
                        </div>
                      }



                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section> <Footer />
      </section>
     

    </>

  );
};

export default ExpertQnaJsx;
