import React, { useState } from 'react';
import { HomeArticleCardJSX } from '../../common/homeArticleCard/homeArticleCard'
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";


export const ExpertBlogSection = (props) => {
  const { t } = useTranslation();
  const { profileData, legalName,blogData } = props;

  const [openModal, setOpenModal] = useState(false);
  const [blog, setBlog] = useState({});


  return (
    <section className="blogexpertppvsection">

      <div className="container">
        <h2>{t("PPVEXPERT.ARTICLETITLE")}</h2>
        <h4 className="mt-3 text-center">
          {t("PPVEXPERT.ARTICLESUBTITLE")}<br />
          {t("PPVEXPERT.ARTICLESUBSUBTITLE")}<br />
          <b>{t("PPVEXPERT.ARTICLELINETWO")}</b>
        </h4>


        <div className={`blogexpertinnernew item1 lengthblock${blogData?.body?.length}`}>


          {
            blogData?.body?.length > 0 &&
            blogData?.body?.map(blog => {
              return <HomeArticleCardJSX

                blogslug={blog?.slug}
                blogimage={blog?.image}
                blogtitle={blog?.title}
                blogshort_content={blog?.short_content}
              />
              {/* <Link to={`/articleDetails/${blog.slug}`}>
                   <div className="p_slider">
                  <div className="blog-image"><img src={blog.image} alt={' '} /></div>
                  
                  <div className="slidertext">
                    <h4>{blog.title}</h4>
                    <p>{blog.short_content}</p></div>
                </div>
                </Link> */}

            })
          }


        </div>
        <div className="showall  text-center">
          <Link
            to={{
              pathname: "/expertPpvBlog",
              state: { data: {legalName,name:profileData?.firstName+' '+profileData?.lastName,...profileData}, frompage: 'ppvAdsSection', pagePath: window.location.pathname,expertIDSlug: window.location.pathname.replace('/expertPpv/', '') }

            
            }}
            className="showallbtn"
            onClick={() => window.scrollTo(0, 0)}>
            {t("COMMON.SHOWALL")}
          </Link></div>
      </div>

      {
        openModal &&
        <div className="modalspecial blog-modal">
          <div>
            <div className="mybtn">
              <button type="button" className="myadbtn" onClick={() => { setOpenModal(false) }}><svg version="1.0" xmlns="http://www.w3.org/2000/svg"
                width="64.000000pt" height="64.000000pt" viewBox="0 0 64.000000 64.000000"
                preserveAspectRatio="xMidYMid meet">

                <g transform="translate(0.000000,64.000000) scale(0.100000,-0.100000)"
                  fill="#ffffff" stroke="none">
                  <path d="M156 484 c-9 -8 -16 -20 -16 -26 0 -5 28 -39 62 -74 l61 -64 -61 -64
c-34 -35 -62 -69 -62 -74 0 -14 28 -42 42 -42 5 0 39 28 74 62 l64 61 64 -61
c35 -34 69 -62 74 -62 14 0 42 28 42 42 0 5 -28 39 -62 74 l-61 64 61 64 c34
35 62 69 62 74 0 14 -28 42 -42 42 -5 0 -39 -28 -74 -62 l-64 -61 -64 61 c-35
34 -69 62 -74 62 -6 0 -18 -7 -26 -16z"/>
                </g>
              </svg></button>
            </div>
            <div className="modal-header text-center">
              <h3>{blog?.title}</h3>
            </div>
            <div className="modal-body text-center">
              <div className="blog-image-dv"><img src={blog.image} alt={' '} />
              </div>
              <div className="blog-content-dv" dangerouslySetInnerHTML={{ __html: blog.content }}></div>
            </div>
          </div>
        </div>

      }

    </section>

  )
}