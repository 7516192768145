import React, { useState } from 'react';
import { Link } from 'react-router-dom/cjs/react-router-dom.min';

import styles from "./productCardList.module.css"
import { useTranslation } from "react-i18next";
import { ProductCard } from "../productCard/productCard.jsx"
import Slider from "react-slick";
import 'bootstrap/dist/css/bootstrap.min.css';
import SubCategoryCardList from '../subCategoryCardList/subCategoryCardList';
import dropbox from "../../../assets/images/dropbox.svg";

export const ProductCardList = (props) => {
    const { t } = useTranslation();

    const {

        showMoreProduct,
        selecteddProductCat,
        getProductData,
        getProductById,
        topcatimgicon1,
        featureProduct,
        setShowMoreProduct,
        onProductClick,

        productData,
        productTitle,
        productsubTitleText,
        productsubTitleTextTwo,
        subCategoryLayout,
        searchText,
        category,
        viewbuttonURL,
        isCategorySubCategory,
        isProductName,
        isViewButton, instituteID

    } = props;

    const hitShowALL = () => {
        instituteID && sessionStorage.setItem("selectCategory",instituteID)
      }




    var productsection = {
        infinite: false,
        arrows:true,
        speed: 500,
        slidesToShow: 2,
        adaptiveHeight: true,
        slidesToScroll: 1,
        dots: false,
        responsive: [
            {
                breakpoint: 1024,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1,
                    infinite: false,
                    dots: false
                }
            },
            {
                breakpoint: 600,
                settings: {
                    dots: false,
                    slidesToShow: 1,
                }
            },
            {
                breakpoint: 480,
                settings: {
                    dots: false,
                    slidesToShow: 1,

                }
            },
            {
                breakpoint: 320,
                settings: {
                    dots: false,
                    slidesToShow: 1,
                }
            }
        ]


    };
 
  

    return (
        subCategoryLayout ?
            <section className={`categorysectiontwo ${category}-bgimg`}>
                <div className="TopRealEstateProducts-inner">
                    <h2>{productTitle}</h2>
                    <h6 >{productsubTitleText}<br /> {productsubTitleTextTwo}</h6>
                </div>
                <div  >
                    <div className="row offersss">
                        {productData?.length > 0 ?
                            <Slider {...productsection}>
                                {
                                    productData?.map(product => {
                                        return <ProductCard
                                            product={product}
                                             viewbuttonURL={(e) => { viewbuttonURL({ data: product, category: product?.parentInstitutionCategory, subCategory: product?.subInstitutionCategory?.name, frompage: 'realestateProduct', pagePath: '/products' }) }}

                                            isCategorySubCategory={isCategorySubCategory}
                                            isProductName={isProductName}
                                            isViewButton={isViewButton}
                                        /> 
                                    })
                                }
                            </Slider>
                            :
                            <div className="dropdefault text-center">
                                <div className="dropdefaultimage">
                                    <img src={dropbox} alt="" />
                                </div>
                                <div className="dropdefaulttext">
                                    <h5 className="opacity0">{t("COMMON.NORECORDFOUND")}</h5>
                                </div>
                            </div>
                        }

                    </div>
                    <div className="text-center" onClick={() => hitShowALL()}>
                        <Link to={'/products'} className={`${styles.topproductshowallbtn}`}>{t("COMMON.SHOWALL")}</Link>
                    </div>
                </div>

            </section>
            :

            <section className={["topproduct"]}>
                <div className="container">
                    {!getProductById ? <div className={styles["topproductheading-text"]}> <h2>{searchText}</h2> </div> 
                    : 
                    <div className={styles["topproduct-text"]}>
                        <h2>{productTitle}</h2>
                        <p className="mb-0">{productsubTitleText}</p>
                    </div>}

                    <div className={[styles["exclusive-slider"] + " " + styles.prodectsslider]}>
                        {productData?.length > 0 &&
                            <Slider {...productsection}>
                                {
                                    productData?.map(product => {
                                        return <ProductCard
                                            product={product}
                                            viewbuttonURL={viewbuttonURL}
                                            isCategorySubCategory={isCategorySubCategory}
                                            isProductName={isProductName}
                                            isViewButton={isViewButton} 
                                            onProductClick={onProductClick}/>
                                    })
                                }
                            </Slider>
                        }
                    </div>
                    <div className="text-center">
                        <Link to={'/products'} className={`${styles.topproductshowallbtn} topprbtn`}>{t("COMMON.SHOWALL")}</Link>
                    </div>
                    {getProductById ? <SubCategoryCardList
                        showMoreData={showMoreProduct}
                        selectedData={selecteddProductCat}
                        getData={getProductData}
                        getById={getProductById}
                        cat1={topcatimgicon1}
                        feature={featureProduct}
                        setShowMore={setShowMoreProduct}
                    /> : <div></div>}

                   
                </div>
            </section>)
}

export default ProductCardList;