import React, { useState } from 'react'
import { useTranslation } from "react-i18next";
import moment from "moment";
import { useEffect } from 'react';
import { OfferCardJSX } from '../../common/offerCard/offerCard';
import { Footer } from '../../common/footer/footer';
import { Link } from 'react-router-dom/cjs/react-router-dom.min';
import Vector2 from "../../../assets/offersimage/Vector2.svg";
import Slider from "react-slick";
import AOS from 'aos';
import 'aos/dist/aos.css';
import { NEW_URL } from '../../../constants/applicationConstants';
import Filter from '../../../shared/filter.jsx';
import { sliderSettingOne } from "../../../shared/sliderSetting.js"
import "../offers/offers.css";
import "../offers/offerresponsive.css";
import { useLocation } from 'react-router-dom/cjs/react-router-dom.min';
import { Pagination } from '../../common/pagination/uiPagination.js';
import newest from "../../../assets/images/newest.svg";
import expire_svg from "../../../assets/images/expire_svg.svg";

const OffersJsx = (props) => {
  const { t } = useTranslation();
  const { pathname } = useLocation()

  const {
    categoryData, hitSearch,
    allOffersData,
    hitOldest,
    oldest,
    selectAll,
    selectAllHit,
    topOffers,
    getAllFeauturedOffersData,
    selectCategory,
    selectSubCategory,
    subCategories,
    categories,
    searchOfferByKeyword, onPaginationButtonClick, page,disableSelectAll
  } = props;
  const itemsPerPage = 10

  const [currentPage, setCurrentPage] = useState(1);
  const [filterrMenuOpen, setFilterrMenuOpen] = useState(false);
  const [sortMenuOpen, setSortMenuOpen] = useState(false);
  const [isMobile, setIsMobile] = useState(false);

  const currentItems = allOffersData?.slice(
    (page - 1) * itemsPerPage,
    page * itemsPerPage
  );

  const checkMobileView = () => {
    setIsMobile(window.innerWidth <= 767);
  };

  // Set up window resize listener to check if in mobile view
  useEffect(() => {
    checkMobileView(); // Initial check
    window.addEventListener('resize', checkMobileView); // Listen for window resize

    return () => {
      window.removeEventListener('resize', checkMobileView); // Clean up event listener
    };
  }, []);

  // useEffect(() => {
  //   getAllFeauturedOffersData()
  // }, [pathname])



  useEffect(() => {
    AOS.init();
    window.scrollTo(0, 0);
  }, [])

  const getProperData = (date) => {
    if (date) {
      const date2 = date?.split('T');
      const date1 = date2[0]?.split('-');
      return `${date1[2]}/${date1[1]}/${date1[0]}`
    }
  }

  return (
    <>
      <section className="container-rimib newofferdesign new-offer-design">
        <section className="herosectionoffer">

          <div className="container">
            <div className="row w-100">
              <div className="col-lg-5 col-sm-5">
                <div className="offerherobannertext">
                  <h2><span>{t("OFFER.BANNERTEXT1")}</span></h2>
                  <h2>{t("OFFER.BANNERTEXT2")}</h2>
                  <h5>{t("OFFER.BANNERTEXT3")}</h5>
                </div>
              </div>
              <div className="col-lg-7 col-sm-7">
                <div className="vectorone vector-img"><img src={Vector2} alt='' /></div>
                <div className="offerbannerslider">
                  <Slider {...sliderSettingOne}>
                    {
                      topOffers?.map(offer => {
                        return <div className="sliderContent slider-image">
                          <Link to={{ pathname: offer.externalUrl ?? '#' }} target="_blank">
                            <img className="d-block w-100" src={`${NEW_URL}/${offer.imageUrl}`} alt="offer-image" />
                          </Link>
                        </div>
                      })
                    }
                  </Slider>
                </div></div>
            </div>
          </div>
        </section>

        <section className="offersearch">
          <div className="container">
            <div className="customsearchblock">
              <input type="search" value={props.keyword} placeholder="" onChange={searchOfferByKeyword} />
              <button className="headersearch" disabled={!props?.keyword?.length} onClick={() => hitSearch()}>{t("COMMON.SEARCH")}</button>
            </div>
            <h2>{t("OFFER.SEARCHLINEONE")}</h2>
            <h4>{t("OFFER.SEARCHLINETWO")}</h4>
          </div>
        </section>


        <section className="limitedoffer">
          <div className="container">

            {/* mobile section */}
            {isMobile ? <div className="mobile-filter sortingdiv">
            <div className="mainofferdetails">
                <h2>{t("OFFER.TITLE")}</h2>
                <h5>{t("OFFER.SUBTITLEONE")}</h5>
                <h5><b>{t("OFFER.SUBTITLETWO")}</b></h5>
              </div>
              <div className="container">
                <div className="d-flex mobiledrop">
                  <div class="dropdown dropfilter">
                    <button class="btn btn-secondary dropdown-toggle" type="button" id="dropdownFilter" onClick={() => { setSortMenuOpen(!sortMenuOpen); setFilterrMenuOpen(false) }}>
                      {t("COMMON.SORTBY")}
                    </button>
                    {sortMenuOpen &&
                      <div class="dropdown-menu-open" aria-labelledby="dropdownFilter">
                        <ul>
                          <li className={!oldest ? "active" : ""}><a onClick={() => hitOldest(false)}>
                            <div className="form-check">
                              <input className="form-check-input" type="checkbox" checked={!oldest} value="" id="Checkme1" /><span className="subcatimage"><img src={newest} /></span>  {t("COMMON.NEWEST")}</div></a></li>
                          <li className={oldest ? "active" : ""}><a onClick={() => hitOldest(true)}>
                            <div className="form-check">
                              <input className="form-check-input" type="checkbox" checked={oldest} value="" id="Checkme1" /><span className="subcatimage"><img src={expire_svg} /></span>{t("COMMON.EXPIRINGSHORTLY")}</div></a></li>
                          {/* <a class="dropdown-item" href="#">Action</a> */}
                        </ul>
                      </div>
                    }
                  </div>
                  <div class="dropshortby">
                    <button class="btn btn-secondary dropdown-toggle" onClick={() => { setFilterrMenuOpen(!filterrMenuOpen); setSortMenuOpen(false) }} type="button" id="dropdownShortBy">
                      {t("COMMON.FILTER")}
                    </button>
                    <div class={`${filterrMenuOpen === true ? 'open-active' : ''} filter-wrap`} aria-labelledby="dropdownShortBy">
                      <Filter
                        categoryData={categoryData}
                        selectAll={selectAll}
                        selectAllHit={selectAllHit}
                        selectCategory={selectCategory}
                        selectSubCategory={selectSubCategory}
                        subCategories={subCategories}
                        categories={categories}
                        disableSelectAll={disableSelectAll}

                      />
                    </div>
                  </div>
                </div>
              </div>
            </div> : <>
              <div className="mainofferdetails">
                <h2>{t("OFFER.TITLE")}</h2>
                <h5>{t("OFFER.SUBTITLEONE")}</h5>
                <h5><b>{t("OFFER.SUBTITLETWO")}</b></h5>

                <Filter
                  categoryData={categoryData}
                  selectAll={selectAll}
                  selectAllHit={selectAllHit}
                  getAllFeauturedOffersData={getAllFeauturedOffersData}
                  selectCategory={selectCategory}
                  selectSubCategory={selectSubCategory}
                  subCategories={subCategories}
                  categories={categories}
                  disableSelectAll={disableSelectAll}
                />
              </div>

              <section className="sortingdiv">
                <div className="container">
                  <span className="shortby">{t("COMMON.SORTBY")}</span>
                  <ul>
                    <li className={!oldest ? "active" : ""}><a onClick={() => hitOldest(false)}> {t("COMMON.NEWEST")}</a></li>
                    <li className={oldest ? "active" : ""}><a onClick={() => hitOldest(true)}> {t("COMMON.EXPIRINGSHORTLY")}</a></li>
                  </ul>
                </div>
              </section>
            </>}
            {allOffersData?.length > 0 ? <div className="limitedofferinner limited-offers">


              <div className="offerslider martop40 mb-5">
                <div className="row">

                  {
                    currentItems?.map(offer => {
                      return <div className="col-lg-6 col-sm-6">
                        <OfferCardJSX
                          offerimgurl={offer?.imageUrl}
                          offerparentInstitutionCategory={offer?.parentInstitutionCategory ?? offer?.institutionCategoryName}
                          offervalidTo={moment(offer?.validTo).format('Do MMM YY')}
                          offerofferName={offer?.offerName}
                          offerdescription={offer?.description}
                          offerexternalUrl={offer?.externalUrl}
                          isDescription={true}
                        />
                      </div>
                    })
                  }

                </div>
              </div>
            </div> : <h4 className="text-center">No results for your search</h4>}

          </div>
        </section>
        {allOffersData?.length > 0 && <section className="newpagination" > <Pagination
          currentPage={currentPage}
          page={page}
          allOffersData={allOffersData}
          currentItems={currentItems}
          onPaginationButtonClick={onPaginationButtonClick}
          itemsPerPage={itemsPerPage}
        /></section>}

      </section>
      <Footer />
    </>

  );
};

export default OffersJsx;
