import React, { Component, Suspense } from "react";
import Login from "./components/common/login/login.js";
import OpsLogin from "./components/login/opsLogin/login.js";
import OpsLoginPass from "./components/login/opsLoginPass/login.js";

import Home from "./components/homePage/home/home.js";
import Insurance from "./components/homePage/insurance/insurance.js";
import Mortgage from "./components/homePage/mortgage/mortgage.js";
import RealEstate from "./components/homePage/realEstate/realEstate.js";
import Investment from "./components/homePage/investment/investment.js";
import Banking from "./components/homePage/banking/banking.js";

import GlobalMainSearch from "./components/homePage/globalSearch/globalMainSearch/globalMainSearch.js";
import GlobalMainSearchExpert from "./components/homePage/globalSearch/globalMainSearchExpert/globalMainSearchExpert.js";
import GlobalMainSearchInstitutions from "./components/homePage/globalSearch/globalMainSearchInstitutions/globalMainSearchInstitutions.js";
import GlobalMainSearchArticles from "./components/homePage/globalSearch/globalMainSearchArticles/globalMainSearchArticles.js";

import Offers from "./components/homePage/offers/offers.js";
import Products from "./components/homePage/products/products.js"
import Articles from "./components/homePage/articles/articles.js";

import AskTheExpert from "./components/homePage/askTheExpert/askTheExpert.js";
import ConfirmAskTheExpert from "./components/homePage/askTheExpert/confirmAskTheExpert/confirmAskTheExpert.js"
import FinalAskTheExpertScreen from "./components/homePage/askTheExpert/finalAsktheExpertScreen/finalAskTheExpertScreen.js"
import {Loader} from "./components/common/loader/loader.jsx"
import {OtpLogin} from "./components/common/otpLogin/otpLogin.js"

import PasswordAuth from "./components/common/passwordAuthorization/passwordAuthorization.js";
import ForgotPassword from "./components/common/forgotPassword/forgotPassword.js";
import OtpGeneratain from "./components/common/otpGeneration/otpGeneration.js";
import OtpAuthentication from "./components/common/otpAuthentication/otpAuthentication.js";
import NewPassword from "./components/common/newPassword/newPassword.js";
import SaveNewPassword from "./components/common/saveNewPassword/newPassword.js";
import Unregistered from "./components/dashboard/dashboardCommon/myExpertUn/myExpert.js"
import PasswordSuccess from "./components/common/passwordSuccess/passwordSuccess.js";
import AddCard from "./components/common/Paymentcard/addCard/addCard.js" 
import PreviewCard from "./components/common/Paymentcard/previewCard/previewCard.js"
import dashBoard from "./components/dashboard/institute/dashBoard/dashBoard.js"
//import customerDashboard from "./components/dashboard/customer/customerDashboardHome/customerDashboardHome.jsx"

import myExpert from "./components/dashboard/dashboardCommon/myExpert/myExpert.js"
import myBlog from "./components/dashboard/dashboardCommon/myBlogs/blogs.js"
import myWorkspace from "./components/dashboard/institute/myWorkspace/myWorkspace.js"
import ExpertAds from "./components/dashboard/expert/expertAds/expertAds.js"
import MyListings from "./components/dashboard/dashboardCommon/myListing/myListing.js"
import MyServices from "./components/dashboard/dashboardCommon/myServices/myServices.js"
import ManageUser from "./components/dashboard/dashboardCommon/manageUser/manageUser.js"
import myPlan from "./components/dashboard/dashboardCommon/myPlane/myPlane.js"
import changePlane from "./components/dashboard/dashboardCommon/myPayment/changePlane/changePlane.js"
import myAccounts from "./components/dashboard/institute/myAccount/myAccount.js"
import expertAccounts from "./components/dashboard/expert/myAccount/myAccount.js"
import customerAccounts from "./components/dashboard/customer/myAccount/myAccount.js"
import askTheExpertQuestionListCustomer from "./components/dashboard/opsDashboard/askTheExpertQuestionListCustomer/askTheExpertQuestionListCustomer.js"
import createAsktheExpertQuestion from "./components/dashboard/opsDashboard/createAsktheExpertQuestion/createAsktheExpertQuestion.js"
import accountSetting from "./components/dashboard/institute/accountSetting/accountSetting.js"
import myPayment from "./components/dashboard/dashboardCommon/myPayment/myPayment.js"
import expertPayment from "./components/dashboard/expert/expertPayment/myPayment.js"
import expertOnboarding from "./components/registration/expert/expertOnboarding/expertOnboarding.js"
import expertRegistration from "./components/registration/expert/expertRegistration/expertRegistration.js"
import expertDashboardHome from "./components/dashboard/expert/expertDashboardHome/expertDashboardHome.js"
import opsDashboardHome from "./components/dashboard/opsDashboard/opsDashboardHome/expertDashboardHome.js"
import opsOffers from "./components/dashboard/opsDashboard/offers/offers.js"
import opsProducts from "./components/dashboard/opsDashboard/products/products.js"
import opsAdvertisements from "./components/dashboard/opsDashboard/advertisements/advertisements.js"

import opsPayment from "./components/dashboard/opsDashboard/opsPayment/opsPayment.js"
import manageExpert from "./components/dashboard/opsDashboard/manageExpert/manageExpert.js"
import ManageInstitute from "./components/dashboard/opsDashboard/manageInstitute/manageInstitute.js"
import ManageUnverifiedExpert from "./components/dashboard/opsDashboard/manageUnverifiedExpert/manageUnverifiedExpert.js"
import ManageUnverifiedInstitute from "./components/dashboard/opsDashboard/manageUnverifiedInstitute/manageUnverifiedInstitute.js"
import TermsCondition from "./components/homePage/termsCondition/termsCondition.js"
import PrivacyPolicy from "./components/homePage/privacyPolicy/privacyPolicy.js"
import expertQna from "./components/dashboard/expert/expertQna/expertQna.js"
import expertAnswer from "./components/dashboard/expert/expertAnswer/expertAnswer.js"
import expertQnaAnswer from "./components/dashboard/expert/expertQnaAnswer/expertQnaAnswer.js"
import expertBlog from "./components/dashboard/expert/expertBlog/expertBlog.js"
import customerBlog from "./components/dashboard/customer/customerBlog/customerBlog.jsx"

import expertPpv from "./components/publicProfileView/expertPpv/expertPpv.js"
import expertPpvBlog from "./components/publicProfileView/expertPpv/expertPpvBlog/expertPpvBlog.js"
import customerQnaAnswer from "./components/dashboard/customer/customerQnaAnswer/customerQnaAnswer.js"
// import suggestedOffer from "./components/dashboard/customer/suggestedOffers/suggestedOffer.js";
import custOffer from "./components/dashboard/customer/custOffers/custOffers.js";
import custProduct from "./components/dashboard/customer/custProducts/custProducts.js";
import custAdd from "./components/dashboard/customer/custAdds/custAdds.js";


import customerQna from "./components/dashboard/customer/customerQna/customerQna.js"
import PasswordCreation from "./components/common/passwordCreation/passwordCreation.js";
import Plans from "./components/registration/registrationCommon/plans/plans.js";
import ExpertPlans from "./components/registration/registrationCommon/plansExpert/plans.js";
import PlansSummary from "./components/registration/registrationCommon/plansSummary/plansSummary.js";
import PublicProfileView from "./components/publicProfileView/institute/publicProfileView.js"
import ListOfOffer from "./components/publicProfileView/ppvCommon/listOfOffer/listOfOffer.js"
import ListOfAd from "./components/publicProfileView/ppvCommon/listOfAd/listOfAd.js"
import DetailedProduct from "./components/common/detailedProduct/detailedProduct.js"
import ListOfProduct from "./components/publicProfileView/ppvCommon/listOfProduct/listOfProduct.js"
import PendingQuestion from "./components/ops/pendingQuestion/pendingQuestion.js"
import AnswerQuestion from "./components/ops/answerQuestion/answerQuestion.js"
import InstitutionalRegistration from "./components/registration/institute/institutionalRegistration.js";
import CustomerRegistration from "./components/registration/customer/customerRegistration.js";
import { BrowserRouter as Router, Route, Switch } from "react-router-dom";
import { withTranslation } from "react-i18next";
import { LayoutContainer } from "./components/LayoutContainer";
import "./app.css";

import "../src/assets/stylesheet/desktop.css";
import "../src/assets/stylesheet/responsive.css";
import PaymentSummery from "./components/registration/registrationCommon/paymentSummery/paymentSummery.js";
import Institutes from "./components/dashboard/opsDashboard/institutes/institutes.js";
import Experts from "./components/dashboard/opsDashboard/experts/experts.js";
import { SpecialInformationFlow } from "./components/registration/expert/specialInformation/specialInformationFlow.js";
import CustomerDashboard from './components/dashboard/customer/customerDashboard/customerDashboard.js';
import CustomerInterest from './components/dashboard/customer/customerInterest/interest.js';

import AdminCategory from "./components/dashboard/opsDashboard/adminCategory/adminCategory.js";
import CustomerQuery from "./components/dashboard/opsDashboard/customerQuery/customerQuery.js";
import ExpertQuery from "./components/dashboard/opsDashboard/expertQuery/expertQuery.js";



// admin ask the expert
import AdminAskTheExpert from "./components/dashboard/opsDashboard/adminAskTheExpert/adminAskTheExpert.js";
import SponsorAdd from "./components/dashboard/opsDashboard/sponsorAdds/adminAskTheExpert.js";

import AdminAskTheExpertEdit from "./components/dashboard/opsDashboard/adminAskTheExpertEdit/adminAskTheExpert.js";

import AdminAskTheExpertSearch from "./components/dashboard/opsDashboard/adminAskTheExpertSearch/adminAskTheExpertSearch.js";
import SponsorAddList from "./components/dashboard/opsDashboard/sponsorAddsList/adminAskTheExpert.js";
import AdminTrackPayment from "./components/dashboard/opsDashboard/adminPaymentTrack/adminPaymentTrack.js";

import AdminAllPlans from "./components/dashboard/opsDashboard/adminAllPlans/adminAllPlans.js";
import AdminAddPlans from "./components/dashboard/opsDashboard/adminAddPlan/adminAddPlan.js";


// 

import CustomerAskTheExpert from "./components/dashboard/customer/customerAskTheExpert/customerAskTheExpert.js";
import SuggestedOffer from "./components/dashboard/customer/suggestedOffers/suggestedOffer.js";
import ConfirmCustomerAskTheExpert from './components/dashboard/customer/customerAskTheExpert/confirmAskTheExpert/confirmAskTheExpert.js';
import FinalCustomerAskTheExpertScreen from './components/dashboard/customer/customerAskTheExpert/finalAsktheExpertScreen/finalAskTheExpertScreen.js';
import CustomerBlog from './components/dashboard/customer/customerBlogs/customerBlogs.js';
import Role from './components/dashboard/opsDashboard/roleManage/roleManage.js'
import articleDetails from './components/homePage/articles/articleDetails/articleDetails.js'
import ListOfArticles from './components/publicProfileView/ppvCommon/listOfArticles/listOfArticles.js'  
import ListOfExpert from "./components/publicProfileView/ppvCommon/listOfExpert/listOfExpert.js";

class LegacyComponent extends Component {
  render() {
    return (
        <Switch>
          <div>
            <Route exact path="/" component={Home} />
            <Route exact path="/login/:type?" component={Login} />
            <Route exact path="/admin/role" component={Role} />

            <Route exact path="/admin" component={OpsLogin} />
            <Route exact path="/admin/opsLoginPass" component={OpsLoginPass} />
            <Route exact path="/termsCondition" component={TermsCondition} />
            <Route exact path="/privacyPolicy" component={PrivacyPolicy} />

            <Route exact path="/institutionalRegistration" component={InstitutionalRegistration} />
            <Route exact path="/customerRegistration" component={CustomerRegistration} />

            <Route exact path="/home" component={Home} />
            <Route exact path="/insurance" component={Insurance} />
            <Route exact path="/mortgage" component={Mortgage} />
            <Route exact path="/realEstate" component={RealEstate} />
            <Route exact path="/investment" component={Investment} />
            <Route exact path="/banking" component={Banking} />
            
            <Route exact path="/globalMainSearch" component={GlobalMainSearch} />
            <Route exact path="/globalMainSearchExpert" component={GlobalMainSearchExpert} />
            <Route exact path="/globalMainSearchInstitutions" component={GlobalMainSearchInstitutions} />
            
            <Route exact path="/searchArticles" component={GlobalMainSearchArticles} />
            
            <Route exact path="/askTheExpert" component={AskTheExpert} />
            <Route exact path="/confirmAskTheExpert" component={ConfirmAskTheExpert} />
            <Route exact path="/confirmAndSubmitAskTheExpert" component={FinalAskTheExpertScreen} />
            <Route exact path="/customerAskTheExpert" component={CustomerAskTheExpert} />
            <Route exact path="/suggestedOffer" component={SuggestedOffer} />
            <Route exact path="/admin/adminAskTheExpert" component={AdminAskTheExpert} />
            <Route exact path="/admin/sponsorAdds" component={SponsorAdd} />

            <Route exact path="/admin/adminCategory" component={AdminCategory} />
            <Route exact path="/admin/customerQuery" component={CustomerQuery} />
            <Route exact path="/admin/expertQuery" component={ExpertQuery} />


            
            <Route exact path="/admin/adminAskTheExpertEdit/:id" component={AdminAskTheExpertEdit} />
            <Route exact path="/admin/adminAllPlans" component={AdminAllPlans} />
            <Route exact path="/admin/AdminAddPlans" component={AdminAddPlans} />


            <Route exact path="/admin/searchQuestion" component={AdminAskTheExpertSearch} />
            <Route exact path="/admin/trackPayment" component={AdminTrackPayment} />

            <Route exact path="/admin/sponsorAddsList" component={SponsorAddList} />



            <Route exact path="/confirmCustomerAskTheExpert" component={ConfirmCustomerAskTheExpert} />
            <Route exact path="/confirmAndSubmitCustomerAskTheExpert" component={FinalCustomerAskTheExpertScreen} />

           

            <Route exact path="/customerBlogs" component={CustomerBlog} />
            <Route exact path="/offers/:type?/:keyword?" component={Offers} />
            <Route exact path="/articles" component={Articles} />
            
            <Route exact path="/products/:keyword?" component={Products} />
            
            <Route exact path="/passwordAuth" component={PasswordAuth} />
            <Route exact path="/forgotPassword" component={ForgotPassword} />
            <Route exact path="/otpGeneration" component={OtpGeneratain} />
            <Route exact path="/otpAuthentication" component={OtpAuthentication} />
            <Route exact path="/newPassword" component={NewPassword} />
            <Route exact path="/saveNewPassword" component={SaveNewPassword} />

            <Route exact path="/passwordSuccess" component={PasswordSuccess} />
            <Route exact path="/dashBoard" component={dashBoard} />
            {/* <Route exact path="/customerDashBoard" component={customerDashboard} /> */}

            <Route exact path="/myExpert" component={myExpert} />
            <Route exact path="/Unregistered" component={Unregistered} />

            <Route exact path="/myBlog" component={myBlog} />
            <Route exact path="/myWorkspace" component={myWorkspace} />
            {/* <Route exact path="/products" component={products} />
            <Route exact path="/myOffer" component={MyOffer} /> */}
            <Route exact path="/myAds" component={ExpertAds} />
            <Route exact path="/myListings" component={MyListings} />
            <Route exact path="/myServices" component={MyServices} />
            <Route exact path="/myAccounts" component={myAccounts} />
            <Route exact path="/expertAccounts" component={expertAccounts} />
            <Route exact path="/customerAccounts" component={customerAccounts} />
            <Route exact path="/asktheexpertquestionlist" component={askTheExpertQuestionListCustomer} />
            <Route exact path="/createasktheexpertquestion" component={createAsktheExpertQuestion} />

            <Route exact path="/accountSettings" component={accountSetting} />
            <Route exact path="/myPayment" component={myPayment} />
            <Route exact path="/expertPayment" component={expertPayment} />
            <Route exact path="/expertOnboardings" component={expertOnboarding} />
            <Route exact path="/expertRegistration" component={expertRegistration} />
            <Route exact path="/specialInformations" component={SpecialInformationFlow} />
            <Route exact path="/myPlan" component={myPlan} />
            <Route exact path="/changeplan" component={changePlane} />
            <Route exact path="/detailedProduct" component={DetailedProduct} />
            <Route exact path="/listOfProduct" component={ListOfProduct} />
            <Route exact path="/expertDashboardHome" component={expertDashboardHome} />
            <Route exact path="/admin/opsDashboardHome" component={opsDashboardHome} />
            <Route exact path="/admin/offers" component={opsOffers} />
            <Route exact path="/admin/institutes" component={Institutes} />
            <Route exact path="/admin/experts" component={Experts} />
            <Route exact path="/admin/products" component={opsProducts} />
            <Route exact path="/admin/advertisements" component={opsAdvertisements} />

            <Route exact path="/admin/opsPayment" component={opsPayment} />
            <Route exact path="/admin/manageExpert" component={manageExpert} />
            <Route exact path="/admin/manageInstitute" component={ManageInstitute} />
            <Route exact path="/admin/manageUnverifiedExpert" component={ManageUnverifiedExpert} />
            <Route exact path="/admin/manageUnverifiedInstitute" component={ManageUnverifiedInstitute} />
            <Route exact path="/expertQna" component={expertQna} />
            <Route exact path="/expertAnswer" component={expertAnswer} />
            <Route exact path="/expertQnaAnswer" component={expertQnaAnswer} />
            <Route exact path="/customerQnaAnswer" component={customerQnaAnswer} />
            {/* <Route exact path="/suggestedOffer" component={suggestedOffer} /> */}
            <Route exact path="/custOffer" component={custOffer} />
            <Route exact path="/custProduct" component={custProduct} />
            <Route exact path="/custAdd" component={custAdd} />


            <Route exact path="/customerQna" component={customerQna}/>
            <Route exact path="/expertBlog" component={expertBlog} />
            <Route exact path="/customerBlog" component={customerBlog} />

            <Route exact path="/expertPpv/:legalName?" component={expertPpv} />
            <Route exact path="/expertPpvBlog" component={expertPpvBlog} />
            <Route exact path="/passwordCreation" component={PasswordCreation} />
            <Route exact path="/plans" component={Plans} />
            <Route exact path="/expertPlans" component={ExpertPlans} />
            <Route exact path="/plansSummary" component={PlansSummary} />
            <Route exact path="/addCard" component={AddCard} />
            <Route exact path="/previewCard" component={PreviewCard} />
            <Route exact path="/paymentSummery" component={PaymentSummery} />
            <Route exact path="/institute/:legalName?" component={PublicProfileView} />
            <Route exact path="/listOfOffer" component={ListOfOffer} />
            <Route exact path="/listOfAd" component={ListOfAd} />
            <Route exact path="/loader" component={Loader} />
            <Route exact path="/manageUser" component={ManageUser} />
            <Route exact path="/pendingQuestion" component={PendingQuestion} />
            <Route exact path="/answerQuestion" component={AnswerQuestion} />
            <Route exact path="/customerDashboard" component={CustomerDashboard} />
            <Route exact path="/customerInterest" component={CustomerInterest} />
            <Route exact path="/otpLogin" component={OtpLogin} />
            <Route exact path="/articleDetails/:id" component={articleDetails} />
            <Route exact path="/listOfArticle" component={ListOfArticles} />  
            <Route exact path="/listOfExpert" component={ListOfExpert}/>


          </div>
        </Switch>
    );
  }
}
const MyComponent = withTranslation()(LegacyComponent);

const loadingMarkup = <h2>Loading..</h2>;

export default function App() {
  
  return (
    <Router>
    <Suspense fallback={loadingMarkup}>
    <LayoutContainer />
      <MyComponent />
    </Suspense>
    </Router>
   
  );
}
