
import React, { Component } from 'react';
import {
    emailRegex, alphaNumericRegex, websiteRegex, alphabetWithSpaceRegex, nameRegex,
    postalCodeRegex, getRoute
} from '../../../../shared/helper.js';
import { Loader } from '../../../common/loader/loader.jsx';
import SpecialInfoModalJSX from './specialInfoModal.jsx';
import SpecialInfoJSX from './specialInformation.jsx';
import {
    baseURL
} from "../../../../constants/applicationConstants.js";
import swal from "sweetalert";
import axios from 'axios'
import signUpjson from "./specialInformation.json";
import facebook from "../../../../assets/images/facebook.svg";
import twitter from "../../../../assets/images/twitter.svg";
import insta from "../../../../assets/images/insta.svg";
import linkedin from "../../../../assets/images/linkedin.svg";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

class SpecialInformationsJsxclass extends Component {
    constructor(props) {
        super(props);
        this.state = {
            formData: {
                instituteId: "",
                catagorySpecialization: "",
                subCategory: "",
                businessName: "",
                businessLicense: "",
                businessPhone: "",
                businessEmail: "",
                businessWebsite: "",
                yearExperience: null,
                postalCode: "",
                province: "",
                city: "",
                country: "",
                street: "",

                description: "",
                institutionName: "",
                legalInstitutionName: "",
                institutionEmail: "",
                segment: "",
                category: null,
                sizeOfInstitution: "",
                dateOfIncorporation: null,
                firstName: "",
                lastName: "",
                email: "",
                phone: "",


                licenseNo: "",
                regulatory: "",
                institutionalContact: "",
                customerContact: "",
                website: "",
                facebook: "",
                insta: "",
                twitter: "",
                linkedin: "",
                tempCat: null,
                prefillPhone: "",
                prefillEmail: ""
            },
            isProcessing: false,
            formError: {},
            showModal: false,
            formSubmitButtonDisable: true,
            isLoading: false,
            fields: signUpjson,
            _URL: window.URL || window.webkitURL,
            imageName: null,
            imageUrl: "",
            keyword: null,
            categorySection: [],
            subCategorySection: [],
            fileInfo: null,
            profileImagePreviewUrl: null,
            specialInfoDetails: [],
            isEditMode: false,
            disableEdit:false,
            image: ""
        };

    }
    changeHideState = (action) => {
        let masterID = sessionStorage.getItem("masterID") && JSON.parse(sessionStorage.getItem("masterID"))
        this.setState({ showModal: action })

        if (action == true && masterID) {
            // this.setState({ isLoading: true })
           
            let token = JSON.parse(sessionStorage.getItem('token'));
            axios.get(`${baseURL}/institutionCategoriesAndSubCategories?instituteId=${masterID}`, {
                headers: { 'x-access-token': token }
            }).then(res => {
                this.setState({
                    categorySection: res.data.data
                })
                // this.setState({ isLoading: false })

            }).catch(err=>{
                this.setState({ isLoading: false })
              })
        }
    }
    onInstituteClick = (name, value, insId) => {
        this.setState({
            categorySection: value
        })

        this.setState(
            {
                formData: {
                    ...this.state.formData,
                    instituteId: insId,
                    institutionName: name
                },

            },

        );

    }


    componentDidMount = () => {
        this.setState({ isLoading: true })
       
        // this.getInsPrefill()
        
        

        axios.get(`${baseURL}/countries`).then(res => {
            this.getUpdateJSON('country', res.data.data);
        })

        axios.get(`${baseURL}/states`).then(res => {
            this.getUpdateJSON('province', res.data.data);
        })

        axios.get(`${baseURL}/institutionSizes`).then(res => {
            this.getUpdateJSON('sizeOfInstitution', res.data.data);
            this.setState({ isLoading: false })
        })
        this.getSpecialApi()
        this.callDetails()

    }
    callDetails = () => {
        let token = sessionStorage.getItem("token") && JSON.parse(sessionStorage.getItem("token"))
        let expertId = sessionStorage.getItem("expertId") && JSON.parse(sessionStorage.getItem("expertId"))
        axios.get(`${baseURL}/expert/${expertId}/details`, {
          headers: { 'x-access-token': token }
        }).then(res => {
    
            this.setState(
                {
                    formData: {
                        ...this.state.formData,
                        prefillPhone:  res.data.data?.phoneNumber,
                        prefillEmail: res.data.data?.email,
                        description: res.data.data?.description

                    },
                },

            );
        
        }).catch(err=>{
          this.setState({ isLoading: false })
        })
      }
    getInsPrefill = () => {
        let masterID = sessionStorage.getItem("masterID") && JSON.parse(sessionStorage.getItem("masterID"))
        let legalName = sessionStorage.getItem("legalName") && sessionStorage.getItem("legalName")
        let isDesc = sessionStorage.getItem("description") && sessionStorage.getItem("description")
        if (masterID || isDesc) {
            this.setState(
                {
                    formData: {
                        ...this.state.formData,
                        instituteId: masterID,
                        institutionName: legalName,
                        description: isDesc

                    },
                },

            );
        }
        if(masterID){
            this.setState({disableEdit:true})
        }
    }

    getSpecialApi = () => {
        this.setState({ isLoading: true })
        let token = sessionStorage.getItem("token") && JSON.parse(sessionStorage.getItem("token"))

        let expertId = sessionStorage.getItem("expertId") && JSON.parse(sessionStorage.getItem("expertId")) || localStorage.getItem('VerifyExpertID')
        let verifyExpertID = localStorage.getItem('VerifyExpertID');
        let tempToken = localStorage.getItem('tempToken');
        axios.get(`${baseURL}/expert/${expertId}/specializations?tokenValidate=${verifyExpertID? tempToken:token}`
         
    ).then(res => {
            this.setState({
                specialInfoDetails: res.data.data,
                isLoading: false
            })
        })
    }


    getUpdateJSON = (label, values) => {
        let dropdownOptions = [{ label: 'Select', value: null }];

        if (label === 'segment') {
            values.map(value => {
                dropdownOptions.push({ label: value.name, value: value.institutionSegmentId, category: value.institutionCategories })
            })
        }
        else if (label === 'category') {
            dropdownOptions = []
            values?.map(value => {
                dropdownOptions.push({ label: value.name, value: value.institutionCategoryId })
            })
        }
        else if (label === 'sizeOfInstitution') {
            values.map(value => {
                dropdownOptions.push({ label: value.size, value: value.institutionSizeId })
            })
        }
        else if (label === 'country') {
            values.map(value => {
                dropdownOptions.push({ label: value.name, value: value.countryCode })
            })
        }
        else if (label === 'province') {
            values.map(value => {
                dropdownOptions.push({ label: value.name, value: value.stateCode })
            })
        }

        let tempArr = [...this.state.fields];
        tempArr.map((data, index) => {
            if (data.name === label)
                tempArr[index] = { ...tempArr[index], options: dropdownOptions };
            else {
                data.fields?.map((data1, index) => {
                    if (data1.name === label)
                        data.fields[index] = { ...data.fields[index], options: dropdownOptions };
                })
            }
        })
        this.setState({ fields: tempArr })
    }

    handleChange = (e, item) => {
        if (item.name === 'segment') {
            this.onSegmentChange(item, e.value)
            this.setState(
                {
                    formData: {
                        ...this.state.formData,
                        [item.name]: e.value,
                        category: []
                    },
                    formError: {
                        ...this.state.formError,
                        [item.name]: "regx",
                    },
                },
                () => this.isFromSubmissionBlock()
            );
        }
        else if (item.name === 'category') {
            this.setState(
                {
                    formData: {
                        ...this.state.formData,
                        [item.name]: e,
                    },
                    formError: {
                        ...this.state.formError,
                        [item.name]: "regx",
                    },
                },
                () => this.isFromSubmissionBlock()
            );
        }
        else if (item === 'subCategory') {
            this.setState(
                {
                    formData: {
                        ...this.state.formData,
                        [item]: e,
                    },
                    formError: {
                        ...this.state.formError,
                        [item]: "regx",
                    },
                },
                () => this.isFromSubmissionBlock()
            );
        }
        else {
            this.setState(
                {
                    formData: {
                        ...this.state.formData,
                        [item.name]: e.value
                    },
                    formError: {
                        ...this.state.formError,
                        [item.name]: "regx",
                    },
                },
                () => this.isFromSubmissionBlock()
            );
        }
    }

    onSegmentChange = (items, value) => {
        const category1 = items?.options?.filter(item => item.value == value)[0].category;
        this.getUpdateJSON('category', category1);
    }
    fillBusinessPhone = () => {
        let expertDetails = sessionStorage.getItem("expertData") && JSON.parse(sessionStorage.getItem("expertData"))

        this.setState(
            {
                formData: {
                    ...this.state.formData,
                    businessPhone: expertDetails?.phoneNumber  || this.state.formData.prefillPhone,
                }
            }
        );
    }
    fillBusinessEmail = () => {
        let expertDetails = sessionStorage.getItem("expertData") && JSON.parse(sessionStorage.getItem("expertData"))
        this.setState(
            {
                formData: {
                    ...this.state.formData,
                    businessEmail: expertDetails?.email || this.state.formData.prefillEmail,
                }
            }
        );
    }

    onCatgeoryClick = (value) => {
        let insExpert = localStorage.getItem("adInsExpert")

        let masterID = sessionStorage.getItem("masterID") && JSON.parse(sessionStorage.getItem("masterID"))
        const data = this.state.categorySection.filter(ctSec => ctSec.institutionCategoryId == value);
        masterID && insExpert ? this.setState({ subCategorySection: data[0]?.subInstitutionCategories }) : this.setState({ subCategorySection: data[0]?.institutionCategory?.subInstitutionCategories })
        
    }

    onInputChangeHandler = (e, item = '') => {
        let name = e.target.name;
        let value = e.target.value;
        if (name === 'catagorySpecialization')
            this.onCatgeoryClick(value);

        if (value) {
            if (!this.inputRegexValidation(name, value)) {
                this.setState(
                    {
                        formData: {
                            ...this.state.formData,
                            [name]: value,
                        },
                        formError: {
                            ...this.state.formError,
                            [name]: "regx",
                        },
                    },
                    () => this.isFromSubmissionBlock()
                );
            } else {
                this.setState(
                    {
                        formData: {
                            ...this.state.formData,
                            [name]: value,
                        },
                        formError: {
                            ...this.state.formError,
                            [name]: "",
                        },
                    },
                    () => this.isFromSubmissionBlock()
                );
            }
        } else {
            this.setState(
                {
                    formData: {
                        ...this.state.formData,
                        [name]: value,
                    },
                    formError: {
                        ...this.state.formError,
                        [name]: "",
                    },
                },
                () => this.isFromSubmissionBlock()
            );
        }
    };
    onTypeSearch = (e) => {
        let value = e.target.value;

        this.setState(
            {
                formData: {
                    ...this.state.formData,
                    institutionName: value
                },

            },

        );
        this.setState({
            keyword: value,
            categorySection: [],
            // subCategorySection: []
        })
        {
            this.setState({
                formSubmitButtonDisable: true,
            });
        }
        // if (value) {

        //     this.setState({ keyword: value })
        //      this.setState({
        //     formData: {

        //         institutionName: value

        //     }
        // })
        // }

    };


    onTouchHandler = (e) => {
        let name = e.target.name;
        let value = e.target.value;
        if (value === "") {
            this.setState({
                formError: {
                    ...this.state.formError,
                    [name]: "req",
                },
            });
        } else {
            if (!this.inputRegexValidation(name, value)) {
                this.setState({
                    formError: {
                        ...this.state.formError,
                        [name]: "regx",
                    },
                });
            }
        }
    };

    getProperPhoneNumber = (value) => {

        if (!isNaN(value)) {
            return value
        }
        if (value) {
            const number = value?.split(' ');
            return number[0]?.replace('(', '').replace(')', '') + number[1] + number[2]
        }

    }

    inputRegexValidation = (name, value) => {
        if (name === 'postalCode')
            return postalCodeRegex.test(value);
        if (name === 'businessLicense')
            return alphaNumericRegex.test(value);
        else if (name === 'businessName' || name === 'regulatory' || name === 'city')
            return alphabetWithSpaceRegex.test(value);
        else if (name === 'firstName' || name === 'lastName' || name === 'city')
            return nameRegex.test(value);
        else if (name === "businessEmail")
            return emailRegex.test(value);
        else if (name === "businessPhone" || name === "institutionalContact" || name === "customerContact")
            return !this.getProperPhoneNumber(value).includes('_');
        else if (name === "businessWebsite")
            return websiteRegex.test(value);
        else if (name === 'facebook')
            return value.includes('facebook')
        else if (name === 'insta')
            return value.includes('instagram')
        else if (name === 'twitter')
            return (value.includes('twitter') || value.includes('x.'))
        else if (name === 'linkedin')
            return value.includes('linkedin')

        return true;
    };

    isFromSubmissionBlock = () => {
        let { businessName, catagorySpecialization, subCategory, businessLicense, postalCode, street, province, city, country } = this.state.formData;

        if (businessName && catagorySpecialization && subCategory && businessLicense && postalCode && street &&
            province && city && country
            // && legalInstitutionName && institutionEmail && segment && (category || tempCat) && sizeOfInstitution &&
            // dateOfIncorporation?.day && firstName && lastName && email && phone  && institutionalContact && website
        ) {

            if (
                this.inputRegexValidation("businessName", businessName) &&
                this.inputRegexValidation("catagorySpecialization", catagorySpecialization) &&
                this.inputRegexValidation("subCategory", subCategory) &&
                this.inputRegexValidation("businessLicense", businessLicense) &&
                this.inputRegexValidation("businessLicense", businessLicense) &&
                this.inputRegexValidation("postalCode", postalCode) &&
                this.inputRegexValidation("street", street) &&
                this.inputRegexValidation("province", province) &&
                this.inputRegexValidation("country", country) &&
                this.inputRegexValidation("city", city)

                //  &&
                // this.inputRegexValidation("legalInstitutionName", legalInstitutionName) &&
                // this.inputRegexValidation("institutionEmail", institutionEmail) &&
                // this.inputRegexValidation("segment", segment) &&
                // this.inputRegexValidation("category", category) &&
                // this.inputRegexValidation("sizeOfInstitution", sizeOfInstitution) &&
                // this.inputRegexValidation("firstName", firstName) &&
                // this.inputRegexValidation("lastName", lastName) &&
                // this.inputRegexValidation("email", email) &&
                // this.inputRegexValidation("phone", phone) &&
                // this.inputRegexValidation("postalCode", postalCode) &&
                // this.inputRegexValidation("street", street) &&
                // this.inputRegexValidation("province", province) &&
                // this.inputRegexValidation("country", country) &&
                // this.inputRegexValidation("city", city) &&
                // this.inputRegexValidation("institutionalContact", institutionalContact) &&
                // this.inputRegexValidation("website", website)
            ) {
                this.setState({
                    formSubmitButtonDisable: false,
                });
            } else {
                this.setState({
                    formSubmitButtonDisable: true,
                });
            }
        } else {
            this.setState({
                formSubmitButtonDisable: true,
            });
        }
    };

    onDateChange = (date) => {
        this.setState(
            {
                formData: {
                    ...this.state.formData,
                    dateOfIncorporation: date,
                }
            },
            () => this.isFromSubmissionBlock()
        );
    }

    getSocialImage = (item) =>
        item.name === 'facebook' ? facebook : item.name === 'insta' ? insta : item.name === 'twitter' ? twitter : linkedin
    testClick = () => {
    }

    editParticularSpecialization = (id) => {

        sessionStorage.setItem("editSpecialization", id)
        this.setState({ isLoading: true })
        this.setState({ hideAdd: true })
        let token = JSON.parse(sessionStorage.getItem('token'));

        axios.get(`${baseURL}/expertSpecialization/${id}`, {
            headers: { 'x-access-token': token }
        }).then(res => {
            let result = res.data.data
            this.onCatgeoryClick(result.institutionCategoryId);
            this.setState(
                {
                    formData: {
                        ...this.state.formData,
                        instituteId: result.instituteId,
                        institutionName: result.institute.name,
                        catagorySpecialization: result.institutionCategoryId,
                        subCategory: result.institutionSubCategoryId,
                        businessName: result.businessName,
                        businessLicense: result.businessLicenseNumber,
                        businessPhone: result.businessPhoneNumber,
                        businessEmail: result.businessEmail,
                        businessWebsite: result.businessWebsite,
                        yearExperience: result.experienceInYears,
                        postalCode: result.postalCode,
                        province: result.stateCode,
                        city: result.city,
                        country: result.countryCode,
                        street: result.address,
                    },
    
                },() => this.isFromSubmissionBlock()
    
            );
            
            //   this.setState({ profileImagePreviewUrl: result.imageUrl })

            //   this.setState({ imageUrl: result.imageUrl })
            //   const subData = this.state.categoryData.filter(itm => itm.institutionCategoryId == result.institutionCategoryId)
            //   this.setState({ subCategoryData: subData[0]?.subInstitutionCategories })
            //   this.setState({ productTypes: [result.productType] })

            // this.setState({ productsDetail: res.data.data })
            this.setState({ isLoading: false })
            this.changeHideState(true)


        })
    }


    onNextButtonClick = () => {
        this.setState({
            isLoading: true
        })


        const { instituteId,
            catagorySpecialization,
            subCategory,
            businessName,
            businessLicense,
            businessPhone,
            businessEmail,
            businessWebsite,
            yearExperience,
            postalCode,
            province,
            city,
            country,
            street } = this.state.formData;
            let extracted = subCategory.map(({ value }) => value)



        let token = sessionStorage.getItem("token") && JSON.parse(sessionStorage.getItem("token"))
        let expertId = sessionStorage.getItem("expertId") && JSON.parse(sessionStorage.getItem("expertId"))
        let edit = sessionStorage.getItem("editSpecialization") && JSON.parse(sessionStorage.getItem("editSpecialization"))
        
        let verifyExpertID=localStorage.getItem('VerifyExpertID');
        const tempToken=localStorage.getItem('tempToken');
        const payloadData1 = {
            // "tokenValidate": verifyExpertID?tempToken:token,
            "instituteId": instituteId,
            "expertId":expertId,
            "institutionCategoryId": catagorySpecialization,
            "institutionSubCategories": extracted,
            "businessName": businessName,
            "businessLicenseNumber": businessLicense,
            "businessPhoneNumber": this.getProperPhoneNumber(businessPhone && businessPhone),
            "businessEmail": businessEmail,
            "businessWebsite": businessWebsite,
            "experienceInYears": yearExperience,
            "address": street,
            "city": city,
            "stateCode": province,
            "countryCode": country,
            "postalCode": postalCode.toString(),
            



        }
        const payloadDataEdit = {
            // "tokenValidate": token,
            // "instituteId": instituteId,
            // "institutionCategoryId": catagorySpecialization,
            // "institutionSubCategoryId": subCategory,
            // "businessName": businessName,
            // "businessLicenseNumber": businessLicense,
            // "businessPhoneNumber": this.getProperPhoneNumber(businessPhone && businessPhone),
            // "businessEmail": businessEmail,
            // "businessWebsite": businessWebsite,
            // "experienceInYears": yearExperience,
            // "businessEmail": businessEmail,
            // "address": street,
            // "city": city,
            // "stateCode": province,
            // "countryCode": country,
            // "postalCode": postalCode,
            // "expertId" : expertId,

            // 
            "tokenValidate": verifyExpertID?tempToken:token,
            "expertId": verifyExpertID? verifyExpertID : expertId,
            "instituteId": instituteId,
            "institutionCategoryId": catagorySpecialization,
            "institutionSubCategories": extracted,
            "businessName": businessName,
            "businessLicenseNumber": businessLicense,
            "businessPhoneNumber": businessPhone,
            "businessEmail": businessEmail,
            "businessWebsite": businessWebsite,
            "experienceInYears": yearExperience,
            "address": street,
            "city": city,
            "stateCode": province,
            "countryCode": country,
            "postalCode": postalCode.toString(),

        }

        if (edit) {

            axios.put(`${baseURL}/expertSpecialization/${edit}`, payloadDataEdit).then(res => {
                if (res.data.success) {
                    this.setState({
                        isLoading: false,
                        showModal: false,
                        keyword: null

                    })
                    this.setState(
                        {
                            formData: {
                                ...this.state.formData,
                                instituteId: "",
                                catagorySpecialization: "",
                                subCategory: "",
                                businessName: "",
                                businessLicense: "",
                                businessPhone: "",
                                businessEmail: "",
                                businessWebsite: "",
                                yearExperience: null,
                                postalCode: "",
                                province: "",
                                city: "",
                                country: "",
                                street: "",
                                institutionName:""
                            },
                            

                        },
                        () => this.isFromSubmissionBlock()

                    );
                    sessionStorage.removeItem("editSpecialization")
                    this.getSpecialApi()
                }
                else {
                    toast.error(res.data.error.message)
                    this.setState({
                        isLoading: false
                    })
                }

            }).catch(err => {
                toast.error(err.response.data.error.message)
                this.setState({
                    isLoading: false
                })
                
            })
        }
        else {
            axios.post(`${baseURL}/specialization`, payloadData1, {
                headers: {
                  'x-access-token': token
                }
              }).then(res => {
                if (res.data.success) {

                    this.setState({
                        isLoading: false,
                        showModal: false,
                        keyword: null

                    })
                    this.setState(
                        {
                            formData: {
                                ...this.state.formData,
                                instituteId: "",
                                catagorySpecialization: "",
                                subCategory: "",
                                businessName: "",
                                businessLicense: "",
                                businessPhone: "",
                                businessEmail: "",
                                businessWebsite: "",
                                yearExperience: null,
                                postalCode: "",
                                province: "",
                                city: "",
                                country: "",
                                street: "",
                                institutionName:""
                            },
                            

                        },
                        () => this.isFromSubmissionBlock()

                    );
                    
                    this.props.callDetails()                   
                     // this.getSpecialApi()
                    // this.getInsPrefill()
                }
                else {
                    toast.error(res.data.error.message)
                    this.setState({
                        isLoading: false
                    })
                }

            }).catch(err => {
                toast.error(err.response.data.error.message)
                this.setState({
                    isLoading: false
                })
            })
        }
    }

    onSubmitButtonclick = () => {

        this.setState({
            isLoading: true
        })
        let expertId = JSON.parse(sessionStorage.getItem("expertId"));
        let token = JSON.parse(sessionStorage.getItem('token'));

        let data = {
            description: this.state.formData.description,
            tokenValidate: token
        }

        let verifyExpertID = localStorage.getItem('VerifyExpertID');

        let payload={
            "description": this.state.formData.description,
            "status": "verified"
        }

        if(verifyExpertID){
            axios.put(`${baseURL}/expert/${verifyExpertID}/verifyAndAddDescription`,payload,{
                headers:{
                    'x-access-token':token
                }
            }).then(res=>{
                this.setState({
                    isLoading: false
                })
                window.location.href = './admin/manageUnverifiedExpert'
                // this.props.history.push('./admin/manageUnverifiedExpert')
            }).catch(err=>{
                this.setState({
                    isLoading: false
                })
                toast.error(err.data.error.message)
            })
        }else{
        axios.post(`${baseURL}/expert/${expertId}/description`, data
        ).then(resp => {
            if (resp.data.success) {
                this.setState({
                    isLoading: false
                })
                // const type = localStorage.getItem('flowName');
                let flowType= localStorage.getItem('flowName') ?? 'expertRegistration'
                const status = localStorage.getItem('verificationStatus');
                const adminFlow = localStorage.getItem('AdminFlow');
                flowType = (status == 'unVerified' ? 'unVerified' : flowType)
                flowType = (adminFlow == 'expert' ? 'admin' : flowType)
                window.location.href = `${getRoute(flowType, 'description')}`
                // this.props.history.push(getRoute(flowType, 'description'));
                sessionStorage.setItem("description",data.description)
            } else {
                toast.error("something went wrong");

                this.setState({
                    isLoading: false
                })
            }
        }).catch(err=>{
            this.setState({isLoading:false})
        })
    }
    };
    onDeleteButtonClick = (data) => {

        swal({
            title: "Are you sure?",
            text: "You will not be able to recover this Specialization",
            icon: "warning",
            buttons: [
                'Do not cancel it!',
                'Yes, Delete it!'
            ],
            dangerMode: true,
        }).then(isConfirm => {
            if (isConfirm) {
                this.deleteSpecialization(data)
            } else {
                //   swal("Cancelled", "Your imaginary file is safe :)", "error");
            }
        })
    }

    deleteSpecialization = (data) => {
        let token = JSON.parse(sessionStorage.getItem('token'));
        this.setState({ isLoading: true })
        axios.delete(`${baseURL}/expertSpecialization/${data}`, {
            headers: { 'x-access-token': token }
        }).then(res => {
            this.setState({
                isLoading: false
            })
            this.getSpecialApi();
        })
    }





    validFileName = (name) => name.includes('.jpeg') || name.includes('.jpg') || name.includes('.png');

    onFileChange = (e) => {
        if (e.target.files[0]?.size) {
            if (e.target.files[0]?.size < 512000) {
                if (e.target.files[0] && this.validFileName(e.target.files[0].name)) {
                    // if ((file = e.target.files[0])) {
                    //     img = new Image();
                    //     img.onload = function () {
                    //         if (this.height > 800) {
                    //             toast.error("big height")
                    //         }
                    //         else{
                    //             toast.error("small height")
                    //             img.src = this.state._URL.createObjectURL(file);
                    //             this.setState({ fileInfo: e.target.files[0] })
                    //             this.setState({ profileImagePreviewUrl: URL.createObjectURL(e.target.files[0]) })
                    //             this.setState({ imageName: e.target.files[0].name },()=> this.isFromSubmissionBlock())
                    //         }
                    //     };
                    //     img.onerror = function () {
                    //         toast.error("not a valid file: " + file.type);
                    //     };
                    // }
                    this.setState({ fileInfo: e.target.files[0] }, () => this.isFromSubmissionBlock())
                    this.setState({ profileImagePreviewUrl: URL.createObjectURL(e.target.files[0]) })
                    this.setState({ imageName: e.target.files[0].name })
                }
                else
                    toast.error('.jpeg/.jpg/.png format required')
            }
            else
                toast.error('File should be less than 500 kb.')
        }
    }

    render() {
        return (
            <>
                <SpecialInfoJSX
                    field={this.state.fields}
                    formData={this.state.formData}
                    onInputChangeHandler={this.onInputChangeHandler}
                    onTypeSearch={this.onTypeSearch}
                    onTouchHandler={this.onTouchHandler}
                    formSubmitButtonDisable={this.state.formSubmitButtonDisable}
                    formError={this.state.formError}
                    formSubmitHandler={this.formSubmitHandler}
                    onDateChange={this.onDateChange}
                    handleChange={this.handleChange}
                    onNextButtonClick={this.onNextButtonClick}
                    getSocialImage={this.getSocialImage}
                    onFileChange={this.onFileChange}
                    image={this.state.image}
                    imageName={this.state.imageName}
                    imgURL={this.state.imageUrl}
                    profileImagePreviewUrl={this.state.profileImagePreviewUrl}
                    editMode={this.state.isEditMode}
                    testClick={this.testClick}
                    changeHideState={this.changeHideState}
                    onSubmitButtonclick={this.onSubmitButtonclick}




                    specialInfoDetails={this.state.specialInfoDetails}
                    changeHideState={this.changeHideState}
                    onDeleteButtonClick={this.onDeleteButtonClick}
                    editParticularSpecialization={this.editParticularSpecialization}
                />
                <SpecialInfoModalJSX
                    field={this.state.fields}
                    disableEdit={this.state.disableEdit}
                    formData={this.state.formData}
                    onInputChangeHandler={this.onInputChangeHandler}
                    onTypeSearch={this.onTypeSearch}
                    onTouchHandler={this.onTouchHandler}
                    changeHideState={this.changeHideState}
                    fillBusinessPhone={this.fillBusinessPhone}
                    fillBusinessEmail={this.fillBusinessEmail}
                    formSubmitButtonDisable={this.state.formSubmitButtonDisable}
                    formError={this.state.formError}
                    formSubmitHandler={this.formSubmitHandler}
                    onDateChange={this.onDateChange}
                    handleChange={this.handleChange}
                    onNextButtonClick={this.onNextButtonClick}
                    getSocialImage={this.getSocialImage}
                    onFileChange={this.onFileChange}
                    image={this.state.image}
                    imageName={this.state.imageName}
                    keyword={this.state.keyword}
                    onInstituteClick={this.onInstituteClick}
                    imgURL={this.state.imageUrl}
                    categorySection={this.state.categorySection}
                    subCategorySection={this.state.subCategorySection}
                    profileImagePreviewUrl={this.state.profileImagePreviewUrl}
                    editMode={this.state.isEditMode}
                    testClick={this.testClick}
                    showModal={this.state.showModal}
                />
                {/* <ViewSpecialInfo
                    specialInfoDetails={this.state.specialInfoDetails}
                    changeHideState={this.changeHideState}
                    onDeleteButtonClick={this.onDeleteButtonClick}
                    editParticularSpecialization={this.editParticularSpecialization}
                /> */}
                {this.state.isLoading && <Loader />}
                <ToastContainer />
            </>
        )
    }
}

export default SpecialInformationsJsxclass;
