import React from 'react'
import { useTranslation } from "react-i18next";
import {  useState } from 'react';
import { ProductCard } from "../../../../components/common/productCard/productCard.jsx"
import 'react-awesome-slider/dist/styles.css';

import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { Pagination } from '../../../common/pagination/uiPagination.js';
import DetailedProductsJsx from '../../../common/detailedProduct/detailedProduct.jsx';

const SuggestedOfferJsx = (props) => {
  const { t } = useTranslation();
  const [productDetails, setProductDetails] = useState({});
  const closeFunction = () => {
    setProductDetails({})
  }
  const {

    productData, onPaginationButtonClick, page
  } = props;

  const itemsPerPage = 10

  const [currentPage, setCurrentPage] = useState(1);

  const currentItems = productData?.slice(
    (page - 1) * itemsPerPage,
    page * itemsPerPage
  );

  const getProperData = (date) => {
    if (date) {
      const date2 = date?.split('T');
      const date1 = date2[0]?.split('-');
      return `${date1[2]}/${date1[1]}/${date1[0]}`
    }
  }
  const sliderSettings = {
    dots: false,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
  };
  return (
    <>
      <section className="alldashboard cust-product">

        <div className="dashboardrightpanel  ">

          <div className="suggestedoffercustomer">
            <div className="dashboard-ps-offer">
              <section className="limitedoffer ">
                {
                  currentItems
                  &&
                  <div className="limitedofferinner">
                    <div className="offerslider  ">
                      {
                        currentItems
                          ?.map(offer => {
                            return <div>
                              <div class={`sliouter ${offer?.parentInstitutionCategory}`}>
                                <ProductCard product={offer}

                                  viewbuttonURL={(e) => { setProductDetails({ data: offer, category: offer?.parentInstitutionCategory, subCategory: offer?.subInstitutionCategory?.name, frompage: 'realestateProduct', pagePath: '/products' }) }}
                                  isCategorySubCategory={true}
                                  isProductName={true}
                                  isViewButton={true} /> </div>

                            </div>
                          })
                      }
                      {productDetails?.data &&
                        <DetailedProductsJsx data={productDetails?.data} category={productDetails?.category} subCategory={productDetails.subCategory} closFunction={closeFunction} />
                      }
                      <section className="newpagination"> <Pagination
                        currentPage={currentPage}
                        page={page}
                        allOffersData={productData}
                        currentItems={currentItems}
                        onPaginationButtonClick={onPaginationButtonClick}
                        itemsPerPage={itemsPerPage}
                      /></section>
                    </div>
                  </div>
                }


              </section>
            </div>
          </div>
        </div>
      </section>
    </>

  );
};

export default SuggestedOfferJsx;