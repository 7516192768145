import axios from 'axios';
import React from 'react'
import { Component } from 'react';
import { baseURL } from '../../../../constants/applicationConstants.js';
import { Loader } from '../../../common/loader/loader.jsx';
import SuggestedOfferJsx from './suggestedOffer.jsx'

export default class SuggestedOffer extends Component {
  constructor(props) {
    super(props);
    this.state = {
      // investmentOfferData: null,
      // mortgageOfferData: null,
      // insuranceOfferData: null,
      // bankingOfferData: null,
      // realestateOfferData: null,
      offerData: null,
      oldest: false,
      isProcessing: false,
      page: 1,
      meta: {},
    }
  }

  componentDidMount = () => {
    this.getOfferData();
    // this.getMortgageOfferData();
    // this.getInsuranceOfferData();
    // this.getBankingOfferData();
    // this.getRealestatefferData();
  }
  hitOldest = (action) => {

    this.setState({ oldest: action, page: 1, isProcessing: true })
    setTimeout(() => {
      this.getOfferData()
    }, 500);
  }
  getOfferData = () => {
    this.setState({ isProcessing: true })
    axios.get(`${baseURL}/search-offers?sortBy=${this.state.oldest ? "expired" : "latest"}`).then(res => {
      this.setState({ offerData: res.data.data, isProcessing: false })
    })
  }
  onPaginationButtonClick = (page) => {
    // window.scrollTo(200,200);
    document.querySelector(".qnanav").scrollIntoView();
    this.setState({ page: page })
}

  // getInvestmentOfferData = () => {
  //   this.setState({ isProcessing: true })
  //   axios.get(`${baseURL}/featuredOffers?institutionCategoryId=2`).then(res => {
  //     this.setState({ investmentOfferData: res.data.data, isProcessing: false })
  //   })
  // }

  // getMortgageOfferData = () => {
  //   this.setState({ isProcessing: true })
  //   axios.get(`${baseURL}/featuredOffers?institutionCategoryId=3`).then(res => {
  //     this.setState({ mortgageOfferData: res.data.data, isProcessing: false })
  //   })
  // }

  // getInsuranceOfferData = () => {
  //   this.setState({ isProcessing: true })
  //   axios.get(`${baseURL}/featuredOffers?institutionCategoryId=1`).then(res => {
  //     this.setState({ insuranceOfferData: res.data.data, isProcessing: false })
  //   })
  // }

  // getBankingOfferData = () => {
  //   this.setState({ isProcessing: true })
  //   axios.get(`${baseURL}/featuredOffers?institutionCategoryId=4`).then(res => {
  //     this.setState({ bankingOfferData: res.data.data, isProcessing: false })
  //   })
  // }

  // getRealestatefferData = () => {
  //   this.setState({ isProcessing: true })
  //   axios.get(`${baseURL}/featuredOffers?institutionCategoryId=5`).then(res => {
  //     this.setState({ realestateOfferData: res.data.data, isProcessing: false })
  //   })
  // }

  render() {
    return (
      <div>
        <SuggestedOfferJsx
          offerData={this.state.offerData}
          hitOldest={this.hitOldest}
          oldest={this.state.oldest}
          page={this.state.page}
          // mortgageOfferData={this.state.mortgageOfferData}
          // insuranceOfferData={this.state.insuranceOfferData}
          // bankingOfferData={this.state.bankingOfferData}
          // realestateOfferData={this.state.realestateOfferData}
          onPaginationButtonClick={this.onPaginationButtonClick}
          meta={this.state.meta}
        />
        {this.state.isProcessing && <Loader />}
      </div>
    )
  }
}