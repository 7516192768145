import React, { useState } from 'react';

import Slider from "react-slick";
import { useTranslation } from "react-i18next";
import { NEW_URL } from '../../../constants/applicationConstants';
import {AdsCard} from '../../common/adsCard/adsCard';
import 'react-awesome-slider/dist/styles.css';
import { Link } from "react-router-dom";

export const AdsSection = (props) => {
  
  const { profileData, legalName } = props;
  const [showModal, SetShowModal] = useState(false);
  const [modalData, setModalData] = useState({})
  
  const showModalDive = (data, action) => {

    setModalData(data)
    SetShowModal(action)
  }
 
  var settingstwo = {
    dots: false,
    arrows: true,
    infinite: false,
    loop: false,
    speed: 500,
    slidesToShow: 2,
    slidesToScroll: 1,
    adaptiveHeight: true,
    margin: 10,
    // initialSlide:2  
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          infinite: false,
          dots: false
        }
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          centerPadding: '0px',
          initialSlide: 1
        }
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          centerPadding: '0px',
          slidesToScroll: 1
        }
      }
    ]
  };

  const { t } = useTranslation();

  return <><section className="limitedoffer" id="ppvAdsSection">
    <div className="container">
      <div className="expppvofferinnersection">
        <div className="heading">
          <h2>{t("PPVEXPERT.ADSHEADING")}</h2>
          <h5 className="h5_style">{t("PPVEXPERT.ADSSUBHEADINGONE")} </h5>
          <h5 className="h5_style">{t("PPVEXPERT.ADSSUBHEADINGTWO")}</h5>
         
          <div className="offerslider">
            <Slider {...settingstwo}>
              {
                profileData?.advertisements?.map((ad, index) =>  {
                  return  <AdsCard
                  imageUrl={`${NEW_URL}/${ad.imageUrl}`}
                  description={ad.description}
                  categoryname={ad.institutionCategoryId}
                  externalLink={ad.externalLink}
                  />
                })
              }
            </Slider>
  
          </div>
          <div className="showall  text-center">
            <Link to={{
              pathname: "/listOfAd",
              state: { data: {legalName,name:profileData?.firstName+' '+profileData?.lastName,...profileData}, frompage: 'ppvAdsSection', pagePath: window.location.pathname }
            }} className="showallbtn mt-4 mb-3">{t("COMMON.SHOWALL")}</Link>
          </div>
         
        </div>
      </div>
    </div>
  </section>
  
  </>

}
