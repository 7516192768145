import React from 'react';
import envelop from "../../../assets/expertppvimage/email.svg";
import facebook from "../../../assets/expertppvimage/facebook.svg";
import twitter from "../../../assets/expertppvimage/twitterppv.png";
import instagram from "../../../assets/expertppvimage/instagram.svg";
import linkedin from "../../../assets/expertppvimage/linkedin.svg";

import call from "../../../assets/expertppvimage/phone.svg";

import { NEW_URL } from '../../../constants/applicationConstants';

import { getProperWebsite, formatCanadianPhoneNumber } from '../../../shared/helper';

export const ExpertDetails = (props) => {
  const { profileData } = props;
    
  return (
    <div className="container">
      <div className="newexpertppvdesignwrap">
        <div className="newexpertppvdesign">
          <div className="basicdetails">
            <div className="expertprofilelogoupload">
              <div className="expertprofilelogouploadinner">
                <img src={!profileData?.imageUrl ? `https://ui-avatars.com/api/?name=${profileData?.firstName}+${profileData?.lastName}&rounded=true` : `${NEW_URL}/${profileData.imageUrl}`} alt={' '} />
                <div className={`ppvplan ${profileData?.planName}`}>{profileData?.planName}</div> </div>
            </div>
            <div className="explitmoile">
            <div className="Expertbasicdeatail">
              
              <h2 className="h2_style">{`${profileData?.firstName.toLowerCase()} ${profileData?.lastName.toLowerCase()}`}</h2> <div className="ovtercslist">
              {
                   profileData?.expertSpecializations?.map(spec => {
                    return (
                      <>                     
                      <div className="cstlist">
                      <img src={`${NEW_URL}/${spec.icon}`} style={{height:"40px",width:"40px"}}  alt=''></img>
                      <span>{spec.parentInstitutionCategory}</span>
                      </div>
                      
                      </>
                    )
                  })
                  
                }</div>
              {/* <div className="cstlist" dangerouslySetInnerHTML={{ __html: getCategory() }}></div> */}
              <div className="csadress"><ul><li><span><svg xmlns="http://www.w3.org/2000/svg" width="14" height="15" viewBox="0 0 14 15" fill="none">
                <circle cx="7.14894" cy="7.21376" r="6.79787" fill="white" />
                <circle cx="7.14894" cy="7.21376" r="6.79787" fill="white" />
                <circle cx="7.14853" cy="7.21396" r="3.08994" fill="white" />
                <circle cx="7.14853" cy="7.21396" r="3.08994" fill="#484848" />
              </svg></span>{ profileData?.expertSpecializations[0]?.city}</li><li><span><svg xmlns="http://www.w3.org/2000/svg" width="14" height="15" viewBox="0 0 14 15" fill="none">
                <circle cx="7.14894" cy="7.21376" r="6.79787" fill="white" />
                <circle cx="7.14894" cy="7.21376" r="6.79787" fill="white" />
                <circle cx="7.14853" cy="7.21396" r="3.08994" fill="white" />
                <circle cx="7.14853" cy="7.21396" r="3.08994" fill="#484848" />
              </svg></span>{ profileData?.expertSpecializations[0]?.state.name}</li> <li><span><svg xmlns="http://www.w3.org/2000/svg" width="14" height="15" viewBox="0 0 14 15" fill="none">
                <circle cx="7.14894" cy="7.21376" r="6.79787" fill="white" />
                <circle cx="7.14894" cy="7.21376" r="6.79787" fill="white" />
                <circle cx="7.14853" cy="7.21396" r="3.08994" fill="white" />
                <circle cx="7.14853" cy="7.21396" r="3.08994" fill="#484848" />
              </svg></span>{ profileData?.expertSpecializations[0]?.postalCode}</li></ul></div>
            </div>
            <div className="basicphoneemaildetails">
              <ul className="deone">
                <li><span><img src={envelop} alt={' '} /></span> <a href={`mailto:${profileData?.email}`} target="_blank" rel="noreferrer" >{profileData?.email} </a> </li>
                <li><span><img src={call} alt={' '} /></span> <a href={`tel:${profileData?.phoneNumber}`}> {profileData?.phoneNumber && formatCanadianPhoneNumber(profileData?.phoneNumber)}</a> </li>
              </ul>
            </div>
            </div>
          </div>
          {(profileData?.socialMedia?.facebookUrl || profileData?.socialMedia?.twitterUrl || profileData?.socialMedia?.instagramUrl || profileData?.socialMedia?.linkedinUrl) &&
            <div className="socialpublic">
              <ul>
                {profileData?.socialMedia?.facebookUrl && <li><span><a href={getProperWebsite(profileData?.socialMedia?.facebookUrl)} target="_blank" rel="noreferrer"  ><img src={facebook} alt={' '} /></a></span></li>}
                {profileData?.socialMedia?.twitterUrl && <li><span><a href={getProperWebsite(profileData?.socialMedia?.twitterUrl)} target="_blank" rel="noreferrer" ><img src={twitter} alt={' '} /></a></span></li>}
                {profileData?.socialMedia?.instagramUrl && <li><span><a href={getProperWebsite(profileData?.socialMedia?.instagramUrl)} target="_blank" rel="noreferrer" ><img src={instagram} alt={' '} /></a></span></li>}
                {profileData?.socialMedia?.linkedinUrl && <li><span><a href={getProperWebsite(profileData?.socialMedia?.linkedinUrl)} target="_blank" rel="noreferrer" ><img src={linkedin} alt={' '} /></a></span></li>}
              </ul>
            </div>
          }

        </div>

      </div>
    </div>

  )
}