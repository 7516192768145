import React, { useState, useEffect } from 'react'

import { useTranslation } from "react-i18next";

import { ToastContainer } from 'react-toastify';
import "../myServices/myServices.model.css"
import minus from "../../../../assets/images/minus.svg";
import plus from "../../../../assets/Instituteppvimages/plus-circle.svg"

const MyServicesJsx = (props) => {
  const {
    serviceData,
    onServiceSave
  } = props;

  const [data, setData] = useState(null);

  useEffect(() => {
    setData(serviceData);
  }, [serviceData])

  const onPlusButtonClick = (value, action, flag) => {
    let tempArray = [...data]
    tempArray.map((tempDT, index) => {
      if (tempDT.institutionCategoryId === value)
        tempArray[index] = { ...tempArray[index], [flag]: action }
    })
    setData(tempArray)
  }

  const onCheckboxClick = (parentID, childID) => {
    let tempArray = [...data]
    tempArray.map((tempDT) => {
      if (tempDT.institutionCategoryId === parentID) {
        tempDT.institutionServices.map((subDT, index) => {
          if (subDT.institutionServiceId === childID)
            tempDT.institutionServices[index] = { ...tempDT.institutionServices[index], isSelected: !tempDT.institutionServices[index].isSelected }
        })
      }

    })
    setData(tempArray)
  }

  const onSaveServiceButtonClick = (data) => {
    const selectedData = data.institutionServices?.filter(service => service.isSelected).map(data => data.institutionServiceId)
    onServiceSave(selectedData, data.institutionCategoryId);
    onPlusButtonClick(data.institutionCategoryId, false, 'isOpen');
    onPlusButtonClick(data.institutionCategoryId, false, 'isEditable');
  }

  const { t } = useTranslation();

  return (
    <>


      <section>
        <div >
          <p className="servicetitle">{t("instituteservice.TITLE")}</p>
          <p className="servicesubtitle">{t("instituteservice.DESCRIPTION")}</p>
        </div>

<div className="service-div">
        {
          data?.map((data1, index) => {
            return <div className="subcategory-heading">
              <ul className="p-0"> <li>

                {!data1.isOpen ? <p className="offer-subcategory"><p className={`category-count-number ${data1.name.toLowerCase().replace(" ", "_")}-counter`} >{index + 1}</p>  {data1.name}</p> : <p className="offer-open">{data1.name}</p>}
                {!data1.isOpen ? <img src={plus} className={`plus ${data1.name.toLowerCase().replace(" ", "_")}-filter`} alt={'plus'} onClick={() => onPlusButtonClick(data1.institutionCategoryId, true, 'isOpen')} /> :
                  <img src={minus} alt={'Minus'} className="minus" onClick={() => onPlusButtonClick(data1.institutionCategoryId, false, 'isOpen')} />}

                {data1.isOpen && data1.institutionServices.length > 0 && <><div className="subcategory-div"> <div className="subcategory-inner"> <table className="myservice-inner">
                  <tr>
                    <th className="table-heading">{t("instituteservice.PARTICULARS")} </th>
                    <th></th>
                  </tr>
                  {
                    data1.institutionServices?.map(service => {
                      return <>
                        {
                          data1.isEditable ?
                            <tr>
                              <td className="table-text">{service.name}</td>
                              <td>
                                {
                                  data1.isEditable ?
                                    service.isSelected? <center><div className="redio-on-outer" onClick={() => onCheckboxClick(data1.institutionCategoryId, service.institutionServiceId)}> 
                                    <div className="redio-on-inner"></div></div></center>: <center><div className="redio-off-outer" onClick={() => onCheckboxClick(data1.institutionCategoryId, service.institutionServiceId)}> <div className=""></div></div></center>:
                                    
                                    <center><div className="redio-outer">
                                       {/* <div className="redio-inner"></div> */}
                                       <div class="checkmark_stem"></div>
                                       <div class="checkmark_kick"></div>
                                       </div></center>
                                }
                              </td>
                            </tr> :
                            service.isSelected ?
                              <tr>
                                <td className="table-text">{service.name}</td>
                                <td>
                                  {
                                    <center><div className="redio-outer"> 
                                    {/* <div className="redio-inner"></div> */}
                                    <div class="checkmark_stem"></div>
                                       <div class="checkmark_kick"></div>
                                    </div>
                                    </center>
                                  }
                                </td>
                              </tr> : null
                        }
                      </>
                    })
                  }
                </table>
                </div>
                </div>
                <center >{
                    data1.isEditable ?
                      <button type="button" className="add-service" onClick={() => onSaveServiceButtonClick(data1)}>{t("instituteservice.SAVE")} </button>
                      :  <button type="button" className="add-service" onClick={() => onPlusButtonClick(data1.institutionCategoryId, true, 'isEditable')}>{t("instituteservice.ADD")} </button>
                  }
                  </center>
                </>
                
                }
              </li>

              </ul>
            </div>;
           
          })
        }
        </div>
      </section>
      <ToastContainer />

    </>

  );
};

export default MyServicesJsx;
