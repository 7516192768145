import axios from 'axios';
import React from 'react'
import { Component } from 'react';
import { baseURL } from '../../../../constants/applicationConstants.js';
import { Loader } from '../../../common/loader/loader.jsx';
import SuggestedOfferJsx from './custAdds.jsx'

export default class SuggestedOffer extends Component {
  constructor(props) {
    super(props);
    this.state = {
      addData: null,
      mortgageOfferData: null,
      insuranceOfferData: null,
      bankingOfferData: null,
      realestateOfferData: null,
      isProcessing: false,
      page:1,
    }
  }

  componentDidMount = () => {
    this.getOfferData();
  }

  getOfferData = () => {
    const customerId = sessionStorage.getItem("masterID") && JSON.parse(sessionStorage.getItem("masterID"))
    const token = sessionStorage.getItem("token") && JSON.parse(sessionStorage.getItem("token"))

    this.setState({ isProcessing: true })
    
    axios.get(`${baseURL}/customer/${customerId}/advertisements`,{
      headers: { 'x-access-token': token }
    }).then(res => {
      const groupedObjects = res.data.data
      // const groupedObjects = res.data.data.reduce((result, obj) => {
      //   (result[obj.parentInstitutionCategory.replace( / /g, "" )] = result[obj.parentInstitutionCategory.replace( / /g, "" )] || []).push(obj);
      //   return result;
      // }, []);

      this.setState({ addData:groupedObjects, isProcessing: false })    })
      
  }
  onPaginationButtonClick = (page) => {
    // window.scrollTo(1200,1200);
    document.querySelector(".qnanav").scrollIntoView();
    this.setState({ page: page })
}

  

  render() {
    return (
      <div>
        <SuggestedOfferJsx
          investmentOfferData={this.state.investmentOfferData}
          mortgageOfferData={this.state.mortgageOfferData}
          insuranceOfferData={this.state.insuranceOfferData}
          bankingOfferData={this.state.bankingOfferData}
          realestateOfferData={this.state.realestateOfferData}
          addData={this.state.addData}
          page={this.state.page}
          onPaginationButtonClick={this.onPaginationButtonClick}
        />
        {this.state.isProcessing && <Loader />}
      </div>
    )
  }
}