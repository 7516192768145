import React, { useState } from 'react'
import { useTranslation } from "react-i18next";
import { Footer } from '../../../common/footer/footer';
import moment from "moment";
import { OfferCardJSX } from '../../../common/offerCard/offerCard';
import 'react-awesome-slider/dist/styles.css';

import Sidebar from "../sidebar/sidebar.js";
import Expiringshortly from "../../../../assets/images/Expiringshortly.svg";
import Latestoffer from "../../../../assets/images/Suggestedoffer.svg";
import "../suggestedOffers/suggestedOffer.css";
import offerdashboard from "../../../../assets/images/offerdashboard.svg";

import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { Pagination } from '../../../common/pagination/uiPagination.js';

const SuggestedOfferJsx = (props) => {
  const { t } = useTranslation();
  const {
    offerData,
    onPaginationButtonClick,
    page, hitOldest, oldest
  } = props;

  const itemsPerPage = 10

  const [currentPage, setCurrentPage] = useState(1);

  const currentItems = offerData?.slice(
    (page - 1) * itemsPerPage,
    page * itemsPerPage
  );

  return (
    <>
      <section className="main_customer_dashboard">
        <section className="entiredashboard maincustomerdash customer-dashboard">
          <div className="container-fluid">
            <div className="row">
              <div className="col-lg-4 col-sm-4 ">
                <div className="dashboardleftpanel ">
                  <div className="dashboardlinkpop ">
                    <Sidebar />
                  </div>
                </div>
              </div>
              <div className="col-lg-8 col-sm-8">
                <div className="dashboardrightpanel ">
                  <div className="dashboardrightpannerinnerleft">
                    <h2 className="h2_style font-weight-600"><span className="headingicon d-block d-lg-none d-sm-none d-md-none"><img src={offerdashboard} /></span>{t("customersidebar.OFFERS")}</h2>
                    <h5 className="h5_style">{t("customerhome.SUGGESTEDOFFERHEADING")} </h5>
                    <h5 className="h5_style"><i>{t("customerhome.SUGGESTEDOFFERTAGLINE")} </i></h5>
                  </div>
                  <section className="alldashboard latest-offers ">
                    <div className="myexpertnav myaccountnav qnanav martop40 customermain">
                      <ul>
                        {/* className={`${activeTab === 'pending' ? 'active' : ''}`} */}
                        <li className={!oldest ? "active" : ""} onClick={() => hitOldest(false)}><img src={Latestoffer} alt="" /> {t("customerhome.LATESTOFFERSBUTTON")}</li>
                        <li className={oldest ? "active" : ""} onClick={() => hitOldest(true)}><img src={Expiringshortly} alt="" /> {t("customerhome.EXPIRING")}</li>
                      </ul>
                    </div>
                    <div className="dashboardrightpanel martop40 mb-5">
                      <div className="suggestedoffercustomer">
                        <div className="dashboard-ps-offer">
                          <section className="limitedoffer">
                            <div className="limitedofferinner">
                              <div className="offerslider ">

                                {currentItems?.map((offer, index) => (

                                  <div key={index}>
                                    <OfferCardJSX
                                      offerimgurl={offer?.imageUrl}
                                      offerparentInstitutionCategory={offer?.parentInstitutionCategory}
                                      offervalidTo={moment(offer?.validTo).format('Do MMM YY')}
                                      offerofferName={offer?.offerName}
                                      offerdescription={offer?.description}
                                      offerexternalUrl={offer?.externalUrl}
                                      isDescription={true}
                                    />
                                  </div>
                                ))}

                                <section className="newpagination"> <Pagination
                                  currentPage={currentPage}
                                  page={page}
                                  allOffersData={offerData}
                                  currentItems={currentItems}
                                  onPaginationButtonClick={onPaginationButtonClick}
                                  itemsPerPage={itemsPerPage}
                                /></section>
                              </div>
                            </div>
                          </section>

                        </div>
                      </div>
                    </div>
                  </section>
                </div>
              </div>
            </div>
          </div>
        </section> <Footer />
      </section>
     
    </>

  );
};

export default SuggestedOfferJsx;