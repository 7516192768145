import { React } from 'react'
import { useEffect } from 'react';
import { Footer } from '../../common/footer/footer';
import certificationicon from "../../../assets/expertppvimage/certicicationicon.svg";
import awardicon from "../../../assets/expertppvimage/awards_icon.svg";
// Import css files
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import "./expertPpv.css";
import "./expertPpvresponsive.css";
import { NEW_URL } from '../../../constants/applicationConstants';


// {my code updated}

import { ExpertDetails } from './expertDetail';
import { AdsSection } from './adsSection';
import { ExpertSpecialization } from './expertPpvSpecialization/expertPpvSpecialization.jsx';
import { ExpertBlogSection } from './expertBlogSection';
import { GetInTouchSection } from '../ppvCommon/getInTouchSection/getInTouchSection.jsx';
import { useTranslation } from 'react-i18next';


const ExpertPpvJsx = (props) => {
  const { t } = useTranslation();
  const { profileData,
    slugUrl,
    blogData,
    userData,
    formError,
    onInputChangeHandler,
    onTouchHandler,
    onSendMessageClick,
    formSubmitButtonDisable
  } = props;

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [])



  return (
    <>
      <section className={`container-rimib entireexpertppv ${profileData?.expertSpecializations?.[0]?.parentInstitutionCategory}`}>
        <section className="expertpublicviewprofiledetails">
          <ExpertDetails profileData={profileData} />
        </section>

        {profileData?.advertisements?.length > 0 && <AdsSection profileData={profileData} legalName={slugUrl} />}

        {<ExpertSpecialization profileData={profileData} />}

        {blogData?.body?.length > 0 && <ExpertBlogSection blogData={blogData} profileData={profileData} legalName={slugUrl} />}
        
        <section className={`aboutexpertppv`}>
          <div className="container">
            <div className="row about_me">
              <div className="col-lg-9 aboutexpertppvback no-pad-left">
                <div className="about-me-ppv">
                  <div className="heading"><h2>{t("PPVEXPERT.ABOUTME")}</h2></div>
                  <h3>{`${profileData?.firstName.toLowerCase()} ${profileData?.lastName.toLowerCase()}`}</h3>
                  <p>{profileData?.description}</p>
                </div>
              </div>
              <div className="col-lg-3 no-pad-right">
                <div className="product-offer-inner">
                  <div className="dp">
                    <div className="triangle">
                    </div>
                   
                      <img src={!profileData?.imageUrl ? `https://ui-avatars.com/api/?name=${profileData?.firstName}+${profileData?.lastName}&rounded=false` : `${NEW_URL}/${profileData.imageUrl}`} alt={profileData?.firstName} />
                    
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
        
        <section className="awards">
          <div className="container">
            <div className="publicpersonaldetails">
              <div className="logemail">
                <heading>{t("PPVEXPERT.CERT")}</heading>
                <ul className="cetificatesection">
                  {
                    profileData?.certificates.length > 0 ? profileData?.certificates.map(certificate => {
                      return <li><div><span><img src={certificationicon} alt={'certificate icon'} /></span><div className="cerdet">{certificate.certificate}</div></div></li>
                    })
                      :
                      <li><div><span><img src={certificationicon} alt={'certificate icon'} /></span><div className="cerdet">{t("PPVEXPERT.NA")}</div></div></li>
                  }
                </ul>
              </div>
            </div>
            <div className="publicpersonaldetails mt-4">
              <heading>{t("PPVEXPERT.AWARDS")}</heading>
              <div className="logemail">
                <ul className="awardssection">
                  {
                    profileData?.awards.length > 0 ? profileData?.awards.map(award => {
                      return <li><div><span><img src={awardicon} alt={'awardicon'} /></span><div className="cerdet">{award.award}</div></div></li>
                    })
                      :
                      <li><div><span><img src={awardicon} alt={'awardicon'} /></span><div className="cerdet">{`N/A`}</div></div></li>
                  }
                </ul>
              </div>
            </div>
          </div>
        </section>
        <GetInTouchSection
          profileData={profileData}
          userData={userData}
          formError={formError}
          planName={profileData?.planName}
          onInputChangeHandler={onInputChangeHandler}
          onTouchHandler={onTouchHandler}
          onSendMessageClick={onSendMessageClick}
          formSubmitButtonDisable={formSubmitButtonDisable}
        />
      </section>
      <Footer />
    </>
  );
};

export default ExpertPpvJsx;