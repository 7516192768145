import React from 'react';
import MyAds from '../../dashboardCommon/myAds/myAds';
import Sidebar from "../sidebar/sidebar.js";
import { Footer } from '../../../common/footer/footer';
export const ExpertAdsJSX = () => {
  return (
    <div>
      <section className="main_customer_dashboard">
      <section className="entiredashboard maincustomerdash expert-dashboard">
        <div className="container-fluid">
          <div className="row">
            <div className="col-lg-4 col-sm-4">
             <div className="dashboardleftpanel">
                <div className="dashboardlinkpop">
                  <Sidebar />
                </div>
              </div>
            </div>
            <div className="col-lg-8 col-sm-8">
            <div className="dashboardrightpanel">
            <div className="dashboardrightpanelinner">
              <MyAds />
              </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <Footer />
     </section>
    </div>
     
  )
}