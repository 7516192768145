import React, { Component } from 'react';
import {
    emailRegex, alphaNumericRegex, websiteRegex, alphabetWithSpaceRegex, nameRegex,
    postalCodeRegex, getRoute, getProperDateMonth
} from '../../../../shared/helper.js';
import { Loader } from '../../../common/loader/loader.jsx';
import ExpertRegistrationJSX from './expertRegistration.jsx';
import {
    baseURL, uploadURL
} from "../../../../constants/applicationConstants.js";
import axios from 'axios'
import signUpjson from "./expertRegistration.json";
import unverifyjson from "./unverified.json";

import facebook from "../../../../assets/images/facebook.svg";
import twitter from "../../../../assets/images/twitter.svg";
import insta from "../../../../assets/images/insta.svg";
import linkedin from "../../../../assets/images/linkedin.svg";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

class ExpertRegistration extends Component {
    constructor(props) {
        const verificationInfo = localStorage.getItem('verificationStatus');

        super(props);

        this.state = {
            formData: {
                firstName: '',
                lastName: "",
                expertEmail: "",
                altEmail: "",
                primaryContact: "",
                secondaryContact: "",
                contactEmail:"",
                dateOfBirth: null,
                gender: "",
                facebook: "",
                insta: "",
                twitter: "",
                linkedin: "",
                certification: [],
                billing_postalCode: "",
                billing_province: "",
                billing_city: "",
                billing_country: "",
                billing_street: "",
                awards: [],
                tempCat: null
            },
            isProcessing: false,
            formError: {},
            formSubmitButtonDisable: true,
            isLoading: false,
            fields: verificationInfo == "unVerified" ? unverifyjson : signUpjson,
            _URL: window.URL || window.webkitURL,
            imageName: null,
            imageUrl: "",
            fileInfo: null,
            profileImagePreviewUrl: null,
            isEditMode: false,
            sameError: false,
            image: ""
        };

    }

    setDefaultValues = (data) => {
        const verificationInfo = localStorage.getItem('verificationStatus');

        const { firstName, lastName, alternativeEmail, email, phoneNumber,
            secondaryPhoneNumber, dateOfBirth, gender, certificates, awards,
            socialMedia, imageUrl, billingAddress } = data;
        const certiLIst = []
        certificates?.map(value => {
            certiLIst.push(value.certificate)
        })
        const awardLIst = []
        awards?.map(value => {
            awardLIst.push(value.award)
        })
        const date = dateOfBirth && dateOfBirth.split('-');

        const { facebookUrl, instagramUrl, linkedinUrl, twitterUrl } = socialMedia;
        sessionStorage.setItem("emailCheck", email)
        sessionStorage.setItem("altCheck", alternativeEmail)
        this.setState({
            formData: {
                firstName: firstName,
                lastName: lastName,
                expertEmail: email,
                altEmail: alternativeEmail,
                primaryContact: phoneNumber,
                secondaryContact: secondaryPhoneNumber,
                dateOfBirth: date && {
                    day: parseInt(date && date[2]),
                    month: parseInt(date && date[1]),
                    year: parseInt(date && date[0])
                },
                gender: gender,
                facebook: facebookUrl,
                insta: instagramUrl,
                twitter: twitterUrl,
                linkedin: linkedinUrl,
                certification: certiLIst,
                awards: awardLIst,
                // billing
                billing_postalCode: billingAddress?.postalCode,
                billing_province: billingAddress?.stateCode,
                billing_city: billingAddress?.city,
                billing_country: billingAddress?.countryCode,
                billing_street: billingAddress?.address,
            },
            isLoading: false,
            isEditMode: true,
            imageUrl: imageUrl,
            image: imageUrl && imageUrl,
        }, () => verificationInfo == "unVerified" ? this.isFromSubmissionBlockUnverify() : this.isFromSubmissionBlock())

    }


    componentDidMount = () => {
        let token = sessionStorage.getItem("token") && JSON.parse(sessionStorage.getItem("token"))

        if (!token) {
            this.props.history.push("/");
        }
        sessionStorage.removeItem("altCheck")
        sessionStorage.removeItem("emailCheck")

        let expertId = sessionStorage.getItem("expertId") && JSON.parse(sessionStorage.getItem("expertId"));

        let verifyExpertID = localStorage.getItem('VerifyExpertID');

        if (verifyExpertID) {
            this.setState({ isLoading: true })
            axios.get(`${baseURL}/expert/${verifyExpertID}/details`, {
                headers: {
                    'x-access-token': token
                }
            }).then(res => {
                this.setDefaultValues(res.data.data)
            })
        }
        else {
            if (token && expertId) {
                this.setState({ isLoading: true })

                axios.get(`${baseURL}/expert/${expertId}/onboarding?tokenValidate=${token}`).then(res => {
                    this.setDefaultValues(res.data.data)
                })
            }
        }
        axios.get(`${baseURL}/countries`).then(res => {
            this.getUpdateJSON('billing_country', res.data.data);

        })

        axios.get(`${baseURL}/states`).then(res => {
            this.getUpdateJSON('billing_province', res.data.data);

        })
    }


    getUpdateJSON = (label, values) => {
        let dropdownOptions = [{ label: 'Select', value: null }];

        if (label === 'segment') {
            values.map(value => {
                dropdownOptions.push({ label: value.name, value: value.institutionSegmentId, category: value.institutionCategories })
            })
        }
        else if (label === 'category') {
            dropdownOptions = []
            values?.map(value => {
                dropdownOptions.push({ label: value.name, value: value.institutionCategoryId })
            })
        }
        else if (label === 'sizeOfInstitution') {
            values.map(value => {
                dropdownOptions.push({ label: value.size, value: value.institutionSizeId })
            })
        }
        else if (label === 'billing_country') {
            values.map(value => {
                dropdownOptions.push({ label: value.name, value: value.countryCode })
            })
        }
        else if (label === 'billing_province') {
            values.map(value => {
                dropdownOptions.push({ label: value.name, value: value.stateCode })
            })
        }

        let tempArr = [...this.state.fields];
        tempArr.map((data, index) => {
            if (data.name === label)
                tempArr[index] = { ...tempArr[index], options: dropdownOptions };
            else {
                data.fields?.map((data1, index) => {
                    if (data1.name === label)
                        data.fields[index] = { ...data.fields[index], options: dropdownOptions };
                })
            }
        })
        this.setState({ fields: tempArr })
    }

    onMetaTagChange = (e, item) => {

        this.setState(
            {
                formData: {
                    ...this.state.formData,
                    [item.name]: e,
                }
            })
        // this.setState(
        //   {
        //     productFormData: {
        //       ...this.state.productFormData,
        //       'metaTags': e,
        //     },

        //   }
        // )

    }

    handleChange = (e, item) => {
        const verificationInfo = localStorage.getItem('verificationStatus');

        if (item.name === 'segment') {
            this.onSegmentChange(item, e.value)
            this.setState(
                {
                    formData: {
                        ...this.state.formData,
                        [item.name]: e.value,
                        category: []
                    },
                    formError: {
                        ...this.state.formError,
                        [item.name]: "regx",
                    },
                },
                () => verificationInfo == "unVerified" ? this.isFromSubmissionBlockUnverify() : this.isFromSubmissionBlock()
            );
        }
        else if (item.name === 'category') {
            this.setState(
                {
                    formData: {
                        ...this.state.formData,
                        [item.name]: e,
                    },
                    formError: {
                        ...this.state.formError,
                        [item.name]: "regx",
                    },
                },
                () => verificationInfo == "unVerified" ? this.isFromSubmissionBlockUnverify() : this.isFromSubmissionBlock()
            );
        }
        else {
            this.setState(
                {
                    formData: {
                        ...this.state.formData,
                        [item.name]: e.value
                    },
                    formError: {
                        ...this.state.formError,
                        [item.name]: "regx",
                    },
                },
                () => verificationInfo == "unVerified" ? this.isFromSubmissionBlockUnverify() : this.isFromSubmissionBlock()
            );
        }
    }

    onSegmentChange = (items, value) => {
        const category1 = items?.options?.filter(item => item.value == value)[0].category;
        this.getUpdateJSON('category', category1);
    }

    onInputChangeHandler = (e, item = '') => {
        const verificationInfo = localStorage.getItem('verificationStatus');

        let name = e.target.name;
        let value = e.target.value;
        if (value) {
            if (name === "expertEmail" || name === "altEmail") {
                if (name === "expertEmail") {
                    sessionStorage.setItem("emailCheck", value)
                }
                if (name === "altEmail") {
                    sessionStorage.setItem("altCheck", value)
                }
                let emailCheck = sessionStorage.getItem("emailCheck")
                let altCheck = sessionStorage.getItem("altCheck")
                if (emailCheck === altCheck) {
                    this.setState({ sameError: true })
                } else {
                    this.setState({ sameError: false })

                }
            }


            if (!this.inputRegexValidation(name, value)) {
                this.setState(
                    {
                        formData: {
                            ...this.state.formData,
                            [name]: value,
                        },
                        formError: {
                            ...this.state.formError,
                            [name]: "regx",
                        },
                    },
                    () => verificationInfo == "unVerified" ? this.isFromSubmissionBlockUnverify() : this.isFromSubmissionBlock()
                );
            } else {
                this.setState(
                    {
                        formData: {
                            ...this.state.formData,
                            [name]: value,
                        },
                        formError: {
                            ...this.state.formError,
                            [name]: "",
                        },
                    },
                    () => verificationInfo == "unVerified" ? this.isFromSubmissionBlockUnverify() : this.isFromSubmissionBlock()
                );
            }
        } else {
            if (name === "expertEmail" || name === "altEmail") {
                if (name === "expertEmail") {
                    sessionStorage.setItem("emailCheck", value)
                }
                if (name === "altEmail") {
                    sessionStorage.setItem("altCheck", value)
                }
                let emailCheck = sessionStorage.getItem("emailCheck")
                let altCheck = sessionStorage.getItem("altCheck")
                if (emailCheck === altCheck) {
                    this.setState({ sameError: true })
                } else {
                    this.setState({ sameError: false })

                }
            }
            this.setState(
                {
                    formData: {
                        ...this.state.formData,
                        [name]: value,
                    },
                    formError: {
                        ...this.state.formError,
                        [name]: "",
                    },
                },
                () => verificationInfo == "unVerified" ? this.isFromSubmissionBlockUnverify() : this.isFromSubmissionBlock()
            );
        }
    };


    onTouchHandler = (e) => {
        let name = e.target.name;
        let value = e.target.value;
        if (value === "") {
            this.setState({
                formError: {
                    ...this.state.formError,
                    [name]: "req",
                },
            });
        } else {
            if (!this.inputRegexValidation(name, value)) {
                this.setState({
                    formError: {
                        ...this.state.formError,
                        [name]: "regx",
                    },
                });
            }
        }
    };

    getProperPhoneNumber = (value) => {

        if (!isNaN(value)) {
            return value
        }

        const number = value.split(' ');
        return number[0].replace('(', '').replace(')', '') + number[1] + number[2]
    }

    inputRegexValidation = (name, value) => {
        if (name === 'postalCode' || name === "billing_postalCode")
            return postalCodeRegex.test(value);
        if (name === 'institutionName' || name === 'licenseNo')
            return alphaNumericRegex.test(value);
        else if (name === 'legalInstitutionName' || name === 'regulatory' || name === 'city' || name === "billing_city")
            return alphabetWithSpaceRegex.test(value);
        else if (name === 'firstName' || name === 'lastName' || name === 'city' || name === "billing_city")
            return nameRegex.test(value);
        else if (name === "altEmail" || name === "expertEmail" || name === "contactEmail")
            return emailRegex.test(value);
        else if (name === "phone" || name === "primaryContact" || name === "secondaryContact")
            return !this.getProperPhoneNumber(value).includes('_');
        else if (name === "website")
            return websiteRegex.test(value);
        else if (name === 'facebook')
            return value.includes('facebook')
        else if (name === 'insta')
            return value.includes('instagram')
        else if (name === 'twitter')
            return (value.includes('twitter') || value.includes('x.'))
        else if (name === 'linkedin')
            return value.includes('linkedin')

        return true;

    };

    isFromSubmissionBlock = () => {
        let { firstName,
            lastName,
            expertEmail,
            altEmail,
            primaryContact,
            secondaryContact,
            facebook,
            insta,
            twitter,
            linkedin,
            billing_postalCode, billing_street, billing_province, billing_city, billing_country,contactEmail
        } = this.state.formData;

        if (
            firstName &&
            lastName &&
            expertEmail &&
            primaryContact && billing_postalCode && billing_street &&
            billing_province && billing_city && billing_country && contactEmail

        ) {

            if (
                // required fields

                this.inputRegexValidation("expertEmail", expertEmail) &&
                this.inputRegexValidation("primaryContact", primaryContact) &&
                this.inputRegexValidation("firstName", firstName) &&
                this.inputRegexValidation("lastName", lastName) &&
                this.inputRegexValidation("postalCode", billing_postalCode) &&
                this.inputRegexValidation("street", billing_street) &&
                this.inputRegexValidation("province", billing_province) &&
                this.inputRegexValidation("country", billing_country) &&
                this.inputRegexValidation("city", billing_city) &&
                this.inputRegexValidation("contactEmail",contactEmail) &&

                // non-required fields

                (altEmail ? this.inputRegexValidation("altEmail", altEmail) : true) &&
                (secondaryContact ? this.inputRegexValidation("secondaryContact", secondaryContact) : true) &&
                (facebook ? this.inputRegexValidation("facebook", facebook) : true) &&
                (insta ? this.inputRegexValidation("insta", insta) : true) &&
                (twitter ? this.inputRegexValidation("twitter", twitter) : true) &&
                (linkedin ? this.inputRegexValidation("linkedin", linkedin) : true)
                // this.inputRegexValidation("facebook", facebook) &&
                // this.inputRegexValidation("insta", insta) &&
                // this.inputRegexValidation("twitter", twitter) &&
                // this.inputRegexValidation("linkedin", linkedin) &&
                // this.inputRegexValidation("certification", certification) &&
                // this.inputRegexValidation("awards", awards)
            ) {
                this.setState({
                    formSubmitButtonDisable: false,
                });
            } else {
                this.setState({
                    formSubmitButtonDisable: true,
                });
            }
        } else {
            this.setState({
                formSubmitButtonDisable: true,
            });
        }
    };

    // for unverify flow///////////////////////
    isFromSubmissionBlockUnverify = () => {
        let { firstName,
            lastName,
            expertEmail,
            altEmail,
            secondaryContact,
            facebook,
            insta,
            twitter,
            linkedin,

        } = this.state.formData;

        if (
            firstName &&
            lastName &&
            expertEmail


        ) {

            if (
                // required fields

                this.inputRegexValidation("expertEmail", expertEmail) &&
                this.inputRegexValidation("firstName", firstName) &&
                this.inputRegexValidation("lastName", lastName) &&


                // non-required fields

                (altEmail ? this.inputRegexValidation("altEmail", altEmail) : true) &&
                (secondaryContact ? this.inputRegexValidation("secondaryContact", secondaryContact) : true) &&
                (facebook ? this.inputRegexValidation("facebook", facebook) : true) &&
                (insta ? this.inputRegexValidation("insta", insta) : true) &&
                (twitter ? this.inputRegexValidation("twitter", twitter) : true) &&
                (linkedin ? this.inputRegexValidation("linkedin", linkedin) : true)
                // this.inputRegexValidation("facebook", facebook) &&
                // this.inputRegexValidation("insta", insta) &&
                // this.inputRegexValidation("twitter", twitter) &&
                // this.inputRegexValidation("linkedin", linkedin) &&
                // this.inputRegexValidation("certification", certification) &&
                // this.inputRegexValidation("awards", awards)
            ) {
                this.setState({
                    formSubmitButtonDisable: false,
                });
            } else {
                this.setState({
                    formSubmitButtonDisable: true,
                });
            }
        } else {
            this.setState({
                formSubmitButtonDisable: true,
            });
        }
    };

    onDateChange = (date) => {

        const verificationInfo = localStorage.getItem('verificationStatus');

        this.setState(
            {
                formData: {
                    ...this.state.formData,
                    dateOfBirth: date,
                }
            },
            () => verificationInfo == "unVerified" ? this.isFromSubmissionBlockUnverify() : this.isFromSubmissionBlock()
        );
    }

    getSocialImage = (item) =>
        item.name === 'facebook' ? facebook : item.name === 'insta' ? insta : item.name === 'twitter' ? twitter : linkedin
    testClick = () => {
        // this.props.history.push("/specialInformations");
    }
    onNextButtonClick = () => {
        this.setState({
            isLoading: true
        })
        let formData1 = new FormData();

        if (this.state.fileInfo) {
            formData1.append('image', this.state.fileInfo)
            formData1.append('imageCategory', "institute")

            axios.post(`${uploadURL}/upload-file`, formData1).then(resp => {
                if (resp.status === 200) {
                    this.setState({ image: resp.data.filename })
                    setTimeout(() => {
                        this.onNextButtonClick1()
                    }, 2000)
                }
            }).catch(() => {
                this.setState({
                    isLoading: false
                })
            })
        }
        else this.onNextButtonClick1();
    };


    onNextButtonClick1 = () => {
        sessionStorage.removeItem("altCheck")
        sessionStorage.removeItem("emailCheck")
        this.setState({
            isLoading: true
        })

        let image = this.state.image;

        const { firstName,
            lastName,
            expertEmail,
            altEmail,
            primaryContact,
            secondaryContact,
            dateOfBirth,
            gender,
            facebook,
            insta,
            twitter,
            linkedin,
            certification,
            awards, billing_city, billing_postalCode, billing_street, billing_province, billing_country, } = this.state.formData;
        let instExpert = localStorage.getItem("instituteExpert") && (localStorage.getItem("instituteExpert"))
        let instituteId = sessionStorage.getItem("masterID") && JSON.parse(sessionStorage.getItem("masterID"))

        const loginData = JSON.parse(sessionStorage.getItem('loginData'));

        const status = localStorage.getItem('verificationStatus');

        const payloadData1 = {
            "status": status == 'unVerified' ? 'unverified' : '',
            "registerdBy": loginData?.userId ?? null,
            "firstName": firstName,
            "lastName": lastName,
            "phoneNumber": this.getProperPhoneNumber(primaryContact) || null,
            "secondaryPhoneNumber": this.getProperPhoneNumber(secondaryContact),
            "email": expertEmail,
            "alternativeEmail": altEmail,
            "dateOfBirth": dateOfBirth && `${dateOfBirth?.year}-${getProperDateMonth(dateOfBirth?.month)}-${getProperDateMonth(dateOfBirth.day)}`,
            "gender": gender,
            "instagramUrl": insta,
            "facebookUrl": facebook,
            "twitterUrl": twitter,
            "linkedinUrl": linkedin,
            "certificates": certification,
            "awards": awards,
            "billingAddress": {
                "address": billing_street || null,
                "city": billing_city || null,
                "stateCode": billing_province || null,
                "countryCode": billing_country || null,
                "postalCode": billing_postalCode && billing_postalCode.toString() || null,
            },
            imageUrl: image
        }
        if (instExpert) {
            payloadData1.instituteId = instituteId;
        }



        let token = sessionStorage.getItem("token") && JSON.parse(sessionStorage.getItem("token"))
        let expertId = sessionStorage.getItem("expertId") && JSON.parse(sessionStorage.getItem("expertId"))

        const payloadData = image ? { ...payloadData1, imageUrl: image } : { ...payloadData1 }

        let verifyExpertID = localStorage.getItem('VerifyExpertID');

        if (verifyExpertID) {
            delete payloadData.status;
            delete payloadData.registerdBy;
            axios.put(`${baseURL}/expert/${verifyExpertID}/verifyOnboarding`, payloadData, {
                headers: {
                    'x-access-token': token
                }
            }).then(res => {
                if (res.data.success) {
                    localStorage.setItem('tempToken', res.data.data.tokenValidate);
                    sessionStorage.setItem("expertId", JSON.stringify(res.data?.data?.expertId));

                    this.props.history.push(getRoute('expert', 'expertRegistration'));
                    this.setState({
                        isLoading: false
                    })
                }
                else {
                    toast.error(res.data.error.message)
                    this.setState({
                        isLoading: false
                    })
                }

            }).catch(err => {
                toast.error(err.response.data.error.message)
                this.setState({
                    isLoading: false
                })
            })
        }
        else if (expertId) {
            let flowType = localStorage.getItem('flowName') ?? ''
            axios.put(`${baseURL}/expert/${expertId}/onboarding`, { "tokenValidate": token, ...payloadData }).then(res => {
                if (res.data.success) {
                    sessionStorage.setItem("token", JSON.stringify(res.data?.data?.tokenValidate));
                    sessionStorage.setItem("email", JSON.stringify(this.state.formData.expertEmail));
                    sessionStorage.setItem("expertId", JSON.stringify(res.data?.data?.expertId));
                    if (!flowType) {
                        localStorage.setItem('flowName', 'expert')
                        localStorage.setItem('flowType', 'expert')
                        flowType = 'expert';
                    }
                    this.props.history.push(getRoute(flowType, 'expertRegistration'));
                    this.setState({
                        isLoading: false
                    })
                }
                else {
                    toast.error(res.data.error.message)
                    this.setState({
                        isLoading: false
                    })
                }

            }).catch(err => {
                toast.error(err.response.data.error.message)
                this.setState({
                    isLoading: false
                })
            })
        }
        else {
            let flowType = localStorage.getItem('flowName') ?? ''
            let isUnverify = localStorage.getItem('manageUnverifyExpert')
            axios.post(`${baseURL}/expert`, payloadData).then(res => {
                if (res.data.success) {
                    isUnverify && sessionStorage.setItem("adminOldToken", token);
                    sessionStorage.setItem("token", JSON.stringify(res.data?.data?.tokenValidate));
                    sessionStorage.setItem("email", JSON.stringify(this.state.formData.expertEmail));
                    sessionStorage.setItem("expertId", JSON.stringify(res.data?.data?.expertId));
                    sessionStorage.setItem("expertData", JSON.stringify(payloadData));
                    // if (!flowType) {
                    localStorage.setItem('flowName', 'expert')
                    localStorage.setItem('flowType', 'expert')
                    flowType = 'expert';
                    // }
                    this.props.history.push(getRoute(flowType, 'expertRegistration'));
                    this.setState({
                        isLoading: false
                    })
                }
                else {
                    toast.error(res.data.error.message)
                    this.setState({
                        isLoading: false
                    })
                }

            }).catch(err => {
                toast.error(err.response.data.error.message)
                this.setState({
                    isLoading: false
                })
            })
        }
    }

    validFileName = (name) => name.includes('.jpeg') || name.includes('.jpg') || name.includes('.png');

    onFileChange = (e) => {
        const file = e.target.files[0];
        const verificationInfo = localStorage.getItem('verificationStatus');
        const reader = new FileReader();
        reader.readAsDataURL(file);
        if (e.target.files[0]?.size) {
            if (e.target.files[0]?.size < 512000) {
                if (e.target.files[0] && this.validFileName(e.target.files[0].name)) {
                    reader.onloadend = () => {
                        const img = new Image();
                        img.src = reader.result;
                        img.onload = () => {
                            const { width, height } = img;
                            const ratio = width / height;
                            if (ratio === 1) {
                                this.setState({ fileInfo: e.target.files[0] }, () => verificationInfo == "unVerified" ? this.isFromSubmissionBlockUnverify() : this.isFromSubmissionBlock())
                                this.setState({ profileImagePreviewUrl: URL.createObjectURL(e.target.files[0]) })
                                this.setState({ imageName: e.target.files[0].name })
                            } else {
                                toast.error('Upload Proper Dimension')
                            }
                        };
                    };

                }
                else
                    toast.error('.jpeg/.jpg/.png format required')
            }
            else
                toast.error('File should be less than 500 KB.')
        }
    }

    render() {
        return (
            <>
                <ExpertRegistrationJSX
                    field={this.state.fields}
                    formData={this.state.formData}
                    onInputChangeHandler={this.onInputChangeHandler}
                    onTouchHandler={this.onTouchHandler}
                    formSubmitButtonDisable={this.state.formSubmitButtonDisable}
                    formError={this.state.formError}
                    formSubmitHandler={this.formSubmitHandler}
                    onDateChange={this.onDateChange}
                    handleChange={this.handleChange}
                    onNextButtonClick={this.onNextButtonClick}
                    getSocialImage={this.getSocialImage}
                    onFileChange={this.onFileChange}
                    onMetaTagChange={this.onMetaTagChange}
                    image={this.state.image}
                    imageName={this.state.imageName}
                    imgURL={this.state.imageUrl}
                    profileImagePreviewUrl={this.state.profileImagePreviewUrl}
                    editMode={this.state.isEditMode}
                    testClick={this.testClick}
                    sameError={this.state.sameError}
                />
                {this.state.isLoading && <Loader />}

                <ToastContainer />
            </>
        )
    }
}

export default ExpertRegistration;