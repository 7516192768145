import React from 'react'
import { useTranslation } from "react-i18next";
import { useEffect } from 'react';
import selectall from "../assets/offersimage/Select_all.svg";
import 'react-awesome-slider/dist/styles.css';
import AOS from 'aos';
import 'aos/dist/aos.css';
import { NEW_URL } from '../constants/applicationConstants';
import { useLocation } from 'react-router-dom/cjs/react-router-dom.min';


export const FilterGlobal = (props) => {
  const { t } = useTranslation();
  const { pathname } = useLocation()

  const {
    categoryData,
    selectAll,
    selectAllHit,
    selectCategory,
    selectSubCategory,
    subCategories,
    categories,
    selectIdsFn, disableSelectAll
  } = props;


  useEffect(() => {
    AOS.init();
    if (selectIdsFn) {
      selectIdsFn()
    }
    window.scrollTo(0, 0);
  }, [])

  return (
    <div className="filtersectionglobal">
      <div className={`selectallcheckbox ${selectAll && 'selected'}`}>
        <div className="form-checkselect">

          <input className="form-check-input"
            checked={selectAll}
            disabled={disableSelectAll}
            onChange={e => selectAllHit(e)}
            type="checkbox" value="" id="Checkall" name="selectAll" />
          <span className="maincheckboxicon"><img src={selectall} alt='' /></span><label className="form-check-label mainchecklabel" for="Checkme4">{t("COMMON.SELECTALL")}</label>
        </div>
      </div>
      <div className="selectallinnercheckbox select-all">
        <ul>
          {categoryData?.map((item) => {
            return (
              <>
                <li className={`selectinner ${item.name}`} disabled={!categories?.includes(item.institutionCategoryId)} >

                  <div className="dropdown">

                    <div className="cstick">
                      <input className="form-check-input" type="checkbox" value="" id={`Checkmerealestate-${item.institutionCategoryId}`} checked={categories?.includes(item.institutionCategoryId) ? true : false} onChange={(e) => selectCategory(item, e)} />
                    </div>
                    <div
                      className={pathname == "/globalMainSearchexpert" || pathname == "/globalMainSearchInstitutions" ? 'form-check' : 'form-check dropdown-toggle'}
                      type="button" id="dropdownMenuButtoninvest" data-bs-toggle="dropdown" aria-expanded="false">

                      <span className="maincheckboxicon">
                        <img src={`${NEW_URL}/${item.icon}`} alt='' />
                      </span><label className="form-check-label mainchecklabel" for="Checkme4">{item.name}</label>

                    </div>
                    {pathname == "/globalMainSearchexpert" || pathname == "/globalMainSearchInstitutions" ?
                      null : <ul className="dropdown-menu" aria-labelledby="dropdownMenuButtoninvest">
                        {item?.subInstitutionCategories?.map((subItem) => {
                          return (
                            <>
                              <li>
                                <a className="dropdown-item">
                                  <div className="form-check">
                                    <input className="form-check-input" type="checkbox"
                                      checked={subCategories?.includes(subItem.institutionCategoryId)}

                                      value="" id="Checkme1" onChange={() => selectSubCategory(subItem)} />
                                    <span className="subcatimage"><img src={`${NEW_URL}/${subItem.icon}`} alt='' /></span> <label className="form-check-label" for="Checkme1">{subItem?.name}</label>
                                  </div>
                                </a>
                              </li>
                            </>
                          )
                        })}


                      </ul>}
                  </div>
                </li>
              </>
            )
          })}
        </ul>
      </div>
    </div>


  );
};

export default FilterGlobal;