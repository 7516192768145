import React from 'react'
import { Component } from 'react';
import axios from 'axios';
import { baseURL } from '../../../constants/applicationConstants.js';
import ArticlesJsx from './articles.jsx';
import { BLOG_URL_TOKEN, BLOG_URL } from '../../../constants/applicationConstants.js';
import { Loader } from '../../common/loader/loader.jsx';
import { fetchArticleDataFromAPI } from '../../../shared/helper.js';


export default class Articles extends Component {
  constructor(props) {
    super(props);
    this.state = {
      categoryData: null,
      isLoading: false,
      categoryName: "",
      subCategoryData: null,
      categoryId: null,
      subCategoryId: null,
      subCategoryName: null,


      blogData: null,
      isProcessing: false,
      topOffers: null,
      page: 1,
      input: "",
      blogType: 'featured,main_news,glossory,trending',
      quoteData: {
        type: null,
        name: null,
        email: null,
        phone: null,
        postalCode: null
      },
      formError: {},
      expertData: null,
      productData: null,
      offerData: null
    }
  }
  getType = {
    Featured: 'main_news',
    Trending: "trending",
    Latest: "featured",
    Glossary: "glossory"
  }

  hitSearch = () => {
    this.state.page = 1;
    const { paramName } = this.getQueryParams();
    if (this.state.input == "") {
      this.state.input = paramName;
    }

    this.props.history.push({ pathname: "searchArticles", search: `search=${this.state.input}` });


    this.onSubmitButtonClick();
  }

  hitShowAll = (type) => {
    this.state.page = 1;

    if (this.state.categoryName) {
      this.props.history.push({ pathname: "searchArticles", search: `category=${this.state.categoryName}&type=${type}` });

    }
    else {
      this.props.history.push({ pathname: "searchArticles",search: `type=${type}` });

    }


  }
  searchOfferByKeyword = (e) => {
    this.state.input = e.target.value;
    this.setState({ input: e.target.value })
  }

  onSubmitButtonClick = () => {
    const { catName } = this.getQueryParams();
    var data = new FormData();
    data.append('limit', '10');
    data.append('device_token', '20.204.66.244');
    data.append('paged', this.state.page)
    data.append('search', this.state.input)
    if (catName) {
      data.append('cat_slug', catName)
    }
    this.setState({ isProcessing: true })

    axios.post(`${BLOG_URL}`, data,
      {
        headers: { 'Eba-access-api-base-token': BLOG_URL_TOKEN }
      }).then(res => {
        this.setState({ articleData: res.data, isProcessing: false });
      }).catch(err => {
        this.setState({ isProcessing: false });
      })
  }

  componentDidMount = () => {
    const { location } = this.props;
    const params = new URLSearchParams(location.search);





    this.setState({ isLoading: true, categoryName: params.get('category') || "" })
    let token = JSON.parse(sessionStorage.getItem('token'));
    axios.get(`${baseURL}/institutionCategoriesAndSubCategories`, {
      headers: { 'x-access-token': token }
    }).then(res => {
      this.setState({ categoryData: res.data.data, isLoading: false }, () => {
        const data = JSON.parse(sessionStorage.getItem('askTheExpert'));
        if (data) {
          const { categoryId, categoryName, subCategoryId, subCategoryName, questionAsked, formData } = data;
          this.setState({ subCategoryName: subCategoryName, questionAsked: questionAsked, formData: formData, categoryId: categoryId, categoryName: categoryName })
          this.setState({
            subCategoryData: this.state.categoryData.filter(catDT => catDT.name === categoryName)[0]?.subInstitutionCategories
          }, () => this.isFromSubmissionBlock())
          this.setState({ subCategoryId: subCategoryId });
          axios.get(`${baseURL}/askExpertQuestions?expertCategoryId=${categoryId}&expertSubCategoryId=${subCategoryId}`).then(res => {
            this.setState({ expertQuestions: res.data.data });
          })
        }
      })

    }).catch(err => {
      this.setState({ isLoading: false })
    })
    const type = 'featured,main_news,glossory,trending'
    setTimeout(async () => {
      const fetchedData = await fetchArticleDataFromAPI(this.state.categoryName, type, this.state.page);
      this.setState({ blogData: fetchedData, isProcessing: false });

    }, 500);

  }

  onCategoryClick = (catId, catName) => {
    this.setState({ isProcessing: true })

    this.props.history.replace('/')
    this.props.history.push(`/articles?category=${catName}`)
    this.setState({ categoryId: catId, categoryName: catName });
    const type = 'featured,main_news,glossory,trending'

    setTimeout(async () => {
      const fetchedData = await fetchArticleDataFromAPI(this.state.categoryName, type, this.state.page);
      this.setState({ blogData: fetchedData, isProcessing: false });

    }, 400);
    // this.setState({
    //     subCategoryData: this.state.categoryData.filter(catDT => catDT.name === catName)[0]?.subInstitutionCategories
    // }, () => this.isFromSubmissionBlock())

  }
  getQueryParams() {
    const { location } = this.props;
    const params = new URLSearchParams(location.search);
    return {
      paramName: params.get('search'), // Change 'paramName' to your actual query parameter name
      catName: params.get('category') ?? null, // Change 'paramName' to your actual query parameter name
    };

  }






  render() {
    return (
      <div>
        <ArticlesJsx
          categoryData={this.state.categoryData}
          onCategoryClick={this.onCategoryClick}
          categoryName={this.state.categoryName}
          hitSearch={this.hitSearch}
          hitShowAll={this.hitShowAll}
          searchOfferByKeyword={this.searchOfferByKeyword}
          input={this.state.input}
          blogData={this.state.blogData} />
        {this.state.isProcessing && <Loader />}

      </div>
    )
  }
}