import React from 'react'
import Sign_out_squre from "../../../../assets/images/Sign_out_squre.svg";
import question from "../../../../assets/images/question.svg"
import { Footer } from '../../../common/footer/footer';
import Sidebar from "../../../../components/dashboard/institute/sidebar/sidebar.js";

import "../myPayment/myPayment.model.css"
import { useTranslation } from "react-i18next";

import help from "../../../../assets/Instituteppvimages/help.svg";

import { MyPaymentCard } from "../../../common/myPaymentCard/myPaymentCard";

const MyPaymentsJsx = (props) => {
  const { t } = useTranslation();
  const {
    paymentData,
    formData,
    onInputChangeHandler,
    onTouchHandler,
    formSubmitButtonDisable,
    formError,
    formSubmitHandler,
    isLoading,
    isShowCard,
    onReplaceCardClick,
    isCardSummary,
    isPaymentSummary,
    onCompletePayment, invoiceDownload, backToPayment, onBackCardClick, backToAddCard
  } = props;

  const cardDetails = JSON.parse(sessionStorage.getItem("addCard"))

 
  return (
    <>
      <section className="main_institute_dashboard">
        <section class="entiredashboard maincustomerdash main institute_dashboard">
        <div className="container-fluid">
          <div className="row dashboard-decoration">
            <div className="col-lg-4 col-sm-4">
              <Sidebar />
            </div>
            <div className="col-lg-8 col-sm-8 dashboard-data">
            <div className="dashboardrightpanel">
              <div className="row title-section" >

                <div className="col-11">

                  <p className="maintitle">{t("institutePayments.TITLE")}</p>
                  <p className="mainsubTitle">{t("institutePayments.SUBTITLE")}</p>
                </div>
                <div class="col-1"  >
                  <div class="helpbutton">
                    <img class="helpImg" src={help} alt={''} />
                    <p className="logout ">{t("institutePayments.HELP")} </p>
                  </div>
                </div>
              
              </div>


              <MyPaymentCard
                    isShowCard={isShowCard}
                    isCardSummary={isCardSummary}
                    isPaymentSummary={isPaymentSummary}
                    paymentData={paymentData}
                    onReplaceCardClick={onReplaceCardClick}
                    formData={formData}
                    onInputChangeHandler={onInputChangeHandler}
                    onTouchHandler={onTouchHandler}
                    invoiceDownload={invoiceDownload}
                    onBackCardClick={() => onBackCardClick()}
                    formError={formError}
                    formSubmitButtonDisable={formSubmitButtonDisable}
                    formSubmitHandler={formSubmitHandler}
                    isLoading={isLoading}
                    question={question}
                    Sign_out_squre={Sign_out_squre}
                    cardDetails={cardDetails}
                    backToAddCard={backToAddCard} 
                    onCompletePayment={onCompletePayment}
                    backToPayment={backToPayment}
                  />

            </div>
            </div>
          </div>
        </div>
      </section>
      <Footer/>
      </section>
    </>

  );
};

export default MyPaymentsJsx;