import React from 'react';
import { Link } from 'react-router-dom/cjs/react-router-dom.min';
import Slider from 'react-slick'; 
import { useTranslation } from "react-i18next";
import { NEW_URL } from '../../../constants/applicationConstants';
import styles from './institutionCard.module.css';

export const InstitutionCard = (props) => {
  const { t } = useTranslation();

  const {
    data, offerplanName
  } = props;


    return (
        <div className={`${data?.instituteCategories[0]?.name} ${offerplanName}`}>
        <div className="ourexpertuser">
            <div className="ourexpertinner">
                <Link to={`institute/${data?.formattedLegalName}`} target="_blank">
                <div className={[styles.ourinstituteinnerimg]}> <img src={!data?.imageUrl ? `https://ui-avatars.com/api/?name=${data.name}+${data.name}&rounded=false` : `${NEW_URL}/${data?.imageUrl}`} alt='profile Image'/></div>
                <h3>{data.name}</h3>
                <p>{data.email}</p>
                </Link>
            </div>
            <div className="{Expertsbutton}">
                <div className="Expertsbuttoni">
                {offerplanName === "Premium" ?
              null :
                <Link to={`/institute/${data?.formattedLegalName}#contact`} target="_blank" className="messagemebuttoncomm">{t("COMMON.CONTACTUS")}</Link>}
                <Link to={`/institute/${data?.formattedLegalName}`} target="_blank" className="myadsbuttoncomm">{t("COMMON.VIEWPROFILE")}</Link>                
                </div>
            </div>
        </div>
    </div>
    )
}
