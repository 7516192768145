import React from 'react';
import { Link } from 'react-router-dom/cjs/react-router-dom.min';
import styles from "../askTheExpertBanner/askTheExpertBanner.module.css"

export const AskTheExpertBanner = (props) => {
        const {
        askimg,
        homeText, expertTitle, expertSubTitle, expertboldSubTitle, askTheExpertText
    } = props;

    return (
        <section className={`${styles.question}`}>
            <div className="container">
                <div className="row" style={{ alignItems: "center" }} >
                    <div className={`col-md-6` + " " + `d-flex` + " " + `align-items-center`}>
                        <div className={`${styles.textquestion}`}>
                            <h2>
                                {homeText} <b>  {expertTitle}</b>
                            </h2>
                            <p>{expertSubTitle} <b className={`${styles["textquestion-b"]}`}>{expertboldSubTitle}</b></p>
                        </div>
                    </div>
                    <div className="col-md-6">
                        <Link to={'/AskTheExpert'}>
                            <div className={`${styles.askbutton}`}>
                                <p className={`askres` + " " + `mb-0`}>{askTheExpertText}</p>
                                <img src={askimg} alt="" />
                            </div>
                        </Link>
                    </div>
                </div>
            </div>
        </section>)
}

export default AskTheExpertBanner;


