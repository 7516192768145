import React from 'react'

import { NEW_URL } from '../../../constants/applicationConstants';
import { formatCanadianPhoneNumber } from '../../../shared/helper'
import { PpvExpertCard } from '../../common/ppvExpertCard/ppvExpertCard.jsx';
import { useTranslation } from "react-i18next";
import { Link } from 'react-router-dom/cjs/react-router-dom.min';

const MyExpertJsx = (props) => {
  const { t } = useTranslation();

  const { profileData } = props;


  return (
    <>
      <section className="ps">
        <div className="container">
          <div className="ppvexperttitle text-center">
            <h2 className="fw-bold">{t("MYEXPERT.HEADING")}</h2>
            <h4>{t("MYEXPERT.SUBHEADING1")}</h4>
            <h4>{t("MYEXPERT.SUBHEADING2")}</h4>
          </div>
          <div className="ppvexpertsecnew">

            {/* search form data remove form here */}

            <div className={`expertsection martop30 disgrid grid-2`}>
              {props?.expertData?.length > 0 ?
                props.expertData?.map(data => {
                  return (

                    <PpvExpertCard
                      profileImage={!data?.imageUrl ? `https://ui-avatars.com/api/?name=${data?.firstName} ${data?.lastName}&rounded=true` : `${NEW_URL}/${data.imageUrl}`}
                      profileName={`${data?.firstName} ${data?.lastName}`}
                      ProfileCategory={data}
                      location={data?.expertSpecializations[0]?.city || "NA"}
                      phone={data.phoneNumber && formatCanadianPhoneNumber(data.phoneNumber)}
                      email={data.email}
                      ppvExpertCardBtnTextmsg={"Message Me"}
                      ppvExpertCardBtnTextPv={"View Profile"}
                      planName={data.planName}
                      linkUrlmsg={`/expertPpv/${data?.slugUrl}#contact`}
                      linkUrlPv={`/expertPpv/${data?.slugUrl}`}
                      institutionCategoryId={data.expertSpecializations[0].institutionCategory.institutionCategoryId}

                    />

                  )
                })
                : <div className="dropdefault text-center">
                <h5 className="text-cenetr">{t("MYEXPERT.NOEXPERTFOUND")} </h5>
              </div>}


            </div>
            <div className={`showallourbesttwo mt-5 text-center`}>
              
              {
                profileData && (
                  <Link
                    to={{ pathname: "/listOfExpert", state: { data: profileData, frompage: 'ppvExpertsSection', pagePath: "/listOfExpert"} }}
                    className="showallourbesttoo"
                    style={{ textDecoration: "none" }}
                  >
                    {t("COMMON.SHOWALL")}
                  </Link>

                )
              }
            </div>
          </div>
        </div>
      </section>
    </>

  );
};

export default MyExpertJsx;