import React from 'react';
import { useEffect, useState } from 'react';
import { Footer } from '../../common/footer/footer';

import ascending from "../../../assets/productimage/ascending-bars.svg";
import newest from "../../../assets/images/newest.svg";
import expire_svg from "../../../assets/images/expire_svg.svg";
import descending from "../../../assets/productimage/descending-bars.svg";
import 'react-awesome-slider/dist/styles.css';
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Slider from "react-slick";
import AOS from 'aos';
import 'aos/dist/aos.css';
import "../products/products.css"
import "../products/productresponsive.css"
import { NEW_URL } from '../../../constants/applicationConstants';
import { sliderSettingOne } from "../../../shared/sliderSetting.js"
import { useLocation } from 'react-router-dom'
import { useTranslation } from 'react-i18next';
import Filter from '../../../shared/filter.jsx';
import DetailedProductsJsx from '../../common/detailedProduct/detailedProduct.jsx';
import { Pagination } from '../../common/pagination/uiPagination.js';
import { ProductCard } from "../../../components/common/productCard/productCard.jsx"

const ProductJsx = (props) => {
  const { t } = useTranslation();
  const [productDetails, setProductDetails] = useState({});
  const closeFunction = () => {
    setProductDetails({})
  }
  const [isVisible, setIsVisible] = useState(false);
  const [isMobile, setIsMobile] = useState(false);

  const {
    categoryData,
    hitSearch,
    selectAll,
    selectAllHit,
    allProductData,
    oldest,
    hitOldest,
    investmentProductData,
    mortgageProductData,
    insuranceProductData,
    bankingProductData,
    realStateProductData,
    topProducts,
    selectCategory,
    selectSubCategory,
    subCategories,
    categories,
    searchProductByKeyword,disableSelectAll, page, onPaginationButtonClick, selectIdsFn
  } = props;


  const itemsPerPage = 12

  const [currentPage, setCurrentPage] = useState(1);
  const [filterrMenuOpen, setFilterrMenuOpen] = useState(false);
  const [sortMenuOpen, setSortMenuOpen] = useState(false);
  const { hash } = useLocation()

  const currentItems = allProductData?.slice(
    (page - 1) * itemsPerPage,
    page * itemsPerPage
  );

  const checkMobileView = () => {
    setIsMobile(window.innerWidth <= 767);
  };

  // Set up window resize listener to check if in mobile view
  useEffect(() => {
    checkMobileView(); // Initial check
    window.addEventListener('resize', checkMobileView); // Listen for window resize

    return () => {
      window.removeEventListener('resize', checkMobileView); // Clean up event listener
    };
  }, []);

 

  useEffect(() => {
    AOS.init();

    if (hash) {
      document.getElementById(hash.replace('#', '')) && document.getElementById(hash.replace('#', '')).scrollIntoView()
    } else {
      window.scrollTo(0, 0);
    }

  }, [realStateProductData, investmentProductData, mortgageProductData, insuranceProductData, bankingProductData])



  // {t("PRINT.TITLE")}
  return (
    <>
     
      
      <section className="container-rimib newproductdesign">
        <section className="herosectionoffer">

          <div className="container">
            <div className="row w-100">
              <div className="col-lg-5 col-sm-5">
                <div className="offerherobannertext">

                  <h2>{t("PRODUCTS.BANNERTEXT1")}</h2>
                  <h5>{t("PRODUCTS.BANNERTEXT2")}</h5>
                </div>
              </div>
              <div className="col-lg-7 col-sm-7">
                <div className="offerbannerslider productbannerslider">
                  <Slider {...sliderSettingOne}>
                    {
                      topProducts?.map(product => {

                        return (
                          // <Link to={{pathname:product.externalUrl ?? '#'}} target="_blank">

                          <div className="sliderContent">
                            <a href={product.externalLink ?? '#'} target="_blank">
                              <img
                                className="d-block w-100"
                                src={`${NEW_URL}/${product.imageUrl}`}
                                alt=" "
                              />
                              {/* <h5>{product.productName}</h5> */} </a>
                          </div>

                        )

                      })
                    }
                  </Slider>
                </div>
              </div>
            </div>

          </div>
        </section>
        <section className="offersearch">
          <div className="container">
            <div className="customsearchblock">
              <input type="search" value={props.keyword} placeholder="" onChange={searchProductByKeyword}
              />
              <button className="headersearch" disabled={!props?.keyword?.length} onClick={() => hitSearch()}>{t("COMMON.SEARCH")}</button>
            </div>
            <h2>{t("PRODUCTS.SEARCHLINEONE")}</h2>
            <h4>{t("PRODUCTS.SEARCHLINETWO")}</h4>
          </div>
        </section>
        {/* mobile section */}
        {isMobile ? <div className="mobile-filter sortingdiv">
        <div className="mainofferdetails">
         
            <h2>{t("PRODUCTS.TITLE")}</h2>
            <h5>{t("PRODUCTS.SUBTITLEONE")} </h5>
            <h5><b className="subtitle_subtitle">{t("PRODUCTS.SUBTITLETWO")}</b></h5>
            </div>
          <div className="container">
            <div className="d-flex mobiledrop">
              <div class="dropdown dropfilter">
                <button class="btn btn-secondary dropdown-toggle" type="button" id="dropdownFilter" onClick={() => {setSortMenuOpen(!sortMenuOpen);setFilterrMenuOpen(false)}}>
                {t("COMMON.SORTBY")}
                </button>
                {sortMenuOpen &&
                  <div class="dropdown-menu-open" aria-labelledby="dropdownFilter">
                    <ul>
                      <li className={!oldest ? "active" : ""}><a onClick={() => hitOldest(false)}>
                        <div className="form-check">
                          <input className="form-check-input" type="checkbox" checked={!oldest} value="" id="Checkme1" /><span className="subcatimage"><img src={newest} /></span>  {t("COMMON.ASC")}</div></a></li>
                      <li className={oldest ? "active" : ""}><a onClick={() => hitOldest(true)}>
                        <div className="form-check">
                          <input className="form-check-input" type="checkbox" checked={oldest} value="" id="Checkme1" /><span className="subcatimage"><img src={expire_svg} /></span>  {t("COMMON.DESC")}</div></a></li>
                      {/* <a class="dropdown-item" href="#">Action</a> */}
                    </ul>
                  </div>
                }
              </div>
              <div class="dropshortby">
                <button class="btn btn-secondary dropdown-toggle" onClick={() => {setFilterrMenuOpen(!filterrMenuOpen);setSortMenuOpen(false)}} type="button" id="dropdownShortBy">
                {t("COMMON.FILTER")}
                </button>
                <div class={`${filterrMenuOpen===true ? 'open-active' : ''} filter-wrap`} aria-labelledby="dropdownShortBy">
                  <Filter
                    categoryData={categoryData}
                    selectAll={selectAll}
                    selectAllHit={selectAllHit}
                    selectCategory={selectCategory}
                    selectSubCategory={selectSubCategory}
                    subCategories={subCategories}
                    categories={categories}
                    selectIdsFn={selectIdsFn}
                    disableSelectAll={disableSelectAll}

                  />
                </div>
              </div>
            </div>
          </div>
        </div> : <>
        <div className="mainofferdetails">
          <div className="container">
            <h2>{t("PRODUCTS.TITLE")}</h2>
            <h5>{t("PRODUCTS.SUBTITLEONE")} </h5>
            <h5><b className="subtitle_subtitle">{t("PRODUCTS.SUBTITLETWO")}</b></h5>
            <Filter
              categoryData={categoryData}
              selectAll={selectAll}
              selectAllHit={selectAllHit}
              selectCategory={selectCategory}
              selectSubCategory={selectSubCategory}
              subCategories={subCategories}
              categories={categories}
              disableSelectAll={disableSelectAll}

            />
          </div>
        </div>
        <section className="sortingdiv">
          <div className="container">
            <span className="shortby">{t("COMMON.SORTBY")}</span>
            <ul>
              <li className={!oldest ? "active" : ""}><a onClick={() => hitOldest(false)}><img src={ascending} className="descending" /> {t("COMMON.ASC")}</a></li>
              <li className={oldest ? "active" : ""}><a onClick={() => hitOldest(true)}><img src={descending} className="descending" /> {t("COMMON.DESC")}</a></li>
            </ul>
          </div>
        </section>
        </>}
        {/* end */}

        
        <section className="entireofferfiltercustomised">
          <div className="container">
            {allProductData?.length > 0 ?
              <section className="topexpertproduct">
                <div className="productslider mt-4">
                  <div className="row">
                    {
                      currentItems?.map(product => {
                        return <div class={`sliouter ${product?.parentInstitutionCategory ?? product?.institutionCategoryName} col-lg-4 col-md-4 col-sm-4`}>
                          <ProductCard product={product}
                            viewbuttonURL={(e) => { setProductDetails({ data: product, category: product?.parentInstitutionCategory, subCategory: product?.subInstitutionCategory?.name, frompage: 'realestateProduct', pagePath: '/products' }) }}
                            isCategorySubCategory={true}
                            isProductName={true}
                            isViewButton={true} /> </div>
                      })
                    }
                  </div>
                </div>

              </section> : <h4 className="text-center">No results for your search</h4>
            }


          </div>
        </section>
      </section>
      {productDetails?.data &&
        <DetailedProductsJsx data={productDetails?.data} category={productDetails?.category} subCategory={productDetails.subCategory} closFunction={closeFunction} />
      }
      {allProductData?.length > 0 ?  <section className="newpagination">
        <Pagination
          currentPage={currentPage}
          page={page}
          allOffersData={allProductData}
          currentItems={currentItems}
          onPaginationButtonClick={onPaginationButtonClick}
          itemsPerPage={itemsPerPage}

        /></section> : null}

      <Footer />
    </>

  );
};

export default ProductJsx;