import React from 'react';

import Slider from "react-slick";
import { useTranslation } from "react-i18next";
import { NEW_URL } from '../../../constants/applicationConstants';
import 'react-awesome-slider/dist/styles.css';
import { Link } from "react-router-dom";
import { AdsCard } from '../../common/adsCard/adsCard';

export const AdsSection = (props) => {
    const { profileData } = props;
    
   
      var settingstwo = {
        dots: false,
        arrows:true,
        infinite: false,
        speed: 500,
        slidesToShow: 2,
        slidesToScroll: 1,
        adaptiveHeight:true,
        // initialSlide:2  
        responsive: [
            {
              breakpoint: 1024,
              settings: {
                slidesToShow: 1,
                slidesToScroll: 1,
                infinite: false,
                dots: false
              }
            },
            {
              breakpoint: 600,
              settings: {
                slidesToShow: 1,
                slidesToScroll: 1,
                centerPadding: '0px',
                initialSlide: 1
              }
            },
            {
              breakpoint: 480,
              settings: {
                slidesToShow: 1,
                centerPadding: '0px',
                slidesToScroll: 1
              }
            }
          ]  
      };
    
    const { t } = useTranslation();
 
    return <section className="limitedoffer" id="ppvAdsSection">
        <div className="container">
            <div className="limitedofferinner ppvofferinnersection">
                <div className="adsHeading"> 
                    <h2 className="text-center fw-bold">{t("PPVINSTITUTE.ADSTITLE")}</h2>
                    <h5 className="mt-2 text-center">{t("PPVINSTITUTE.ADSSUBTITLE1")}</h5>
                    <h5 className="mt-2 text-center">{t("PPVINSTITUTE.ADSSUBTITLE2")}</h5>
                    <div className="offerslider">
                    <Slider {...settingstwo}>
                {
                  profileData?.advertisements?.map(ad => {
                    return (
                      <AdsCard                    
                    imageUrl={`${NEW_URL}/${ad.imageUrl}`}
                    description={ad.description}
                    category={ad?.subInstitutionCategory?.name}
                    externalLink={ad.externalLink}
                    categoryname={ad?.institutionCategoryId}
                    //externalLink={ad.NEW_URL}
                    />
                    )
                    
                  })
                }
              </Slider>
                      
                    </div>
                    <div className="d-flex justify-content-center">
                    <Link
                        to={{
                            pathname: "/listOfAd",
                            state: { data: {...profileData,legalName:profileData.formattedLegalName}, frompage: 'ppvAdsSection' ,pagePath:window.location.pathname }
                        }}
                        className="showallbtn"
                        onClick={()=> window.scrollTo(0,0)}>
                        Show All
                    </Link>
                    </div>
                    {/* <button type="button" className="ctaonebutton widthhalf mt-5" onClick={() => onViewAllButtonClick('Ads')}>View All</button> */}
                </div>
            </div>
        </div>
    </section>

}
