import React from 'react'
import { Link } from "react-router-dom";

import { NEW_URL } from '../../../constants/applicationConstants';
import {  useTranslation } from 'react-i18next';
import styles from './expertCard.module.css';


const ExpertCardJsx = (props) => {
    const { t } = useTranslation();

    const {
         category, data, CategoryID
    } = props;

    return (
        <>

            <div>
                <Link to={`/expertPpv/${data?.slugUrl}`} target="_blank">

                    <div className={`${styles.ourexpertuser} expertallouter expertoutershadow category-id${CategoryID}`}>
                        <div className={[styles.ourexpertinner]}>
                            <div className={[styles.ourexpertinnerimg + " " + "ourexpertinnerimgborder"]}> <img src={!data?.imageUrl ? `https://ui-avatars.com/api/?name=${data?.firstName}+${data?.lastName}&rounded=false` : `${NEW_URL}/${data?.imageUrl}`} alt='' /></div>
                            <h3 className="ourexpertinnerborder">{data?.firstName.toLowerCase()} {data?.lastName.toLowerCase()}</h3>
                            <p>{data?.description}</p>
                        </div>
                        {category ? <div className={[styles.ourexperticon]} >
                            <div className={[styles.ourexperticoninner + " " + "expertButtonContainer" + " " + "lightbackground"]} >
                                <p className={[styles.firstlatter + " " + 'mb-0' + " " + 'ms-3']}>
                                    {category.name}
                                </p>
                            </div>
                        </div>
                            :
                            <div className={[styles.Expertsbutton + " " + "expertButtonContainer"]} >
                                <div className={[styles.Expertsbuttoni]}>
                                    <Link to={`/expertPpv/${data?.slugUrl}#contact`} className={[styles.messagemebuttoncomm + " " + "expertbuttonlist"]}>{t("COMMON.MESSAGEME")}</Link>
                                </div>
                            </div>
                        }

                    </div>
                </Link>
            </div>          
        </>

    );
};

export default ExpertCardJsx;
