import React, { useEffect, useState } from 'react'
import { Footer } from '../../../common/footer/footer';
import { ArticlesCardJSX } from '../../../common/articlesCard/articlesCard';
import Tab from 'react-bootstrap/Tab';

import dropbox from "../../../../assets/images/dropbox.svg";
import { Pagination } from '../../../common/pagination/uiPagination.js';
import Sidebar from "../sidebar/sidebar.js";
import "../customerBlogs/customerBlogs.css";
import articledashboard from "../../../../assets/images/articledashboard.svg";
import { useTranslation } from 'react-i18next';
import { NEW_URL } from '../../../../constants/applicationConstants';
import { Col, Nav, Row } from 'react-bootstrap';



const CustomerBlogJsx = (props) => {
    const { t } = useTranslation();

    const {
        categoryData,
        blogData,
        onPaginationButtonClick,
        onBlogTypeClick,

        page

    } = props;
    const itemsPerPage = 12

    const [currentPage, setCurrentPage] = useState(1);
    const currentItems = blogData?.body?.slice(
        (page - 1) * itemsPerPage,
        page * itemsPerPage
    );


    const [openModal, setOpenModal] = useState(false);
    const [blog, setBlog] = useState({});

    const onSelectClick = (title) => {
        const formattedTitle = title.toLowerCase().replace(/\s+/g, '-');
        onBlogTypeClick(formattedTitle);
    }


    const [activeKey, setActiveKey] = useState();
    useEffect(() => {
        if (categoryData?.data?.length > 0) {
            setActiveKey(categoryData.data[4].name);
        }
    }, [categoryData]);

    const formatSlug = (categoryName) => {
        return categoryName.toLowerCase().replace(/\s+/g, '-');
    };
    return (
        <>
            <section className="main_customer_dashboard">
                <section className="entiredashboard maincustomerdash customer-dashboard blogs-main">
                    <div className="container-fluid">
                        <div className="row ">
                            <div className="col-lg-4 col-sm-4">
                                <div className="dashboardleftpanel">
                                    <div className="dashboardlinkpop">
                                        <Sidebar />
                                    </div>
                                </div>
                            </div>
                            <div className="col-lg-8 col-sm-8 blogsinner">
                            <div className="dashboardrightpannerinnerleft">
                                    <h2 className="h2_style font-weight-600 d-block d-lg-none d-sm-none d-md-none"><span className="headingicon d-block d-lg-none d-sm-none d-md-none "><img src={articledashboard} /></span>Articles</h2>
                                    
                                </div>
                                <div className="dashboardrightpanel">
                                    <div className="blogs-category">
                                        <Tab.Container id="left-tabs-example" activeKey={activeKey} onSelect={(k) => setActiveKey(k)}>
                                            <Row>
                                                <Col sm={12} className="main-round-wrapper">
                                                    <Nav variant="pills" className="main-round-box">
                                                        {categoryData?.data?.length > 0 ? (
                                                            categoryData.data.map((tab, index) => (
                                                                <Nav.Item key={tab.id} className="round-box">
                                                                    <Nav.Link
                                                                        onClick={() => {
                                                                            onSelectClick(tab.name, tab.icon);
                                                                            // setCurrentCategory(tab.name); // Set current category
                                                                        }}
                                                                        className="blog-cat-roundbox"
                                                                        eventKey={tab.name}
                                                                    ><h4 className="d-block d-lg-none d-sm-none d-md-none finame">{tab.name}</h4>
                                                                       {tab.name}  <img src={`${NEW_URL}/${tab?.icon}`} alt="" />
                                                                       
                                                                    </Nav.Link>
                                                                </Nav.Item>
                                                            ))
                                                        ) : (
                                                            <Nav.Item>
                                                                <Nav.Link disabled>{t("customerblogs.NOCATEGORIES")}</Nav.Link>
                                                            </Nav.Item>
                                                        )}
                                                    </Nav>
                                                </Col>
                                            </Row>
                                            <div className="dashboard-ps-offer">
                                                <Tab.Content>
                                                    {categoryData?.data?.map((tab) => (
                                                        <Tab.Pane eventKey={tab.name} key={tab.id}>
                                                            <div className="row acc-home main-blog-section">
                                                                {currentItems?.length > 0 ? (
                                                                    currentItems?.map(blog => {
                                                                        return <div className={`col-lg-4 col-md-6 col-sm-6 p-0 blogblogmain ${blog.cats[0].cat_name?.replace(/\s+/g, '')}`} key={blog.id}>
                                                                            <div className={`basicstructure ${blog.cats[0]?.name}`}>
                                                                                <ArticlesCardJSX blogslug={blog?.slug}
                                                                                    blogimage={blog?.image}
                                                                                    blogtitle={blog?.title}
                                                                                    blogcat={blog?.cats.map((cat) => cat.name).join(',')}
                                                                                    articleauthor={blog?.author}
                                                                                    articledatepublish={blog?.date_publish}
                                                                                    isSerchArticle={false} /></div>


                                                                        </div>
                                                                    })
                                                                ) : (
                                                                    <div className="dropdefault text-center">
                                                                        <div className="dropdefaultimage">
                                                                            <img src={dropbox} alt="" />                                                                </div>
                                                                        <div className="dropdefaulttext">
                                                                            <h5>{t("customerblogs.YOUDONTHAVETEXT")}</h5>
                                                                        </div>
                                                                    </div>
                                                                )}
                                                                
                                                                <section className="newpagination"> {currentItems?.length > 0 && <Pagination
                                                                    currentPage={currentPage}
                                                                    page={page}
                                                                    allOffersData={blogData?.body}
                                                                    onPaginationButtonClick={onPaginationButtonClick}
                                                                    itemsPerPage={itemsPerPage}
                                                                />}
                                                                </section>
                                                            </div>
                                                        </Tab.Pane>
                                                    ))}
                                                </Tab.Content>
                                            </div> </Tab.Container>


                                    </div>
                                </div>
                            </div>

                        </div>

                    </div>




                </section> <Footer />
            </section>


        </>

    );
};

export default CustomerBlogJsx;