import React from 'react';
import 'react-awesome-slider/dist/styles.css';
import { useTranslation } from "react-i18next";
import { OfferCardListJSX } from '../../common/offerCardList/offerCardList.jsx';
import './instituteppv.css'



export const OfferSection = (props) => {
  const { t } = useTranslation();
  const { profileData } = props;
 
  return <section className="limitedtimeoffer" id="ppvOfferSection">
      <OfferCardListJSX
        SubCatTitle={t("PPVINSTITUTE.OFFERTITLE")}
        SubCatText={t("PPVINSTITUTE.OFFERSUBTITLE")}
        offerData={profileData.instituteOffers}
        redirectUrl="/listOfOffer"
        isDescription={true}
        isSlider={true}
        profileData={profileData}
      />
  </section>
}