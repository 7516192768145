import React, { useState } from 'react'
import { useEffect } from 'react';
import { useTranslation } from "react-i18next";
import { OfferCardListJSX } from '../../common/offerCardList/offerCardList';
import { HomeArticleCardJSX } from '../../common/homeArticleCard/homeArticleCard'
import { HomeMobileBannerJsx } from '../../common/homeMobileBanner/homeMobileBanner.jsx'
import { NEW_URL } from '../../../constants/applicationConstants';
import { Footer } from '../../common/footer/footer';
import rimib11 from "../../../assets/homeimages/RealEstateblack.svg";
import rimib22 from "../../../assets/homeimages/Investmentblack.svg";
import rimib33 from "../../../assets/homeimages/Mortgageblack.svg";
import rimib44 from "../../../assets/homeimages/Insuranceblack.svg";
import rimib55 from "../../../assets/homeimages/Bankingblack.svg";

import realmobilebanner from "../../../assets/homeimages/RealEstate_Banner.svg";
import insurancemobilebanner from "../../../assets/homeimages/Investment_Banner.svg";
import mortgagemobilebanner from "../../../assets/homeimages/Mortgage_Image.svg";
import investmentmobilebanner from "../../../assets/homeimages/Insurance_Image.svg";
import Bankingmobilebanner from "../../../assets/homeimages/Banking_Image.svg";

import askimg from "../../../assets/homeimages/askbtn.svg";
import cat1 from "../../../assets/homeimages/blackblackicon.png";
import topcatimgicon1 from "../../../assets/homeimages/whiteiconall.png";
import dropbox from "../../../assets/images/dropbox.svg";
import { onOfferClick } from '../../../shared/helper';
import { InstituteList } from "../../../components/common/instituteList/instituteList.jsx"
import { ProductCardList } from "../../../components/common/productCardList/productCardList.jsx"
import SubCategoryCardList from "../../common/subCategoryCardList/subCategoryCardList.jsx"
import { AskTheExpertBanner } from "../../common/askTheExpertBanner/askTheExpertBanner.jsx"




// Import css files
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { Link } from "react-router-dom";
import Slider from "react-slick";
import AOS from 'aos';
import 'aos/dist/aos.css';
import { useLocation } from 'react-router-dom/cjs/react-router-dom.min';
import Tab from 'react-bootstrap/Tab';
import Tabs from 'react-bootstrap/Tabs';

import "../home/home.css";
import "../home/homeresponsive.css";

import { useHistory } from "react-router-dom";
import { useRef } from 'react';
import ExpertCardJsx from '../../common/expertCard/expertCard.jsx';
import SearchNowJsx from '../../common/searchNowStrip/searchNowStrip.jsx';


// import MagicSliderDots from 'react-magic-slider-dots';
// import 'react-magic-slider-dots/dist/magic-dots.css';



const HomeJsx = (props) => {


    const { t } = useTranslation();
    const [active, setActive] = useState(false);
    const {
        blogData, onSelectBlogType,
        productData, featureProduct, featureOffer, getOfferById, getProductById, getOfferData,
        getProductData, expertDataFull, sponsorAddsData, offerData, instituteData, selectedOfferCat, selecteddProductCat
    } = props;

    const location = useLocation()
    const history = useHistory();



    useEffect(() => {
        AOS.init();
        const hash = window.location.hash;
        if (hash) {
            // Remove the '#' character to get the section ID
            const sectionId = hash.substring(1);

            // Find the element with the corresponding ID
            const sectionElement = document.getElementById(sectionId);

            if (sectionElement) {
                // Scroll to the section
                sectionElement.scrollIntoView({ behavior: 'smooth' });

            }
        } else {
            window.scrollTo(0, 0);
        }
        // alert(hash) 
    }, [location])

    var rimibslidermobile = {
        infinite: true,
        speed: 500,
        slidesToShow: 1,
        slidesToScroll: 1,
        autoplay: false,
        autoplaySpeed: 1500,
        adaptiveHeight: true,
        arrows: false,
        cssEase: 'cubic-bezier(0.7, 0, 0.3, 1)',
        dots: false,
        responsive: [
            {
                breakpoint: 1024,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1,
                    infinite: false,
                    dots: false
                }
            },
            {
                breakpoint: 600,
                settings: {
                    dots: false,
                    arrows: true,
                }
            },
            {
                breakpoint: 480,
                settings: {
                    dots: false, arrows: true,

                }
            },
            {
                breakpoint: 320,
                settings: {
                    dots: false, arrows: true,

                }
            }
        ]

    };




    const sliderSettings = {
        infinite: true,
        speed: 500,
        slidesToShow: 1,
        slidesToScroll: 1,
        autoplay: true,
        autoplaySpeed: 5000,
        adaptiveHeight: true,
        //cssEase: 'cubic-bezier(0.7, 0, 0.3, 1)',
        arrows: false,
        dots: false,
        responsive: [
            {
                breakpoint: 1024,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1,
                    infinite: false,
                    dots: false
                }
            },
            {
                breakpoint: 600,
                settings: {
                    dots: false,
                    speed: 1000,  // Transition speed in milliseconds
                    fade: true,    // Enables fade effect
                    cssEase: 'linear', // Smooth transition
                    autoplay: true,
                    autoplaySpeed: 2000

                }
            },
            {
                breakpoint: 480,
                settings: {
                    dots: false,
                    speed: 1000,  // Transition speed in milliseconds
            fade: true,    // Enables fade effect
            cssEase: 'linear', // Smooth transition
            autoplay: true,
            autoplaySpeed: 2000

                }
            },
            {
                breakpoint: 320,
                settings: {
                    dots: false,
                    speed: 1000,  // Transition speed in milliseconds
                    fade: true,    // Enables fade effect
                    cssEase: 'linear', // Smooth transition
                    autoplay: true,
                    autoplaySpeed: 2000

                }
            }
        ]
    }



    const [activeButton, setActiveButton] = useState('insurance');
    const [showMoreOffer, setShowMoreOffer] = useState(false);
    const [showMoreProduct, setShowMoreProduct] = useState(false);

    const handleButtonClick = (buttonType) => {
        setActiveButton(buttonType);
    };
    const [name, setName] = useState('');
    const handleChange = (event) => {
        const { value } = event.target;
        const isValid = /^[a-zA-Z\s]*$/.test(value);
        if (isValid) {
            setName(value);
        }
    };


    const [openModal, setOpenModal] = useState(false);
    const [blog, setBlog] = useState({});

    const onSelectClick = (title) => {
        onSelectBlogType(title);
    }

    const [showAll, setShowAll] = useState(false);
    const handleShowAll = () => {

    };


    const initialArticlesCount = 5;



    const [activeSlider, setActiveSlider] = useState(0);
    const sliderRefs = useRef([]);

    useEffect(() => {
        // Start autoplay on the first slider initially
        if (sliderRefs.current[0]) {
            sliderRefs.current[0].slickPlay();
        }
    }, [location]);

    useEffect(() => {
        const timeoutId = setTimeout(() => {
            if (sliderRefs.current[0]) {
                sliderRefs.current[0].slickPlay();
            }
        }, 2000);
        return () => clearTimeout(timeoutId);
    }, []);

    // Function to handle end of a slider and trigger the next one
    const handleSliderEnd = (currentSliderIndex) => {
        sliderRefs.current[currentSliderIndex].slickPause(); // Stop the current slider

        const nextSliderIndex = currentSliderIndex + 1;

        if (nextSliderIndex < 5) {
            setActiveSlider(nextSliderIndex);
            sliderRefs.current[nextSliderIndex].slickGoTo(0);
            sliderRefs.current[nextSliderIndex].slickPlay(); // Start the next slider
        } else {
            setActiveSlider(0);
            sliderRefs.current[0].slickGoTo(0);
            sliderRefs.current[0].slickPlay();
        }
    };

    // Slider settings
    const sliderSettingsn = {
        autoplay: false,
        infinite: false,
        slidesToShow: 1,
        slidesToScroll: 1,
        dots: false,
        arrows: false,
        draggable: false,
        swipeToSlide: false,
        touchMove: false,
        afterChange: (current) => {
            let noOfslide = sliderRefs.current[activeSlider].innerSlider.list.querySelectorAll('.slick-slide').length

            // If at the last slide of the active slider, trigger handleSliderEnd
            if (current === (noOfslide - 1)) {
                handleSliderEnd(activeSlider);
            }
        },
    };

    return (
        <>
            <section className="homecustom">
                {/* start */}
                <section className="herosection p-0 overflow-hidden herosectionhid d-none d-sm-block">
                    <div className="container-fluid p-0">
                        <div className="heroinner row">
                            <div className="col-2 nopad p-0 impspace" id={`offerslide-1`}>
                                <Slider ref={(el) => (sliderRefs.current[0] = el)} {...sliderSettingsn}>
                                    <div>
                                        <div className="rimib-block" id="rbg">
                                            <Link to={'/realEstate'}>
                                                <div className="rimib-block-letter">R</div>
                                                <div className="rimib-block-letter-text">{t("HOMEPAGE.REALESTATE")}</div>
                                                <div className="rimib-block-letter-image">
                                                <img src={rimib11} alt='realestate'/>
                                                </div>
                                            </Link>
                                        </div>
                                    </div>


                                    {
                                        sponsorAddsData?.['Real Estate'].map(offer => {
                                            return <div className="hover-content p-0">
                                                <div className="hover-content-inner">
                                                    <img src={`${NEW_URL}/${offer?.imageUrl}`} alt="realEstate" className="mainadiamge" />
                                                    <h3 className="mar20">{offer?.subInstitutionCategory?.name}</h3>
                                                    <p className="mb-2 mainsliderdescription">{offer?.description}</p>
                                                    <div className="text-center">
                                                        <a className="findbtn" href={(offer?.externalUrl)} target="_blank">{offer?.offerName}</a>
                                                    </div>
                                                </div>
                                                <div className="text-center mb-3" style={{ display: "flex", justifyContent: "center" }}>
                                                    <Link to={'/realEstate'} className="realbtn" style={{ display: "flex", justifyContent: "center", alignItems: "center" }}><img src={rimib11} className="me-3" alt="" /> {offer?.parentInstitutionCategory}</Link>
                                                </div>
                                            </div>
                                        })
                                    }
                                    <div>
                                        <div className="rimib-block" id="rbg">
                                            <Link to={'/realEstate'}>
                                                <div className="rimib-block-letter">R</div>
                                                <div className="rimib-block-letter-text">{t("HOMEPAGE.REALESTATE")}</div>
                                                <div className="rimib-block-letter-image">
                                                <img src={rimib11}  alt='realestate'/>
                                                </div>
                                            </Link>
                                        </div>
                                    </div>

                                </Slider>
                            </div>
                            {/* 2 */}
                            <div className="col-2 nopad p-0 impspace">
                                <Slider ref={(el) => (sliderRefs.current[1] = el)} {...sliderSettingsn}>
                                    <div>
                                        <div className="rimib-block rimib-block-two" id="rbg2">
                                            <Link to={'/investment'}>
                                                <div className="rimib-block-letter">I</div>
                                                <div className="rimib-block-letter-text">{t("HOMEPAGE.INVESTMENT")}</div>
                                                <div className="rimib-block-letter-image">
                                                <img src={rimib22} alt='investment' />
                                                </div>
                                            </Link>
                                        </div>
                                    </div>

                                    {
                                        sponsorAddsData?.Investment?.map(offer => {
                                            return <div className="hover-content-too p-0 mb-5">
                                                <div className="hover-content-inner-too ">
                                                    <img src={`${NEW_URL}/${offer?.imageUrl}`} alt="investment" className="mainadiamge" />
                                                    <h3 className="mar20">{offer?.subInstitutionCategory?.name}</h3>
                                                    <p className="mb-2 mainsliderdescription">{offer?.description}</p>
                                                    <div className="  text-center ">
                                                        <a className="findbtn-too" href={(offer?.externalUrl)} target="_blank">{offer?.offerName}</a>
                                                    </div>
                                                </div>
                                                <div className=" text-center mb-3" style={{ display: "flex", justifyContent: "center" }}>
                                                    <Link to={'/investment'} className="realbtn-too " style={{ display: "flex", justifyContent: "center", alignItems: "center" }}><img className="me-3" src={rimib22} alt="" /> {offer?.parentInstitutionCategory}</Link>
                                                </div>
                                            </div>
                                        })
                                    }
                                    <div>
                                        <div className="rimib-block rimib-block-two" id="rbg2">
                                            <Link to={'/investment'}>
                                                <div className="rimib-block-letter">I</div>
                                                <div className="rimib-block-letter-text">{t("HOMEPAGE.INVESTMENT")}</div>
                                                <div className="rimib-block-letter-image">
                                                <img src={rimib22}  alt='investment'/>
                                                </div>
                                            </Link>
                                        </div>
                                    </div>

                                </Slider>
                            </div>
                            {/* 2 */}
                            {/* 3 */}
                            <div className="col-2 nopad p-0 impspace">
                                <Slider ref={(el) => (sliderRefs.current[2] = el)} {...sliderSettingsn}>
                                    <div>
                                        <div className="rimib-block rimib-block-three" id="rbg3">
                                            <Link to={'/mortgage'}>
                                                <div className="rimib-block-letter">M</div>
                                                <div className="rimib-block-letter-text">{t("HOMEPAGE.MORTGAGE")}</div>
                                                <div className="rimib-block-letter-image"><img src={rimib33} alt='mortgage'/></div>
                                            </Link>
                                        </div>
                                    </div>


                                    {
                                        sponsorAddsData?.Mortgage?.map(offer => {
                                            return <div className="hover-content-three p-0">
                                                <div className="hover-content-inner-three">
                                                    <img src={`${NEW_URL}/${offer?.imageUrl}`} alt="mortgage" className="mainadiamge" />
                                                    <h3 className="mar20">{offer?.subInstitutionCategory?.name}</h3>
                                                    <p className="mb-2 mainsliderdescription">{offer?.description}</p>
                                                    <div className="  text-center ">
                                                        <a className="findbtn-three" href={(offer?.externalUrl)} target="_blank">{offer?.offerName}</a>
                                                    </div>
                                                </div>
                                                <div className=" text-center mb-3" style={{ display: "flex", justifyContent: "center" }}>
                                                    <Link to={'/mortgage'} className="realbtn-three" style={{ display: "flex", justifyContent: "center", alignItems: "center" }}><img className="me-3" src={rimib33} alt="" />{offer?.parentInstitutionCategory}</Link>
                                                </div>
                                            </div>
                                        })
                                    }
                                    <div>
                                        <div className="rimib-block rimib-block-three" id="rbg3">
                                            <Link to={'/mortgage'}>
                                                <div className="rimib-block-letter">M</div>
                                                <div className="rimib-block-letter-text">{t("HOMEPAGE.MORTGAGE")}</div>
                                                <div className="rimib-block-letter-image"><img src={rimib33}  alt='mortgage'/></div>
                                            </Link>
                                        </div>
                                    </div>

                                </Slider>
                            </div>
                            {/* 3/ */}
                            {/* 4 */}
                            <div className="col-2 nopad p-0 impspace">
                                <Slider ref={(el) => (sliderRefs.current[3] = el)} {...sliderSettingsn}>
                                    <div>
                                        <div className="rimib-block rimib-block-four" id="rbg4">
                                            <Link to={'/insurance'}>
                                                <div className="rimib-block-letter">I</div>
                                                <div className="rimib-block-letter-text">{t("HOMEPAGE.INSURANCE")}</div>
                                                <div className="rimib-block-letter-image"><img src={rimib44}  alt='insurance'/></div>
                                            </Link>
                                        </div>
                                    </div>

                                    {
                                        sponsorAddsData?.Insurance?.map(offer => {
                                            return <div className="hover-content-four p-0">
                                                <div className="hover-content-inner-four">
                                                    <img src={`${NEW_URL}/${offer?.imageUrl}`} alt="insurance" className="mainadiamge" />
                                                    <h3 className="mar20">{offer?.subInstitutionCategory?.name}</h3>
                                                    <p className="mb-2 mainsliderdescription">{offer?.description}</p>
                                                    <div className="  text-center">
                                                        <a className=" findbtn-four" href={(offer?.externalUrl)} target="_blank">{offer?.offerName}</a>
                                                    </div>
                                                </div>
                                                <div className=" text-center mb-3" style={{ display: "flex", justifyContent: "center" }}>
                                                    <Link to={'/insurance'} className=" realbtnfour" style={{ display: "flex", justifyContent: "center", alignItems: "center" }}>
                                                        <img className="me-3" src={rimib44} alt='insurance' />{offer?.parentInstitutionCategory}</Link>
                                                </div>
                                            </div>
                                        })
                                    }
                                    <div>
                                        <div className="rimib-block rimib-block-four" id="rbg4">
                                            <Link to={'/insurance'}>
                                                <div className="rimib-block-letter">I</div>
                                                <div className="rimib-block-letter-text">{t("HOMEPAGE.INSURANCE")}</div>
                                                <div className="rimib-block-letter-image"><img src={rimib44} alt='insurance'/></div>
                                            </Link>
                                        </div>
                                    </div>

                                </Slider>
                            </div>
                            {/* 4/ */}
                            {/* 5 */}
                            <div className="col-2 nopad p-0 impspace">
                                <Slider ref={(el) => (sliderRefs.current[4] = el)} {...sliderSettingsn}>
                                    <div>
                                        <div className="rimib-block rimib-block-five" id="rbg5">
                                            <Link to={'/banking'}>
                                                <div className="rimib-block-letter">B</div>
                                                <div className="rimib-block-letter-text">{t("HOMEPAGE.BANKING")}</div>
                                                <div className="rimib-block-letter-image"><img src={rimib55} alt='banking'/></div>
                                            </Link>
                                        </div>
                                    </div>

                                    {
                                        sponsorAddsData?.Banking?.map(offer => {
                                            return <div className="hover-content-five p-0">
                                                <div className="hover-content-inner-five">
                                                    <img src={`${NEW_URL}/${offer?.imageUrl}`} alt="banking" className="mainadiamge" />
                                                    <h3 className="mar20">{offer?.subInstitutionCategory?.name}</h3>
                                                    <p className="mb-2 mainsliderdescription">{offer?.description}</p>
                                                    <div className="  text-center  ">
                                                        <a className="findbtn-five" href={(offer?.externalUrl)} target="_blank">{offer?.offerName}</a>
                                                    </div>
                                                </div>
                                                <div className=" text-center mb-3" style={{ display: "flex", justifyContent: "center" }}>
                                                    <Link to={'/banking'} className="realbtn-five " style={{ display: "flex", justifyContent: "center", alignItems: "center" }}>
                                                        <img className="me-3" src={rimib55} alt="banking" /> {offer?.parentInstitutionCategory}</Link>
                                                </div>
                                            </div>
                                        })
                                    }
                                    <div>
                                        <div className="rimib-block rimib-block-five" id="rbg5">
                                            <Link to={'/banking'}>
                                                <div className="rimib-block-letter">B</div>
                                                <div className="rimib-block-letter-text">{t("HOMEPAGE.BANKING")}</div>
                                                <div className="rimib-block-letter-image"><img src={rimib55}  alt='banking'/></div>
                                            </Link>
                                        </div>
                                    </div>

                                </Slider>
                            </div>
                            {/* 5/ */}
                        </div>
                    </div>
                </section>
                {/* end */}
             <HomeMobileBannerJsx/>
                
             


                <AskTheExpertBanner askimg={askimg} homeText={t("HOMEPAGE.HOME")} expertTitle={t("HOMEPAGE.MENU1")} expertSubTitle={t("HOMEPAGE.MENU2")} expertboldSubTitle={t("HOMEPAGE.MENU3")} askTheExpertText={t("COMMON.ASKTHEEXPERTS")} />

                <SubCategoryCardList
                    showMoreData={showMoreOffer}
                    selectedData={selectedOfferCat}
                    getData={getOfferData}
                    getById={getOfferById}
                    cat1={cat1}
                    feature={featureOffer}
                    setShowMore={setShowMoreOffer}
                    heading={t("HOMEPAGE.INTERESTEDHEADING")}
                    subHeading={t("HOMEPAGE.INTERESTEDSUBHEADING")}
                />

                <section className="exclusive">
                    <OfferCardListJSX
                        SubCatTitle={t("HOMEPAGE.TOPOFFERTITLE")}
                        SubCatText={t("HOMEPAGE.TOPOFFERSUBTITLE")}
                        offerData={offerData}
                        redirectUrl="/offers"
                        isDescription={false}
                        isSlider={true}
                    />
                </section>

                <ProductCardList
                    selecteddProductCat={selecteddProductCat}
                    showMoreProduct={showMoreProduct}
                    getProductData={getProductData}
                    getProductById={getProductById}
                    topcatimgicon1={topcatimgicon1}
                    featureProduct={featureProduct}
                    setShowMoreProduct={setShowMoreProduct}

                    productData={productData}
                    productTitle={t("HOMEPAGE.TOPPRODUCTTITLE")}
                    productsubTitleText={t("HOMEPAGE.TOPPRODUCTSUBTITLE")}
                    subCategoryLayout={false}
                    onProductClick={onOfferClick}
                    isCategorySubCategory={false}
                    isProductName={false}
                    isViewButton={false} />


                <section className="ourbest">
                    <div className="container">
                        <div className="ourbexttext ourbestmargin" >
                            <h2>{t("HOMEPAGE.EXPERTHEADING")}</h2>
                            <p className="mb-0">{t("HOMEPAGE.EXPERTHEADING1")} {t("HOMEPAGE.EXPERTHEADING2")}</p>
                        </div>
                        <div className="row" style={{ justifyContent: "center", alignItems: "center" }}>
                            {
                                expertDataFull?.map(category => {
                                    return <div className={`col-lg-2 col-12 category-id${category?.institutionCategoryId}`}>
                                        <Slider {...sliderSettings}>
                                            {
                                                category?.experts?.map(expert => {
                                                    return <ExpertCardJsx
                                                        data={expert}
                                                        category={category}
                                                    />

                                                })
                                            }
                                        </Slider>
                                    </div>
                                })
                            }
                        </div>
                        <div className="showallourbesttwo  text-center mt-3 mb-3 mb-5">
                            <Link to={'/globalMainSearchExpert#searchexpert'} className="showallourbesttoo">{t("COMMON.SHOWALL")}</Link>
                        </div>
                    </div>
                </section>

                <SearchNowJsx type={"expert"} />

                <section className="articles">
                    <div className="container">
                        <div className="articles-text">
                            <h2>{t("HOMEPAGE.ARTICLES")}</h2>
                            <p>{t("HOMEPAGE.ARTICLESSUBTITLETWO")} </p>
                        </div>

                        <div className="articles-box ">
                            <div className="main-articles-box">

                                <div className="item1">
                                    {
                                        blogData?.body?.length > 0 ? (
                                            blogData.body.slice(0, showAll ? blogData.body.length : initialArticlesCount).map(blog => (
                                                <HomeArticleCardJSX
                                                    blogslug={blog?.slug}
                                                    blogimage={blog?.image}
                                                    blogtitle={blog?.title}
                                                    blogshort_content={blog?.short_content}
                                                />

                                            ))
                                        ) : (
                                            <div className="dropdefault text-center">
                                                <div className="dropdefaultimage">
                                                    <img src={dropbox} alt="No blogs" />
                                                </div>
                                                <div className="dropdefaulttext">
                                                    <h5>{t("COMMON.TAGLINEDEFAULT")}</h5>
                                                </div>
                                            </div>
                                        )
                                    }
                                </div>
                            </div>
                        </div>

                        <div className="showallourbesttwo text-center mt-3">
                            {!showAll ? (
                                <div className="showallourbesttwo  text-center mt-0 mb-0">
                                    <button className="showallourbesttoo mt-0 mb-0" onClick={() => { history.push(`/searchArticles`); }} >{t("COMMON.SHOWALL")}</button>
                                </div>
                            ) : (
                                <div className="showallourbesttwo  text-center mt-0 mb-5">
                                    <button className="showallourbesttoo mt-0 mb-4" onClick={handleShowAll}>{t("COMMON.SHOWALL")}</button>
                                </div>
                            )}
                        </div>
                    </div>
                </section>

                <InstituteList
                    instituteData={instituteData}
                    bestInstituteText={t("HOMEPAGE.INSTITUTELIST")}
                    topRatedText={t("HOMEPAGE.INSTITUTELISTLINEONE")}
                    checkOutText={t("HOMEPAGE.INSTITUTELISTLINETWO")}
                    finalcialText={t("HOMEPAGE.INSTITUTELISTLINETHREE")} />

                <SearchNowJsx type={"institute"} />

                <Footer />
            </section>
        </>

    );
};

export default HomeJsx;
