import React from 'react' 
import { Component } from 'react';  
import ArticleDetailsJsx from './articleDetails.jsx';

import axios from 'axios';
import { BLOG_URL, BLOG_URL_TOKEN } from '../../../../constants/applicationConstants.js'; 
import { Loader } from '../../../../components/common/loader/loader.jsx';

export default class articleDetails extends Component {
     
    constructor(props) {
        super(props);
        this.state = {
            detailsData: null,
            isLoading:false
        }
    }


    onDataDetails = ( ) => {
        
        this.setState({ isLoading: true })
        
        var data = new FormData(); 
        data.append('device_token', '20.204.66.244'); 
        data.append('slug',`${this.props.match.params.id}`)
        
        
        axios.post(`${BLOG_URL}`, data,
            {
                headers: { 'Eba-access-api-base-token': BLOG_URL_TOKEN }
            }).then(res => {
                
                this.setState({ detailsData: res.data, isLoading: false }); 
            }).catch(err => {
                this.setState({ isLoading: false })
            })
    }


    componentDidMount = () => {
        
        this.onDataDetails();
    }


    render() {
        return (
            <div>
                <ArticleDetailsJsx
                 detailsData={this.state.detailsData}  
                 isLoading={this.isLoading}/>

                    {this.state.isLoading && <Loader />}
            </div>
        )
    }
}