import { NEW_URL } from "../../../../constants/applicationConstants";
import { formatCanadianPhoneNumber } from "../../../../shared/helper";


export default function SpecializationCard(props) {
    const { showData, expertSpec, activeIndex, index, ...rest } = props
    return (
        <>
            <div className={`latestdeailsexpertppvspecialize ${activeIndex === index ? 'active' : ''} ${expertSpec?.parentInstitutionCategory} category-id${expertSpec?.institutionCategoryId}`}>
                <div className="bg-white-new-exp">
                    <div className="disgrid grid-2">
                        <div className="detailsbank">
                            <h5>{showData?.businessName}</h5>
                        </div>
                        {/* <div className="detailsbank">
                                                    <h5>{showData?.businessName}</h5>
                                                </div> */}
                        <div className="detailsbank">
                            <h5>{showData?.businessEmail}</h5>
                        </div>

                        <div className="detailsbank">
                            <h5>{showData?.businessLicenseNumber} </h5>
                        </div>
                        <div className="detailsbank">
                            <h5>{showData?.businessPhoneNumber && formatCanadianPhoneNumber(showData?.businessPhoneNumber)}</h5>
                        </div>
                        <div className="detailsbank">

                            <h5>{showData?.address},{showData?.state?.name},{showData?.city},{showData?.country?.name},{showData?.postalCode}  </h5>
                        </div>
                    </div>
                </div>
                <div className="bg-white-new-exp">
                    <div className="detailsbank subcategoryposition">
                        <h5>Areas of Expertise</h5>
                        <div className="categoryonesetwidthexpert">
                            <div className="categoryonesetwidthinner ">
                                {showData?.expertSpecializationSubCategories?.map((item1, i) => (
                                    <span>
                                        <div className="iconone">
                                            <img src={`${NEW_URL}/${item1?.subInstitutionCategory?.icon}`} alt={item1?.subInstitutionCategory
                                                ?.name} />
                                        </div>
                                        {item1?.subInstitutionCategory
                                            ?.name}</span>
                                ))}
                            </div>
                        </div>
                    </div>

                </div>
                <div className="bg-white-new-exp">

                    <div className="disgrid grid-2">
                        <div className="detailsbank">

                            <h5>{showData?.institute?.name} - {showData?.institute?.instituteLicenses.map((e, key) => (
                                <span key={key}>{e?.licenseNumber || 'N/A'}</span>
                            ))}
                                {/* <Link to={showData?.businessWebsite} target="_blank"><a href={showData?.businessWebsite}>{showData?.businessWebsite}</a></Link> */}
                                <span >{showData?.businessLicenseNumber || 'N/A'}</span>
                            </h5>
                        </div>

                    </div>
                </div>
            </div>
        </>
    )
}