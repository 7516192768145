import React, { useState, useRef } from 'react'
import InputMask from 'react-input-mask';
import ReCAPTCHA from "react-google-recaptcha";
import phone from "../../../../assets/Instituteppvimages/phone.svg";
import user from "../../../../assets/Instituteppvimages/user.svg";
import envelop from "../../../../assets/Instituteppvimages/mail-pencil.svg";
import textareamessage from "../../../../assets/Instituteppvimages/textareamessage.svg";
import { CAPTCHA_SECRET_KEY } from "../../../../constants/applicationConstants";
import { useTranslation } from "react-i18next";
export const GetInTouchSection = (props) => {
    const {
        profileData,
        onInputChangeHandler,
        onTouchHandler,
        userData,
        formError,
        onSendMessageClick,
        planName,
        formSubmitButtonDisable
    } = props;
    console.log('profileData',profileData);
    const [recapchaVerified, setRecaptchaVerified] = useState(false);
    const onChange = (value) => {
        if (value) {
            setRecaptchaVerified(true);
        }
    }
    const { t } = useTranslation();
    let flowName = window.location.href.includes('expertPpv')
    const recaptchaRef = useRef();

    return <section className={`expertformppi ${planName}`} id="contact">
        <div className="container">
            <div className="disgrid grid-2">
                <div>
                    <h2>{t("GETINTOUCH.GETINTOUCHTITLE")}</h2>
                    <h4>{t("GETINTOUCH.GETINTOUCHSUBTITLE")}</h4>
                    
                    <div className="expertformppiinner-git">
                        <div className="expertformppiinnerdis-git">
                            <div className="mb-4 input-container-git form-group-git">
                                <input type="text" onChange={onInputChangeHandler} onBlur={onTouchHandler} name='name' 
                                 value={userData.name} className="input-git form-control-git"  id="getname" placeholder={t("GETINTOUCH.NAME")} maxLength={60} />
                                
                                <span className="text-danger">
                                    {formError['name'] &&
                                        (formError['name'] === "req"
                                            ? 'Enter a valid Name'
                                    : 'Enter a valid Name')}
                                </span>
                                <span className="formfieldicon"><img src={user} alt='user'/></span>
                            </div>
                            <div className="mb-4 input-container-git form-group-git">
                                <InputMask mask={'(999) 999 9999'} value={userData.phone} name="phone" onChange={onInputChangeHandler} onBlur={onTouchHandler}>
                                    {(inputProps) => <input {...inputProps} type="tel"  id="gettel" className="input-git form-control-git" placeholder={t("GETINTOUCH.PHONE")} disableUnderline />}
                                </InputMask>
                                
                                <span className="text-danger">
                                    {formError['phone'] &&
                                        (formError['phone'] === "req"
                                        ? 'Enter a valid Phone Number'
                                        : 'Enter a valid Phone Number')}
                                </span>
                                <span className="formfieldicon"><img src={phone} alt='phone'/></span>
                            </div>
                        </div>
                        <div className="expertformppiinnerdis-git">
                            <div className="mb-4 input-container-git form-group-git">
                                <input type="text" name='email' onChange={onInputChangeHandler} onBlur={onTouchHandler} value={userData.email} className="input-git form-control-git" id="getemail" placeholder={t("GETINTOUCH.EMAIL")} maxLength={60} />
                                
                                <span className="text-danger">
                                    {formError['email'] &&
                                        (formError['email'] === "req"
                                        ? 'Enter a valid Email Address'
                                        : 'Enter a valid Email Address')}
                                </span>
                                <span className="formfieldicon"><img src={envelop} alt='mail'/></span>
                            </div>
                            <div className="input-container-git form-group-git">
                                <select className="input-git form-control-git form-select" onChange={onInputChangeHandler} name='phoneNumber' placeholder={t("GETINTOUCH.CATEGORY")}>
                                    <option value=''>{t("GETINTOUCH.SELECT")}</option>
                                    {
                                        flowName ?
                                            profileData?.expertSpecializations?.map(cat => {
                                                return <option value={cat?.instituteCategoryId}>{cat?.parentInstitutionCategory}</option>
                                            })
                                            :
                                            profileData?.instituteCategories?.map(cat => {
                                                return <option value={cat.instituteCategoryId}>{cat.institutionCategory?.name}</option>
                                            })
                                    }
                                </select>
                                
                            </div>
                        </div>
                        <div className="ppvgitmessage">
                            <div className="input-container-git form-group-git">
                                <textarea className="input-git form-control-git textareaform" id="gettext" onChange={onInputChangeHandler} onBlur={onTouchHandler} name='message' value={userData.message} placeholder={t("GETINTOUCH.MESSAGE")} maxLength={250}></textarea>
                                <span className="text-danger">
                                    {formError['message'] &&
                                        (formError['message'] === "req"
                                        ? 'Message is required'
                                        : '')}
                                </span>
                                <span className="formfieldicon" ><img src={textareamessage} alt='message' /></span>
                            </div>
                            <div className="recaptcha_css">
                                <ReCAPTCHA
                                    ref={recaptchaRef}
                                    sitekey={CAPTCHA_SECRET_KEY}
                                    onChange={onChange}
                                    
                                />
                            </div>
                        </div>
                        <div className="ppvgitbutton">
                            <button type="submit" disabled={formSubmitButtonDisable || !recapchaVerified } className="ctatwobutton-git" onClick={() => onSendMessageClick()}>{t("GETINTOUCH.SEND")}</button>
                        </div>
                    </div>
                </div>
                <div>

                </div>
            </div>
        </div>
    </section>

}