import axios from 'axios';
import React from 'react'
import { Component } from 'react';
import { Loader } from '../../../common/loader/loader.jsx';
import InstituteJsx from './institutes.jsx'
import { toast } from 'react-toastify';
import { baseURL } from '../../../../constants/applicationConstants.js';
import { nameRegex, postalCodeRegex } from '../../../../shared/helper.js';

export default class Institutes extends Component {
    constructor(props) {
        super(props);
        this.state = {
            instituteData: null,
            wholeData: null,
            resetPagination: false,
            subCategoryData: null,
            formData: {
                instituteName: '',
                productName: '',
                institutionCategory: '',
                status:'',
                homeFeatured: ''
            },
            page: 1,

            categoryData: null,
            formError: {},
            formSubmitButtonDisable: true,
            isLoading: false,
            expertData: [],
            checked: false

        }
    }

    componentDidMount = () => {
        this.getInstitute()
        this.getCategories()
        const token = JSON.parse(sessionStorage.getItem('token'));

        if (!token) {
            this.props.history.push("/admin");
        }
    }
    getCategories = () => {
        this.setState({ isLoading: true })
        let token = JSON.parse(sessionStorage.getItem('token'));
        axios.get(`${baseURL}/institutionCategoriesAndSubCategories`, {
            headers: { 'x-access-token': token }
        }).then(res => {
            this.setState({ categoryData: res.data.data, isLoading: false })
        }).catch(err => {
            this.setState({ isLoading: false })
        })
    }

    getInstitute = () => {
        this.setState({ isLoading: true })
        let token = JSON.parse(sessionStorage.getItem('token'));
        const { instituteName, institutionCategory,status } = this.state.formData;

        axios.get(`${baseURL}/searchInstitutes?page=${this.state.page}&perPage=10&name=${instituteName}&institutionCategoryId=${institutionCategory}&status=${status}`, {
            headers: {
                'x-access-token': token
            }
        }
        ).then(res => {
            this.setState({ instituteData: res.data.data, isLoading: false })
            this.setState({ wholeData: res.data, isLoading: false })

        }).catch(err => {
            this.setState({ isLoading: false })
        })
    }
    approveExpert = (id, action) => {
        this.setState({ isLoading: true });
        const token = JSON.parse(sessionStorage.getItem('token'));

        let payload = {
            "status": action
        }
        axios.put(`${baseURL}/institute/${id}/status`, payload, {
            headers: {
                'x-access-token': token
            }
        }).then(res => {
            this.getInstitute()
        }).catch(err => {
            this.setState({ isLoading: false });
            toast.error(err?.response.data.error.message);
        })
    }
    forceLoginInstitute = (data) => {
        const token = JSON.parse(sessionStorage.getItem('token'));
        const loginData = sessionStorage.getItem('loginData');

        this.setState({
            isLoading: true
        })

        axios.post(`${baseURL}/institute/${data}/login`, null, {
            headers: {
                'x-access-token': token
            }
        }).then((res) => {
            sessionStorage.setItem("adminOldToken", token);
            sessionStorage.setItem("adminLoginData", loginData);

            sessionStorage.setItem("auth", JSON.stringify(res?.data?.data));
            sessionStorage.setItem("token", JSON.stringify(res.data?.data?.accessToken));
            sessionStorage.setItem("isLoggedIn", true);
            sessionStorage.setItem("masterID", JSON.stringify(res.data?.data?.instituteId));
            sessionStorage.setItem("loginData", JSON.stringify(res.data?.data));
            localStorage.setItem('flowName', 'institute')
            localStorage.setItem('flowType', 'institute')
            sessionStorage.setItem("loginAs",'institute')

            this.setState({
                isLoading: false
            })

            toast.success("You have logged-in successfully");
            setTimeout(() => {
                this.props.history.push("/dashboard");
                window.location.reload()
            }, 2000)

        }).catch((res) => {
            toast.error(res?.response.data.error.message);
            this.setState({
                isLoading: false
            })
        })

    };

    onFirstLoginClick = (data) =>{
        let token = JSON.parse(sessionStorage.getItem("token"))

        this.setState({
          isLoading: true
        }) 
        axios.get(`${baseURL}/institute/${data.instituteId}/details`, {
            headers: { 'x-access-token': token }
        }).then(res=>{
          this.setState({
            isLoading: false
          })
          
          sessionStorage.setItem("instituteId", JSON.stringify(res.data?.data?.instituteId));
          sessionStorage.setItem("resumeOnBoard",true)

          sessionStorage.setItem("adminOldToken", token);
          sessionStorage.setItem("token", JSON.stringify(res.data?.data?.tokenValidate));
          localStorage.setItem('flowName', 'institute')
          localStorage.setItem('flowType', 'institute')
          localStorage.setItem('AdminFlow', 'institute')
          this.props.history.replace('/')
            if(res.data.data.onboardingStep === 'subscription-paid')
              this.setState({isFirstTime:false});
            else if(res.data.data.onboardingStep === 'basic-info')
              this.props.history.push('./plans')
            else if(res.data.data.onboardingStep === 'verify-otp')
              this.props.history.push('./plans')
            else
              this.props.history.push('./newPassword')
        }).catch(err=>{
          toast.error(err.response.data.error.message);
          this.setState({
            isLoading: false
          })
        })
      }

    //   complete registration
    completeRegistration = (data) =>{
        let token = JSON.parse(sessionStorage.getItem("token"))

        this.setState({
          isLoading: true
        }) 
        axios.get(`${baseURL}/institute/${data.instituteId}/details`, {
            headers: { 'x-access-token': token }
        }).then(res=>{
          this.setState({
            isLoading: false
          })
          
          sessionStorage.setItem("instituteId", JSON.stringify(res.data?.data?.instituteId));
          sessionStorage.setItem("resumeOnBoard",true)

          sessionStorage.setItem("adminOldToken", token);
          sessionStorage.setItem("token", JSON.stringify(res.data?.data?.tokenValidate));
          localStorage.setItem('flowName', 'institute')
          localStorage.setItem('flowType', 'institute')
          localStorage.setItem('AdminFlow', 'institute')
        //   this.props.history.replace('/')
          this.props.history.push('/institutionalRegistration')

        }).catch(err=>{
          toast.error(err.response.data.error.message);
          this.setState({
            isLoading: false
          })
        })
      }

      onPaginationButtonClick = (page) => {
        window.scrollTo(0, 0);
        this.setState({ page: page }, () => this.getInstitute())
    }

    handleChange = (e, id) => {
        const { checked } = e.target
        this.setState({
            checked: checked
        })
        this.makeFeature(id, checked)
    }
    makeFeature = (id, action) => {
        const token = JSON.parse(sessionStorage.getItem('token'));

        this.setState({ isLoading: true });
        let data = {
            "isFeatured": action
        }
        axios.put(`${baseURL}/institute/${id}/featured`, data, {
            headers: {
                'x-access-token': token
            }
        }).then(res => {
            this.getInstitute()
        }).catch(err => {
            this.setState({ isLoading: false });
            toast.error(err?.response.data.error.message);
        })
    }

    onCatgoryClick = (value) => {
        const subData = this.state.categoryData.filter(itm => itm.institutionCategoryId == value)
        this.setState({ subCategoryData: subData[0]?.subInstitutionCategories })
    }

    onInputChange = (e) => {
        let name = e.target.name;
        let value = e.target.value;

        if (name === 'category')
        {
            this.onCatgoryClick(value)
        }
        if (value) {
            if (!this.inputRegexValidation(name, value)) {
                this.setState(
                    {
                        formData: {
                            ...this.state.formData,
                            [name]: value,
                        },
                        formError: {
                            ...this.state.formError,
                            [name]: "regx",
                        },
                    },
                    () => this.isFromSubmissionBlock()
                );
            } else {
                this.setState(
                    {
                        formData: {
                            ...this.state.formData,
                            [name]: value,
                        },
                        formError: {
                            ...this.state.formError,
                            [name]: "",
                        },
                    },
                    () => this.isFromSubmissionBlock()
                );
            }
        } else {
            this.setState(
                {
                    formData: {
                        ...this.state.formData,
                        [name]: value,
                    },
                    formError: {
                        ...this.state.formError,
                        [name]: "",
                    },
                },
                () => this.isFromSubmissionBlock()
            );
        }
    }

    inputRegexValidation = (name, value) => {
        if (name === 'instituteName' || name === "productName")
            return nameRegex.test(value);
        if (name === 'postalCode')
            return postalCodeRegex.test(value);

        return true;
    };

    isFromSubmissionBlock = () => {
        let { productName } = this.state.formData;

        if (productName) {
            if (
                this.inputRegexValidation('productName', productName)
            ) {
                this.setState({
                    formSubmitButtonDisable: false
                });
            }
            else {
                this.setState({
                    formSubmitButtonDisable: true
                });
            }
        } else {
            this.setState({
                formSubmitButtonDisable: true
            });
        }
    };

    onTouchHandler = (e) => {
        let name = e.target.name;
        let value = e.target.value;
        if (value === "") {
            this.setState({
                formError: {
                    ...this.state.formError,
                    [name]: "req",
                },
            });
        } else {
            if (!this.inputRegexValidation(name, value)) {
                this.setState({
                    formError: {
                        ...this.state.formError,
                        [name]: "regx",
                    },
                });
            }
        }
    };

    onSubmitButtonClick = () => {
        let token = JSON.parse(sessionStorage.getItem('token'));

        this.setState({ isLoading: true });
        const { instituteName, institutionCategory,status } = this.state.formData;
        axios.get(`${baseURL}/searchInstitutes?name=${instituteName}&perPage=10&institutionCategoryId=${institutionCategory}&status=${status}`, {
            headers: {
                'x-access-token': token
            }
        }).then(res => {
            this.setState({ instituteData: res.data.data, isLoading: false })
            this.setState({ wholeData: res.data, isLoading: false })
            this.setState({resetPagination:true},()=> this.setState({resetPagination:false}))

        }).catch(err => {
            this.setState({ isLoading: false });
            toast.error(err?.response.data.error.message);
        })
    }

    onViewProfieClick = (url) => {
        this.props.history.push(`/institute/${url}`)
    }


    render() {
        return (
            <div>
                <InstituteJsx
                    instituteData={this.state.instituteData}
                    wholeData={this.state.wholeData}

                    subCategoryData={this.state.subCategoryData}
                    formData={this.state.formData}
                    categoryData={this.state.categoryData}
                    onInputChange={this.onInputChange}
                    formError={this.state.formError}
                    onTouchHandler={this.onTouchHandler}
                    resetPagination={this.state.resetPagination}
                    handleChange={this.handleChange}
                    formSubmitButtonDisable={this.state.formSubmitButtonDisable}
                    onSubmitButtonClick={this.onSubmitButtonClick}
                    expertData={this.state.expertData}
                    onViewProfieClick={this.onViewProfieClick}
                    approveExpert={this.approveExpert}
                    forceLoginInstitute={this.forceLoginInstitute}
                    onFirstLoginClick={this.onFirstLoginClick}
                    completeRegistration={this.completeRegistration}
                    onPaginationButtonClick={this.onPaginationButtonClick}

                />
                {this.state.isLoading && <Loader />}
            </div>
        )
    }
}
