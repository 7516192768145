import React, { useState } from 'react'
import { useTranslation } from "react-i18next";
import { useEffect } from 'react';

import Phone from "../../../assets/search/phone.svg";
import location from "../../../assets/search/location.svg";
import profile_icon from "../../../assets/expertppvimage/profile_icon.svg";
import unverifiedinfo from "../../../assets/search/unverifiedinfo.svg";

import email from "../../../assets/search/email.svg";
import { NEW_URL } from '../../../constants/applicationConstants';
import styles from './expertListCard.module.css';
import { Link } from "react-router-dom";

export const ExpertListCardJSX = (props) => {

  const { t } = useTranslation();
  const [isMobile, setIsMobile] = useState(false);
  const checkMobileView = () => {
    setIsMobile(window.innerWidth <= 768);
  };
  const {
    offerimageUrl, offerbusinessPhoneNumber, offercity, offeremail, offerfirstName, offerslugUrl,
    expertSpecializations, offerplanName, status, offerlastName, offercategoryName,
    onFirstLoginClick, isResumeShowAndContactHide, isparentInstitutionCategory
  } = props;
  useEffect(() => {
    checkMobileView(); // Initial check
    window.addEventListener('resize', checkMobileView); // Listen for window resize

    return () => {
      window.removeEventListener('resize', checkMobileView); // Clean up event listener
    };
  }, []);
  return (
    <>
    {offercategoryName &&
      <div className={`${styles.institutesearchmainlistingsectionps} ${offerplanName}  ${offercategoryName} status${status}`}>

        <div className={styles.instilogoouter}>
          <div className={[styles.instilogo + " " + 'statuslogo']}>
            {status === "unverified" || status === "verified" ?
              <div className={[styles.instilogoimage + " " + 'imageborder' + " " + status]}>{status}</div>
              :
              <Link to={`/expertPpv/${offerslugUrl}`} target="_blank">
                <div className={[styles.instilogoimage + " " + 'imageborder']}><img src={!offerimageUrl
                  ? `https://ui-avatars.com/api/?name=${offerfirstName}+${offerlastName}&rounded=true`
                  : `${NEW_URL}/${offerimageUrl}`} alt='${offerfirstName}'
                /></div>
              </Link>
            }

          </div>
          {status === "unverified" || status === "verified" ?
            null :
            <div className={`${styles.plainde} expertoutershadow ${offerplanName}`}>{offerplanName || "NA"}</div>}
        </div>
        <div className={[styles.instisegricateouter + " " + 'd-flex']}>
          <div className={styles.instisegricateone}>
            <h4>{offerfirstName.toLowerCase() + " " + offerlastName.toLowerCase()}</h4>
            <div className={`${styles.instisegricatetwo} instisegricatetwops d-block d-lg-none d-sm-none d-md-none `}>

<ul>
  {
    isparentInstitutionCategory ? expertSpecializations.map(data => {
      return (
        <li key={offercategoryName} className={data.parentInstitutionCategory}>
          {data.parentInstitutionCategory}
        </li>
      );
    }) : expertSpecializations?.map(cat => cat.categoryName).map(categoryName => {
      return (
        <li key={offercategoryName} className={categoryName}>
          {categoryName}
        </li>
      );
    })
  }

</ul>
</div>
            {isResumeShowAndContactHide && <button className={`${styles.resumebutton}`} onClick={onFirstLoginClick}>{t("instituteExpertrs.RESUME")}</button>}
            <div className={styles.condetails}>
              <ul>
                {offercity && <li><span><img src={location} alt={offerfirstName + " " + offerlastName} className="imagefilter" /></span> {offercity}</li>}
                {offerbusinessPhoneNumber && <li><span><img src={Phone} alt={offerfirstName + " " + offerlastName} className="imagefilter" /></span><a href="tel:416262-5457">{offerbusinessPhoneNumber}</a> </li>}
                {email && <li><span><img src={email} alt={offerfirstName + " " + offerlastName} className="imagefilter" /></span> <a href="mailto:samar@indexrealtybrokerage.com">{offeremail}</a></li>}
              </ul>

            </div>
            {status === "unverified" || status === "verified" ?
              null : <div className={`${styles.basicdetails} d-flex`}>
                                {offerplanName === "Premium" ?
                  null :
                  !isResumeShowAndContactHide && <Link to={`/expertPpv/${offerslugUrl}#contact`} target="_blank" className={[styles.bdetail + " " + 'bdeatailglobal']}><span><img src={Phone} alt="profile Icon" /></span>{t("COMMON.CONTACTUS")}</Link>}
                {!isResumeShowAndContactHide && <Link to={`/expertPpv/${offerslugUrl}`} target="_blank" className={[styles.bdetail + " " + 'bdeatailglobal']}><span><img src={profile_icon} alt="profile Icon" /></span>{t("COMMON.VIEWPROFILE")}</Link>}
              </div>
            }
            {status === "unverified" &&
              <span className="unverifiedinfo"><img src={unverifiedinfo} /> {t("COMMON.UNVERIFIEDINFO")}</span>
            } 
          </div>
         
          <div className={`${styles.instisegricatetwo} instisegricatetwops d-none d-sm-block`}>

            <ul>
              {
                isparentInstitutionCategory ? expertSpecializations.map(data => {
                  return (
                    <li key={offercategoryName} className={data.parentInstitutionCategory}>
                      {data.parentInstitutionCategory}
                    </li>
                  );
                }) : expertSpecializations?.map(cat => cat.categoryName).map(categoryName => {
                  return (
                    <li key={offercategoryName} className={categoryName}>
                      {categoryName}
                    </li>
                  );
                })
              }

            </ul>
          </div>
        </div>
      </div>
}
    </>
  )
}
