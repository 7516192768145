import React from 'react'
import InputMask from 'react-input-mask';
import { formatCanadianPhoneNumber } from '../../../../shared/helper'
import cross from "../../../../assets/images/cross.svg";

const OtherDetailsJsx = (props) => {
  const {
    onInputChangeHandler, onOthersButtonClick, editOther,
    changeOtherState, formError, onTouchHandler
  } = props;
  const isRequired = true
  const totalInformation = props.responseData
  const information = props?.wholeData
  const getMuskPattern = (name) =>
    name === 'institutionalContact' || 'customerContact' ? '(999) 999 9999' : 'a9a 9a9'
  return (
    <>
      {!editOther ? <div className="myaccountsection institutedetails">
        <div className="myaccountsectionheading">
          <h3 className="h3_style">Other Details</h3>
          <div className="mybtn">
            <button type="button" className="myadbtn" onClick={() => changeOtherState(true)}><svg width="15" height="15" viewBox="0 0 15 15" fill="none" xmlns="http://www.w3.org/2000/svg">
<path fill-rule="evenodd" clip-rule="evenodd" d="M12.4597 5.8743L13.8341 4.49994L13.8341 4.49992C14.0686 4.26543 14.1858 4.14818 14.2703 4.03599C14.8063 3.32377 14.8063 2.34278 14.2703 1.63057C14.1858 1.51837 14.0686 1.40112 13.8341 1.16663L13.8341 1.16661L13.8341 1.16658C13.5996 0.932098 13.4823 0.814852 13.3701 0.730405C12.6579 0.194357 11.6769 0.194357 10.9647 0.730405C10.8525 0.814852 10.7353 0.932097 10.5008 1.16658L10.5007 1.16661L9.10713 2.56023C9.90404 3.94047 11.0608 5.08825 12.4597 5.8743ZM7.65253 4.01482L2.19046 9.4769C1.7654 9.90196 1.55287 10.1145 1.41314 10.3756C1.2734 10.6367 1.21446 10.9314 1.09657 11.5209L0.647836 13.7645C0.581314 14.0971 0.548053 14.2634 0.642661 14.358C0.737269 14.4526 0.903573 14.4194 1.23618 14.3529H1.23618L3.47983 13.9041C4.06928 13.7862 4.36401 13.7273 4.62511 13.5876C4.8862 13.4478 5.09873 13.2353 5.52379 12.8102L11.0014 7.33267C9.65352 6.48191 8.51135 5.34748 7.65253 4.01482Z" fill="white"/>
</svg></button>
          </div>
        </div>

        <div className="disgrid grid-2">
          <div className="form-fieldinner">
            <label>License No</label>
            <div className="fieldans">{totalInformation?.instituteLicenses?.map(x => x.licenseNumber)?.join(',')}</div>
          </div>
          <div className="form-fieldinner">
            <label>Regulatory Association</label>
            <div className="fieldans">{totalInformation?.regulatoryAssociation}</div>
          </div>
        </div>
        <div className="disgrid grid-2">
          <div className="form-fieldinner">
            <label>Customer Care Number</label>
            <div className="fieldans">{totalInformation?.customerCareNumber && (formatCanadianPhoneNumber(totalInformation?.customerCareNumber))}</div>
          </div>
          <div className="form-fieldinner">
            <label> Institute Contact Number *</label>
            <div className="fieldans">{formatCanadianPhoneNumber(totalInformation?.contactNumber)}</div>
          </div>
          
        </div>
        <div className="disgrid grid-2">
          <div className="form-fieldinner">
            <label>Website *</label>
            <div className="fieldans">{totalInformation?.website}</div>
          </div>
        </div>
      </div>
        : null}
      { editOther ? <div className="myaccountsection institutedetails martop60">
        <div className="myaccountsectionheading">
          <h3 className="h3_style">Other Details</h3>
          <div className="mybtn">
            <button type="button" className="myadbtn" onClick={() => changeOtherState(false)}>
            <img src={cross}  alt='close'/>
            </button>
          </div>
        </div>


        <div className="disgrid grid-2">
          <div className="form-fieldinner">
            <div className="input-container form-group"><label className="placeholder">License No</label>
              <input type="text" className={
                isRequired && formError["licenseNo"]
                  ? "input form-control  is-invalid"
                  : "input form-control"
              } placeholder=" " name="licenseNo" maxLength={50} value={information?.licenseNo} onChange={onInputChangeHandler} onBlur={onTouchHandler} />
              
              <span className="text-danger">
                {isRequired &&
                  formError["licenseNo"] &&
                  (formError["licenseNo"] === "req"
                    ? "License No is required!"
                    : "Please enter a proper License No!")}
              </span>
            </div>
          </div>
          <div className="form-fieldinner">
            <div className="input-container form-group"> <label className="placeholder">Regulatory Association</label>
              <input type="text" className={
                isRequired && formError["regulatory"]
                  ? "input form-control  is-invalid"
                  : "input form-control"
              } placeholder=" " maxLength={50} name="regulatory" value={information?.regulatory} onChange={onInputChangeHandler} onBlur={onTouchHandler} />
             
              <span className="text-danger">
                {isRequired &&
                  formError["regulatory"] &&
                  (formError["regulatory"] === "req"
                    ? "Regulatory Association is required!"
                    : "Please enter a proper Regulatory Association!")}
              </span>
            </div>
          </div>
        </div>
        <div className="disgrid grid-2">
        <div className="form-fieldinner">
            <div className="input-container form-group"><label className="placeholder">Customer Care Number</label>
              {/* <input type="tel" className="input form-control" placeholder=" " name="phone" value={information.phone} onChange={onInputChangeHandler} />
              <label className="placeholder">Contact Number * </label> */}
              <InputMask mask={getMuskPattern('customerContact')} name="customerContact" value={information?.customerContact} onChange={onInputChangeHandler} onBlur={onTouchHandler}>
                {(inputProps) => <input {...inputProps} type="tel" className={
                  isRequired && formError["customerContact"]
                    ? "input form-control  is-invalid"
                    : "input form-control"
                } placeholder=" " disableUnderline />}
              </InputMask>
              <div className="cut"></div>
              
              <span className="text-danger">
                {isRequired &&
                  formError["customerContact"] &&
                  (formError["customerContact"] === "req"
                    ? "Customer Care Number is required!"
                    : "Please enter a proper Customer Care Number!")}
              </span>
            </div>
          </div>
          <div className="form-fieldinner">
            <div className="input-container form-group"><label className="placeholder"> Institute Contact Number * </label>
              {/* <input type="tel" className="input form-control" placeholder=" " name="phone" value={information.phone} onChange={onInputChangeHandler} />
              <label className="placeholder"> Institute Contact Number * </label> */}
              <InputMask mask={getMuskPattern('institutionalContact')} name="institutionalContact" value={information?.institutionalContact} onChange={onInputChangeHandler} onBlur={onTouchHandler}>
                {(inputProps) => <input {...inputProps} type="tel" className={
                  isRequired && formError["institutionalContact"]
                    ? "input form-control  is-invalid"
                    : "input form-control"
                } placeholder=" " disableUnderline />}
              </InputMask>
              <div className="cut"></div>
              
              <span className="text-danger">
                {isRequired &&
                  formError["institutionalContact"] &&
                  (formError["institutionalContact"] === "req"
                    ? " Institute Contact Number is required!"
                    : "Please enter a proper  Institute Contact Number!")}
              </span>
            </div>
          </div>

          
        </div>
        <div className="disgrid grid-2">
          <div className="form-fieldinner"><label className="placeholder">Website *</label>
            <div className="input-container form-group">
              <input type="text" className={
                isRequired && formError["website"]
                  ? "input form-control  is-invalid"
                  : "input form-control"
              } placeholder=" " name="website" maxLength={50} value={information.website} onChange={onInputChangeHandler} onBlur={onTouchHandler} />
              
              <span className="text-danger">
                {isRequired &&
                  formError["website"] &&
                  (formError["website"] === "req"
                    ? "Website No is required!"
                    : "Please enter a proper Website!")}
              </span>
            </div>
          </div>
         
        </div>
        <button type="button" disabled={!information?.website?.length || !information?.institutionalContact?.length} className="ctaonebutton widthhalf" onClick={() => onOthersButtonClick()}>Save</button>
      </div> : null}

    </>
  );
};

export default OtherDetailsJsx;
