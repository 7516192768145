import React from 'react' 
import { useHistory, useLocation } from "react-router-dom";
import { Footer } from '../../../common/footer/footer' 

import bannerBG from "../../../../assets/Instituteppvimages/bannerbg.svg";
import { BannerPpvStrip } from "../../../common/bannerPpvStrip/bannerPpvStrip.jsx";

import { OfferCardListJSX } from '../../../common/offerCardList/offerCardList'; 
import { useTranslation } from "react-i18next";
import { Pagination } from '../../../common/pagination/pagination';

const ListOfOffersJsx = (props) => {
  const history = useHistory();
  const location = useLocation()
  const { state: locationState } = location

  const { t } = useTranslation();
  const {
    offerData,
    onPaginationButtonClick,
    profileData, 
  } = props;


  return (
    <>
      <section className={["listofoffermainsection"]}>
         
      <BannerPpvStrip bannerBG={bannerBG} profileData={profileData}   />
        <div className="container-fluid"> 
          <section class="exclusive offerlistpadding">
            <OfferCardListJSX
              SubCatTitle={t("PPVINSTITUTE.OFFERTITLE")}
              SubCatText={t("PPVINSTITUTE.OFFERSUBTITLE")} 
              offerData={offerData?.data}
              isDescription={true}
              isSlider={false}
            />

             {offerData?.data?.length > 0 &&  <section className="newpagination"> <Pagination
                                limit={10} 
                                totalCount={offerData?.meta?.totalCount}
                                onPaginationButtonClick={onPaginationButtonClick}
                            />  </section>}
                            
          </section>
        </div>
      </section>
      <Footer />
    </>

  );
};

export default ListOfOffersJsx;