import React from 'react'

import "react-modern-calendar-datepicker/lib/DatePicker.css";
import { Footer } from '../../../common/footer/footer';
import Sidebar from "../sidebar/sidebar.js";
import MyAds from '../../dashboardCommon/myAds/myAds.js';
import MyOffer from '../../dashboardCommon/myOffer/myOffer.js';
import MyProduct from '../../dashboardCommon/myProducts/products.js'
import MyServices from "../../dashboardCommon/myServices/myServices.js"
import "../myWorkspace/myWorkspace.model.css"
import { Trans, useTranslation } from 'react-i18next';

import offer from "../../../../assets/Instituteppvimages/myoffer.svg";
import service from "../../../../assets/Instituteppvimages/service.svg";
import product from "../../../../assets/Instituteppvimages/myproduct.svg";
import ads from "../../../../assets/Instituteppvimages/myads.svg";


const MyWorkspaceJsx = (props) => {
  const {
    activeTab,
    onTabClick,

  } = props;

  const { t } = useTranslation();

  return (
    <>
      <section className="main_institute_dashboard">
        <section class="entiredashboard maincustomerdash main institute_dashboard">
          <div className="container-fluid">
            <div className="row dashboard-decoration">
              <div className="col-lg-4 col-sm-4">
                <Sidebar />
              </div>
              <div className="col-lg-8 col-sm-8 myworkspace-data">
                <div  className="dashboardrightpanel">
                  <div className="row " >
                    <div className="col-lg-12 col-sm-12">
                      <p className="dahsboardTitle">{t("instituteWorkspace.TITLE")}</p>
                      <h5 className="dahsboardSubTitle mb-2">{t("instituteWorkspace.SUBTITLE")}</h5>
                    </div>
                  
            
                <div class="myworkspacenav">
                  <ul>
                    <li class={`${activeTab === 'myAds' ? 'active' : ''}`} onClick={() => onTabClick('myAds')}><img class={`${activeTab === 'myAds' ? 'active' : ''}`} src={ads} alt={''} /> My Ads</li>
                    <li class={`${activeTab === 'myProduct' ? 'active' : ''}`} onClick={() => onTabClick('myProduct')}><img class={`${activeTab === 'myProduct' ? 'active' : ''}`} src={product} alt={''} /> My Products</li>
                    <li class={`${activeTab === 'myOffer' ? 'active' : ''}`} onClick={() => onTabClick('myOffer')}><img class={`${activeTab === 'myOffer' ? 'active' : ''}`} src={offer} alt={''} /> My Offers</li>
                    <li class={`${activeTab === 'myService' ? 'active' : ''}`} onClick={() => onTabClick('myService')}><img class={`${activeTab === 'myService' ? 'active' : ''}`} src={service} alt={''} /> My Services</li>

                  </ul>
                </div>
                {
                  activeTab === 'myAds' ? <MyAds /> : activeTab === 'myOffer' ? <MyOffer /> : activeTab === 'myService' ? <MyServices /> : <MyProduct />
                }
              </div>
            </div>
            </div>
          </div>
          </div>
        </section>
        <Footer/>
      </section>
    </>

  );
};

export default MyWorkspaceJsx;
