import React from 'react'
import { useEffect, useState } from 'react';
import moment from 'moment';
import { Footer } from '../../../common/footer/footer';
import AOS from 'aos';
import 'aos/dist/aos.css';
import { useTranslation } from "react-i18next";
import Sidebar from "../sidebar/sidebar.js";
import { Pagination } from '../../../common/pagination/uiPagination';

export const CustomerAskTheExpertJsx = (props) => {
  const { t } = useTranslation();
  const {
    expertQueryData,page,onPaginationButtonClick
    // meta
  } = props;
  useEffect(() => {
    AOS.init();
    window.scrollTo(0, 0);
  }, [])
  
  const itemsPerPage = 10

  const [currentPage, setCurrentPage] = useState(1);
  const currentItems = expertQueryData?.slice(
    (page - 1) * itemsPerPage,
    page * itemsPerPage
  );


  // {t("PRINT.TITLE")}
  return (
    <>
      <section className="entiredashboard admindashboard">
        <div className="container">

          <div className="row">
            <div className="col-lg-3 col-sm-3">


              <div className="dashboardleftpanel martop40">

                <div className="dashboardlink martop40">
                  <Sidebar />
                </div>
              </div>
            </div>
            <div className="col-lg-9 col-sm-9">
              <div className="dashboardrightpanel martop40">
                <h2 className="h2_style">Expert Query</h2>


                
              </div>

              <div className="expertsection martop30 mb-5">
                <div className="searchdetails searchdetalsen">
                  <table className="searchdetailsdb">
                    <tr>
                      <th>Expert ID</th>
                      <th>Question</th>
                      <th>First Name</th>
                      <th>Last Name</th>
                      <th>Email</th>

                     



                      <th>Created ad</th>
                      {/* <th>Action</th> */}
                    </tr>

                    {currentItems.map((item, i) => (
                      <>
                        <tr>
                           <td>{item.expertSupportId}</td>
                          <td>{item.message}</td> 
                          <td>{item.expertDetails.firstName}</td>

                          <td>{item.expertDetails.lastName}</td>
                          <td>{item.expertDetails.email}</td>
                         


                          <td>{moment(item.createdAt).format('MM-DD-YYYY')}</td>
                          {/* <td>
                          <button className="btn btn-success" onClick={() => editCategory(item?.institutionCategoryId)}>Edit</button>
                                  <button className="btn btn-danger"  onClick={() => deleteOffer(item?.institutionCategoryId)} >delete</button></td> */}
                          
                        </tr>
                      </>
                    ))}

                  </table>
                  {currentItems?.length > 0 && <section className="newpagination" > <Pagination
              currentPage={currentPage}
              page={page}
              itemsPerPage={itemsPerPage}
              allOffersData={expertQueryData}
              currentItems={currentItems}
              onPaginationButtonClick={onPaginationButtonClick}

            /></section>}
                </div>
              </div>


            </div>

          </div>

        </div>
      </section>

      <Footer />
    </>

  );
};

export default CustomerAskTheExpertJsx;