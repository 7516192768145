
// Import css files
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

import 'aos/dist/aos.css';
import { Footer } from '../../../common/footer/footer';
import React, { useEffect } from 'react';
import { useTranslation } from "react-i18next";
import './articleDetails.css'; 
import { decodeHtmlEntities } from "../../../../shared/helper";

const ArticleDetailsJsx  = (props) => {
  const {
    detailsData 
  } = props;

 
  const { t } = useTranslation();
 

  
  useEffect(() => { 
  }, []);




  return (
    <>
      <section className="homecustom">
        <section  >
          <div className="container"> 
          <div className="row col-12">
           
          <p className="articleflow">{t("COMMON.HOME")} / {detailsData?.body[0].cats[0].name } / <span className="articleflowsub">{detailsData?.body[0].title}</span></p>
         
          <h1 className="articlesubheading">{detailsData?.body[0].cats[0].name}</h1>
          <div className="subunderlines"></div>
          </div>
           
            <div className="modal-header articlepart">
              <h1 className="articleTitle">{detailsData?.body[0].title}</h1>
            </div>
            
          



            <div className="articlepart" >
              <div className="d-flex basicuserdetails">
                <div className="userimage">
                  <img src={`https://ui-avatars.com/api/?name=${detailsData?.body[0].author}&rounded=true`} alt={`${detailsData?.body[0].author}`} />
                </div>
                <div className="name">{detailsData?.body[0].author}</div>
              </div>
              <p className="articlepublishdate">{detailsData?.body[0].date_publish}</p>
            </div>
            <div className="modal-body  articlepart">
              <div className="blog-image-dv articleimage"><img src={detailsData?.body[0].image} alt={' '} />
              </div>
              <div className="blog-content-dv articledetails" dangerouslySetInnerHTML={{ __html: detailsData?.body[0].content }}></div>
            </div>
          </div>
        </section>
        <Footer />
      </section>

    </>

  );
};

export default ArticleDetailsJsx;