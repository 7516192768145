import React from 'react'
import { useTranslation } from "react-i18next";
import { useState } from 'react';
import 'react-awesome-slider/dist/styles.css';
import { NEW_URL } from '../../../../constants/applicationConstants';
import { AdsCard } from '../../../common/adsCard/adsCard.jsx';

import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { Pagination } from '../../../common/pagination/uiPagination.js';

const SuggestedOfferJsx = (props) => {
  const { t } = useTranslation();
  const [productDetails, setProductDetails] = useState({});
  const closeFunction = () => {
    setProductDetails({})
  }
  const {

    addData,onPaginationButtonClick, page
  } = props;

  const itemsPerPage = 10

  const [currentPage, setCurrentPage] = useState(1);

  const currentItems = addData?.slice(
    (page - 1) * itemsPerPage,
    page * itemsPerPage
  );

  const getProperData = (date) => {
    if (date) {
      const date2 = date?.split('T');
      const date1 = date2[0]?.split('-');
      return `${date1[2]}/${date1[1]}/${date1[0]}`
    }
  }
  const sliderSettings = {
    dots: false,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
  };
  return (
    <>
      <section className="alldashboard cust-adds">

        <div className="dashboardrightpanel ">
          <div className="suggestedoffercustomer">
            <div className="dashboard-ps-offer">
            <section className="limitedoffer ">
                <div className="limitedofferinner">
                  <div className="offerslider">
                       {currentItems?.map((offer, index) => (
                        <div key={index}>
                            <div class={`sliouter ${offer?.parentInstitutionCategory}`}> 
                            <AdsCard
                      imageUrl={`${NEW_URL}/${offer.imageUrl}`}
                      description={offer.description}
                      category={offer?.subInstitutionCategory?.name}
                      externalLink={offer.externalLink}
                      categoryname={offer.institutionCategoryId}
                    /> </div>
                          </div>
                        ))}
                         <section className="newpagination"> <Pagination
                        currentPage={currentPage}
                        page={page}
                        allOffersData={addData}
                        currentItems={currentItems}
                        onPaginationButtonClick={onPaginationButtonClick}
                        itemsPerPage={itemsPerPage}
                      /></section>
                  </div>
                </div>                      
            </section>
          </div>
          </div>
        </div>
      </section>
    </>

  );
};

export default SuggestedOfferJsx;