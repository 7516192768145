import React, { useState } from 'react'
import { Link } from "react-router-dom";
import { Trans, useTranslation } from "react-i18next";
import realmobilebanner from "../../../assets/homeimages/RealEstate_Banner.svg";
import insurancemobilebanner from "../../../assets/homeimages/Investment_Banner.svg";
import mortgagemobilebanner from "../../../assets/homeimages/Mortgage_Image.svg";
import investmentmobilebanner from "../../../assets/homeimages/Insurance_Image.svg";
import Bankingmobilebanner from "../../../assets/homeimages/Banking_Image.svg";
import rimib11 from "../../../assets/homeimages/RealEstateblack.svg";
import rimib22 from "../../../assets/homeimages/Investmentblack.svg";
import rimib33 from "../../../assets/homeimages/Mortgageblack.svg";
import rimib44 from "../../../assets/homeimages/Insuranceblack.svg";
import rimib55 from "../../../assets/homeimages/Bankingblack.svg";
import Tab from 'react-bootstrap/Tab';
import Tabs from 'react-bootstrap/Tabs';
export const HomeMobileBannerJsx = (props) => {

    const { t } = useTranslation();

    const { type } = props;


    return (
        <>
        <section className="d-block d-sm-none">
                    <div className="newblock">{t("HOMEPAGE.MOBILETAGLINE")}</div>
                     <Tabs
      defaultActiveKey="tabrealestate"      id="rimib"       className="rimibblock "
    >
      <Tab eventKey="tabrealestate" title="Real Estate" className="tabrealestate">
          
      <div className="bannerimagmobile">
          <button className="mobileabso" onClick={() => window.location.href='/realEstate'}>{t("HOMEPAGE.REALESTATE")}<img src={rimib11} alt='{t("HOMEPAGE.REALESTATE")}'/> </button> 
          <img src={realmobilebanner} alt='{t("HOMEPAGE.REALESTATE")}'/>
      </div>
      </Tab>
      <Tab eventKey="tabinvesment" title="Invesment" className="tabinvesment">
      <div className="bannerimagmobile">
          <button className="mobileabso" onClick={() => window.location.href='/investment'}>{t("HOMEPAGE.INVESTMENT")} <img src={rimib22} alt='{t("HOMEPAGE.INVESTMENT")}'/> </button> 
          <img src={insurancemobilebanner} alt='{t("HOMEPAGE.INVESTMENT")}'/>
      </div>
      </Tab>
      <Tab eventKey="tabmortgage" title="Mortgage"  className="tabmortgage">
      <div className="bannerimagmobile">
          <button className="mobileabso" onClick={() => window.location.href='/mortgage'}>{t("HOMEPAGE.MORTGAGE")} <img src={rimib33} alt='{t("HOMEPAGE.MORTGAGE")}'/> </button> 
          <img src={mortgagemobilebanner} alt='{t("HOMEPAGE.MORTGAGE")}'/>
      </div>
      </Tab>
      <Tab eventKey="tabinsurance" title="Insurance"  className="tabinsurance">
      <div className="bannerimagmobile">
          <button className="mobileabso" onClick={() => window.location.href='/insurance'}>{t("HOMEPAGE.INSURANCE")} <img src={rimib44} alt='{t("HOMEPAGE.INSURANCE")}'/> </button> 
          <img src={investmentmobilebanner} alt='{t("HOMEPAGE.INSURANCE")}'/>
      </div>
      </Tab>
      <Tab eventKey="tabbanking" title="Banking"  className="tabbanking">
      <div className="bannerimagmobile">
          <button className="mobileabso" onClick={() => window.location.href='/banking'}>{t("HOMEPAGE.BANKING")} <img src={rimib55} alt='{t("HOMEPAGE.BANKING")}'/> </button> 
          <img src={Bankingmobilebanner}  alt='{t("HOMEPAGE.BANKING")}'/>
      </div>
      </Tab>
    </Tabs>
                </section>
            
                </>
  )
}
