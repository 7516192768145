import React, { Component } from "react";

import axios from "axios";
import {
  emailRegex,
  encrypt,
  allRegex
} from '../../../shared/helper.js'
import { Loader } from "../../common/loader/loader.jsx";
import {
  baseURL,
} from "../../../constants/applicationConstants.js";
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

// import cookies from "js-cookie"

import LoginPage from './login.jsx'

export class Login extends Component {
  constructor(props) {
    super(props);
    this.state = {
      formData: {
        email: "",
        password: "",
      },
      isProcessing: false,
      formError: {},
      formSubmitButtonDisable: true,
      isLoading: false,
      isFirstTime: true
    };
  }

  componentDidMount = () => {
    const selectedType = this.props.match.params.type;
    if (selectedType == "institute" || selectedType == "expert" || selectedType == "customer") {
      this.props.history.push(`/login/${selectedType}`);

    } else {
      this.props.history.push("/");

    }
    let isLogin = sessionStorage.getItem('isLoggedIn') && sessionStorage.getItem('isLoggedIn');
    if (isLogin && isLogin) {
      this.props.history.push("/dashboard");
    } else {
      //   this.props.history.push("/login");

    }
    sessionStorage.removeItem("instituteId")
    sessionStorage.removeItem("email")

    sessionStorage.removeItem("token")
    sessionStorage.removeItem("comingFrom")

    sessionStorage.removeItem("instLoginType")
    localStorage.removeItem("flowName")
    localStorage.removeItem("AdminFlow")

    localStorage.removeItem("flowType")

  }

  onInputChangeHandler = (e) => {
    let name = e.target.name;
    let value = e.target.value;
    if (value) {
      if (!this.inputRegexValidation(name, value)) {
        this.setState(
          {
            formData: {
              ...this.state.formData,
              [name]: value,
            },
            formError: {
              ...this.state.formError,
              [name]: "regx",
            },
          },
          () => this.isFromSubmissionBlock()
        );
      } else {
        this.setState(
          {
            formData: {
              ...this.state.formData,
              [name]: value,
            },
            formError: {
              ...this.state.formError,
              [name]: "",
            },
          },
          () => this.isFromSubmissionBlock()
        );
      }
    } else {
      this.setState(
        {
          formData: {
            ...this.state.formData,
            [name]: value,
          },
          formError: {
            ...this.state.formError,
            [name]: "",
          },
        },
        () => this.isFromSubmissionBlock()
      );
    }
  };
 
  onTouchHandler = (e) => {
    let name = e.target.name;
    let value = e.target.value;
    if (value === "") {
      this.setState({
        formError: {
          ...this.state.formError,
          [name]: "req",
        },
      });
    } else {
      if (!this.inputRegexValidation(name, value)) {
        this.setState({
          formError: {
            ...this.state.formError,
            [name]: "regx",
          },
        });
      }
    }
  };

  inputRegexValidation = (name, value) => {

    if (name === "email") {
      return emailRegex.test(value);
    }
    else {
      return allRegex.test(value)
    }
  };

  isFromSubmissionBlock = () => {
    let { email, password } = this.state.formData;
    if (email && password) {
      if (
        !this.inputRegexValidation("email", email)
        ||
        !this.inputRegexValidation("password", password)
      ) {
        this.setState({
          formSubmitButtonDisable: true,
        });
      } else {
        this.setState({
          formSubmitButtonDisable: false,
        });
      }
    } else {
      this.setState({
        formSubmitButtonDisable: true,
      });
    }
  };
  formSubmitHandler = (e) => {
    const selectedType = this.props.match.params.type;

    this.setState({
      isLoading: true
    })
    e.preventDefault();
    let data = {
      email: this.state.formData.email,
      password: encrypt(this.state.formData.password)

    };
    // institute
    if (selectedType == "institute") {
      axios.post(`${baseURL}/institute/login`, data).then((res) => {
        sessionStorage.setItem("resendOtpData", JSON.stringify(data))
        sessionStorage.setItem("otpValidateToken", JSON.stringify(res.data?.data?.tokenValidate));
        localStorage.setItem('flowName', 'institute')
        localStorage.setItem('flowType', 'institute')
        this.setState({
          isLoading: false
        })
        toast.success("Login OTP sent to you Email successfully");
        setTimeout(() => {
          this.props.history.push("/otpLogin");
        }, 2000)

      }).catch((res) => {
        toast.error(res?.response.data.error.message);
        this.setState({
          isLoading: false
        })
      })
    } else if (selectedType == "customer") {
      axios.post(`${baseURL}/customer/login`, data).then((res) => {
        sessionStorage.setItem("resendOtpData", JSON.stringify(data))

        sessionStorage.setItem("otpValidateToken", JSON.stringify(res.data?.data?.tokenValidate));

        localStorage.setItem('flowName', 'customer')
        localStorage.setItem('flowType', 'customer')
        

        this.setState({
          isLoading: false
        })
        
        toast.success("Login OTP sent to you Email successfully");
        setTimeout(() => {
          this.props.history.push("/otpLogin");
        }, 2000)

      }).catch((res) => {
        toast.error(res?.response.data.error.message);
        this.setState({
          isLoading: false
        })
      })
    } else {
      axios.post(`${baseURL}/expert/login`, data).then((res) => {
        sessionStorage.setItem("otpValidateToken", JSON.stringify(res.data?.data?.tokenValidate));

        sessionStorage.setItem("resendOtpData", JSON.stringify(data))
        localStorage.setItem('flowName', 'expert')
        localStorage.setItem('flowType', 'expert')
        this.setState({
          isLoading: false
        })

        toast.success("Login OTP sent to you Email successfully");
        setTimeout(() => {
          this.props.history.push("/otpLogin");
        }, 2000)


      }).catch((res) => {
        toast.error(res?.response.data.error.message);
        this.setState({
          isLoading: false
        })
      })
    }
  };

  onResendOTPClick = () => {
    const selectedType = this.props.match.params.type;

    let dataInstitute = {
      category: "institute",
      userType: "institute"
    }
    let dataCustomer = {
      category: "customer",
    }
    let dataExpert = {
      category: "expert",
    }
    let email = JSON.parse(sessionStorage.getItem("email"))
    this.setState({ isLoading: true });
    axios.post(`${baseURL}/forgotPassword/${email}/sendOTP`, selectedType == "institute" ? dataInstitute : selectedType == "expert" ? dataExpert : dataCustomer).then((res) => {
      sessionStorage.setItem("token", JSON.stringify(res.data?.data?.tokenValidate));
    }).catch((res) => {
      toast.error(res?.response.data.error.message);

      this.setState({ isLoading: false });
    })
  }

  onFirstLoginClick = () => {
    const selectedType = this.props.match.params.type;

    if (selectedType == "institute") {
      this.onFirstLoginClickInstitute()
    } else if (selectedType == "expert") {
      this.onFirstLoginClickExpert()
    } else {
      this.onFirstLoginClickCustomer()
    }
  }

  // institute
  onFirstLoginClickInstitute = () => {
    this.setState({
      isLoading: true
    })

    sessionStorage.removeItem("instLoginType");
    sessionStorage.removeItem("instUserId");
    axios.post(`${baseURL}/institute/${this.state.formData?.email}/existence`).then(res => {
      this.setState({
        isLoading: false
      })

      sessionStorage.setItem("instLoginType", res?.data?.data?.userType);
      if (res?.data?.data?.userType == 'institute_user') {
        sessionStorage.setItem("instUserId", res?.data?.data?.instituteUserId);
      }
      // checking start
      if (res.data.data.onboardingStep === 'subscription-paid')
        this.setState({ isFirstTime: false });
      else {
        sessionStorage.setItem("email", JSON.stringify(this.state.formData?.email));
        localStorage.setItem('flowName', 'institutional')
        localStorage.setItem('flowType', 'institute')
        sessionStorage.setItem("comingFrom", "login")
        localStorage.setItem('AdminFlow', 'institute')

        sessionStorage.setItem("instituteId", JSON.stringify(res.data?.data?.instituteId));

        this.onResendOTPClick()
        this.props.history.push('./passwordAuth')
      }
      // checking end
    }).catch(err => {
      toast.error(err.response.data.error.message);
      this.setState({
        isLoading: false
      })
    })
  }

  // expert
  onFirstLoginClickExpert = () => {
    this.setState({
      isLoading: true
    })
    axios.post(`${baseURL}/expert/${this.state.formData?.email}/existence`).then(res => {
      this.setState({
        isLoading: false
      })
      // start
      if (res.data.data.onboardingStep === 'subscription-paid')
        this.setState({ isFirstTime: false });
      else {
        sessionStorage.setItem("email", JSON.stringify(this.state.formData?.email));
        localStorage.setItem('flowName', 'expert')
        localStorage.setItem('flowType', 'expert')
        sessionStorage.setItem("comingFrom", "login")
        localStorage.setItem('AdminFlow', 'expert')
        sessionStorage.setItem("expertId", JSON.stringify(res.data?.data?.expertId));

        this.onResendOTPClick()
        this.props.history.push('./passwordAuth')
      }
      // end

    }).catch(err => {
      toast.error(err.response.data.error.message);
      this.setState({
        isLoading: false
      })
    })
  }

  // customer

  onFirstLoginClickCustomer = () => {
    this.setState({
      isLoading: true
    })
    axios.post(`${baseURL}/customer/${this.state.formData?.email}/existence`).then(res => {
      this.setState({
        isLoading: false
      })
      // res && sessionStorage.setItem("token", JSON.stringify(res.data?.data?.tokenValidate));


      this.setState({ isFirstTime: false });
      if (res.data.data.onboardingStep === 'basic-info' || res.data.data.onboardingStep === 'verify-otp') {
        sessionStorage.setItem("email", JSON.stringify(this.state.formData?.email));
        localStorage.setItem('flowName', 'customer')
        localStorage.setItem('flowType', 'customer')
        this.onResendOTPClick()
        this.props.history.push('/passwordAuth')
      }
    }).catch(err => {
      toast.error(err.response.data.error.message);
      this.setState({
        isLoading: false
      })
    })
  }


  render() {
    return (
      <div>
        <LoginPage
          formData={this.state.formData}
          onInputChangeHandler={this.onInputChangeHandler}
          onTouchHandler={this.onTouchHandler}
          formSubmitButtonDisable={this.state.formSubmitButtonDisable}
          formError={this.state.formError}
          formSubmitHandler={this.formSubmitHandler}
          isLoading={this.state.isLoading}
          isFirstTime={this.state.isFirstTime}
          onFirstLoginClick={this.onFirstLoginClick}
          selectedType={this.props.match.params.type}        
        />
        {this.state.isLoading && <Loader />}
      </div>
    );
  }
}

export default Login;
