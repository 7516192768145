import React from 'react'
import { useTranslation } from "react-i18next";
import close_btn from '../../../assets/images/white_cross.svg';
import { NEW_URL } from '../../../constants/applicationConstants';
import ScrollToTopButton from '../../../shared/scrollToTop.jsx';


const DetailedProductsJsx = (props) => {
  const { t } = useTranslation();


  const {
    data,
    subCategory,
    closFunction
  } = props;
  return (
    <>
      <div className="modalspecial productdetail-modal"><div>     
        <section className={`listofoffermainsection category-${data?.institutionCategoryId}`}>     
          <div className="container">
            <div className="leftsightbackdetails listofofferleft">
              <button className="close_btn" onClick={() => closFunction()} ><img src={close_btn} alt={t("CUSTOMERINTEREST.CLOSEBUTTON")} /></button>
              <div className="plaindetailsleft">
                <h1 className="h2_style font-weight-bold">{t("PRODUCTDETAIL.HEADING")}</h1>
                <h5 className="h5_style">{t("PRODUCTDETAIL.SUBHEADING1")}</h5>
                <h5 className="h5_style">{t("PRODUCTDETAIL.SUBHEADING2")}</h5>
                <h5 className="h5_style">{t("PRODUCTDETAIL.SUBHEADING3")}</h5>
              </div>
            </div>
            <div className="detailedview">
              <div className="row">
                <div className="col-lg-6 col-sm-6">
                <div className="pname">{data?.productName}</div>
              <div className="sidetext">
                {/* <p>{category}</p> */}
                <p>{subCategory}</p></div>
              {/* <p>{category}</p> */}
              <div className="detailedtext mt-4">
                <div className="detailedofferheading">{t("PRODUCTDETAIL.DESCRIPTION")}</div>
                <p>{data?.description} </p>
                <div className="disgrid grid-2">
                  <div>
                    <div className="detailedofferheading">{t("PRODUCTDETAIL.BENIFITS")}</div>
                    <p>{data?.benefits} </p>
                  </div>
                  <div>
                    <div className="detailedofferheading">{t("PRODUCTDETAIL.FEATURES")}</div>
                    <p>{data?.features} </p>
                  </div>
                </div>
                {data?.externalLink && <button className="ctaonebutton widthhalf" onClick={() => window.open(data?.externalLink, '_blank')}>Apply Now</button>}
              </div>
                </div>
                <div className="col-lg-6 col-sm-6">
                  <div className="detailedimage">
                    <img src={`${NEW_URL}/${data?.imageUrl}`} alt={' '} />
                  </div>
                </div>
              </div>
            
            </div>
          </div><ScrollToTopButton />
        </section>
      </div>

      </div>
    </>

  );
};

export default DetailedProductsJsx;