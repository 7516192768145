import React from 'react'
import { useTranslation } from 'react-i18next';
import Select, { components } from 'react-select';
import cross from '../../../../assets/images/cross.svg';
import editicon from "../../../../assets/images/edit-icon.png";



const OccupationJsx = (props) => {
  const {t} =useTranslation();
  const {
    onInputChangeHandler, onOthersButtonClick, editOther, occupationBlock,
    changeOtherState, formError, onTouchHandler, handleChange, responseData
  } = props;
  const isRequired = true

  const information = props?.wholeData




  const interestData = [
    {
      "label": "Insurance",
      "value": 1
    },
    {
      "label": "Investment",
      "value": 2
    },
    {
      "label": "Mortgage",
      "value": 3
    },
    {
      "label": "Banking",
      "value": 4
    },
    {
      "label": "Real Estate",
      "value": 5
    }
  ]

  const occuData = [
    {

      "label": "Select",
      "value": ''
    },
    {
      "label": "Marketing & Sales",
      "value": "Marketing & Sales"
    },
    {
      "label": "Healthcare",
      "value": "Healthcare"
    },
    {
      "label": "Education",
      "value": "Education"
    },
    {
      "label": "Finance & Business",
      "value": "Finance & Business"
    },
    {
      "label": "Administration",
      "value": "Administration"
    },
    {
      "label": "Restaurants",
      "value": "Restaurants"
    },
    {
      "label": "Transportation & Logistics",
      "value": "Transportation & Logistics"
    },
    {
      "label": "Manufacturing & Engineering",
      "value": "Manufacturing & Engineering"
    },
    {
      "label": "Construction & Trades",
      "value": "Construction & Trades"
    },
    {
      "label": "Customer Service",
      "value": "Customer Service"
    },
    {
      "label": "Information Technology",
      "value": "Information Technology"
    },
    {
      "label": "Others",
      "value": "Others"
    }
  ]

  const annualData = [
    {
      "label": "Select",
      "value": ''
    },
    {
      "label": "Less than CAD 55,000",
      "value": "55,000"
    },
    {
      "label": "CAD 55,000 - 110,000",
      "value": "110,000"
    },
    {
      "label": "CAD 110,000 - 165,000",
      "value": "165,000"
    },
    {
      "label": "CAD 165,000 - 220,000",
      "value": "220,000"
    },
    {
      "label": "More than CAD 220,000",
      "value": "220,000+"
    }
  ]

  const netWorthData = [
    {
      "label": "Select",
      "value": ''
    },
    {
      "label": "Less than 1 million",
      "value": "Less than 1 million"
    },
    {
      "label": "1 million - 5 million",
      "value": "1 million - 5 million"
    },
    {
      "label": "5 million - 10 million",
      "value": "5 million - 10 million"
    },
    {
      "label": "10 million - 15 million",
      "value": "10 million - 15 million"
    },
    {
      "label": "More than 15 million",
      "value": "More than 15 million"
    }
  ]

  const annualHouseData = [
    {
      "label": "Select",
      "value": ''
    },
    {
      "label": "Less than CAD 55,000",
      "value": "55,000"
    },
    {
      "label": "CAD 55,000 - 110,000",
      "value": "110,000"
    },
    {
      "label": "CAD 110,000 - 165,000",
      "value": "165,000"
    },
    {
      "label": "CAD 165,000 - 220,000",
      "value": "220,000"
    },
    {
      "label": "More than CAD 220,000",
      "value": "220,000+"
    }
  ]




  const getMuskPattern = (name) =>
    name === 'institutionalContact' || 'customerContact' ? '(999) 999 9999' : 'a9a 9a9'
  return (
    <>
      {!editOther ? <div className="myaccountsection institutedetails martop60 occupation">
        <div className="myaccountsectionheading">
          <h3 className="h3_style">{t("customeroccupation.HEADING")}</h3>
          <div className="mybtn">
            <button type="button" className="edit-button" onClick={() => changeOtherState(true)}>{t("customeroccupation.EDITBUTTON")} <img src={editicon} alt="" /></button>
          </div>
        </div>

        <div className="disgrid grid-2">
          <div className="form-fieldinner">
            <label>{t("customeroccupation.OCCUPATION")}</label>
            <div className="fieldans">{information?.occupation}</div>
          </div>
          <div className="form-fieldinner">
            <label>{t("customeroccupation.ANNUALINCOME")}</label>
            <div className="fieldans">{information?.annualIncome}</div>
          </div>
          {/* <div className="form-fieldinner">
            <label>{t("customeroccupation.INTEREST")}</label>
            <div className="intersr-name">
              {responseData && responseData?.interests?.map((item, index) => {
                
                return (
                  <div key={index} className="fieldans">
                    {item.institutionCategory?.name}
                    {index < responseData.interests.length - 1 ? ',' : ''}
                  </div>
                );
              })}

           
            </div>
          </div> */}
        </div>
        <div className="disgrid grid-2">
          <div className="form-fieldinner">
            <label>{t("customeroccupation.NETWORTH")}</label>
            <div className="fieldans">{information?.netWorth}</div>
          </div>
        <div className="form-fieldinner">
            <label>{t("customeroccupation.HOUSEHOLD")}</label>
            <div className="fieldans">{information?.annualHouseHoldIncome}</div>
          </div>
          
        </div>
       

      </div>
        : null}
      { editOther ? <div className="myaccountsection institutedetails martop60">
        <div className="myaccountsectionheading">
          <h3 className="h3_style">{t("customeroccupation.HEADING")}</h3>
          <div className="mybtn">
            <button type="button" className="myadbtn cross_btn" onClick={() => changeOtherState(false)}></button>
          </div>
        </div>


        <div className="disgrid grid-2">
          <div className="form-fieldinner">
            <div className="input-container form-group">   
             <label className="placeholder">{t("customeroccupation.OCCUPATION")} </label>
              <select className="input form-control form-select" onChange={onInputChangeHandler} onBlur={onTouchHandler} name="occupation">
                {occuData?.map((item) => {
                  return (
                    <>
                      <option value={item.value} selected={item.value == information?.occupation} >{item.label}</option>
                    </>
                  )
                })}
              </select>
          
             
            </div>
          </div>
          <div className="form-fieldinner">
            <div className="input-container form-group"><label className="placeholder">{t("customeroccupation.ANNUALINCOME")}
              </label>
              <select className="input form-control form-select" onChange={onInputChangeHandler} onBlur={onTouchHandler} name="annualIncome">
                {annualData?.map((item) => {
                  return (
                    <>
                      <option value={item.value} selected={item.value == information?.annualIncome} >{item.label}</option>
                    </>
                  )
                })}
              </select>
              
            </div>
          </div>
        
        
        </div>
        <div className="disgrid grid-2">

          {/* <div className="form-fieldinner">
            <div className="input-container form-group">
              <Select className="mb-4 form-container form-group multiselect"
                closeMenuOnSelect={false}
                classNamePrefix="multicustomselect"
                isMulti
                options={interestData}
                onChange={(e) => handleChange(e, 'interest')}

                value={information['interest']}
                components={{
                  Input: (props) => {
                    const { value } = props

                    return <>
                      <label className={`multiplaceholer ${(information['interest']?.length > 0 || value !== '') ? 'active' : ''}`}>{t("customeroccupation.INTEREST")}
                                                                    {<span className="plantooltip custooltip" title="" > <div className="tooltipcontent">{t("customeroccupation.INTEREST")}</div></span>}
                      </label>

                      <components.Input {...props} className={"mb-4 input-container form-group"} >
                        {props.children}
                      </components.Input>
                    </>
                  },
                  Option: (props) => {
                    const { isSelected } = props

                    return (
                      <div>
                        <components.Option {...props}>
                          <input
                            type="checkbox"
                            checked={isSelected}
                            onChange={() => null}
                          />
                          {props.children}
                        </components.Option>
                      </div>
                    );
                  }
                }}

              />
            </div>
          </div> */}
  <div className="form-fieldinner">
              <div className="input-container form-group"> <label className="placeholder">{t("customeroccupation.NETWORTH")}              </label>
                <select className="input form-control form-select" onChange={onInputChangeHandler} onBlur={onTouchHandler} name="netWorth">
                  {netWorthData?.map((item) => {
                    return (
                      <>
                        <option value={item.value} selected={item.value == information?.netWorth} >{item.label}</option>
                      </>
                    )
                  })}
                </select>
               
              </div>
            </div>
          
          <div className="form-fieldinner">
            <div className="input-container form-group">  <label className="placeholder">{t("customeroccupation.HOUSEHOLD")}
              </label>
              <select className="input form-control form-select" onChange={onInputChangeHandler} onBlur={onTouchHandler} name="annualHouseHoldIncome">
                {annualHouseData?.map((item) => {
                  return (
                    <>
                      <option value={item.value} selected={item.value == information?.annualHouseHoldIncome} >{item.label}</option>
                    </>
                  )
                })}
              </select>
            
            </div>
          </div>
         
        </div>
        


        <button type="button" className="ctaonebutton widthhalf" disabled={occupationBlock} onClick={() => onOthersButtonClick()}>{t("customeroccupation.SAVEBUTTON")}</button>
      </div> : null}

    </>
  );
};

export default OccupationJsx;
