import React, { Component } from "react";
import SideBarsJsx from "./sidebar.jsx";
import ConSideBarsJsx from "./conditionalSidebar.jsx"

import { Loader } from '../../../common/loader/loader.jsx';
import axios from "axios";
import { baseURL } from "../../../../constants/applicationConstants.js";

export class SideBarsJsxclass extends Component {
  constructor(props) {
    super(props);
    this.state = {
      responseData: null, role: false
    };
  }
  componentDidMount = () => {
    // this.setState({ isLoading: true })
    let token = sessionStorage.getItem("token") && JSON.parse(sessionStorage.getItem("token"))
    let instituteId = sessionStorage.getItem("masterID") && JSON.parse(sessionStorage.getItem("masterID"))

    if (token && instituteId) {
      this.callDetails()
    }
  }
  callDetails = () => {
    let token = sessionStorage.getItem("token") && JSON.parse(sessionStorage.getItem("token"))
    let expertID = sessionStorage.getItem("masterID") && JSON.parse(sessionStorage.getItem("masterID"))
    axios.get(`${baseURL}/user/${expertID}`, {
      headers: { 'x-access-token': token }
    }).then(res => {

      if (res?.data?.data?.role) {
        this.setState({ role: true })

      }
      this.setState({ responseData: res.data.data })
      // sessionStorage.setItem("legalName",res.data.data?.firstName);
      // sessionStorage.setItem("planName",res.data.data?.planName);

      this.setState({ isLoading: false })
    }).catch(err => {
      this.setState({ isLoading: false })
    })
  }
  render() {
    // Transforming the data to the desired structure
    let data = this.state.responseData?.role?.roleModules
    // const transformedData = data?.map(moduleItem => ({
    //   moduleName: moduleItem.module.moduleName,
    //   modulePermissions: moduleItem.module.modulePermissions
    //     .filter(permission => permission.hasPermission)
    //     .map(permission => permission.name)
    // }));
    const transformedData =  null;

    return (
      <div>

        {/* {this.state.role ? <ConSideBarsJsx response={this.state.responseData}
          transformedData={transformedData} /> : <SideBarsJsx
          response={this.state.responseData}
          transformedData={transformedData}

        />} */}
        <SideBarsJsx
          response={this.state.responseData}
          transformedData={transformedData} />

        {this.state.isLoading && <Loader />}
      </div>
    );
  }
}

export default SideBarsJsxclass;