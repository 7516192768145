import React from 'react'
import { NEW_URL } from "../../../../constants/applicationConstants.js";
import uploadicon from "../../../../assets/images/uploadicon.svg"

import "./accountSetting.model.css"
import { useTranslation } from 'react-i18next';
 
const LogoJsx = (props) => {
    const {
        imgURL, onFileChange, profileImagePreviewUrl, onNextButtonClick
    } = props;
    const { t } = useTranslation();

    return (
        <>
        <section>
            <div className="myaccountsection institutedetails my-profile">
                <div className="myaccountsectionheading">
                    <h3 className="h3_style">{t("instituteAccountSetting.LOGOHEADING")}</h3>
                
                </div>
                <div className="logoform">
                    <div className="form-fieldinner">
                        {
                            profileImagePreviewUrl ?
                                <img
                                    src={profileImagePreviewUrl}
                                    height="170px"
                                    width="250px"
                                    alt="edit profile"
                                    className="logoclass"
                                /> :

                                <img
                                    src={`${NEW_URL}/${imgURL}`}
                                    height="170px"
                                    width="250px"
                                    alt="edit profile"
                                    className="logoclass"
                                />

                        }

                    <div className="uploaddetails-section">
                            <h1 className="file-heading">{t("instituteAccountSetting.FILENAMETEXT")}</h1>
                            <h1 className="file-name">{profileImagePreviewUrl ?profileImagePreviewUrl.split('/').pop():imgURL.split('/').pop()}</h1> 
                            <div className="img-uplod-section">

                                <div className="btn_upload ">
                                    <input type="file" id="upload_file" name="" onChange={onFileChange} accept="image/png, image/gif, image/jpeg" />
                                    <label className="change_button" for="upload_file">{t("instituteAccountSetting.CHANGELOGO")}<img src={uploadicon}  alt='upload'/></label>
                                </div>
                                <div className="b">

                                    <button type="button" className="delete-button" disabled={!profileImagePreviewUrl} onClick={() => onNextButtonClick()}>Upload Picture</button>
                                </div>
                            </div>
                            
                        </div>
                    </div>
                </div>
            </div>
            </section>
        </>
    );
};

export default LogoJsx;