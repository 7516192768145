import React from 'react'
import { useTranslation } from "react-i18next";
import logIN from "../../../assets/images/login.png";
import { Link, useHistory } from "react-router-dom";
import { ToastContainer } from 'react-toastify';
import { getRoute } from '../../../shared/helper';

const LoginJsx = (props) => {
  const { t } = useTranslation();
  const history = useHistory();
  const {
    onInputChangeHandler,
    onTouchHandler,
    formSubmitButtonDisable,
    formError,
    formSubmitHandler,
    isLoading,
    isFirstTime,
    onFirstLoginClick, selectedType
  } = props;

  return (
    <>
      <section className="loginscreeninstitute loginmainds">
        <div className="container">
          <div className="row">
            <div className="col-lg-5 col-sm-5">

              <Link to="/login/institute"
                className={`logincs ${selectedType === "institute" ? 'active' : ''}`}
              >
                <div className="loginscreenimage">
                  <figure><img src={logIN} alt={' '} /></figure>
                  <figcaption>
                    <h3>{t("LOGIN.INSTITUTETITTLE")}</h3>
                    <p>{t("LOGIN.INSTITUTESUBTITTLE")}</p>
                  </figcaption>
                </div>
              </Link>

              <Link to="/login/expert" className={`logincs ${selectedType === "expert" ? 'active' : ''}`}
              >
                <div className="loginscreenimage">
                  <figure><img src={logIN} alt={' '} /></figure>
                  <figcaption>
                    <h3>{t("LOGIN.EXPERTTITTLE")}</h3>
                    <p>{t("LOGIN.EXPERTSUBTITTLE")}</p>
                  </figcaption>
                </div>
              </Link>

              <Link to="/login/customer" className={`logincs ${selectedType === "customer" ? 'active' : ''}`}
              >
                <div className="loginscreenimage">
                  <figure><img src={logIN} alt={' '} /></figure>
                  <figcaption>
                    <h3>{t("LOGIN.CUSTOMERTITTLE")}</h3>
                    <p>{t("LOGIN.CUSTMERSUBTITTLE")}</p>
                  </figcaption>
                </div>
              </Link>
            </div>
            <div className="col-lg-7 col-sm-7">
              <div className="institutelogintext martop60 paddingbot60">
                <h2 className="h2_style text-center font-weight-600">{selectedType == "institute" ? "Institute" : selectedType == "expert" ? "Expert" : "Customer"} {t("COMMON.LOGIN")}</h2>
                <h6 className="h6_style text-center">{t("LOGIN.SUB_TITLE")}</h6>
                <p className="smalltext martop30">{t("LOGIN.DESCRIPTION")}</p>

                <div className="mb-4 input-container form-group">
                  <input
                    onChange={onInputChangeHandler}
                    onBlur={onTouchHandler}
                    required
                    type={'text'}
                    placeholder=" "
                    className={
                      formError['email']
                        ? "input form-control  is-invalid"
                        : "input form-control"
                    }
                    id={'email'}
                    name={'email'}
                    maxLength={50}

                  />
                  <div className="cut"></div>
                  <label className="placeholder">{t(`Email`)} </label>

                  <span className="text-danger">
                    {
                      formError['email'] &&
                      (formError['email'] === "req"
                        ? t('Email is required!')
                        : t('Enter a valid email address.'))}
                  </span>

                </div>

                {
                  isFirstTime && <button className='mt-4 ctatwobutton' onClick={onFirstLoginClick}>{t("COMMON.LOGIN")}</button>
                }

                {!isFirstTime && <div className="mb-4 input-container form-group">
                  <input
                    onChange={onInputChangeHandler}
                    onBlur={onTouchHandler}
                    required
                    type={'password'}
                    placeholder=" "
                    className={
                      formError['password']
                        ? "input form-control  is-invalid"
                        : "input form-control"
                    }
                    id={'password'}
                    name={'password'}
                    maxLength={16}

                  />
                  <div className="cut"></div>
                  <label className="placeholder">{t(`password`)} </label>

                  <span className="text-danger">
                    {
                      formError['password'] &&
                      (formError['password'] === "req"
                        ? t('Password is required!')
                        : t('Enter a valid Password.'))}
                  </span>

                </div>
                }
                {!isFirstTime && <> <div id="forgetpass" className="form-text" onClick={(e) => { history.push('/forgotPassword'); localStorage.setItem('requestType', 'forgot-password'); localStorage.setItem('flowName', 'login'); localStorage.setItem('flowType', selectedType == "institute" ? "institute" : selectedType == "expert" ? "expert" : "customer") }}>{t("LOGIN.FORGOT")}</div>                  <button type="submit" className={
                  formSubmitButtonDisable
                    ? "btn-secondary btn mt-4 ctatwobutton"
                    : "mt-4 ctatwobutton"
                }
                  onClick={formSubmitHandler}
                  disabled={formSubmitButtonDisable}>{isLoading ? t("COMMON.PROCESSING") : t("COMMON.LOGIN")}</button></>}
                <div className="form-text mt-3">{t("LOGIN.IS_ACCOUNT")} </div>
                {selectedType == "customer" && <Link className={'mt-4 ctaonebutton'} to={getRoute('customer', 'login')}>Register Now</Link>
                }
              </div>
            </div>
          </div>
        </div>
      </section>
      <ToastContainer />

    </>

  );
};

export default LoginJsx;
