import React from 'react'
import { Component } from 'react';
import CustomerDashboardJsx from './customerDashboard.jsx'

export default class CustomerDashboard extends Component{
    constructor(props){
        super(props)
        this.state={
            activeTab:'answered',
            searchLink:'/globalMainSearchExpert',
        }
    }

    onTabClick = (tab) =>{
        this.setState({activeTab:tab})
    }

    seTypeSearchValue = (val) =>{
        this.setState({searchLink:val})
    
    }

    redirectSearchPage = () =>{
        window.location.href = this.state.searchLink
    }

    render(){
        return(
            <div>
                 <CustomerDashboardJsx 
                    activeTab={this.state.activeTab}
                    onTabClick={this.onTabClick}
                    seTypeSearchValue={this.seTypeSearchValue}
                    redirectSearchPage={this.redirectSearchPage}
                />
            </div>
        )
    }
}