import axios from 'axios';
import React from 'react'
import { Component } from 'react';
import { baseURL } from '../../../constants/applicationConstants.js';
import { Loader } from '../../common/loader/loader.jsx';
import OffersJsx from './offers.jsx'


export default class Offers extends Component {
    constructor(props) {
        super(props);
        this.state = {
            categoryData: null,
            subCategoryData: null,

            keyword: null,
            selectedCategoryId: null,
            subCategories: [],
            categories: [],
            checkedCats: [],
            checkedSubCats: [],
            oldest: false,

            allOffersData: null,
            overallOffers: null,
            topOffers: null,
            isProcessing: false,

            formSubmitButtonDisable: true,
            isLoading: false,
            selectAll: false,
            expertData: [],
            page: 1,
            totalSubCategories: "",
            disableSelectAll : true

        }
    }


    componentDidMount = () => {
        const categoryIdFromPev = sessionStorage.getItem("selectCategory");
        this.getTopOffers();
        this.getAllFeauturedOffersData();
        this.getcategorySubcategory();
        setTimeout(() => {
            categoryIdFromPev ? this.checkCategoryFromPrevious() : this.selectAllIds()



        }, 1000);

        this.props.match.params.keyword && this.fetchSearchData(this.props.match.params.keyword);


    }



    onPaginationButtonClick = (page) => {
        document.querySelector(".sortingdiv").scrollIntoView();
        this.setState({ page: page })
    }

    getcategorySubcategory = () => {
        this.setState({ isLoading: true })
        let token = JSON.parse(sessionStorage.getItem('token'));
        axios.get(`${baseURL}/institutionCategoriesAndSubCategories`, {
            headers: { 'x-access-token': token }
        }).then(res => {
            this.setState({ categoryData: res.data.data, isLoading: false })

            // Calculate the total number of subInstitutionCategories
            const totalSubCategories = this.state.categoryData?.reduce((acc, category) => {
                return acc + (category.subInstitutionCategories ? category.subInstitutionCategories.length : 0);
            }, 0);

            // Set the totalSubCategories in state
            this.setState({ totalSubCategories });
        })
    }

    hitOldest = (action) => {

        this.setState({ oldest: action, isProcessing: true, page: 1 })
        // this.props.history.replace('/')
        // this.props.history.push(`/offers`)
        setTimeout(() => {
            this.getAllFeauturedOffersData(this.state.keyword)
            this.selectAllIds()
        }, 500);
    }
    getAllFeauturedOffersData = (search) => {
        const selectedType = this.props.match.params.type;
        this.setState({ isProcessing: true })
        axios.get(`${baseURL}/search-offers?sortBy=${this.state.oldest ? "expired" : "latest"}&search=${search || ''}`).then(res => {
            this.setState({ allOffersData: res.data.data, overallOffers: res.data.data, isProcessing: false, selectAll: true })
        })

    }

    selectAllHit = (e) => {
        const { checked } = e.target
        if (!checked) {
            this.setState({ selectAll: false })
        } else {
            this.setState({ selectAll: true })
        }
        if (checked) {
            this.setState({ allOffersData: this.state.overallOffers, keyword: "",disableSelectAll:true })
            this.selectAllIds()
        }
    }

    searchOfferByKeyword = (e) => {
        const data = e.target.value
        this.setState({ keyword: data })
        if (data === '') {
            this.setState({ page: 1 })
            this.props.history.push(`/offers`)
            this.getAllFeauturedOffersData()
            this.selectAllIds()

        }

    }

    hitSearch = () => {

        this.props.history.replace('/')
        this.props.history.push(`/offers/${this.state.keyword}`)
        this.setState({ page: 1 })

        this.getAllFeauturedOffersData(this.state.keyword)
        this.selectAllIds()
    }

    fetchSearchData = (data) => {

        axios.get(`${baseURL}/search-content?keyword=${data}`).then(res => {
            this.setState({ allOffersData: res.data.data.offers, overallOffers: res.data.data.offers, isProcessing: false })
        })
    }


    getTopOffers = () => {
        this.setState({ isProcessing: true })
        axios.get(`${baseURL}/topOffers`).then(res => {
            this.setState({ topOffers: res.data.data, isProcessing: false })
        })
    }


    onCatgoryClick = (value) => {
        const subData = this.state.categoryData.filter(itm => itm.institutionCategoryId == value)

        this.setState({ subCategoryData: subData[0]?.subInstitutionCategories })
    }

    // filter all selected id
    selectAllIds = () => {
        sessionStorage.removeItem("selectCategory")
        const institutionCategoryIds = this.state.categoryData?.map(category => category.institutionCategoryId);
        this.setState({ categories: institutionCategoryIds })

        // Extract sub-institution category IDs as well
        this.state.categoryData?.forEach(category => {
            if (category?.subInstitutionCategories) {
                const subIds = category?.subInstitutionCategories?.map(subCategory => subCategory.institutionCategoryId);
                // institutionCategoryIds.push(...subIds);
                this.setState(prevState => ({
                    subCategories: [...prevState.subCategories, ...subIds] // Spread existing items and add new items
                }));
                // this.setState({ subCategories: ...subIds })

            }
        });

        this.setState({ institutionCategoryIds });
    }


    filterCatData = () => {
        const catDataFiltered = this.state.overallOffers.filter(offer =>
            this.state.subCategories.includes(offer.institutionSubCategoryId)
        );
        this.setState({ allOffersData: catDataFiltered })
        if (this.state.subCategories.length === 0) {
            this.setState({ allOffersData: this.state.overallOffers, selectAll: true })
            this.selectAllIds()

        }

    }
    filterSubData = () => {
        const subCatDataFiltered = this.state.overallOffers.filter(offer =>
            this.state.subCategories.includes(offer.institutionSubCategoryId)

        );
        this.setState({ allOffersData: subCatDataFiltered })
        if (this.state.subCategories.length === 0) {
            this.setState({ allOffersData: this.state.overallOffers, selectAll: true })
            this.selectAllIds()

        }

    }


    selectCategory = (data, e) => {
        sessionStorage.removeItem("selectCategory")
        sessionStorage.setItem("changing", true)
        this.triggerSelectAll()
        const { checked } = e.target
        this.setState({ selectAll: false,disableSelectAll:false })
        // 
        const selectedId = data.institutionCategoryId;
        this.setState({ selectedCategoryId: selectedId });

        const selectedCategory = this.state.categoryData.find(category => category.institutionCategoryId === selectedId);


        if (selectedCategory) {
            const insInsideCat = selectedCategory.subInstitutionCategories
            const institutionCategoryIds = insInsideCat.map(category => category.institutionCategoryId);


            if (checked) {
                this.setState((prevState) => ({
                    subCategories: [...prevState.subCategories, ...institutionCategoryIds],
                }));
                this.setState({ page: 1 })


            } else {

                this.setState((prevState) => ({
                    subCategories: prevState.subCategories.filter(
                        (id) => !institutionCategoryIds.includes(id) // Remove IDs present in newIds
                    )
                }));
                this.setState({ page: 1 })


            }
        } else {
            this.setState({ subCategories: [] });
        }
        setTimeout(() => {
            this.filterCatData()

        }, 500);


        this.setState((prevState) => {
            const { categories } = prevState;

            // Check if the id is already in the categories
            if (categories.includes(data.institutionCategoryId)) {
                // Remove the id
                return { categories: categories.filter(selectedId => selectedId !== data.institutionCategoryId) };
            } else {
                // Add the id
                return { categories: [...categories, data.institutionCategoryId] };
            }
        });
    }

    checkCategoryFromPrevious = () => {
        const categoryIdFromPev = sessionStorage.getItem("selectCategory");
        this.setState({ selectAll: false })
        if (categoryIdFromPev) {
            // Find the category in categoryData that matches the category ID 
            const selectedCategory = this.state.categoryData.find(

                category => category.institutionCategoryId.toString() === categoryIdFromPev

            );


            if (selectedCategory) {
                // Select this category and its subcategories
                this.selectCategory1(selectedCategory);
            }
        }
    }
    selectCategory1 = (selectedCategory) => {
        const institutionCategoryIds = selectedCategory.subInstitutionCategories.map(subCategory => subCategory.institutionCategoryId);

        // Add the category and its subcategories to the state
        this.setState(prevState => ({
            subCategories: [...prevState.subCategories, ...institutionCategoryIds],
            categories: [...prevState.categories, selectedCategory.institutionCategoryId]
        }));

        // Optionally trigger a filtering or update
        setTimeout(() => {
            this.filterCatData();
        }, 500);
    }


    handleCheckboxChange = (id) => (event) => {
        if (event.target.checked) {
            // Add ID to the selected array if not already present
            this.setState((prevState) => ({
                selectedIds: prevState.selectedIds.includes(id)
                    ? prevState.selectedIds
                    : [...prevState.selectedIds, id]
            }));
        } else {
            // Remove ID from the selected array when unchecked
            this.setState((prevState) => ({
                selectedIds: prevState.selectedIds.filter((selectedId) => selectedId !== id)
            }));
        }
    };

    selectSubCategory = (data) => {
        sessionStorage.removeItem("selectCategory")
        sessionStorage.setItem("changing", true)
        this.setState({ selectAll: false,disableSelectAll:false })

        this.triggerSelectAll()

        this.setState((prevState) => {
            if (prevState.subCategories.includes(data.institutionCategoryId)) {
                // If the ID is already selected, remove it
                return { subCategories: prevState.subCategories.filter((prevId) => prevId !== data.institutionCategoryId) };
            } else {
                // If the ID is not selected, add it
                return { subCategories: [...prevState.subCategories, data.institutionCategoryId] };
            }
        });

        setTimeout(() => {
            this.filterSubData()

        }, 500);
    }
    triggerSelectAll = () => {
        setTimeout(() => {
            const uniqueData = new Set(this.state.subCategories);

            // Get the length of the unique data
            const uniqueCount = uniqueData.size;

            if (uniqueCount >= this.state.totalSubCategories) {
                this.setState({ selectAll: true })
            }
            if(uniqueCount ===  this.state.totalSubCategories){
                this.setState({ disableSelectAll: true })

            }
        }, 500);

    }



    render() {
        const { id } = this.props.match.params;

        return (
            <div>
                <OffersJsx
                    subCategories={this.state.subCategories}
                    categories={this.state.categories}
                    searchOfferByKeyword={this.searchOfferByKeyword}
                    hitSearch={this.hitSearch}
                    selectAllHit={this.selectAllHit}
                    keyword={this.state.keyword}
                    getAllFeauturedOffersData={this.getAllFeauturedOffersData}

                    topOffers={this.state.topOffers}

                    allOffersData={this.state.allOffersData}
                    oldest={this.state.oldest}
                    hitOldest={this.hitOldest}
                    categoryData={this.state.categoryData}
                    subCategoryData={this.state.subCategoryData}
                    onPaginationButtonClick={this.onPaginationButtonClick}
                    page={this.state.page}
                    selectCategory={this.selectCategory}
                    selectSubCategory={this.selectSubCategory}
                    selectAll={this.state.selectAll}
                    disableSelectAll={this.state.disableSelectAll}
                />
                {this.state.isProcessing && <Loader />}
            </div>
        )
    }
}