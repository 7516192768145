import React from 'react'
import { useEffect, useState } from 'react';

import datecalender from "../../../../assets/images/datecalender.svg";
import planpricingtooltip from "../../../../assets/images/planpricingtooltips.svg";
import dropbox from "../../../../assets/images/dropbox.svg";
import DatePicker3 from "react-modern-calendar-datepicker";
import "react-modern-calendar-datepicker/lib/DatePicker.css";
import { formatInputValue } from '../../../../shared/helper';
import { TagsInput } from "react-tag-input-component";
import { NEW_URL } from '../../../../constants/applicationConstants';
import { MyAdCard } from '../../../common/myAdCard/myAdCard';
import adsbg from '../../../../assets/Instituteppvimages/ads_bg.svg';
import cross from '../../../../assets/images/cross.svg';
import uploadfile from '../../../../assets/Instituteppvimages/uploadfile.svg';
import { useTranslation } from "react-i18next";
import './myAds.model.css';
import { Pagination } from '../../../common/pagination/uiPagination';

const MyAdsJsx = (props) => {
    const {
        blogFormData,
        formError,
        onDateChange,
        onInputChangeHandler,
        onRadioButtonClick,
        isExpires,
        showAddModal,
        onModalClose,
        onSubmitButtonclick,
        onTouchHandler,
        formSubmitButtonDisable,
        categoryData,
        subCategoryData,
        onMetaTagChange,
        onEditButtonClick,
        onDeleteButtonClick,
        adData,
        onScrollerChange,
        profileImagePreviewUrl,
        imgURL,
        editMode,
        onFileChange, page, onPaginationButtonClick
    } = props;

    const itemsPerPage = 10

    const [currentPage, setCurrentPage] = useState(1);
    const currentItems = adData?.slice(
        (page - 1) * itemsPerPage,
        page * itemsPerPage
    );

    const limitData = JSON.parse(sessionStorage.getItem('loginData'));

    let flowName = localStorage.getItem('flowName');

    const { t } = useTranslation();
    return (
        <>

            <div className="ad-main">

                {flowName === 'expert' && <div>
                    <div className="dashboardrightpannerinnerleft">
                        <h2>{t("instituteAds.TITLE")}</h2>
                        <h5>{t("instituteAds.ADDISCRIPTION")}</h5>
                    </div>
                </div>}

                {!editMode &&
                    <div className="craete-ad-containt">

                        <div className="craete-ad-containt-left">
                            <p className="subtitle"> {t("instituteAds.SUBTITLE")} </p>
                            <p className="subtitle">{t("instituteAds.CREATEDADS")}</p>
                            <div className="userguide">
                                <div className="dropdown-toggle userdropdown-toggle" id="dropdownMenu2" data-bs-toggle="dropdown" aria-expanded="false">
                                    <img className="guide-img" src={planpricingtooltip} alt={' '} /> <span className="guide-text">Help Guide</span> </div>

                                <ul className="dropdown-menu userdropdown-menu" aria-labelledby="dropdownMenu2">
                                    <li><a className="dropdown-item" type="button">{t("instituteAds.HOWTOCREATE")}</a>
                                        <ul className="dropdown-menu dropdown-submenu">
                                            <li><a className="dropdown-item" type="button">{t("instituteAds.UPLOADADIMAGE")}</a></li>
                                            <li><a className="dropdown-item" type="button">{t("instituteAds.ENTERADCAPTION")}</a></li>
                                            <li><a className="dropdown-item" type="button">{t("instituteAds.EXPIREDATE")}
                                                <ol>
                                                    <li>{t("instituteAds.SELECTEXPIRE")}</li>
                                                    <li>{t("instituteAds.ENTERVALIDDATE")}</li>
                                                </ol></a></li>
                                            <li><a className="dropdown-item" type="button">{t("instituteAds.NEVEREXPIRE")}</a></li>
                                            <li><a className="dropdown-item" type="button">{t("instituteAds.CLICKSUBMIT")}</a></li>
                                            <li><a className="dropdown-item" type="button">{t("instituteAds.DELETEIT")}</a></li>

                                        </ul>
                                    </li>
                                    <li><a className="dropdown-item" type="button">{t("instituteAds.EDITAD")}</a>
                                        <ul className="dropdown-menu dropdown-submenu">
                                            <li><a className="dropdown-item"  >{t("instituteAds.CLICKEDIT")}</a></li>

                                            <li><a className="dropdown-item"  >{t("instituteAds.POPUPINFO")}
                                                <ol>
                                                    <li>{t("instituteAds.UPLOADNEWAD")} </li>
                                                    <li>{t("instituteAds.EDITADCAPTION")} </li>
                                                </ol> </a></li>
                                            <li><a className="dropdown-item"  >{t("instituteAds.CLICKSUBMITBUTTON")} </a></li>
                                            <li><a className="dropdown-item"  >{t("instituteAds.DISCARDCHANGES")} </a></li>

                                        </ul>
                                    </li>
                                    <li><a className="dropdown-item" type="button">{t("instituteAds.DELETEEXISTINGAD")}</a>
                                        <ul className="dropdown-menu dropdown-submenu">
                                            <li><a className="dropdown-item"  >{t("instituteAds.DELETEOPTION")} </a></li>


                                        </ul>
                                    </li>
                                    <li><a className="dropdown-item" type="button">{t("instituteAds.ADDMOEAD")} </a>
                                        <ul className="dropdown-menu dropdown-submenu">
                                            <li><a className="dropdown-item"  >{t("instituteAds.ADLIMIT")}</a></li>

                                        </ul>
                                    </li>
                                    <li><a className="dropdown-item" type="button">{t("instituteAds.ADSTATUS")}</a>
                                        <ul className="dropdown-menu dropdown-submenu">
                                            <li><a className="dropdown-item"  >{t("instituteAds.SWITCH")} </a></li>
                                            <li><a className="dropdown-item"  >{t("instituteAds.ACTIVETOGGLE")}</a></li>
                                            <li><a className="dropdown-item"  >{t("instituteAds.INACTIVETOGGLE")} </a></li>


                                        </ul>
                                    </li>
                                </ul>
                            </div>
                        </div>
                        <button type="button" disabled={adData?.length === limitData?.totalAdsLimit} className="col-4 craete-ads-button" onClick={() => onModalClose(true)}>{t("MYADS.CREATEAD")}</button>


                    </div>
                }
                {showAddModal && <div className="opmodal" style={{ marginTop: '30px' }}>

                    <div className="craete-ads">
                        <div>
                            <button type="button" className="close-ads" onClick={() => onModalClose(false)}><img src={cross} /></button>
                        </div>

                        <div className="row input-form-div">
                            <div className="col-lg-12"> <p className="ads-title">{editMode ? t("MYADS.EDITADD") : "Create New Ad"}</p></div>
                            <div className="col-lg-12"> <div className="samll">{editMode ? t("MYADS.EDITADDTAG") : "How to Create an Ad?"}</div></div>

                            <div className="col-lg-6 col-sm-6">
                                <div className="adsbg">
                                    <img className="adsimg" src={adsbg} alt={''} />
                                    <div className="outerlogo">
                                    </div>
                                    <div className="uploadexpert mb-4">
                                        <div className="d-flex border-div">
                                            <div className="upload-image"> <img src={uploadfile} alt={''} /></div>
                                            <div className="brows-part">
                                                {!(editMode || profileImagePreviewUrl) ? <><p className="uplod-title"><b>{t("instituteAds.UPLOADLOGO")}</b> <span className="divider-text">Or</span></p>

                                                </>
                                                    :
                                                    <div className="uploadlogo chnageupload">
                                                        <div className="brows-part">
                                                            <input type="file" id="upload_file" name="" onChange={onFileChange} />
                                                            <label for="upload_file">{"Change Image"}</label>
                                                        </div>
                                                    </div>
                                                }
                                                {
                                                    profileImagePreviewUrl ? <img
                                                        src={profileImagePreviewUrl}
                                                        className="select-img"
                                                        alt="edit profile"
                                                    /> : <div className="uploadlogo">
                                                        <input type="file" id="upload_filelogo" name="" onChange={onFileChange} />
                                                        <label for="upload_filelogo">

                                                            {editMode ?
                                                                <img
                                                                    src={`${NEW_URL}/${imgURL}`}
                                                                    className="select-img"
                                                                    alt="edit profile"
                                                                />
                                                                : <p>Browse</p>
                                                            }

                                                        </label>
                                                    </div>}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="input-container form-group radioform">
                                    <label className="radiocontrol">
                                        {t("instituteAds.EXPIRES")}<input type="radio" name="radio" onClick={() => onRadioButtonClick(true)} checked={blogFormData.isExpires} /></label>

                                    <label className="radiocontrol">
                                        {t("instituteAds.NEVEREXPIRES")}<input type="radio" name="radio" onClick={() => onRadioButtonClick(false)} checked={!blogFormData.isExpires} /></label>
                                </div>
                                {isExpires && <div className="row">
                                    <div className="col-lg-6">
                                        <DatePicker3 className="input-caption" name="startDate"
                                            onChange={(date) => { onDateChange('validFrom', date) }}
                                            inputPlaceholder="dd/mm/yyyy"
                                            shouldHighlightWeekends
                                            wrapperClassName="input-caption"
                                            maximumDate={{
                                                year: new Date().getFullYear(),
                                                month: new Date().getMonth() + 1,
                                                day: new Date().getDate()
                                            }}
                                            renderInput={({ ref }) => (
                                                <><label className="placeholder">Valid From*</label>
                                                    <input
                                                        ref={ref}
                                                        placeholder=""
                                                        type="text"
                                                        required
                                                        className="input-caption-border"
                                                        value={formatInputValue(blogFormData?.validFrom)}
                                                    />
                                                    <div className="cut"></div>

                                                    <span className="input-icon-add"><img src={datecalender} alt={''} /></span>
                                                </>
                                            )}
                                            formatInputText={() => formatInputValue(blogFormData?.validFrom)}

                                        />
                                    </div>
                                    <div className="col-lg-6">
                                        <DatePicker3 className="input-caption" name="startDate"
                                            onChange={(date) => { onDateChange('validTill', date) }}
                                            inputPlaceholder="dd/mm/yyyy"
                                            shouldHighlightWeekends
                                            wrapperClassName="input-caption"
                                            minimumDate={{
                                                year: new Date().getFullYear(),
                                                month: new Date().getMonth() + 1,
                                                day: new Date().getDate() + 1
                                            }}
                                            renderInput={({ ref }) => (
                                                <><label className="placeholder">Valid Till*</label>
                                                    <input
                                                        ref={ref}
                                                        placeholder=""
                                                        type="text"
                                                        required
                                                        className="input-caption-border"
                                                        value={formatInputValue(blogFormData?.validTill)}
                                                    />
                                                    <div className="cut"></div>
                                                    <span className="input-icon-add"><img src={datecalender} alt={''} /></span>
                                                </>
                                            )}
                                            formatInputText={() => formatInputValue(blogFormData?.validTill)}
                                        />
                                    </div>
                                </div>
                                }
                            </div>
                            <div className="col-lg-6 col-sm-6">
                                <div className="textarea-caption">
                                    <label className="placeholder mt-0">Caption*</label>
                                    <textarea
                                        onBlur={onTouchHandler}
                                        className={"textarea-caption"}
                                        placeholder={t("instituteAds.ADDCAPTION")}
                                        value={blogFormData.caption}
                                        name="caption"
                                        onChange={onInputChangeHandler}
                                        maxLength={500}></textarea>
                                    <span className=" ">
                                        {formError['caption'] &&
                                            (formError['caption'] === "req"
                                                ? 'Caption is required'
                                                : '')}
                                    </span>
                                </div>
                                <div className="mt-2 mb-2 input-container form-group">
                                    <label className="placeholder">{t("instituteAds.CATEGORY")}</label>
                                    <select className="input form-control form-select" onChange={onInputChangeHandler} name="category">
                                        <option value="">{t("instituteAds.SELECTCATEGORY")}</option>
                                        {categoryData?.map((item) => {
                                            return (
                                                <>
                                                    <option value={item.institutionCategoryId} selected={item.institutionCategoryId === blogFormData.category}>{item.name}</option>
                                                </>
                                            )
                                        })}
                                    </select>
                                </div>
                                <div className="mb-2 input-container form-group">
                                    <label className="placeholder">{t("instituteAds.SUBCAT")}</label>
                                    <select className="input form-control form-select" onChange={onInputChangeHandler} name="subCategory">
                                        <option value="">{t("instituteAds.SELECTSUBCAT")}</option>
                                        {subCategoryData?.map((item, i) => {
                                            return (
                                                <>
                                                    <option value={item.institutionCategoryId} selected={item.institutionCategoryId === blogFormData.subCategory}>{item.name}</option>
                                                </>
                                            )
                                        })}
                                    </select>

                                </div>
                            </div>

                            <div className="col-lg-12 ">
                                <div className="input-caption">
                                    <label className="placeholder">{t("instituteAds.METATAG")}<span className="plantooltip custooltip" ><img src={planpricingtooltip} alt={''} className="tooltipim" />
                                        <div className="tooltipcontent">{t("instituteAds.METATAGTOOLTIP")}</div>
                                    </span></label>
                                    <div className="input-caption-border" id="tagInput" titleLbl={'Product Meta Tag'}
                                        onFocus={() => {
                                            let elem = document.getElementById("tagInput");
                                            elem.classList.add("my-classfocus");
                                        }}
                                        onBlur={() => {
                                            let elem = document.getElementById("tagInput");
                                            elem.classList.remove("my-classfocus");
                                        }}

                                    >

                                        <TagsInput
                                            value={blogFormData.metaTag}
                                            onChange={onMetaTagChange}
                                            label="xxxxxxxxx"
                                            name="metaTags"
                                            placeHolder=" "
                                            classNames={{ tag: 'tag-clsxxxx', input: 'input-caption' }}
                                        />
                                    </div>


                                </div>
                                <div className="input-caption mb-4">
                                    <label className="placeholder">{t("instituteAds.EXTERNALLINK")}*</label>
                                    <input
                                        onBlur={onTouchHandler}
                                        className={'input form-control '}
                                        placeholder=" "
                                        value={blogFormData.link}
                                        name="link"
                                        onChange={onInputChangeHandler}
                                        maxLength={100}
                                    />

                                    <span className="text-danger">
                                        {formError['link'] &&
                                            (formError['link'] === "req"
                                                ? 'Link is Required'
                                                : 'Enter a valid link')}
                                    </span>
                                </div>
                                <div className="mb-4 input-container form-group ">
                                    <button type="button" disabled={formSubmitButtonDisable} className="showallourbesttoo" onClick={() => onSubmitButtonclick()}>{t("instituteAds.SAVE")}</button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                }
                <div  >
                    <p className="adstitle">{t("instituteAds.TITLE")} </p>
                    <p className="adsubtitle">{t("instituteAds.VIIBLEAD")} </p>
                    <p className="adsubtitle">{t("instituteAds.BELOWAD")}  {t("instituteAds.YOUCANUPLOAD")} {limitData?.totalAdsLimit} {t("instituteAds.BELOWAD1")}</p>
                </div>

                <div className="adsblog">
                    <div className="row">
                        {
                            adData?.length > 0 ?
                            currentItems?.map(data => {
                                    return < MyAdCard
                                        data={data}
                                        onEditButtonClick={() => onEditButtonClick(data)}
                                        onDeleteButtonClick={() => onDeleteButtonClick(data)}
                                        onScrollerChange={() => onScrollerChange(data)}
                                    />
                                }) :
                                <div className="dropdefault text-center mb-5">
                                    <div className="dropdefaultimage">
                                        <img src={dropbox} alt={''} />
                                    </div>
                                    <div className="dropdefaulttext">
                                        <h5>{t("instituteAds.LIVEAD")} </h5>
                                        <h5>{t("instituteAds.CREATENEWAD")}</h5>
                                    </div>

                                </div>
                        }
                         {currentItems?.length > 0 && <section className="newpagination" > <Pagination
              currentPage={currentPage}
              page={page}
              itemsPerPage={itemsPerPage}
              allOffersData={adData}
              currentItems={currentItems}
              onPaginationButtonClick={onPaginationButtonClick}

            /></section>}
                    </div>
                </div>
            </div>

        </>
    );
};

export default MyAdsJsx;
