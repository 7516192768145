import React from 'react'

import downarrow from "../../../../assets/images/downarrow.svg";
import cross from "../../../../assets/images/cross.svg";
import Select from 'react-select';
import DatePicker3 from "react-modern-calendar-datepicker";
import { formatInputValue } from '../../../../shared/helper';
import datecalender from "../../../../assets/images/datecalender.svg"
import { getProperDateMonth } from "../../../../shared/helper.js";

const InstituteDetailsJsx = (props) => {
    const { onInputChangeHandler, sizeList, handleChange, onDateChange,
        onInstituteButtonClick, editInstitite, changeHideState, formError, onTouchHandler
    } = props;
    const isRequired = true
    const information = props?.wholeData
    const totalInformation = props.responseData
    const options =  [
        {
            "label": "Select",
            "value": null
        },
        {
            "label": "0-25 experts (small)",
            "value": "0-25 experts (small)"
        },
        {
            "label": "25-200 experts (medium)",
            "value": "25-200 experts (medium)"
        },
        {
            "label": "200 & above experts(large)",
            "value": "200 & above experts(large)"
        }
    ]
    return (
        <>
            {!editInstitite ? <div className="myaccountsection institutedetails">
                <div className="myaccountsectionheading">
                    <h3 className="h3_style">Institutional Details</h3>
                    <div className="mybtn">
                        <button type="button" className="myadbtn" onClick={() => changeHideState(true)}><svg width="15" height="15" viewBox="0 0 15 15" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path fill-rule="evenodd" clip-rule="evenodd" d="M12.4597 5.8743L13.8341 4.49994L13.8341 4.49992C14.0686 4.26543 14.1858 4.14818 14.2703 4.03599C14.8063 3.32377 14.8063 2.34278 14.2703 1.63057C14.1858 1.51837 14.0686 1.40112 13.8341 1.16663L13.8341 1.16661L13.8341 1.16658C13.5996 0.932098 13.4823 0.814852 13.3701 0.730405C12.6579 0.194357 11.6769 0.194357 10.9647 0.730405C10.8525 0.814852 10.7353 0.932097 10.5008 1.16658L10.5007 1.16661L9.10713 2.56023C9.90404 3.94047 11.0608 5.08825 12.4597 5.8743ZM7.65253 4.01482L2.19046 9.4769C1.7654 9.90196 1.55287 10.1145 1.41314 10.3756C1.2734 10.6367 1.21446 10.9314 1.09657 11.5209L0.647836 13.7645C0.581314 14.0971 0.548053 14.2634 0.642661 14.358C0.737269 14.4526 0.903573 14.4194 1.23618 14.3529H1.23618L3.47983 13.9041C4.06928 13.7862 4.36401 13.7273 4.62511 13.5876C4.8862 13.4478 5.09873 13.2353 5.52379 12.8102L11.0014 7.33267C9.65352 6.48191 8.51135 5.34748 7.65253 4.01482Z" fill="white" />
                        </svg></button>

                    </div>
                </div>
                <div className="form-field">
                    <div className="disgrid grid-2">
                        <div className="form-fieldinner">
                            <label>Name of the Institution *</label>
                            <div className="fieldans">{totalInformation?.name}</div>
                        </div>
                        <div className="form-fieldinner">
                            <label>Legal Name of Institution *</label>
                            <div className="fieldans">{totalInformation?.legalName}</div>
                        </div>
                        <div className="form-fieldinner">
                            <label>Institution Login Email * </label>
                            <div className="fieldans">{information?.institutionEmail}</div>
                        </div>
                        {/* <div className="form-fieldinner">
                        <label>confirm Institution Login Email * </label>
                        <div className="fieldans">{information?.confirmInstitutionEmail}</div>
                    </div> */}

                        <div className="form-fieldinner">
                            <label>Choose Your Segment *  </label>
                            <div className="fieldans">{totalInformation?.institutionSegment?.name}</div>
                        </div>
                        <div className="form-fieldinner Category-fieldinner">
                            <label>Category *  </label>

                            {information?.tempCat?.map((item, i) => (
                                <>
                                    <div className="fieldans"><span>{item.institutionCategory.name}</span></div>
                                </>
                            ))}



                        </div>


                        <div className="form-fieldinner">
                            <label>Size of Institution *</label>
                            <div className="fieldans">{totalInformation?.instituteSize?.size}</div>
                        </div>
                        <div className="form-fieldinner">
                            <label>Date of Incorporation *</label>
                            {/* <div className="fieldans">{moment(information.dateOfIncorporation).format("DD-MM-YYYY")}</div> */}
                            <div className="fieldans">{getProperDateMonth(information?.dateOfIncorporation?.day)}-{getProperDateMonth(information?.dateOfIncorporation?.month)}-{information?.dateOfIncorporation?.year}</div>
                        </div>
                        {/* <div className="form-fieldinner">
                            <label>Institution Login Email * </label>
                            <div className="fieldans">{information?.institutionEmail}</div>
                        </div> */}

                    </div>
                </div>
            </div> : null}
            {editInstitite ? <div className="myaccountsection institutedetails martop60">
                <div className="myaccountsectionheading">
                    <h3 className="h3_style">Institutional Details</h3>
                    <div className="mybtn">
                        <button type="button" className="myadbtn" onClick={() => changeHideState(false)}>
                            <img src={cross} alt='close'/>
                        </button>
                    </div>
                </div>
                <div className="form-field">
                    <div className="disgrid grid-2">
                        <div className="form-fieldinner">
                            <div className="input-container form-group">
                                <label className="placeholder">Name of the Institution *</label>
                                <input type="text"
                                    // className="input form-control"

                                    className={
                                        isRequired && formError["institutionName"]
                                            ? "input form-control  is-invalid"
                                            : "input form-control"
                                    }
                                    maxLength={30}
                                    name="institutionName" placeholder=" " value={information.institutionName} onChange={onInputChangeHandler} onBlur={onTouchHandler} />

                                <span className="text-danger">
                                    {isRequired &&
                                        formError["institutionName"] &&
                                        (formError["institutionName"] === "req"
                                            ? "institutionName is required!"
                                            : "Please enter a proper institutionName!")}
                                </span>
                            </div>
                        </div>
                        <div className="form-fieldinner">
                            <div className="input-container form-group">
                                <label className="placeholder">Legal Name of Institution *</label>
                                <input type="text" readOnly
                                    className={
                                        isRequired && formError["legalInstitutionName"]
                                            ? "input form-control  is-invalid"
                                            : "input form-control"
                                    }
                                    name="legalInstitutionName" placeholder=" " value={information.legalInstitutionName} onChange={onInputChangeHandler} onBlur={onTouchHandler} />

                                <span className="text-danger">
                                    {isRequired &&
                                        formError["legalInstitutionName"] &&
                                        (formError["legalInstitutionName"] === "req"
                                            ? "legalInstitutionName is required!"
                                            : "Please enter a proper legalInstitutionName!")}
                                </span>
                            </div>
                        </div>
                        <div className="form-fieldinner">
                            <div className="input-container form-group">
                                <label className="placeholder">Institution Login Email *  </label>
                                <input type="text" className={
                                    isRequired && formError["institutionEmail"]
                                        ? "input form-control  is-invalid"
                                        : "input form-control"
                                } name="institutionEmail" disabled placeholder=" " value={information.institutionEmail} onChange={onInputChangeHandler} onBlur={onTouchHandler} />

                            </div>
                        </div>
                        {/* 
                        <div className="form-fieldinner">
                            <div className="input-container form-group">
                                <label className="placeholder">Confirm Institution Login Email *  </label>
                                <input type="text" className={
                                    isRequired && formError["confirmInstitutionEmail"]
                                        ? "input form-control  is-invalid"
                                        : "input form-control"
                                } name="confirmInstitutionEmail" disabled placeholder=" " value={information.confirmInstitutionEmail} onChange={onInputChangeHandler} onBlur={onTouchHandler} />
                                
                            </div>
                        </div> */}


                        {/* <div className="disgrid grid-2">
                        <div className="form-fieldinner">
                            <div className="input-container form-group">
                                <Select className={`selectbox`}
                                    closeMenuOnSelect={true}
                                    placeholder={" "}
                                    name="segment"
                                    isDisabled
                                    options={segmentList}
                                    onChange={(e) => handleChange(e, 'segment')}
                                    value={segmentList.filter(ite => ite.value === information.segment)}
                                />
                                <div className="placeholder">segment *</div>
                            </div>
                        </div>
                        <div className="form-fieldinner">
                            <div className="input-container selectboxmain form-group">
                                <Select className="mb-4 form-container form-group multiselect"
                                    closeMenuOnSelect={false}
                                    isMulti
                                    isDisabled
                                    options={categoryList}
                                    onChange={(e) => handleChange(e, 'category')}
                                    value={information.category}
                                    components={{
                                        Input: (props) => {
                                            return <>
                                                <label className={`multiplaceholer ${information.category?.length > 0 ? 'active' : ''}`}>{'Category * '}</label>

                                                <components.Input {...props} className={"mb-4 input-container form-group"} >
                                                    {props.children}
                                                </components.Input>
                                            </>
                                        },
                                    }}

                                />
                            </div>
                        </div>


                    </div> */}

                        <div className="bluetab1">
                            <div className="form-fieldinner">
                                <div className="input-container form-group">
                                    <label className="placeholder">Segment *   </label>
                                    <div className="selctbox form-control" disabled>
                                        <div className="fieldans">{totalInformation?.institutionSegment?.name}</div>
                                        <div className="downarrow"><img src={downarrow} alt={''} /></div></div>
                                </div>
                            </div>
                        </div>
                        <div className="bluetab1 bluetab2">
                            <div className="form-fieldinner">
                                <div className="input-container form-group">
                                    <label className="placeholder">Category *  </label>
                                    <div className="selctbox form-control" disabled>
                                        {information?.tempCat?.map((item, i) => (
                                            <>
                                                <div className="fieldans"><span>{item.institutionCategory.name}</span></div>
                                            </>
                                        ))}
                                        <div className="downarrow"><img src={downarrow} alt={''} /></div>
                                    </div>

                                </div>
                            </div>
                        </div>


                        <div className="form-fieldinner">
                            <div className="input-container form-group">
                                <div className="placeholder">Size of Institution *</div>
                                {/* <input type="text" className="input form-control" name="sizeOfInstitution" placeholder=" " value={totalInformation?.instituteSize?.size} onChange={onInputChangeHandler} />
                                <label className="placeholder">Size of Institution * </label> */}
                                <Select className={`selectbox`}
                                    closeMenuOnSelect={true}
                                    placeholder={" "}
                                    name="sizeOfInstitution"
                                    options={sizeList}
                                    onChange={(e) => handleChange(e, 'sizeOfInstitution')}
                                    value={sizeList.filter(ite => ite.value === information.sizeOfInstitution)}

                                />

                            </div>
                        </div>
                        <div className="form-fieldinner">
                            <div className="input-container form-group">
                                {/* <input type="text" className="input form-control" name="dateOfIncorporation" placeholder=" " value={moment(information.dateOfIncorporation).format("DD-MM-YYYY")} onChange={onInputChangeHandler} />
                                <label className="placeholder">Date of Incorporation * </label> */}
                                <label className="placeholder">Date of Incorporation * </label>
                                <DatePicker3 className="form-control" name="startDate"
                                    onChange={(date) => { onDateChange(date) }}
                                    inputPlaceholder="dd/mm/yyyy"
                                    shouldHighlightWeekends
                                    maximumDate={{
                                        year: new Date().getFullYear(),
                                        month: new Date().getMonth() + 1,
                                        day: new Date().getDate()
                                    }}
                                    wrapperClassName="input-container form-group"
                                    renderInput={({ ref }) => (
                                        <>
                                            <input
                                                ref={ref}
                                                placeholder=" "
                                                type="text"
                                                required
                                                disabled
                                                className="input form-control"
                                                value={formatInputValue(information.dateOfIncorporation)}
                                            />
                                            <div className="cut"></div>

                                            <span className="dateim"><img src={datecalender} alt={''} /></span>
                                        </>
                                    )}
                                    formatInputText={() => formatInputValue(information.dateOfIncorporation)}
                                />
                            </div>
                        </div>

                    </div>
                    <button type="button" disabled={!information?.institutionName?.length || !information?.sizeOfInstitution } className="ctaonebutton widthhalf" onClick={() => onInstituteButtonClick()}>Save</button>
                </div>
            </div> : null}
        </>
    );
};

export default InstituteDetailsJsx;
