import React, { useState } from 'react'
import { Trans, useTranslation } from 'react-i18next';
import { QuestionAnswerCardJSX } from '../../../common/questionAnswerCard/questionAnswerCard';
import moment from 'moment';
import Sign_out_squre from "../../../../assets/images/Sign_out_squre.svg";
import "../expertDashboard.css";
import { Footer } from '../../../common/footer/footer';
import Sidebar from "../sidebar/sidebar.js";
import dropbox from "../../../../assets/images/dropbox.svg";
import { Pagination } from '../../../common/pagination/uiPagination.js';

const ExpertDashboardHomeJsx = (props) => {
  const { t } = useTranslation();
  const {
    giveAnswer, questionData, sendAnswer, onInputChangeHandler, openModal, isOpenModal, givenAnswer, answerData
    , onPaginationButtonClick, page } = props;
  const itemsPerPage = 10

  const [currentPage, setCurrentPage] = useState(1);

  const currentItems = questionData?.slice(
    (page - 1) * itemsPerPage,
    page * itemsPerPage
  );

  return (
    <>
      <section className="main_customer_dashboard">
        <section className="entiredashboard maincustomerdash expert-dashboard">
          <div className="container-fluid">
            <div className="row">
              <div className="col-lg-4 col-sm-4">
                <div className="dashboardleftpanel">
                  <div className="dashboardlinkpop">
                    <Sidebar />
                  </div>
                </div>
              </div>
              <div className="col-lg-8 col-sm-8">
                <div className="dashboardrightpanel">
                  <div className="dashboardrightpanelinner">
                    <div className="dashboardrightpannerinnerleft">
                      <h2 className="h2_style font-weight-600">{t("expertDashboard.MyAccount")}</h2>
                      <h5 className="h5_style">{t("expertDashboard.MYACCOUNT_TEXT")} </h5>

                    </div>
                    <div className="dashboardrightpannerinnerright">
                      <div className="signout">
                        <a href="#logout">
                          <img src={Sign_out_squre} alt={''} /><span className="title">Logout</span>
                        </a>
                      </div>
                      <div id="logout" className="modal-window">
                        <div>
                          <a title="Close" className="modal-close">Close</a>
                          <div className="modal-header">

                          </div><div className="modal-body mb-4">
                            <div className="modal-body-inner">
                              <h2 className="h2_style text-center">Are you sure you want to
Logout ?</h2>
                            </div>
                          </div>
                          <div className="modal-footer">
                            <button type="button" className="ctaonebutton"> Save</button>
                            <button type="button" className="ctatwobutton"> Save</button>
                          </div>
                        </div>
                      </div>
                    </div>

                    <div className="questioninner">
                      <h2>{t("expertDashboard.PRIORITY_TEXT_HEADING")}</h2>
                      <h5 className="h5_style">{t("expertDashboard.PRIORITY_TEXT")}</h5>

                      {currentItems && currentItems.length ? <>
                        {
                          currentItems?.map((data, index) => {
                            const globalIndex = (page - 1) * itemsPerPage + index; // Global index with page offset

                            return <div>
                              <QuestionAnswerCardJSX
                                singleData={data}
                                imgurl={data?.askExpertQuestionUserResponse?.icon}
                                listexpertimageUrl={data?.expert?.imageUrl}
                                listexpertfirstName={data?.expert?.firstName}
                                listexpertlastName={data?.expert?.lastName}
                                listfirstName={data?.expert?.firstName}
                                listlastName={data?.expert?.firstName}
                                listaskExpertQuestionUserResponsequestion={data.askExpertQuestionUserResponse.question}
                                listanswer={data.answer}
                                dataOpen={data.isOpen}
                                questiondate={moment(data?.createdAt).format('MMM DD , YYYY')}
                                giveAnswer={giveAnswer}
                                index={globalIndex}
                                questionData={currentItems}
                                sendAnswer={sendAnswer}
                                onInputChangeHandler={onInputChangeHandler}
                                answerData={answerData}
                                openModal={openModal}
                                isOpenModal={isOpenModal}
                                givenAnswer={givenAnswer}
                                listaskExpertCategoryone={data.askExpertQuestionUserResponse.parentInstitutionCategory}
                                listaskExpertCategory={data.askExpertQuestionUserResponse.parentInstitutionCategory?.replace(/\s+/g, '')}
                              />
                            </div>

                            //                         <div className="questioninnerin mt-3 mb-3">
                            //                           <h5 className="h5_style">{data.askExpertQuestionUserResponse.question} </h5>

                            //                           <div className="d-flex questioninnerinbottom">
                            //                             <div className="expertquestiondetails">
                            //                               <div className="expertuser"><img src={`https://ui-avatars.com/api/?name=${data?.askExpertQuestionUserResponse?.fullName}&rounded=true`} alt={''} /></div>
                            //                               <div className="questionid"><span>Question ID -</span> {data.askExpertQuestionUserResponseId}<div className="questiondate">{moment(data?.createdAt).format('MM-DD-YYYY')}</div></div>
                            //                             </div>
                            //                             <div className="expertquestiondetailscategory"><span>{data.askExpertQuestionUserResponse.parentInstitutionCategory}</span></div>
                            //                             {!data.isOpen ? <button type="button" className="ctaonebutton" onClick={() => giveAnswer(index, true)}>Answer Now</button> : null}

                            //                             {/*  */}
                            //                           </div>
                            //                           {/* answer part */}
                            //                           {data.isOpen ?
                            //                             <>
                            //                               <div className="mb-4 mt-3 input-container form-group">
                            //                                 <textarea className="input form-control textareaform"
                            //                                   onChange={onInputChangeHandler}
                            //                                   name={`answer${data.askExpertQuestionUserResponseId}`}
                            //                                   className="input form-control textareaform"
                            //                                   placeholder=" "
                            //                                   maxLength={750}
                            //                                 />
                            //                                 <label className="placeholder">Answer Now </label>
                            //                               </div>
                            //                               <div className="d-flex questioninnerinbottom">
                            //                                 <div className="expertquestiondetails">
                            //                                   <div>
                            //                                     <h5 className="h5_style"><a href="#questionmodal" onClick={() => openModal(true)}>Click here</a> to see a sample response.</h5>
                            //                                     {isOpenModal &&
                            //                                       <>
                            //                                         <div className="modalspecial qmodal" style={{}}>
                            //                                           <div>
                            //                                             <div className="mybtn">
                            //                                               <button className="myadbtn" onClick={() => openModal(false)}><svg version="1.0" xmlns="http://www.w3.org/2000/svg"
                            //                                                 width="64.000000pt" height="64.000000pt" viewBox="0 0 64.000000 64.000000"
                            //                                                 preserveAspectRatio="xMidYMid meet">

                            //                                                 <g transform="translate(0.000000,64.000000) scale(0.100000,-0.100000)"
                            //                                                   fill="#ffffff" stroke="none">
                            //                                                   <path d="M156 484 c-9 -8 -16 -20 -16 -26 0 -5 28 -39 62 -74 l61 -64 -61 -64
                            // c-34 -35 -62 -69 -62 -74 0 -14 28 -42 42 -42 5 0 39 28 74 62 l64 61 64 -61
                            // c35 -34 69 -62 74 -62 14 0 42 28 42 42 0 5 -28 39 -62 74 l-61 64 61 64 c34
                            // 35 62 69 62 74 0 14 -28 42 -42 42 -5 0 -39 -28 -74 -62 l-64 -61 -64 61 c-35
                            // 34 -69 62 -74 62 -6 0 -18 -7 -26 -16z"/>
                            //                                                 </g>
                            //                                               </svg></button>

                            //                                             </div>
                            //                                             <Trans i18nKey="expertDashboard.QNA_SUGGESTED_TEXT" ></Trans><br/>
                            //                                             <ul>
                            //                                               <li><Trans i18nKey="expertDashboard.QNA_SUGGESTED_TEXT_LISTONE" ></Trans></li>  
                            //                                               <li><Trans i18nKey="expertDashboard.QNA_SUGGESTED_TEXT_LISTTWO" ></Trans></li> 
                            //                                               <li><Trans i18nKey="expertDashboard.QNA_SUGGESTED_TEXT_LISTTHREE" ></Trans></li> 
                            //                                               <li><Trans i18nKey="expertDashboard.QNA_SUGGESTED_TEXT_LISTFOUR" ></Trans></li> 
                            //                                               <li><Trans i18nKey="expertDashboard.QNA_SUGGESTED_TEXT_LISTFIVE" ></Trans></li> 
                            //                                               <li><Trans i18nKey="expertDashboard.QNA_SUGGESTED_TEXT_LISTSIX" ></Trans></li> 
                            //                                             </ul>
                            //                                            <p> <small><Trans i18nKey="expertDashboard.QNA_DISCLAIMER" ></Trans></small></p>

                            //                                           </div>
                            //                                         </div>

                            //                                       </>
                            //                                     }
                            //                                     <h5 className="h5_style">This is how your answer appears in customer’s inbox.</h5>
                            //                                   </div>
                            //                                 </div>
                            //                                 <div className="qbtn">
                            //                                   <button type="button" disabled={!givenAnswer} className="ctaonebutton" onClick={() => sendAnswer(data.askExpertQuestionUserResponseId, data.allocateAskExpertQuestionResponseId)}>Send</button>
                            //                                   <button type="button" className="ctaonebutton" onClick={() => giveAnswer(index, false)}>Cancel</button>
                            //                                 </div>
                            //                               </div></> : null

                            //                           }

                            //                           {/*  */}
                            //                         </div>
                          })
                        }
                        <section className="newpagination"> <Pagination
                          currentPage={currentPage}
                          page={page}
                          allOffersData={questionData}
                          currentItems={currentItems}
                          onPaginationButtonClick={onPaginationButtonClick}
                          itemsPerPage={itemsPerPage}
                        /></section>
                      </> :
                        <div className="dropdefault text-center">
                          <div className="dropdefaultimage">
                            <img src={dropbox} alt={''} />
                          </div>
                          <div className="dropdefaulttext">
                            <h5>You don't have any Questions. </h5>
                          </div>
                        </div>
                      }

                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section><Footer />
      </section>


    </>

  );
};

export default ExpertDashboardHomeJsx;
